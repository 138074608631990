// alterado - revisado

import ApiService from './ApiService'

import AuthService from './AuthService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    mensagemErroErro
} from '../business-components/Toastr/Toastr'



export class LogradouroService extends ApiService {

    constructor() {
        super(HARDCODE.apiLogradouros)
    }

    buscarByCepMandatorio(variaveisGlobais, idUsuario, cep) {
        let params = `idUsuario=${idUsuario}`

        params = `${params}&cep=${cep}`

        return this.get(variaveisGlobais, `${HARDCODE.apiLogradourosBuscarByCepMandatorio}/?${params}`, false)
    }
}



export const buscarEndereco = async ( variaveisGlobais, item, naoExibirErro ) => {

    const logradouroService = new LogradouroService()

    try {
        const response = await logradouroService.buscarByCepMandatorio(variaveisGlobais,
            AuthService.obterUsuarioLogado(variaveisGlobais), item.cep
        )

        item.endereco = response.data.descricaoSemNumero.toUpperCase()
        item.bairro = response.data.descricaoBairro.toUpperCase()
        item.cidade = response.data.descricaoCidade.toUpperCase()
        item.uf = response.data.uf.toUpperCase()

    } catch (erro) {

        if (!naoExibirErro) {
        
            mensagemErroErro(erro)
        }
    }
}