import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    dataAtual,
    formataDataAmericanaSemTraco,
    somaDiasData
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    calculaTaxaRetornoLiquida,
} from '../estudo-form/EstudoServiceMatematicaFinanceira'



export const processaCalculoTaxas = (values) => {

    calculaCenariosTaxasTaxaRetornoNominalAnual(values)

    calculaCenariosTaxasTaxaRetornoAnualReal(values)

    calculaCenariosTaxasTaxaRetornoAnualFgtsReal(values)

    if (!values.diferenciarTaxasAposAposentadoria) {

        values.taxaCDIAnualAposAposentadoria = values.taxaCDIAnual
        values.retornoCDIAposAposentadoria = values.retornoCDI
        values.taxaJurosAnualAposAposentadoria = values.taxaJurosAnual
        values.taxaInflacaoAnualAposAposentadoria = values.taxaInflacaoAnual
        values.taxaRetornoAnualRealAposAposentadoria = values.taxaRetornoAnualReal
        values.taxaJurosAnualFgtsAposAposentadoria = values.taxaJurosAnualFgts
        values.taxaRetornoAnualFgtsRealAposAposentadoria = values.taxaRetornoAnualFgtsReal
    
    } else {
    
        calculaCenariosTaxasTaxaRetornoNominalAnualAposAposentadoria(values)

        calculaCenariosTaxasTaxaRetornoAnualRealAposAposentadoria(values)

        calculaCenariosTaxasTaxaRetornoAnualFgtsRealAposAposentadoria(values)
    }
}

export const processaValoresDefaultIniciaisCenariosTaxas = ( values ) => {
    
    // O valor default dos campos taxaJurosAnual, taxaInflacaoAnual, taxaJurosAnualFgts foram implementados
    // em ClienteService - inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient
    
    // O valor default do campo dataSimulacaoEstudo foi implementado em ClienteForm - useSingleton

    if (!values.exibirEvolucaoAporteMensalInflacao) {

        values.exibirEvolucaoAporteMensalInflacao = false
    }

    if (!values.exibirEvolucaoRetiradaMensalInflacao) {

        values.exibirEvolucaoRetiradaMensalInflacao = false
    }
    
    if (!values.utilizarPercRentabilidadeAnualRecursosFinanceiros) {

        values.utilizarPercRentabilidadeAnualRecursosFinanceiros = false
    }

    if (!values.diferenciarTaxasAposAposentadoria) {

        values.diferenciarTaxasAposAposentadoria = false
    }

    processaCalculoTaxas(values)
}



export const calculaCenariosTaxasTaxaRetornoNominalAnual = ( values ) => {

    if (values.taxaCDIAnual || values.retornoCDI) {

        values.taxaJurosAnual = 
            round(values.taxaCDIAnual * values.retornoCDI / 100, 2)
    }
}

export const calculaCenariosTaxasTaxaRetornoAnualReal = ( values ) => {

    values.taxaRetornoAnualReal =
        calculaTaxaRetornoLiquida(values.taxaJurosAnual, values.taxaInflacaoAnual, values.tipoMomentoTributacaoIR,
            values.percIR, values.tempoMinimoEntreAportesResgatesCalculoIRAnos
        )
}

export const calculaCenariosTaxasTaxaRetornoAnualFgtsReal = ( values ) => {

    values.taxaRetornoAnualFgtsReal =
        calculaTaxaRetornoLiquida(values.taxaJurosAnualFgts, values.taxaInflacaoAnual, null, null, null)
}



export const calculaCenariosTaxasTaxaRetornoNominalAnualAposAposentadoria = ( values ) => {

    if (values.taxaCDIAnualAposAposentadoria || values.retornoCDIAposAposentadoria) {

        values.taxaJurosAnualAposAposentadoria = 
            round(values.taxaCDIAnualAposAposentadoria * values.retornoCDIAposAposentadoria / 100, 2)
    }
}

export const calculaCenariosTaxasTaxaRetornoAnualRealAposAposentadoria = ( values ) => {

    values.taxaRetornoAnualRealAposAposentadoria =
        calculaTaxaRetornoLiquida(values.taxaJurosAnualAposAposentadoria, values.taxaInflacaoAnualAposAposentadoria,
            values.tipoMomentoTributacaoIR, values.percIR, values.tempoMinimoEntreAportesResgatesCalculoIRAnos)
}

export const calculaCenariosTaxasTaxaRetornoAnualFgtsRealAposAposentadoria = ( values ) => {
    
    values.taxaRetornoAnualFgtsRealAposAposentadoria =
        calculaTaxaRetornoLiquida(values.taxaJurosAnualFgtsAposAposentadoria, values.taxaInflacaoAnualAposAposentadoria,
            null, null, null
        )
}



export const urlBoletimFocus = () => {

    let dataAtualAux = dataAtual()

    const diaSemana = dataAtualAux.getDay()

    const hora = dataAtualAux.getHours()

    if (diaSemana === 0 || (diaSemana === 1 && hora < 10) || diaSemana === 7) {

        let ajuste

        if (diaSemana === 0) {
           
            ajuste = 2

        } else {

            if (diaSemana === 1) {
           
                ajuste = 3

            } else {

                ajuste = 1
            }
        }

        somaDiasData(dataAtualAux, - 7 - ajuste)

    } else {

        somaDiasData(dataAtualAux, - diaSemana - 2)

    }

    const dataBoletimFocus = formataDataAmericanaSemTraco(dataAtualAux)

    return HARDCODE.urlBoletimFocus.replace("$1$", dataBoletimFocus)
}