import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import NUMMIN from '../../NumMinMax/NumMin'

import NUMMAX from '../../NumMinMax/NumMax'

import TAMMAX from '../../TamMax/TamMax'

import {
    formataArrayData,
    formataAnoMesInteiroComBarra,
    formataMesDiaInteiroDiaMesComBarra,
} from '../../../business-components/Date/Date'

import {
    formataValor,
    formataPerc,
    formataCpf,
    formataCelular,
} from '../../../business-components/Formata/Formata'

import {
    GridContainer,
    GridItem12,
} from '../../../business-components/Grid/Grid'

import {
    IconButtonMaisInformacoes,
} from '../../../business-components/IconButton/IconButton'

import {
    CustomizedDialogsPopup,
} from '../../../business-components/Dialog/CustomizeDialogs'

import {
    pesquisaList,
    pesquisaDescricaoList,
    incluiCampoList,
} from '../ListService'

import sexoList from '../sexoList'

import parentescoList from '../parentescoList'

import tipoPeriodicidadeList from '../tipoPeriodicidadeList'

import tipoFormaPagtoPremioList from '../tipoFormaPagtoPremioList'

import seguradoraList from './seguradoraList'

import statusPropostaList from './statusPropostaList'

import simNaoList from '../simNaoList'



export const camposPropostaList = ( list, columnsExibe, grafico, filtroList, filtroLists ) => {

    let camposListAux = []

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoUsuario',
        label: LABEL.usuario,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 10,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoGestorLider',
        label: LABEL.gestorLider,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 15,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nome',
        label: LABEL.nomeSegurado,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.nome,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 20,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cpf',
        label: LABEL.cpf,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCpf,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCpf(list[dataIndex].cpf))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 30,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nascimento',
        label: LABEL.nascimento,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].nascimento))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 40,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'mesDiaNascimento',
        label: LABEL.diaMesNascimento,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeDiaMes,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataMesDiaInteiroDiaMesComBarra(list[dataIndex].mesDiaNascimento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 50,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'sexo',
        label: LABEL.sexo,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.sexoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(sexoList(true), list[dataIndex].sexo))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 60,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'parentesco',
        label: LABEL.parentesco,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.parentescoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(parentescoList(true), list[dataIndex].parentesco))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 70,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'profissao',
        label: LABEL.profissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.profissaoLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 80,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'email',
        label: LABEL.email,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeEmail,
        tamMax: HARDCODE.email,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 90,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'celular',
        label: LABEL.celular,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCelular,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCelular(list[dataIndex].celular))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 100,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cidadeEnderecoResidencial',
        label: LABEL.cidade,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.cidadeEnderecoResidencialLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 110,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'statusProposta',
        label: LABEL.statusProposta,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.statusPropostaLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (
                    <GridContainer
                        style={
                            {backgroundColor: pesquisaList(statusPropostaList, list[dataIndex].statusProposta).cor}
                        }
                    >
                        <GridItem12
                            className='font-bold mt-2 mb-2'
                        >
                            {pesquisaDescricaoList(statusPropostaList, list[dataIndex].statusProposta)}
                        </GridItem12>
                    </GridContainer>
                )
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 120,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorPremioAnualizado',
        label: LABEL.valorPremioAnualizado,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorPremioAnualizado, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 130,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoPeriodicidadePagtoPremio',
        label: LABEL.periodicidadePagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoPeriodicidadePagtoPremioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoPeriodicidadeList(false, true), list[dataIndex].tipoPeriodicidadePagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 140,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'seguradora',
        label: LABEL.seguradora,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.seguradoraLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(seguradoraList(), list[dataIndex].seguradora))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 150,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroProposta',
        label: LABEL.numeroProposta,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroProposta,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 160,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroCotacao',
        label: LABEL.numeroCotacao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroCotacao,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 170,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataAssinatura',
        label: LABEL.dataAssinatura,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataAssinatura))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 180,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesAssinatura',
        label: LABEL.anoMesAssinatura,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesAssinatura))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 190,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataUltimaAlteracao',
        label: LABEL.dataUltimaAlteracao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataUltimaAlteracao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 200,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesUltimaAlteracao',
        label: LABEL.anoMesUltimaAlteracao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesUltimaAlteracao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 210,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoFormaPagtoPremio',
        label: LABEL.formaPagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoFormaPagtoPremioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoFormaPagtoPremioList, list[dataIndex].tipoFormaPagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 220,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'observacao',
        label: LABEL.observacao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.observacao,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    {list[dataIndex].observacao && (
                        <>
                            {list[dataIndex].observacao.substring(0, 20) + '...'}
                            <CustomizedDialogsPopup
                                maxWidth="lg"
                                popupTitle={LABEL.observacao}
                                tooltipTitle={LABEL.observacao}
                                PopupButton={IconButtonMaisInformacoes}
                                popupComponent={
                                    <span>
                                        {list[dataIndex].observacao}
                                    </span>
                                }
                            />
                        </>
                    )}
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 230,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroConta',
        label: LABEL.numeroConta,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroConta,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 240,
    })
	
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoCorretorSeguros',
        label: LABEL.idCorretorSeguros,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 250,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'codigoAgenteCorretorSeguros',
        label: LABEL.codigoAgenteCorretorSeguros,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.codigoAgente,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 260,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percDivisaoComissao',
        label: LABEL.percDivisaoComissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataPerc(list[dataIndex].percDivisaoComissao, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 270,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoCorretorSeguros2',
        label: LABEL.idCorretorSeguros2,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 280,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'codigoAgenteCorretorSeguros2',
        label: LABEL.codigoAgenteCorretorSeguros2,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.codigoAgente,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 290,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percDivisaoComissao2',
        label: LABEL.percDivisaoComissao2,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataPerc(list[dataIndex].percDivisaoComissao2, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 300,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'codigoAgenteAssessorInvestimentos',
        label: LABEL.codigoAgenteAssessorInvestimentos,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.codigoAgente,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 310,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'mapaPropostas',
        label: LABEL.mapaPropostas,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: simNaoList,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(simNaoList, list[dataIndex].mapaPropostas))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 320,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'pendentesMesAtual2Meses',
        label: LABEL.pendentesMesAtual2Meses,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: simNaoList,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(simNaoList, list[dataIndex].pendentesMesAtual2Meses))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 330,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'pagasMesAtual',
        label: LABEL.pagasMesAtual,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: simNaoList,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(simNaoList, list[dataIndex].pagasMesAtual))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 340,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'links',
        label: LABEL.links,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    <CustomizedDialogsPopup
                        maxWidth="lg"
                        popupTitle={LABEL.extratoProposta}
                        tooltipTitle={LABEL.extratoProposta}
                        PopupButton={IconButtonDocument}
                        popupComponent={
                            <PopupComponent/>
                        }
                    />
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 220,
    })
    */

    return camposListAux
}

export default camposPropostaList