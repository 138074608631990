import ApoliceCobrancaService from '../../../business-api/ApoliceCobrancaService'

import ClienteService from '../../../business-api/ClienteService'

import VwClienteAcessoApoliceCobrancaService from '../../../business-api/VwClienteAcessoApoliceCobrancaService'

import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    formataAnoMesInteiroComBarra,
} from '../../../business-components/Date/Date'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
    pesquisaIndexList,
} from '../../../business-rules/List/ListService'

import statusParcelaList from '../../../business-rules/List/SeguroVida/statusParcelaList'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'



export const getApoliceCobrancaList = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAcessoApoliceCobrancaService = new VwClienteAcessoApoliceCobrancaService()

    try {
        const response = await vwClienteAcessoApoliceCobrancaService.buscarApoliceCobrancaList(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getSumApoliceCobranca = async ( variaveisGlobais, campoAgrupamento, filtroList ) => {

    const vwClienteAcessoApoliceCobrancaService = new VwClienteAcessoApoliceCobrancaService()

    try {
        const response = await vwClienteAcessoApoliceCobrancaService.sumApoliceCobranca(variaveisGlobais, campoAgrupamento, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}



export const putApoliceCobrancaList = async ( variaveisGlobais, idArquivo, seguradora, jsonApoliceCobrancaList) => {

    const apoliceCobrancaService = new ApoliceCobrancaService()

    try {

        const response = await apoliceCobrancaService.atualizarSalvarDeletarApoliceCobrancaList(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idArquivo, seguradora, JSON.stringify(jsonApoliceCobrancaList))

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)

        return 0
    }
}



export const trataCobrancasList = (list, ordenarPorAgrupamento, agrupamentoAnoMes, tipoValorAgrupamento, tipoList, primeiros) => {

    const graficoLista = {
        grafico: {
            data: [],
            data2: [],
            categories: [],
        },
        lista: [],
        listaClick: [],
    }

    let agrupamento

    let indexLista

    let qtdeParcelasTotal
    let valorAPagarTotal

    list?.forEach((linhaList, indexList) => {

        agrupamento =
            tipoList
                ?
                    pesquisaList(tipoList, linhaList.agrupamento).descricao
                :
                    linhaList.agrupamento

        indexLista = pesquisaIndexList(graficoLista.lista, agrupamento)

        const linhaStatusParcela = pesquisaList(statusParcelaList, linhaList.statusParcela)

        if (indexLista === null) {

            graficoLista.lista.push(
                {
                    id: agrupamento,
                    filtro: linhaList.agrupamento,
                    qtdeParcelas: linhaList.qtdeParcelas,
                    qtdeParcelasAberta:
                        linhaStatusParcela.aberta === 'S'
                            ?
                                linhaList.qtdeParcelas
                            :
                                0,
                    qtdeParcelasCancelada:
                        linhaStatusParcela.cancelada === 'S'
                            ?
                                linhaList.qtdeParcelas
                            :
                                0,
                    qtdeParcelasPaga:
                        linhaStatusParcela.paga === 'S'
                            ?
                                linhaList.qtdeParcelas
                            :
                                0,
                    valorAPagar: linhaList.valorAPagar,
                    valorAPagarAberta:
                        linhaStatusParcela.aberta === 'S'
                            ?
                                linhaList.valorAPagar
                            :
                                0,
                    valorAPagarCancelada:
                        linhaStatusParcela.cancelada === 'S'
                            ?
                                linhaList.valorAPagar
                            :
                                0,
                    valorAPagarPago:
                        linhaStatusParcela.paga === 'S'
                            ?
                                linhaList.valorAPagar
                            :
                                0,
                    valorPago: linhaList.valorPago,
                }
            )

            indexLista = graficoLista.lista.length - 1

        } else {

            graficoLista.lista[indexLista].qtdeParcelas += linhaList.qtdeParcelas
            graficoLista.lista[indexLista].qtdeParcelasAberta +=
                linhaStatusParcela.aberta === 'S'
                    ?
                        linhaList.qtdeParcelas
                    :
                        0
            graficoLista.lista[indexLista].qtdeParcelasCancelada +=
                linhaStatusParcela.cancelada === 'S'
                    ?
                        linhaList.qtdeParcelas
                    :
                        0
            graficoLista.lista[indexLista].qtdeParcelasPaga +=
                linhaStatusParcela.paga === 'S'
                    ?
                        linhaList.qtdeParcelas
                    :
                        0
            graficoLista.lista[indexLista].valorAPagar += linhaList.valorAPagar
            graficoLista.lista[indexLista].valorAPagarAberta +=
                linhaStatusParcela.aberta === 'S'
                    ?
                        linhaList.valorAPagar
                    :
                        0
            graficoLista.lista[indexLista].valorAPagarCancelada +=
                linhaStatusParcela.cancelada === 'S'
                    ?
                        linhaList.valorAPagar
                    :
                        0
            graficoLista.lista[indexLista].valorAPagarPaga +=
                linhaStatusParcela.paga === 'S'
                    ?
                        linhaList.valorAPagar
                    :
                        0
            graficoLista.lista[indexLista].valorPago += linhaList.valorPago
        }

        qtdeParcelasTotal += graficoLista.lista[indexLista].qtdeParcelas
        valorAPagarTotal += graficoLista.lista[indexLista].valorAPagar

        graficoLista.lista[indexLista].ordem =
            ordenarPorAgrupamento
                ?
                    agrupamento
                :
                    graficoLista.lista[indexLista].valorAPagar * -1
    })

    graficoLista.lista = sortAsc(graficoLista.lista)

    let indexGrafico = 0

    const tamGraficoLista = graficoLista.lista.length

    graficoLista.lista?.forEach((linhaLista, indexLista) => {

        //if (linhaLista.id !== LABEL.naoInformado || tipoValorAgrupamento !== HARDCODE.tipoValorAgrupamentoApoliceCobrancaPaga) {
        if (linhaLista.id !== null) {

            if (
                (primeiros && indexGrafico < HARDCODE.qtdeBarrasGraficoDashboard) ||
                (!primeiros && indexLista > (tamGraficoLista - 1 - HARDCODE.qtdeBarrasGraficoDashboard))

        ) {
                
                graficoLista.grafico.data.push(
                    tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaAberta
                        ?
                            linhaLista.valorAPagarAberta
                        :
                            tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaCancelada
                                ?
                                    linhaLista.valorAPagarCancelada
                                :
                                    tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaPaga
                                        ?
                                            linhaLista.valorPago
                                        :
                                            linhaLista.valorAPagar
                )

                graficoLista.grafico.data2.push(
                    tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaAberta
                        ?
                            linhaLista.qtdeParcelasAberta
                        :
                            tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaCancelada
                                ?
                                    linhaLista.qtdeParcelasCancelada
                                :
                                    tipoValorAgrupamento === HARDCODE.tipoValorAgrupamentoApoliceCobrancaPaga
                                        ?
                                            linhaLista.qtdeParcelasPaga
                                        :
                                            linhaLista.qtdeParcelas
                )
                
                graficoLista.grafico.categories.push(
                    agrupamentoAnoMes ? formataAnoMesInteiroComBarra(linhaLista.id) : linhaLista.id
                )

                graficoLista.listaClick.push(
                    linhaLista
                )
            }

            indexGrafico++
        }

        graficoLista.lista[indexLista].percQtdeParcelasTotal =
            qtdeParcelasTotal
                ?
                    round(graficoLista.lista[indexLista].qtdeParcelas / qtdeParcelasTotal * 100, 2)
                :
                    ''

        graficoLista.lista[indexLista].percQtdeParcelasAberta =
            graficoLista.lista[indexLista].qtdeParcelas
                ?
                    round(graficoLista.lista[indexLista].qtdeParcelasAberta / graficoLista.lista[indexLista].qtdeParcelas * 100, 2)
                :
                    ''
        graficoLista.lista[indexLista].percQtdeParcelasCancelada =
            graficoLista.lista[indexLista].qtdeParcelas
                ?
                    round(graficoLista.lista[indexLista].qtdeParcelasCancelada / graficoLista.lista[indexLista].qtdeParcelas * 100, 2)
                :
                    ''

        graficoLista.lista[indexLista].percQtdeParcelasPaga =
            graficoLista.lista[indexLista].qtdeParcelas
                ?
                    round(graficoLista.lista[indexLista].qtdeParcelasPaga / graficoLista.lista[indexLista].qtdeParcelas * 100, 2)
                :
                    ''

        graficoLista.lista[indexLista].percValorAPagarTotal =
            valorAPagarTotal
                ?
                    round(graficoLista.lista[indexLista].valorAPagar / valorAPagarTotal * 100, 2)
                :
                    ''

        graficoLista.lista[indexLista].percValorAPagarAberta =
            graficoLista.lista[indexLista].valorAPagar
                ?
                    round(graficoLista.lista[indexLista].valorAPagarAberta / graficoLista.lista[indexLista].valorAPagar * 100, 2)
                :

        graficoLista.lista[indexLista].percValorAPagarCancelada =
            graficoLista.lista[indexLista].valorAPagar
                ?
                    round(graficoLista.lista[indexLista].valorAPagarCancelada / graficoLista.lista[indexLista].valorAPagar * 100, 2)
                :
                    ''

        graficoLista.lista[indexLista].percValorAPagarPaga =
            graficoLista.lista[indexLista].valorAPagar
                ?
                    round(graficoLista.lista[indexLista].valorAPagarPaga / graficoLista.lista[indexLista].valorAPagar * 100, 2)
                :
                    ''
    })


    return graficoLista
}