import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import MESSAGE from '../../../business-const/Message/Message'

import {
    ano,
    mes,
    dataAnoMesDia,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    sortAscId
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import tipoPrazoRecebtoRendaCoberturaSeguroVidaList from '../../../business-rules/List/tipoPrazoRecebtoRendaCoberturaSeguroVidaList'

import {
    pesquisaFamiliar,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    calculaValorPerc,
    calculaValorMensal,
    calculaIdadeVaiAposentar,
} from '../cliente-form/ClienteService'

import {
    dadosCompletosLinhaReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    incrementaContSeguroVidaApolice,
    obtemContSeguroVidaApolice,
    dadosCompletosLinhaSeguroVidaApolice,
    seguroVidaApoliceAtiva,
} from './SeguroVidaServiceSeguroVidaApolice'

import {
    obtemContSeguroVidaCobertura,
    incluiSeguroVidaCoberturaDefault,
    dadosCompletosLinhaSeguroVidaCobertura,
    seguroVidaCoberturaAtiva,
} from './SeguroVidaServiceSeguroVidaCobertura'



export const incluiSeguroVidaReceitaMensalCobertura = ( values, idSeguroVidaApolice, idTipoCoberturaSeguroVida, valorCS, perc,
    dataTerminoCobertura ) => {

    const linhaTipoCoberturaSeguroVida = pesquisaList(tipoCoberturaSeguroVidaList, idTipoCoberturaSeguroVida)

    values.seguroVidaCobertura.push(
        {
            id: obtemContSeguroVidaCobertura(values),
            idSeguroVidaApolice: idSeguroVidaApolice,
            grupoCoberturaSeguroVida: linhaTipoCoberturaSeguroVida.grupoCoberturaSeguroVida,
            tipoCoberturaSeguroVida: idTipoCoberturaSeguroVida,
            valorCS: calculaValorPerc(valorCS, perc),
            valorPremio: 0,
            dataTerminoCobertura: dataTerminoCobertura,
        }
    )
}

export const incluiSeguroVidaReceitaMensalApolice = ( values, linhaReceitaMensal, idFamiliar, perc, dataTerminoCobertura ) => {

    const idSeguroVidaApolice = obtemContSeguroVidaApolice(values)

    values.seguroVidaApolice.push(
        {
            id: idSeguroVidaApolice,
            numeroApolice: idSeguroVidaApolice,
            familiar: idFamiliar,
            tipoPeriodicidadePagtoPremio: HARDCODE.idTipoPeriodicidadeMensal,
            seguradora: linhaReceitaMensal.seguradora,
            seguradoraOutras: linhaReceitaMensal.seguradoraOutras,
            observacao: MESSAGE().seguroVidaIncluidoReceitaMensal +
                (linhaReceitaMensal.observacao ? " (" + linhaReceitaMensal.observacao + ")" : ''),
            idReceitaMensal: linhaReceitaMensal.id,
        }
    )

    if (round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorte, 2) > 0) {
        incluiSeguroVidaReceitaMensalCobertura(values, idSeguroVidaApolice, 
            HARDCODE.idTipoCoberturaSeguroVidaEmGrupoMorte,
                linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorte, perc, dataTerminoCobertura)
    }

    if (round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorteAcidental, 2) > 0) {
        incluiSeguroVidaReceitaMensalCobertura(values, idSeguroVidaApolice, 
            HARDCODE.idTipoCoberturaSeguroVidaEmGrupoMorteAcidental,
                linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorteAcidental, perc, dataTerminoCobertura)
    }
    
    if (round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezPorAcidente, 2) > 0) {
        incluiSeguroVidaReceitaMensalCobertura(values, idSeguroVidaApolice, 
            HARDCODE.idTipoCoberturaSeguroVidaEmGrupoInvalidezPorAcidente,
                linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezPorAcidente, perc, dataTerminoCobertura)
    }

    if (round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca, 2) > 0) {
        incluiSeguroVidaReceitaMensalCobertura(values, idSeguroVidaApolice, 
            HARDCODE.idTipoCoberturaSeguroVidaEmGrupoInvalidezFuncionalPorDoenca,
                linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca, perc, dataTerminoCobertura)
    }

    if (round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoFuneral, 2) > 0) {
        incluiSeguroVidaReceitaMensalCobertura(values, idSeguroVidaApolice, 
            HARDCODE.idTipoCoberturaSeguroVidaEmGrupoFuneral,
                linhaReceitaMensal.valorCSSeguroVidaEmGrupoFuneral, perc, dataTerminoCobertura)
    }
}

export const incluiSeguroVidaSeguroVidaEmGrupoFilhao = (values, linhaReceitaMensal, dataTerminoCobertura) => {
    values.familiares?.forEach( (linhaFamiliares, index) => {
        if (linhaFamiliares.parentescoFamiliar === HARDCODE.idParentescoFilhao) {
            incluiSeguroVidaReceitaMensalApolice(values, linhaReceitaMensal, linhaFamiliares.familiar,
                linhaReceitaMensal.percExtensivelFilhao, dataTerminoCobertura)
        }
    })
}

export const incluiSeguroVidaSeguroVidaEmGrupoReceitaMensal = ( values, linhaReceitaMensal ) => {

    let dataTerminoCobertura = null
    let idOutro = ''
    let linhaFamiliaresList = ''

    if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarCliente) {
        dataTerminoCobertura =
            dataAnoMesDia(ano(values.dataSimulacaoEstudo) + values.idadeVaiAposentarCliente - values.idadeDataSimulacaoCliente,
                mes(values.dataSimulacaoEstudo), 1)
        idOutro = HARDCODE.idFamiliarConjuge
    } else {
        if (linhaReceitaMensal.familiar === HARDCODE.idFamiliarConjuge) {
            dataTerminoCobertura =
                dataAnoMesDia(ano(values.dataSimulacaoEstudo) + values.idadeVaiAposentarConjuge - values.idadeDataSimulacaoConjuge,
                    mes(values.dataSimulacaoEstudo), 1)
            idOutro = HARDCODE.idFamiliarCliente
        } else {
            linhaFamiliaresList = pesquisaList(familiaresList, linhaReceitaMensal.familiar)

            if (
                linhaFamiliaresList.parentesco === HARDCODE.idParentescoFilhao
            ) {
                dataTerminoCobertura =
                    dataAnoMesDia(ano(values.dataSimulacaoEstudo) + calculaIdadeVaiAposentar(linhaFamiliaresList.sexoFamiliar) -
                        linhaFamiliaresList.idadeFamilia, mes(values.dataSimulacaoEstudo), 1)
            }
        }
    }

    incluiSeguroVidaReceitaMensalApolice(values, linhaReceitaMensal, linhaReceitaMensal.familiar, 100, dataTerminoCobertura)

    if (linhaReceitaMensal.percExtensivelClienteConjuge > 0) {
        incluiSeguroVidaReceitaMensalApolice(values, linhaReceitaMensal, idOutro,
            linhaReceitaMensal.percExtensivelClienteConjuge, dataTerminoCobertura)
    }

    if (linhaReceitaMensal.percExtensivelFilhao > 0) {
        incluiSeguroVidaSeguroVidaEmGrupoFilhao(values, linhaReceitaMensal, dataTerminoCobertura)
    }
}

export const incluiSeguroVidaSeguroVidaEmGrupo = ( values ) => {

    if (values.processarIncluiSeguroVidaSeguroVidaEmGrupo) {

        if (values.seguroVidaApolice) {

            let indexApolice = values.seguroVidaApolice.length - 1

            while (indexApolice >= 0) {

                if (values.seguroVidaApolice[indexApolice].idReceitaMensal) {

                    if (values.seguroVidaCobertura) {

                        let indexCobertura = values.seguroVidaCobertura.length - 1
            
                        while (indexCobertura >= 0) {
            
                            if (
                                    values.seguroVidaCobertura[indexCobertura].idSeguroVidaApolice === 
                                        values.seguroVidaApolice[indexApolice].id
                                ) {
                                    
                                values.seguroVidaCobertura.splice(indexCobertura, 1)
                            }
            
                            indexCobertura -= 1
                        }
                    }
                    
                    values.seguroVidaApolice.splice(indexApolice, 1)
                }

                indexApolice -= 1
            }
        }

        values.receitaMensal?.forEach( (linhaReceitaMensal, index) => {

            if (
                    dadosCompletosLinhaReceitaMensal(linhaReceitaMensal) && 
                    (
                        round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorte, 2) > 0 ||
                        round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoMorteAcidental, 2) > 0 ||
                        round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezPorAcidente, 2) > 0 ||
                        round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoInvalidezFuncionalPorDoenca, 2) > 0 ||
                        round(linhaReceitaMensal.valorCSSeguroVidaEmGrupoFuneral, 2) > 0
                    )
            ) {
                
                if (!values.seguroVidaApolice) {
                    values.seguroVidaApolice = []
                }

                if (!values.seguroVidaCobertura) {
                    values.seguroVidaCobertura = []
                }

                incluiSeguroVidaSeguroVidaEmGrupoReceitaMensal(values, linhaReceitaMensal)
            }
        })

        if (values.seguroVidaApolice) {
            values.seguroVidaApolice = sortAscId(values.seguroVidaApolice)
        }

        calculaTotaisSeguroVida(values)

        values.processarIncluiSeguroVidaSeguroVidaEmGrupo = false
    }
}



export const processaRegistrosDefaultIniciaisSeguroVida = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisSeguroVida) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVida = []
        }

        if (!values.seguroVidaApolice) {

            values.seguroVidaApolice = []
        }
        
        if (!values.seguroVidaCobertura) {

            values.seguroVidaCobertura = []
        }
        
        if (
                !pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisSeguroVida, familiar).id
        ) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVida.push(
                {
                    id: familiar
                }
            )

            incrementaContSeguroVidaApolice(values)

            values.seguroVidaApolice.push(
                {
                    id: values.contSeguroVidaApolice,
                    familiar: familiar,
                }
            )

            incluiSeguroVidaCoberturaDefault (values, values.contSeguroVidaApolice)
        }
    }
}



export const calculaAnosRecebtoRendaVitalicio = ( dataSimulacaoEstudo, idade, idadeExpectativaVida ) => {

    return idadeExpectativaVida - idade + ((12 - mes(dataSimulacaoEstudo) + 1) / 12)
}

export const calculaTempoCoberturaSeguroVida = ( values, linhaSeguroVidaCobertura, linhaFamiliaresList ) => {

    let tempoCoberturaSeguroVida = ''

    let linhaTipoCoberturaSeguroVidaList = pesquisaList(tipoCoberturaSeguroVidaList,
        linhaSeguroVidaCobertura.tipoCoberturaSeguroVida)

    if (linhaTipoCoberturaSeguroVidaList.tipoValorInformado === "Diaria") {
        tempoCoberturaSeguroVida = HARDCODE.diasDiariaCoberturaSeguroVida
    } else {
        //if (linhaTipoCoberturaSeguroVidaList.tipoValorInformado === "Renda") {
        if (
                linhaTipoCoberturaSeguroVidaList.tipoValorInformado === "Renda" &&
                (
                    linhaTipoCoberturaSeguroVidaList.informaTipoPrazoRecebtoRenda === "S" ||
                    linhaTipoCoberturaSeguroVidaList.informaTipoPrazoRecebtoRenda === "V"
                )
        ) {
            
            let linhaTipoPrazoRecebtoRendaCoberturaSeguroVidaList = 
                pesquisaList(tipoPrazoRecebtoRendaCoberturaSeguroVidaList((
                    linhaTipoCoberturaSeguroVidaList.informaTipoPrazoRecebtoRenda)),
                        linhaSeguroVidaCobertura.tipoPrazoRecebtoRenda)

            let anosRecebtoRenda = linhaTipoPrazoRecebtoRendaCoberturaSeguroVidaList.anosRecebtoRenda

            if (anosRecebtoRenda === HARDCODE.anosRecebtoRendaVitalicio) {
                if (linhaFamiliaresList.id === HARDCODE.idFamiliarCliente) {
                    anosRecebtoRenda = 
                        calculaAnosRecebtoRendaVitalicio(values.dataSimulacaoEstudo, values.idadeDataSimulacaoCliente,
                            values.idadeExpectativaVidaCliente)
                } else {
                    if (linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge) {
                        anosRecebtoRenda = 
                            calculaAnosRecebtoRendaVitalicio(values.dataSimulacaoEstudo, values.idadeDataSimulacaoConjuge,
                                values.idadeExpectativaVidaConjuge)
                    } else {
                        let linhaFamiliar = pesquisaFamiliar(values.familiares, linhaFamiliaresList.id)

                        anosRecebtoRenda = 
                            calculaAnosRecebtoRendaVitalicio(values.dataSimulacaoEstudo,
                                linhaFamiliar.idadeFamiliarDataSimulacao, linhaFamiliar.idadeExpectativaVidaFamiliar)
                    }
                }
            }

            tempoCoberturaSeguroVida = anosRecebtoRenda
        } else {
            if (linhaSeguroVidaCobertura.dataTerminoCobertura) {
                tempoCoberturaSeguroVida =
                    ano(linhaSeguroVidaCobertura.dataTerminoCobertura) - ano(values.dataSimulacaoEstudo) + 1
            }
        }
    }

    return tempoCoberturaSeguroVida
}

export const calculaValorAcumuladoCoberturaSeguroVida = ( linhaSeguroVidaCobertura, tempoCoberturaSeguroVida ) => {

    let valorAcumulado = ''

    let linhaTipoCoberturaSeguroVidaList =
        pesquisaList(tipoCoberturaSeguroVidaList, linhaSeguroVidaCobertura.tipoCoberturaSeguroVida)

    if (linhaTipoCoberturaSeguroVidaList.tipoValorInformado === "Diaria") {
        valorAcumulado = round(linhaSeguroVidaCobertura.valorCS * tempoCoberturaSeguroVida, 2)
    } else {
        if (linhaTipoCoberturaSeguroVidaList.tipoValorInformado === "Renda") {
            if (tempoCoberturaSeguroVida) {
                valorAcumulado = round(linhaSeguroVidaCobertura.valorCS * 12 * tempoCoberturaSeguroVida, 2)
            }
        } else {
            valorAcumulado = round(linhaSeguroVidaCobertura.valorCS, 2)
        }
    }

    return valorAcumulado
}

export const calculaTotaisSeguroVida = ( values ) => {

    let familiarSeguroVida = Number.parseInt(values.familiarSeguroVida)

    const indexStepsValorPatrimonio = HARDCODE.indexPatrimonioSeguroVida

    values.stepsValorPatrimonio[indexStepsValorPatrimonio] = 0
    values.stepsValor2Patrimonio[indexStepsValorPatrimonio] = 0

    const indexStepsValorAcompanhamentoSeguroVida = HARDCODE.indexAcompanhamentoSeguroVidaApolice

    values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0
    values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0

    let indexStepsValorSeguroVida = ''

    values.stepsValorSeguroVida?.forEach( (linhaStepsValorSeguroVida, index) => {
        values.stepsValorSeguroVida[index] = 0
        values.stepsValor2SeguroVida[index] = 0
    })

    values.totalSeguroVida = []

    if (!values.familiarSeguroVida) {

        values.familiarSeguroVida = HARDCODE.idFamiliarCliente
    }

    let valorAcumulado
    let valorPremio
    
    let valorTotal
    let valorPremioTotal

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        valorTotal = 0
        valorPremioTotal = 0

        values.seguroVidaApolice?.forEach( (linhaSeguroVidaApolice, indexSeguroVidaApolice) => {

            if (
                    linhaSeguroVidaApolice.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaApolice) &&
                    seguroVidaApoliceAtiva(linhaSeguroVidaApolice.statusApolice)
            ) {

                values.seguroVidaCobertura?.forEach( (linhaSeguroVidaCobertura, indexSeguroVidaCobertura) => {

                    if (
                            linhaSeguroVidaCobertura.idSeguroVidaApolice === linhaSeguroVidaApolice.id &&
                            dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCobertura, false) &&
                            seguroVidaCoberturaAtiva(linhaSeguroVidaCobertura.statusCobertura)
                    ) {

                        let tempoCoberturaSeguroVida =
                            calculaTempoCoberturaSeguroVida(values, linhaSeguroVidaCobertura, linhaFamiliaresList)

                        valorAcumulado =
                            calculaValorAcumuladoCoberturaSeguroVida(linhaSeguroVidaCobertura, tempoCoberturaSeguroVida)

                        valorPremio =
                            calculaValorMensal(linhaSeguroVidaCobertura.valorPremio,
                                linhaSeguroVidaApolice.tipoPeriodicidadePagtoPremio)

                        if (pesquisaList(tipoCoberturaSeguroVidaList,
                            linhaSeguroVidaCobertura.tipoCoberturaSeguroVida).cobreMorte === "S") {

                            valorTotal += valorAcumulado
                        }

                        valorPremioTotal += valorPremio
                        
                        if (linhaSeguroVidaApolice.familiar === familiarSeguroVida) {

                            indexStepsValorSeguroVida = linhaSeguroVidaCobertura.grupoCoberturaSeguroVida

                            values.stepsValorSeguroVida[indexStepsValorSeguroVida] += valorAcumulado
                            values.stepsValor2SeguroVida[indexStepsValorSeguroVida] +=valorPremio
                        }
                    }
                })
            }
        })

        if (
                valorTotal !== 0 ||
                valorPremioTotal !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {
    
            values.totalSeguroVida.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    title: LABEL.morteQualquerCausa,
                    valor: valorTotal,
                    title2: LABEL.valorPremioTotal,
                    valor2: valorPremioTotal,
                }
            )

            values.stepsValorPatrimonio[indexStepsValorPatrimonio] += valorTotal
            values.stepsValor2Patrimonio[indexStepsValorPatrimonio] += valorPremioTotal

            values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorTotal
            values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorPremioTotal
        }
    })
}



export const calculaValorCS = ( values, idFamiliar, idGrupoCoberturaSeguroVidaNULL, idTipoCoberturaSeguroVidaNULL) => {
    let valorCS = 0

    values.seguroVidaApolice?.forEach( (linhaSeguroVidaApolice, index) => {

        if (
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaApolice) &&
                seguroVidaApoliceAtiva(linhaSeguroVidaApolice.statusApolice) &&
                !pesquisaList(values.exclusaoFamiliares, linhaSeguroVidaApolice.familiar).id &&
                !pesquisaList(values.exclusaoDespesasGarantias, LABEL.seguroVida + '/' +
                    linhaSeguroVidaApolice.id).id &&
                linhaSeguroVidaApolice.familiar === idFamiliar
        ) {

            values.seguroVidaCobertura?.forEach( (linhaSeguroVidaCobertura, index) => {

                if (
                        linhaSeguroVidaCobertura.idSeguroVidaApolice === linhaSeguroVidaApolice.id &&
                        dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCobertura, false) &&
                        seguroVidaCoberturaAtiva(linhaSeguroVidaCobertura.statusCobertura) &&
                        (
                            linhaSeguroVidaCobertura.grupoCoberturaSeguroVida === idGrupoCoberturaSeguroVidaNULL ||
                            linhaSeguroVidaCobertura.tipoCoberturaSeguroVida === idTipoCoberturaSeguroVidaNULL
                        )
                ) {
                    valorCS += round(linhaSeguroVidaCobertura.valorCS, 2)
                }
            })
        }
    })

    return valorCS
}