// alterado - revisado

import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    primNome
} from '../../../business-components/String/String'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import familiaList from '../../../business-rules/List/familiaList'

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    dadosCompletosLinhaDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    dadosCompletosLinhaEducacao,
} from '../despesas-form/DespesasServiceEducacao'

import {
    dadosCompletosLinhaFinanciamento,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    dadosCompletosLinhaProjetoVidaSonho,
} from '../projetoVidaSonho-form/ProjetoVidaSonhoServiceProjetoVidaSonho'

import {
    dadosCompletosLinhaPatrimonioImobilizado,
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'

import {
    dadosCompletosLinhaReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    dadosCompletosLinhaRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'



export const incrementaContFamiliar = ( values ) => {
    if (!values.contFamiliar) {
        values.contFamiliar = HARDCODE.idFamiliarConjuge
    }
    values.contFamiliar = values.contFamiliar + 1
}



export const dadosCompletosFamiliar = ( linhaFamiliares ) => {
    let dadosCompletosFamiliarAux = false

    if (
            (linhaFamiliares.nomeFamiliar || linhaFamiliares.nomeCurtoFamiliar) &&
            (linhaFamiliares.idadeFamiliar === 0 || linhaFamiliares.idadeFamiliar) &&
            linhaFamiliares.sexoFamiliar
    ) {
        dadosCompletosFamiliarAux = true
    }

    return dadosCompletosFamiliarAux
}



export const dadosCompletosFamiliares = ( values ) => {
    let dadosCompletosFamiliaresAux = ''

    values.familiares?.forEach( (linhaFamiliares, index) => {
        if (!dadosCompletosFamiliar(linhaFamiliares)) {
            if (dadosCompletosFamiliaresAux) {
                dadosCompletosFamiliaresAux += ', '
            }

            dadosCompletosFamiliaresAux += LABEL.espacoAlertaCamposObrigatorios + "<b>" +
                linhaFamiliares.nomeFamiliar + "</b>"
        }
    })

    return dadosCompletosFamiliaresAux
}



export const pesquisaFamiliar = (familiares, familiar) => {

    let familiarEncontrado = {}

    familiares?.forEach( (linhaFamiliar, index) => {

        if (linhaFamiliar.familiar === familiar) {
            
            familiarEncontrado = linhaFamiliar
        }
    })

    return familiarEncontrado
}



export const possuiFamiliares = ( values ) => {

    let possuiFamiliaresAux = false

    values.familiares?.forEach( (linhaFamiliares, index) => {

        if (!pesquisaList(values.exclusaoFamiliares, linhaFamiliares.familiar).id) {
            possuiFamiliaresAux = true
        }
    })

    return possuiFamiliaresAux
}



export const possuiFilhao = ( values ) => {
    let possuiFilhaoAux = false

    values.familiares?.forEach( (linhaFamiliar, index) => {
        
        if (linhaFamiliar.parentescoFamiliar === HARDCODE.idParentescoFilhao) {

            possuiFilhaoAux = true
        }
    })

    return possuiFilhaoAux
}



export const idadeFamiliarDataSimulacao = ( values, id ) => {

    return pesquisaList(familiaList(values), id).idadeDataSimulacao
}



export const familiarPossuiDespesaReceita = ( values, id ) => {

    let possuiDespesaReceita = false

    if (
            values.diaDia &&
            values.diaDia.filter(linhaDiaDia => linhaDiaDia.familiar === id && dadosCompletosLinhaDiaDia(linhaDiaDia)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.educacao &&
            values.educacao.filter(linhaEducacao => linhaEducacao.familiar === id && dadosCompletosLinhaEducacao(linhaEducacao, false)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.financiamento &&
            values.financiamento.filter(linhaFinanciamento =>
                linhaFinanciamento.familiar === id && dadosCompletosLinhaFinanciamento(linhaFinanciamento)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.projetoVidaSonho &&
            values.projetoVidaSonho.filter(linhaProjetoVidaSonho =>
                linhaProjetoVidaSonho.familiar === id && dadosCompletosLinhaProjetoVidaSonho(values, linhaProjetoVidaSonho)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.patrimonioImobilizado &&
            values.patrimonioImobilizado.filter(linhaPatrimonioImobilizado => linhaPatrimonioImobilizado.familiar === id &&
                dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.receitaMensal &&
            values.receitaMensal.filter(linhaReceitaMensal => linhaReceitaMensal.familiar === id &&
                dadosCompletosLinhaReceitaMensal(linhaReceitaMensal)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.recursosFinanceiros &&
            values.recursosFinanceiros.filter(linhaRecursosFinanceiros => linhaRecursosFinanceiros.familiar === id &&
                dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros)).length > 0
    ) {

        possuiDespesaReceita = true
    }
    
    if (
            !possuiDespesaReceita &&
            values.seguroVidaCotacao &&
            values.seguroVidaCotacao.filter(linhaSeguroVidaCotacao => linhaSeguroVidaCotacao.familiar === id &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)).length > 0
    ) {

        possuiDespesaReceita = true
    }
    
    if (
            !possuiDespesaReceita &&
            values.seguroVidaProposta &&
            values.seguroVidaProposta.filter(linhaSeguroVidaProposta => linhaSeguroVidaProposta.familiar === id &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaProposta)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    if (
            !possuiDespesaReceita &&
            values.seguroVidaApolice &&
            values.seguroVidaApolice.filter(linhaSeguroVidaApolice => linhaSeguroVidaApolice.familiar === id &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaApolice)).length > 0
    ) {

        possuiDespesaReceita = true
    }

    return possuiDespesaReceita
}



export const identificaFamiliarSemCpf = (values, nome, nascimento, sexo) => {

    let familiar = ''

    familiaresList(values)?.forEach( (linhaFamiliar, indexFamiliar) => {

        if (
                !linhaFamiliar.cpf &&
                (
                    nome === linhaFamiliar.nome ||
                    (
                        primNome(nome) === primNome(linhaFamiliar.nome) &&
                        nascimento === linhaFamiliar.nascimento &&
                        sexo === linhaFamiliar.sexo
                    )
                )
        ) {

            familiar = linhaFamiliar.id
        }

    })

    return familiar
}



export const pesquisaIndexFamiliar = (values, familiar) => {

    let index = -1

    values.familiares?.forEach( (linhaFamiliar, indexFamiliar) => {

        if (linhaFamiliar.familiar === familiar) {

            index = indexFamiliar
        }
    })

    return index
}