import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    obtemTextoArray,
    indexCampoComecaTerminaComNumero,
} from '../../../business-components/Array/Array'

import {
    dataAtual,
    ano,
    mes,
    dia,
    dataAnoMesDia,
} from '../../../business-components/Date/Date'

import {
    espacoAntes,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaIndexList,
} from '../../../business-rules/List/ListService'

import {
    calculaValorAnual,
} from '../cliente-form/ClienteService'

import {
    trataTexto,
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    barraData,
    identificaTipoCoberturaSeguroVida,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoPDFAnterior,
    obtemDadoXLSX,
    obtemDadoPDFAvulso,
    indexPDFAvulso,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    numeroCotacaoAutomatico,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaPropostaMetLife = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    if (
            PDFTxt.indexOf(fimLinha + 'Doenças Graves e' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Doenças Graves e' + espaco, 
            fimLinha + 'Doenças Graves e Procedimentos Cirúrgicos' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos' + fimLinha, fimLinha)
    }

    if (
            PDFTxt.indexOf(fimLinha + 'Doenças Graves e' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos Premium' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Doenças Graves e' + espaco, 
            fimLinha + 'Doenças Graves e Procedimentos Cirúrgicos' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos Premium' + fimLinha, fimLinha)
    }
    
    if (
            PDFTxt.indexOf(fimLinha + 'Diária por Internação' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Hospitalar' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Diária por Internação' + espaco, 
            fimLinha + 'Diária por Internação Hospitalar' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Hospitalar' + fimLinha, fimLinha)
    }


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta de Contratação de seguro de vida' + fimLinha + 'N.°' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do proponente (completo e sem abreviações)' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF (preenchimento obrigatório)' + fimLinha, fimLinha, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de nascimento' + fimLinha, fimLinha, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão / ocupação profissional' + fimLinha, fimLinha,
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

    if (PDFTxt.indexOf('Endereço completo (rua, número e complemento) - Tipo de endereço: Residencial') !== -1) {
        
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço completo (rua, número e complemento) - Tipo de endereço: Residencial' + fimLinha,
            fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    
    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço completo (rua, número e complemento) - Tipo de endereço: Comercial' + fimLinha,
            fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro' + fimLinha, fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade' + fimLinha, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'UF' + fimLinha, fimLinha,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone celular (Cód. País + Cód.' + fimLinha + 'Cidade + Telefone):' + fimLinha,
        fimLinha, HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bruto (R$)Nome Prazo' + fimLinha,
        fimLinha + 'Coberturas Adicionais do Seguro (exceto para os produtos MetLife Vida Empresarial Total e MetLife Vida' + fimLinha,
            HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)
    
    const coberturaBasica = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Prêmio' + fimLinha + 'Bruto (R$)',
        fimLinha + 'Total' + espaco,
            HARDCODE.importacaoPropostaCoberturas, false, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturaBasica +
        (
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
                ?
                    fimLinha + jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
                :
                    ''
        )

    if (PDFTxt.indexOf('[x] Mensal') !== -1) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal

    } else {

        jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeAnual
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'um token em' + fimLinha, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Assinado pelo' + fimLinha + 'proponente através' + fimLinha +
            'de biometria facial' + fimLinha + 'em' + espaco, fimLinha,
                HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

            jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura = dataAtual()
        }
    }

    

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
        let indexLinhasCoberturaAux = 0

        const linhasCobertura = []
        let indexLinhasCobertura = -1
        let adicionarLinhasCobertura = true

        while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

            if (linhasCoberturaAux[indexLinhasCoberturaAux].indexOf('PROPOSTA N.°') === -1) {

                if (adicionarLinhasCobertura) {

                    indexLinhasCobertura++
    
                    linhasCobertura.push('')
    
                    adicionarLinhasCobertura = false
                }

                linhasCobertura[indexLinhasCobertura] +=
                    (linhasCobertura[indexLinhasCobertura] ? espaco : '' ) + linhasCoberturaAux[indexLinhasCoberturaAux]

                if (linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(',') !== -1) {
                    
                    adicionarLinhasCobertura = true

                }
            }

            indexLinhasCoberturaAux++
        }



        const coberturas = []

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco = ''
        let valorPremioExtra = ''
        let duracaoPremioExtra = ''
        let dataTerminoCobertura
        let valorRendaMensal = ''

        indexLinhasCobertura = 0

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
            
            valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 4])
            valorPremioLiquido = trataValorTexto(dadosCobertura[dadosCobertura.length - 3])
            valorIof = trataValorTexto(dadosCobertura[dadosCobertura.length - 2])
            valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 1])

            descricaoCobertura = ''

            indexLinhasCoberturaAux = 0

            while (indexLinhasCoberturaAux < dadosCobertura.length - 5) {
            
                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
            
                indexLinhasCoberturaAux++
            }

            if (descricaoCobertura.indexOf('VIDA TOTAL') !== -1) {

                dataTerminoCobertura = dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura)
                )

            } else {

                dataTerminoCobertura = dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) +
                        Number.parseInt(dadosCobertura[dadosCobertura.length - 5]),
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura)
                )
            } 

            const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida, null, valorCS,
                valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
                    dataTerminoCobertura, valorRendaMensal)

            indexLinhasCobertura += 1
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaPropostaMetLifeNovoLayout = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replace(fimLinha + 'Empresário(a) / Administrador' + fimLinha + '(a)' + fimLinha,
        fimLinha + 'Empresário(a) / Administrador(a)' + fimLinha)

    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Capital segurado' + fimLinha + '(R$)' + fimLinha + 'Prêmio' + fimLinha + 'Líquido (R$)' +
        fimLinha + 'IOF' + fimLinha + '(R$)' + fimLinha + 'Prêmio' + espaco + fimLinha + 'Bruto (R$)' + fimLinha, fimLinha)
    
    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Cobertura Prazo' + fimLinha + 'Capital segurado' + fimLinha + '(R$)' + fimLinha + 'Prêmio' + fimLinha + 'Líquido (R$)' +
        fimLinha + 'IOF' + fimLinha + '(R$)' + fimLinha + 'Prêmio' + espaco + fimLinha + 'Bruto (R$)Plano' + fimLinha, fimLinha)
    
    /*
    if (
            PDFTxt.indexOf(fimLinha + 'Doenças Graves e' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Doenças Graves e' + espaco, 
            fimLinha + 'Doenças Graves e Procedimentos Cirúrgicos' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos' + fimLinha, fimLinha)
    }

    if (
            PDFTxt.indexOf(fimLinha + 'Doenças Graves e' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos Premium' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Doenças Graves e' + espaco, 
            fimLinha + 'Doenças Graves e Procedimentos Cirúrgicos' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Procedimentos' + fimLinha + 'Cirúrgicos Premium' + fimLinha, fimLinha)
    }
    
    if (
            PDFTxt.indexOf(fimLinha + 'Diária por Internação' + espaco) !== -1 &&
            PDFTxt.indexOf(fimLinha + 'Hospitalar' + fimLinha) !== -1
    ) {

        PDFTxt = PDFTxt.replace(fimLinha + 'Diária por Internação' + espaco, 
            fimLinha + 'Diária por Internação Hospitalar' + espaco)
        PDFTxt = PDFTxt.replace(fimLinha + 'Hospitalar' + fimLinha, fimLinha)
    }
    */

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF (preenchimento obrigatório)' + fimLinha, fimLinha, 
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NIF* (para estrangeiros)' + fimLinha, fimLinha, 
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço completo (rua, número e complemento)' + espaco + fimLinha,
        fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha, fimLinha,
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Renda mensal' + fimLinha, fimLinha,
        HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)

    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, espaco,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro' + fimLinha, fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cidade' + fimLinha, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'UF' + fimLinha, fimLinha,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)
            
    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'Telefone celular (Cód. País + Cód.' + fimLinha + 'Cidade + Telefone):' +
        fimLinha, fimLinha, HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'E-mail' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta de Contratação de seguro de vida' + fimLinha, 'Nº' + fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
        
    obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'Nome do proponente (completo e sem abreviações)' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente

    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Produto – Cobertura Básica do Seguro' + fimLinha + 'Nome Prazo' + fimLinha,
        fimLinha + 'Coberturas Adicionais do Seguro (exceto para os produtos MetLife Vida Empresarial Total e MetLife Vida' + fimLinha,
            HARDCODE.importacaoPropostaCoberturas, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Produto – Cobertura Básica do Seguro' + fimLinha + 'Nome Prazo' + fimLinha,
            fimLinha + 'Vida Total Singular - Ramo 1391 (Vida Individual) Proc. Susep Nº 15414.632170/2024-49' + fimLinha,
                HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)
        
    }

    /*
    const coberturaBasica = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Prêmio' + fimLinha + 'Bruto (R$)',
        fimLinha + 'Total' + espaco,
            HARDCODE.importacaoPropostaCoberturas, false, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturaBasica +
        (
            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
                ?
                    fimLinha + jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas
                :
                    ''
        )
    */

    if (PDFTxt.indexOf('MensalX') !== -1) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal

    } else {

        jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeAnual
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'token enviado por Email/SMS em' + espaco + fimLinha, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email/SMS em' + espaco, espaco,
            HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'um token enviado por Email/SMS' + espaco + fimLinha + 'em' +
                espaco, espaco, HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

            if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

                obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'enviado por Email/SMS em' + espaco + fimLinha, espaco,
                    HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)
            }
        }
    }
    


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
        let indexLinhasCoberturaAux = 0

        const linhasCobertura = []
        let indexLinhasCobertura = -1
        let adicionarLinhasCobertura = true

        while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

            if (
                    linhasCoberturaAux[indexLinhasCoberturaAux].indexOf('PROPOSTA N.°') === -1 &&
                    linhasCoberturaAux[indexLinhasCoberturaAux].substring(0, 6) !== 'TOTAL '
            ) {

                if (adicionarLinhasCobertura) {

                    indexLinhasCobertura++
    
                    linhasCobertura.push('')
    
                    adicionarLinhasCobertura = false
                }

                linhasCobertura[indexLinhasCobertura] +=
                    (linhasCobertura[indexLinhasCobertura] ? espaco : '' ) + linhasCoberturaAux[indexLinhasCoberturaAux].trim()

                if (linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(',') !== -1) {
                    
                    adicionarLinhasCobertura = true

                }
            }

            indexLinhasCoberturaAux++
        }



        const coberturas = []

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let dadosCobertura
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco = ''
        let valorPremioExtra = ''
        let duracaoPremioExtra = ''
        let dataTerminoCobertura
        let valorRendaMensal = ''

        indexLinhasCobertura = 0

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)
            
            valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 4])
            valorPremioLiquido = trataValorTexto(dadosCobertura[dadosCobertura.length - 3])
            valorIof = trataValorTexto(dadosCobertura[dadosCobertura.length - 2])
            valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 1])

            descricaoCobertura = ''

            indexLinhasCoberturaAux = 0

            while (indexLinhasCoberturaAux < dadosCobertura.length - 4/*5*/) {
            
                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
            
                indexLinhasCoberturaAux++
            }

            if (descricaoCobertura.indexOf('VIDA TOTAL') !== -1) {

                dataTerminoCobertura = dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura)
                )

            } else {

                dataTerminoCobertura = dataAnoMesDia(
                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) +
                        Number.parseInt(dadosCobertura[dadosCobertura.length - 5]),
                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura),
                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura)
                )
            } 

            const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida, null, valorCS,
                valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra,
                    dataTerminoCobertura, valorRendaMensal)

            indexLinhasCobertura += 1
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasImplantacaoMetLife = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    while (linha < XLSX.length) {

        if (XLSX[linha][2]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            const codigoAgente = trataTexto(XLSX[linha][8])

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, codigoAgente)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta =
                '' + Number.parseInt(jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)


            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 6, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 32, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 35, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 31, HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 28, HARDCODE.importacaoCelularSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 29, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 36, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)



            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoStatusProposta, true,
                linhaIntegracao.statusProposta, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4, HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

            jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = 
                calculaValorAnual(jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado,
                    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) 
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 33, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)



            if (usuarioClienteSelecionado) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
     
            } else {

                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", codigoAgente)
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }
       
        linha++
    }

    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasMetLife = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    while (linha < XLSX.length) {

        if (XLSX[linha][2]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            const codigoAgente = trataTexto(XLSX[linha][8])

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, codigoAgente)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta =
                '' + Number.parseInt(jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoStatusProposta, true,
                linhaIntegracao.statusProposta, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 4, HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

            jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = 
                calculaValorAnual(jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado,
                    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) 
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 33, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)



            if (usuarioClienteSelecionado) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
     
            } else {

                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", codigoAgente)
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }
       
        linha++
    }

    return jsonRegistros.jsonRegistros
}



export const importaApoliceMetLife = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    PDFTxt = PDFTxt.replace(fimLinha + 'Cirúrgicos' + fimLinha + '84-Doença Grave ou Terminal' + espaco,
        fimLinha + 'Cirúrgicos' + espaco + '84-Doença Grave ou Terminal' + espaco
    )

    PDFTxt = PDFTxt.replace('peterlongo' + fimLinha, 'PETERLONGO' + fimLinha)
    PDFTxt = PDFTxt.replace('Retiro das Pedras' + fimLinha, 'RETIRO DAS PEDRAS' + fimLinha)
    //PDFTxt = PDFTxt.replace(fimLinha + 'Fator ' + fimLinha + 'aditivo' + fimLinha + 'Fator ' + fimLinha + 'multiplicativo' + fimLinha,
    //    fimLinha + 'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas. ' + fimLinha)
    
    const linhasPDFTxt = PDFTxt.split(fimLinha)

    let indexAux



    if (linhasPDFTxt[0] === 'Apólice individual do seguro') {

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[3], HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[5], HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[6], HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true,
            null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[7], HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
        


        const cepUFCidadeBairro = linhasPDFTxt[19].split(espaco)

        obtemDadoPDFAvulso (jsonRegistros, cepUFCidadeBairro[0], HARDCODE.importacaoCepSeguradoCliente, true,
            null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, cepUFCidadeBairro[1].substring(0, 2), HARDCODE.importacaoUfSeguradoCliente, true,
            null, erros, nomeArquivo)

            

        cepUFCidadeBairro[1] = cepUFCidadeBairro[1].substring(2)

        let indexCepUFCidadeBairro = 1

        let cidade = ''

        while (
            cepUFCidadeBairro[indexCepUFCidadeBairro].substring(cepUFCidadeBairro[indexCepUFCidadeBairro].length - 2) !==
            cepUFCidadeBairro[indexCepUFCidadeBairro].substring(cepUFCidadeBairro[indexCepUFCidadeBairro].length - 2)
                .toUpperCase()
        ) {

            cidade += (cidade ? espaco : '') + cepUFCidadeBairro[indexCepUFCidadeBairro]
            
            indexCepUFCidadeBairro++
        }

        let indexBairro = 1

        while (
            cepUFCidadeBairro[indexCepUFCidadeBairro].substring(indexBairro, indexBairro + 1) !==
            cepUFCidadeBairro[indexCepUFCidadeBairro].substring(indexBairro, indexBairro + 1).toUpperCase()
        ) {

            indexBairro++
        }

        cidade += (cidade ? espaco : '') + cepUFCidadeBairro[indexCepUFCidadeBairro].substring(0, indexBairro)

        obtemDadoPDFAvulso (jsonRegistros, cidade, HARDCODE.importacaoCidadeSeguradoCliente, true,
            null, erros, nomeArquivo)



        let bairro = cepUFCidadeBairro[indexCepUFCidadeBairro].substring(indexBairro)

        indexCepUFCidadeBairro++

        while (indexCepUFCidadeBairro < cepUFCidadeBairro.length) {

            bairro += (bairro ? espaco : '') + cepUFCidadeBairro[indexCepUFCidadeBairro]
            
            indexCepUFCidadeBairro++
        }

        obtemDadoPDFAvulso (jsonRegistros, bairro, HARDCODE.importacaoBairroSeguradoCliente, true,
            null, erros, nomeArquivo)



        let posRua = linhasPDFTxt[20].indexOf('RUA' + espaco)

        //if (posRua === -1) {

        //    posRua = linhasPDFTxt[20].indexOf('AVENIDA' + espaco)
        //}

        if (posRua !== -1) {

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[20].substring(0, posRua),
                HARDCODE.importacaoNumeroEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[20].substring(posRua),
                HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

        } else {

            posRua = linhasPDFTxt[20].length - 1

            while (
                    isNaN(linhasPDFTxt[20].substring(posRua, posRua + 1)) ||
                    linhasPDFTxt[20].substring(posRua, posRua + 1) === espaco
            ) {
                
                posRua--
            }

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[20].substring(0, posRua),
                HARDCODE.importacaoNumeroEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[20].substring(posRua),
                HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
        }



        const posBarra = linhasPDFTxt[21].indexOf(barraData)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[21].substring(0, posBarra -2), HARDCODE.importacaoCpfSeguradoCliente,
            true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[21].substring(posBarra -2, posBarra + 8),
            HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[21].substring(posBarra + 8), HARDCODE.importacaoNomeSeguradoCliente,
            true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, HARDCODE.idStatusApoliceAtiva, HARDCODE.importacaoStatusApolice, true, null, erros, nomeArquivo)



        const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

        let achou

        let indexIntegracao

        const coberturas = []
    
        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let statusCobertura = HARDCODE.idStatusCoberturaAtivaPagandoPremio
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        indexAux = indexPDFAvulso(linhasPDFTxt, 'Cobertura(s) e capital(is) segurado(s) individual(is)', 0) + 1

        while (
            linhasPDFTxt[indexAux] !==
                'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas.Os Capitais de ' &&
            linhasPDFTxt[indexAux] !==
                'A Cobertura contratada terá seu início e término de vigência às 24 horas da data acima indicada.Os Capitais de ' &&
            linhasPDFTxt[indexAux] !==
                'A Cobertura contratada terá seu início e término de vigência às 24 horas da data acima indicada. ' &&
            linhasPDFTxt[indexAux] !==
                'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas. '
        ) {

            achou = false

            indexIntegracao = 0

            while (indexIntegracao < linhaIntegracao.cobertura1.length) {

                const linhaCobertura = linhaIntegracao.cobertura1[indexIntegracao]

                if (!achou) {

                    if (linhasPDFTxt[indexAux].substring(0, linhaCobertura.chave.length) === linhaCobertura.chave) {

                        descricaoCobertura = linhaCobertura.descricaoCobertura

                        if (!descricaoCobertura) {

                            descricaoCobertura =
                                trataTexto(linhasPDFTxt[indexAux - 2] + linhasPDFTxt[indexAux - 1] + ' (' + linhaCobertura.chave + ')')
                        }

                        valorCS =
                            trataValorTexto(
                                linhasPDFTxt[indexAux].substring(
                                    linhaCobertura.chave.length,
                                    linhasPDFTxt[indexAux].indexOf(espaco, linhaCobertura.chave.length + 2)
                                )
                            )

                        if (linhasPDFTxt[indexAux].substring(linhasPDFTxt[indexAux].length - 9) === 'Vitalício') {

                            dataTerminoCobertura =
                                dataAnoMesDia(
                                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                                )

                        } else {

                            dataTerminoCobertura = trataData(linhasPDFTxt[indexAux].substring(linhasPDFTxt[indexAux].length - 10))
                        }

                        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura,
                            linhaCobertura.tipoCoberturaSeguroVida, statusCobertura, valorCS, valorPremioLiquido, valorIof,
                                valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura,
                                    valorRendaMensal)
                            
                        coberturas[coberturas.length - 1].id = linhaCobertura.id

                        achou = true
                    }
                }

                indexIntegracao++
            }
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            indexAux++
        }



        indexAux = indexPDFAvulso(linhasPDFTxt, 'Prêmio(s) por cobertura(s)', indexAux) + 1

        while (linhasPDFTxt[indexAux] !== 'Prêmio de referência do mês de emissão desta apólice.') {

            achou = false

            indexIntegracao = 0

            while (indexIntegracao < linhaIntegracao.premio1.length) {

                const linhaPremio = linhaIntegracao.premio1[indexIntegracao]

                if (!achou) {

                    if (linhasPDFTxt[indexAux].substring(0, linhaPremio.chave.length) === linhaPremio.chave) {

                        let linha

                        if (linhaPremio.linha === 0) {

                            linha = linhasPDFTxt[indexAux].substring(linhaPremio.chave.length + 1)

                        } else {

                            linha = linhasPDFTxt[indexAux + linhaPremio.linha]
                        }

                        linha = linha.split(espaco)

                        const indexCobertura = pesquisaIndexList(coberturas, linhaPremio.id)

                        coberturas[indexCobertura].valorPremioLiquido = trataValorTexto(linha[3])
                        coberturas[indexCobertura].valorIof = trataValorTexto(linha[2])
                        coberturas[indexCobertura].valorPremio = trataValorTexto(linha[5])

                        achou = true
                    }
                }

                indexIntegracao++
            }
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            indexAux++
        }

    } else {

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[10].substring(0, linhasPDFTxt[10].indexOf(espaco)),
            HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[12].substring(linhasPDFTxt[12].indexOf(barraData) - 2),
            HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[11].substring(linhasPDFTxt[11].indexOf(espaco) + 1),
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[10].substring(linhasPDFTxt[10].indexOf(espaco) + 1),
            HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[16].substring(linhasPDFTxt[16].indexOf(espaco) + 1),
            HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[16].substring(0, linhasPDFTxt[16].indexOf(espaco)),
            HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[14], HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, HARDCODE.idStatusApoliceAtiva, HARDCODE.importacaoStatusApolice, true, null, erros, nomeArquivo)

        indexAux = indexPDFAvulso(linhasPDFTxt, 'Telefone residencial', 0)

        if (linhasPDFTxt[indexAux + 2].substring(0, 1) === '+') {

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[indexAux + 2], HARDCODE.importacaoTelefoneSegurado, true, null, erros, nomeArquivo)
        }

        indexAux = indexPDFAvulso(linhasPDFTxt, 'Telefone comercial', indexAux)

        if (linhasPDFTxt[indexAux + 2].substring(0, 1) === '+') {

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[indexAux + 2], HARDCODE.importacaoTelefoneComercialSegurado,
                true, null, erros, nomeArquivo)
        }

        indexAux = indexPDFAvulso(linhasPDFTxt, 'Telefone celular', indexAux)

        if (linhasPDFTxt[indexAux + 2].substring(0, 1) === '+') {

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[indexAux + 2],
                HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)
        }

        indexAux = indexPDFAvulso(linhasPDFTxt,
            'Os seus dados identificados acima estão cadastrados em nosso sistema e serão utilizados como meio de contato,', indexAux)

        if (linhasPDFTxt[indexAux - 1].substring(0, 6) === 'Email:') {

            obtemDadoPDFAvulso (jsonRegistros, linhasPDFTxt[indexAux - 1].substring(7), HARDCODE.importacaoEmailSeguradoCliente,
                true, null, erros, nomeArquivo)
        }
        
        const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

        let achou

        let indexIntegracao

        const coberturas = []
    
        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let statusCobertura = HARDCODE.idStatusCoberturaAtivaPagandoPremio
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        indexAux = indexPDFAvulso(linhasPDFTxt, ' Cobertura Ramo Capital segurado (R$) Data de fim de vigência', 0) + 1

        while (
            linhasPDFTxt[indexAux] !==
            'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas.'
        ) {

            achou = false

            indexIntegracao = 0

            while (indexIntegracao < linhaIntegracao.cobertura2.length) {

                const linhaCobertura = linhaIntegracao.cobertura2[indexIntegracao]

                if (!achou) {

                    if (linhasPDFTxt[indexAux].substring(0, linhaCobertura.chave.length) === linhaCobertura.chave) {

                        descricaoCobertura = linhaCobertura.descricaoCobertura

                        if (!descricaoCobertura) {

                            descricaoCobertura =
                                trataTexto(linhasPDFTxt[indexAux - 2] + ' ' + linhasPDFTxt[indexAux - 1] + ' (' + linhaCobertura.chave + ')')
                        }

                        let inicioValorCS

                        if (linhaCobertura.proximaLinha) {

                            inicioValorCS = 0
                            indexAux++

                        } else {

                            inicioValorCS = linhaCobertura.chave.length
                        }

                        valorCS =
                            trataValorTexto(
                                linhasPDFTxt[indexAux].substring(
                                    inicioValorCS,
                                    linhasPDFTxt[indexAux].indexOf(espaco, inicioValorCS + 1)
                                )
                            )

                        if (linhasPDFTxt[indexAux].substring(linhasPDFTxt[indexAux].length - 9) === 'Vitalício') {

                            dataTerminoCobertura =
                                dataAnoMesDia(
                                    ano(jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente) + 100/* - 1*/,
                                    mes(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao),
                                    dia(jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao)
                                )

                        } else {

                            dataTerminoCobertura = trataData(linhasPDFTxt[indexAux].substring(linhasPDFTxt[indexAux].length - 10))
                        }

                        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura,
                            linhaCobertura.tipoCoberturaSeguroVida, statusCobertura, valorCS, valorPremioLiquido, valorIof,
                                valorPremio, classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura,
                                    valorRendaMensal)
                            
                        coberturas[coberturas.length - 1].id = linhaCobertura.id

                        achou = true
                    }
                }

                indexIntegracao++
            }
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            indexAux++
        }



        indexAux = indexPDFAvulso(linhasPDFTxt, 'multiplicativo', indexAux) + 1

        while (linhasPDFTxt[indexAux] !== 'Prêmio de referência do mês de emissão desta apólice.') {

            achou = false

            indexIntegracao = 0

            while (indexIntegracao < linhaIntegracao.premio2.length) {

                const linhaPremio = linhaIntegracao.premio2[indexIntegracao]

                if (!achou) {

                    if (linhasPDFTxt[indexAux].substring(0, linhaPremio.chave.length) === linhaPremio.chave) {

                        let linha

                        if (linhaPremio.linha === 0) {

                            linha = linhasPDFTxt[indexAux].substring(linhaPremio.chave.length + 1)

                        } else {

                            linha = linhasPDFTxt[indexAux + linhaPremio.linha]
                        }

                        linha = linha.split(espaco)

                        const indexCobertura = pesquisaIndexList(coberturas, linhaPremio.id)

                        coberturas[indexCobertura].valorPremioLiquido = trataValorTexto(linha[0])
                        coberturas[indexCobertura].valorIof = trataValorTexto(linha[1])
                        coberturas[indexCobertura].valorPremio = trataValorTexto(linha[2])

                        achou = true
                    }
                }

                indexIntegracao++
            }
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas

            indexAux++
        }
    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    
    return jsonRegistros.jsonRegistros
}


export const importaListaApolicesMetLifeImplantacao = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][38])

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 6, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 5, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 41, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 0, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 43, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 44, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 45, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 20, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)
            
            jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = 
                calculaValorAnual(jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado,
                    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) 

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)


            
            if (usuarioClienteSelecionado) {
               
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
    
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][38])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }
    

    return jsonRegistros.jsonRegistros
}



export const importaApoliceMetLifeNovoLayout = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Morte Com Capital Segurado' + espaco + fimLinha +
        'Decrescente A Cada Ano' + espaco,
            fimLinha + 'Morte Com Capital Segurado Decrescente A Cada Ano' + espaco)

    PDFTxt = PDFTxt.replaceAll(fimLinha + 'Invalidez Total Ou Parcial Por' + espaco + fimLinha +
        'Acidente' + espaco,
            fimLinha + 'Invalidez Total Ou Parcial Por Acidente' + espaco)


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cobertura(s) e capital(is) segurado(s) individual(is)' + fimLinha +
        'Cobertura Ramo Capital segurado (R$)' + fimLinha + 'Data de fim de' + espaco + fimLinha + 'vigência' + fimLinha +
            'Período de' + espaco + fimLinha + 'vigência' + fimLinha,
                fimLinha + 'As Coberturas contratadas terão seu início e término de vigência às 24 horas das datas acima indicadas.' +
                    espaco + fimLinha, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

    //const coberturas1 = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
    //console.log(coberturas1)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do segurado Data de nascimento CPF' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    let linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    let tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso(jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso(jsonRegistros, linhaDados[tamLinhaDados - 2],
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso(jsonRegistros, obtemTextoArray(linhaDados, 0, tamLinhaDados - 3),
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço completo Nº Complemento' + fimLinha, fimLinha,
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro CEP Cidade UF' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso(jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    const indexCep = indexCampoComecaTerminaComNumero(linhaDados)

    obtemDadoPDFAvulso(jsonRegistros, linhaDados[indexCep],
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso(jsonRegistros, obtemTextoArray(linhaDados, 0, indexCep - 1),
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso(jsonRegistros, obtemTextoArray(linhaDados, indexCep + 1, linhaDados.length - 2),
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº da proposta:' + espaco, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Nº da apólice:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade de pagamento dos prêmios:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
    //
    //console.log(jsonRegistros.jsonRegistros[indexJsonRegistros])



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
        HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)

  

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Número' + fimLinha, fimLinha,
        HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Complemento' + fimLinha, fimLinha + '03 Dados da apólice' + fimLinha,
        HARDCODE.importacaoComplementoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
        HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)

    

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'do dia' + espaco, fimLinha, 
        HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

   

    const linhasCobertura = []
    let indexLinhasCobertura = 0

    const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
    let indexLinhasPosicaoAux = 0
    let linhaCoberturaAux = ''

    const coberturas = []

    let dadosCobertura
    let indexDadosCobertura

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let tipoCoberturaSeguroVida
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

        if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('/') === -1) {

            linhaCoberturaAux = espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux])

        } else {

            linhasCobertura.push(espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux]))

            linhaCoberturaAux = ''
        }

        indexLinhasPosicaoAux += 1
    }

    while (indexLinhasCobertura < linhasCobertura.length) {

        dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

        indexDadosCobertura = 0

        descricaoCobertura = ''

        while (indexDadosCobertura < dadosCobertura.length && dadosCobertura[indexDadosCobertura] !== 'R$') {

            descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexDadosCobertura]

            indexDadosCobertura++
        }

        tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        valorCS = trataValorTexto(dadosCobertura[indexDadosCobertura + 1])
        valorPremio = trataValorTexto(dadosCobertura[indexDadosCobertura + 5])

        dataTerminoCobertura = trataData(dadosCobertura[indexDadosCobertura + 6])

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
        
        indexLinhasCobertura++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaCotacaoMetLifeTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    
    let modelo

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data que a primeira cotação foi gerada:' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacao, false, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Capital Segurado:' + espaco, fimLinha,
        HARDCODE.importacaoValorCS, true, null, erros, nomeArquivo)
        
    if (jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacao) {

        modelo = 1

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Prolongado' + fimLinha, fimLinha + 'Data da simulação:' + espaco,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)

    } else {

        modelo = 2
        
        jsonRegistros.indexInicial = 0

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Período de cobertura Período de pagamento Idade do cliente' +
            fimLinha + 'IdadeAno', fimLinha +
                'As informações indicadas neste formulário são simulações, não constituem compromisso ou responsabilidade entre ambas as partes,' + fimLinha,
                    HARDCODE.importacaoTabelaEvolucaoValorResgate, false, null, erros, nomeArquivo)
                    
        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Período onde a renovação é permitida Período de cobertura Período de pagamento Idade do cliente' +
                fimLinha + 'IdadeAno', fimLinha +
                    'As informações indicadas neste formulário são simulações, não constituem compromisso ou responsabilidade entre ambas as partes,' + fimLinha,
                        HARDCODE.importacaoTabelaEvolucaoValorResgate, false, null, erros, nomeArquivo)

            if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

                obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Período de cobertura Idade do cliente' +
                    fimLinha + 'IdadeAno', fimLinha +
                        'As informações indicadas neste formulário são simulações, não constituem compromisso ou responsabilidade entre ambas as partes,' + fimLinha,
                            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)
            }
        }

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data da simulação:' + espaco, fimLinha,
            HARDCODE.importacaoDataCotacao, true, null, erros, nomeArquivo)
    }



    const funeralIndividual = PDFTxt.indexOf(fimLinha + 'Funeral Individual' + fimLinha + 'Capital Segurado:' + espaco)

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = numeroCotacaoAutomatico()
        let dataCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacao
        let ano
        let idade
        let valorCS = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (
                    '123456789'.indexOf(linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                        .substring(0, 1)) !== -1
            ) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        let tamanhoPrimeiraLinha

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            if (!tamanhoPrimeiraLinha) {
            
                tamanhoPrimeiraLinha = dadosTabelaEvolucaoValorResgate.length
            }

            ano = Number.parseInt(dadosTabelaEvolucaoValorResgate[0])
            idade = Number.parseInt(dadosTabelaEvolucaoValorResgate[1])
            valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[2])
            valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[3])

            beneficioProlongado = ''

            if (modelo === 1) {

                valorSaldado = trataValorTexto(dadosTabelaEvolucaoValorResgate[4])

                let indexDadosTabelaEvolucaoValorResgate = 5

                while (indexDadosTabelaEvolucaoValorResgate < dadosTabelaEvolucaoValorResgate.length) {

                    beneficioProlongado +=
                        dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate] + espaco

                    indexDadosTabelaEvolucaoValorResgate++
                }

            } else {
                
                if (PDFTxt.indexOf('Vida Total Singular 5') === -1) {
                    
                    let indexDadosTabelaEvolucaoValorResgate = 4

                    while (dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate].indexOf(',') === -1) {

                        beneficioProlongado +=
                            dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate] + espaco

                        indexDadosTabelaEvolucaoValorResgate++
                    }

                    valorSaldado = trataValorTexto(dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate])

                    if (
                            funeralIndividual !== -1 &&
                            dadosTabelaEvolucaoValorResgate.length - 1 > indexDadosTabelaEvolucaoValorResgate + 1
                    ) {

                        if (tamanhoPrimeiraLinha > 15) {

                            indexDadosTabelaEvolucaoValorResgate += 3

                            while (dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate].indexOf(',') === -1) {

                                indexDadosTabelaEvolucaoValorResgate++
                            }

                            if (dadosTabelaEvolucaoValorResgate.length - 1 > indexDadosTabelaEvolucaoValorResgate) {

                                valorPremioAnualizadoAcumulado += trataValorTexto(dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate])
                                valorResgate += trataValorTexto(dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate + 1])
                            }

                        } else {

                            valorPremioAnualizadoAcumulado += trataValorTexto(dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate + 1])
                            valorResgate += trataValorTexto(dadosTabelaEvolucaoValorResgate[indexDadosTabelaEvolucaoValorResgate + 2])
                        }
                    }
                }
            }
            
            beneficioProlongado = beneficioProlongado.trim()

            if (idade <= 100) {
            
                incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                    valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
            }

            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}