import LABELESPECIAL from '../../business-const/Label/LabelEspecial'

import {
    descricaoMenu,
} from '../../business-components/String/String'

import {
    sortAsc
} from '../../business-components/Sort/Sort'

import {
    semAcento
} from '../../business-components/String/String'



export const pesquisaList = (list, id) => {

    let listEncontrada = {}

    list?.forEach( (linhaList, index) => {

        if (linhaList.id === id) {
            
            listEncontrada = linhaList
        }
    })

    return listEncontrada
}



export const pesquisaDescricaoList = (list, id) => {
    const descricao = pesquisaList(list, id).descricao

    return descricao ? descricao : ""
}



export const pesquisaIndexList = (list, id) => {

    let indexEncontrado = null

    list?.forEach( (linhaList, index) => {

        if (linhaList.id === id) {
            
            indexEncontrado = index
        }
    })

    return indexEncontrado
}



export const pesquisaIdList = (list, descricao) => {
    
    let listEncontrada = {}

    list?.forEach( (linhaList, index) => {

        if (!listEncontrada.id) {

            if (semAcento(linhaList.descricao.toUpperCase()) === semAcento(descricao.toUpperCase())) {
            
                listEncontrada = linhaList
            }
        }
    })

    return listEncontrada.id
}



export const pesquisaChaveList = (list, chave) => {
    
    let listEncontrada = {}

    list?.forEach( (linhaList, index) => {

        if (!listEncontrada.id) {

            linhaList.chaves?.forEach( (linhaChaves, indexChaves) => {

                if (linhaChaves.id === chave) {
                
                    listEncontrada = linhaList
                }
            })
        }
    })

    return listEncontrada
}



export const pesquisaAutoCompleteList = (list, descricao) => {
    
    let listEncontrada = null

    list?.forEach( (linhaList, index) => {

        if (!listEncontrada) {
        
            if (semAcento(linhaList.toUpperCase()) === semAcento(descricao.toUpperCase())) {
                
                listEncontrada = linhaList
            }
        }
    })

    return listEncontrada
}



export const indexMenu = (menu, descricao) => {

    let indexMenuAux = null

    menu?.forEach( (linhaMenu, index) => {

        if (indexMenuAux === null && descricaoMenu(linhaMenu) === descricao) {
            
            indexMenuAux = index
        }
    })

    return indexMenuAux
}



export const descricoesValidasList = (list) => {

    let listAux = []

    listAux.push(...list)

    listAux = sortAsc(listAux)


    
    let descricoesValidas = ''

    listAux?.forEach( (linhaListAux, index) => {

        if (descricoesValidas) {

            descricoesValidas += ', '
        }
            
        descricoesValidas += linhaListAux.id + LABELESPECIAL.ou + linhaListAux.descricao
    })

    return descricoesValidas
}



const posicaoColumnsExibe = ( columnsExibe, id, ordem ) => {

    if (columnsExibe) {

        columnsExibe?.forEach( ( linhaColumnsExibe, index) => {

            if ( linhaColumnsExibe.id === id ) {

                ordem = index
            }
        })
    }

    return ordem
}



export const incluiCampoList = ({ camposList, columnsExibe, grafico, filtroList, name, label, filtro, filtroGrafico, type, tamMax, numMin, numMax,
    list, columnsDefault, options, permiteAgrupar, permiteGerarPDFQuebra, ordem }) => {

    if (
            (!columnsExibe || pesquisaList(columnsExibe, name).id) &&
            (!grafico || filtroGrafico === 'S' || pesquisaList(filtroList, name).id)
    ) {

        camposList.push(
            {
                id: name,
                name: name,
                descricao: label,
                label: label,
                type: type,
                filtro: filtro,
                tamMax: tamMax,
                numMin: numMin,
                numMax: numMax,
                list: list,
                columnsDefault: columnsDefault,
                options: options,
                permiteAgrupar: permiteAgrupar,
                permiteGerarPDFQuebra: permiteGerarPDFQuebra,
                ordem: posicaoColumnsExibe(columnsExibe, name, ordem),
            },
        )
    }
}



export const calculaColumnsInicial = ( list, dashboard, filtroLists, camposList ) => {

    camposList(
        list,
            (
                dashboard.columnsExibe.length === 0 && dashboard.columnsNaoExibe.length === 0
                    ? 
                        null
                    :
                        dashboard.columnsExibe
            ), false, dashboard.filtroList, filtroLists)?.forEach( (linhaList, indList) => {

        if (
                linhaList.columnsDefault === 'S'
        ) {

            dashboard.columnsExibe.push({
                id: linhaList.name,
                content: linhaList.label,
                permiteAgrupar: linhaList.permiteAgrupar,
                permiteGerarPDFQuebra: linhaList.permiteGerarPDFQuebra,
            })

        } else {
                
            dashboard.columnsNaoExibe.push({
                id: linhaList.name,
                content: linhaList.label,
                permiteAgrupar: linhaList.permiteAgrupar,
                permiteGerarPDFQuebra: linhaList.permiteGerarPDFQuebra,
            })
        }
    })
}
