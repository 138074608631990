// alterado - revisado

import {
    useRef
} from 'react'

const useSingleton = (callBack) => {
    const hasBeenCalled = useRef(false)

    if (hasBeenCalled.current) return

    callBack()

    hasBeenCalled.current = true
}

export default useSingleton