import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    mes,
    ano,
    mesMesAno,
    anoMesAno,
    formataMesAnoSemBarra,
    calculaMesAnoFinal,
} from '../../../business-components/Date/Date'

import {
    round,
} from '../../../business-components/round/round'

import {
    incrementaContDiaDia,
    processaRegistrosDefaultIniciaisDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    incrementaContReceitaMensal,
    processaRegistrosDefaultIniciaisReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    calculaTotaisRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    converteTaxaAnualEmMensal
} from './EstudoServiceMatematicaFinanceira'



const descricaoEspecializacaoPeriodoSabatico = (values) => {

    return values.descricaoEspecializacaoPeriodoSabatico
        ?
            values.descricaoEspecializacaoPeriodoSabatico
        :
            LABEL.especializacaoPeriodoSabatico
}

const indexPeriodoPlanejamento = (despesaReceita, periodoPlanejamento) => {

    let index = null

    let periodoPlanejamentoAux = 0
    
    despesaReceita?.forEach( (linhaDespesaReceita, indexDespesaReceita) => {

        if (linhaDespesaReceita.planejamentoEspecializacaoPeriodoSabatico) {

            periodoPlanejamentoAux++

            if (periodoPlanejamento === periodoPlanejamentoAux) {

                index = indexDespesaReceita
            }
        }

    })

    return index
}

const excluiReceitaMensalEspecializacaoPeriodoSabatico = (values) => {

    let index = values.receitaMensal.length - 1

    while (index >= 0) {

        if (values.receitaMensal[index].planejamentoEspecializacaoPeriodoSabatico) {

            values.receitaMensal.splice(index, 1)
        }

        index -= 1
    }
}

const incluiReceitaMensalEspecializacaoPeriodoSabatico = (values, periodoPlanejamento, valor, mesAnoInicial, mesAnoFinal) => {

    const index = indexPeriodoPlanejamento(values.receitaMensal, periodoPlanejamento)

    const dados = {
        valor: valor,
        mesAnoInicial: mesAnoInicial,
        mesAnoFinal: mesAnoFinal,
    }

    if (index === null) {

        incrementaContReceitaMensal(values)

        values.receitaMensal.push(
            {
                id: values.contReceitaMensal,
                familiar: HARDCODE.idFamiliarCliente,
                tipoReceitaMensal: HARDCODE.idTipoReceitaMensalProfissionalLiberal,
                tipoPeriodicidadeRecebto: HARDCODE.idTipoPeriodicidadeMensal,
                alterarTipoCalculoAcumulado: true,
                ...dados,
                planejamentoEspecializacaoPeriodoSabatico: true,
            }
        )
        
    } else {

        values.receitaMensal[index] = {
            ...values.receitaMensal[index],
            ...dados
        }
    }
}

const excluiDiaDiaEspecializacaoPeriodoSabatico = (values) => {

    let index = values.diaDia.length - 1

    while (index >= 0) {

        if (values.diaDia[index].planejamentoEspecializacaoPeriodoSabatico) {

            values.diaDia.splice(index, 1)
        }

        index -= 1
    }
}

const incluiDiaDiaEspecializacaoPeriodoSabatico = (values, periodoPlanejamento, valor, mesAnoInicial, mesAnoFinal) => {

    const index = indexPeriodoPlanejamento(values.diaDia, periodoPlanejamento)

    const dados = {
        valor: valor,
        mesAnoInicial: mesAnoInicial,
        mesAnoFinal: mesAnoFinal,
    }

    if (index === null) {

        incrementaContDiaDia(values)

        values.diaDia.push(
            {
                id: values.contDiaDia,
                familiar: HARDCODE.idFamiliarCliente,
                tipoDiaDia: HARDCODE.idTipoDiaDiaOutros,
                tipoPeriodicidadePagto: HARDCODE.idTipoPeriodicidadeMensal,
                alterarPeriodoDiaDia: true,
                ...dados,
                planejamentoEspecializacaoPeriodoSabatico: true,
            }
        )

    } else {

        values.diaDia[index] = {
            ...values.diaDia[index],
            ...dados
        }
    }
}


export const informouPlanejamentoEspecializacaoPeriodoSabatico = (values) => {

    return values.valorReceitaMensalAntesEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaAntesEspecializacaoPeriodoSabatico &&
        round(values.valorReceitaMensalAntesEspecializacaoPeriodoSabatico, 2) >
        round(values.valorDiaDiaAntesEspecializacaoPeriodoSabatico, 2) &&
        values.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaDuranteEspecializacaoPeriodoSabatico &&
        round(values.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico, 2) <
        round(values.valorDiaDiaDuranteEspecializacaoPeriodoSabatico, 2) &&
        values.mesesEspecializacaoPeriodoSabatico &&
        values.valorReceitaMensalDepoisEspecializacaoPeriodoSabatico &&
        values.valorDiaDiaDepoisEspecializacaoPeriodoSabatico &&
        round(values.valorReceitaMensalDepoisEspecializacaoPeriodoSabatico, 2) >=
        round(values.valorDiaDiaDepoisEspecializacaoPeriodoSabatico, 2)
}

export const gerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico = (values) => {

    processaRegistrosDefaultIniciaisReceitaMensal(values, HARDCODE.idFamiliarCliente)

    processaRegistrosDefaultIniciaisDiaDia(values, HARDCODE.idFamiliarCliente)
    
    if (informouPlanejamentoEspecializacaoPeriodoSabatico(values)) {
    
        const fatorTaxaJurosMensal = converteTaxaAnualEmMensal(values.taxaRetornoAnualReal) / 100

        const custoMensalDuranteEspecializacaoPeriodoSabatico =
            round(values.valorDiaDiaDuranteEspecializacaoPeriodoSabatico, 2) -
                round(values.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico, 2)

        const valorEspecializacaoPeriodoSabatico =
            round(custoMensalDuranteEspecializacaoPeriodoSabatico * values.mesesEspecializacaoPeriodoSabatico, 2)

        const valorPresenteInicioEspecializacaoPeriodoSabatico =
            round(
                (custoMensalDuranteEspecializacaoPeriodoSabatico
                ) *
                (
                    Math.pow(1 + fatorTaxaJurosMensal, values.mesesEspecializacaoPeriodoSabatico) - 1
                ) /
                (
                    Math.pow(1 + fatorTaxaJurosMensal, values.mesesEspecializacaoPeriodoSabatico + 1) - 
                    Math.pow(1 + fatorTaxaJurosMensal, values.mesesEspecializacaoPeriodoSabatico)
                ), 2
            )

        calculaTotaisRecursosFinanceiros(values)

        let saldoAtualRecursosFinanceiros = values.stepsValorPatrimonio[HARDCODE.indexPatrimonioRecursosFinanceiros]

        let mesesAcumulacao = 0

        const capacidadePoupanca = round(values.valorReceitaMensalAntesEspecializacaoPeriodoSabatico, 2) -
            round(values.valorDiaDiaAntesEspecializacaoPeriodoSabatico, 2)

        while (saldoAtualRecursosFinanceiros < valorPresenteInicioEspecializacaoPeriodoSabatico) {

            saldoAtualRecursosFinanceiros =
                round((saldoAtualRecursosFinanceiros + capacidadePoupanca) * (1 + fatorTaxaJurosMensal), 2)

            mesesAcumulacao++
        }

        const mesAtual = mes(values.dataSimulacaoEstudo)
        const anoAtual = ano(values.dataSimulacaoEstudo)

        const mesAnoInicialAcumulacao = formataMesAnoSemBarra(mesAtual, anoAtual)
        const mesAnoFinalAcumulacao = calculaMesAnoFinal(mesAtual, anoAtual, mesesAcumulacao)

        incluiReceitaMensalEspecializacaoPeriodoSabatico(values, 1, values.valorReceitaMensalAntesEspecializacaoPeriodoSabatico,
            mesAnoInicialAcumulacao, mesAnoFinalAcumulacao)

        incluiDiaDiaEspecializacaoPeriodoSabatico(values, 1, values.valorDiaDiaAntesEspecializacaoPeriodoSabatico,
            mesAnoInicialAcumulacao, mesAnoFinalAcumulacao)

        const mesAnoInicialEspecializacaoPeriodoSabatico = calculaMesAnoFinal(mesMesAno(mesAnoFinalAcumulacao), anoMesAno(mesAnoFinalAcumulacao), 2)
        const mesAnoFinalEspecializacaoPeriodoSabatico = calculaMesAnoFinal(mesMesAno(mesAnoInicialEspecializacaoPeriodoSabatico),
            anoMesAno(mesAnoInicialEspecializacaoPeriodoSabatico), values.mesesEspecializacaoPeriodoSabatico)
        
        incluiReceitaMensalEspecializacaoPeriodoSabatico(values, 2, values.valorReceitaMensalDuranteEspecializacaoPeriodoSabatico,
            mesAnoInicialEspecializacaoPeriodoSabatico, mesAnoFinalEspecializacaoPeriodoSabatico) 
    
        incluiDiaDiaEspecializacaoPeriodoSabatico(values, 2, values.valorDiaDiaDuranteEspecializacaoPeriodoSabatico,
                mesAnoInicialEspecializacaoPeriodoSabatico, mesAnoFinalEspecializacaoPeriodoSabatico) 
    
        const mesAnoInicialDepoisEspecializacaoPeriodoSabatico =
            calculaMesAnoFinal(mesMesAno(mesAnoFinalEspecializacaoPeriodoSabatico), anoMesAno(mesAnoFinalEspecializacaoPeriodoSabatico), 2)
        const mesAnoFinalDepoisEspecializacaoPeriodoSabatico = '12' + values.anoFinalVaiAposentarCliente
        
        incluiReceitaMensalEspecializacaoPeriodoSabatico(values, 3, values.valorReceitaMensalDepoisEspecializacaoPeriodoSabatico,
            mesAnoInicialDepoisEspecializacaoPeriodoSabatico, mesAnoFinalDepoisEspecializacaoPeriodoSabatico)
        
        incluiDiaDiaEspecializacaoPeriodoSabatico(values, 3, values.valorDiaDiaDepoisEspecializacaoPeriodoSabatico,
            mesAnoInicialDepoisEspecializacaoPeriodoSabatico, mesAnoFinalDepoisEspecializacaoPeriodoSabatico)
                    
        values.aplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria = true
        values.percAplicarEconomiaReceitaMensalAteAposentadoriaReservaAposentadoria = ''
        values.deduzirComplementoReceitaMensalAteAposentadoriaReservaAposentadoria = true

        values.planejamentoEspecializacaoPeriodoSabatico = {
            descricaoEspecializacaoPeriodoSabatico: descricaoEspecializacaoPeriodoSabatico(values),
            valorEspecializacaoPeriodoSabatico: valorEspecializacaoPeriodoSabatico,
            valorPresenteInicioEspecializacaoPeriodoSabatico: valorPresenteInicioEspecializacaoPeriodoSabatico,
            mesesAcumulacao: mesesAcumulacao,
            saldoAtualRecursosFinanceiros: saldoAtualRecursosFinanceiros,
            mesAnoInicialAcumulacao: mesAnoInicialAcumulacao,
            mesAnoFinalAcumulacao: mesAnoFinalAcumulacao,
            mesAnoInicialEspecializacaoPeriodoSabatico: mesAnoInicialEspecializacaoPeriodoSabatico,
            mesAnoFinalEspecializacaoPeriodoSabatico: mesAnoFinalEspecializacaoPeriodoSabatico,
            mesAnoInicialDepoisEspecializacaoPeriodoSabatico: mesAnoInicialDepoisEspecializacaoPeriodoSabatico,
            mesAnoFinalDepoisEspecializacaoPeriodoSabatico: mesAnoFinalDepoisEspecializacaoPeriodoSabatico,
        }

    } else {
        
        excluiReceitaMensalEspecializacaoPeriodoSabatico(values)

        excluiDiaDiaEspecializacaoPeriodoSabatico(values)

        values.planejamentoEspecializacaoPeriodoSabatico = null
    }
}