import HARDCODE from '../../business-const/HardCode/HardCode'



const tipoContatoList = [
    {
        id: HARDCODE.idTipoContatoEmail, // HARDCODEBACKEND.idTipoContatoEmail
        descricao: 'E-mail',
        ordem: 10,
        formato: 'email',
    },
    {
        id: HARDCODE.idTipoContatoCelular, // HARDCODEBACKEND.idTipoContatoCelular
        descricao: 'Celular',
        ordem: 20,
        formato: 'cel',
    }, 
    {
        id: HARDCODE.idTipoContatoCelularComercial,
        descricao: 'Cel. Comercial',
        ordem: 25,
        formato: 'cel',
    },
    {
        id: HARDCODE.idTipoContatoTelefoneFixo,
        descricao: 'Tel. Fixo',
        ordem: 30,
        formato: 'telef',
    },
    {
        id: HARDCODE.idTipoContatoTelefoneComercial,
        descricao: 'Tel. Comercial',
        ordem: 35,
        formato: 'telef',
    },
    {
        id: HARDCODE.idTipoContatoOutros,
        descricao: 'Outros',
        ordem: 40,
        formato: 'outro',
    }, 
]

export default tipoContatoList