import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class ApoliceComissaoService extends ApiService {

    constructor() {
        super(HARDCODE.apiApoliceComissao)
    }

    async atualizarSalvarDeletarApoliceComissaoList(variaveisGlobais, idUsuario, idArquivo, seguradora, jsonApoliceComissaoList) {

        const apoliceComissaoListDTO = {
            idUsuario: idUsuario,
            idArquivo: idArquivo,
            seguradora: seguradora,
	        jsonApoliceComissaoList: jsonApoliceComissaoList,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiApoliceComissaoAtualizarSalvarDeletarApoliceComissaoList,
            apoliceComissaoListDTO, false)
    }
}

export default ApoliceComissaoService