import {
    calcDifPerc100,
} from '../cliente-form/ClienteService'



export const processaValoresDefaultIniciaisClienteConjuge = ( values ) => {
    // A inicialização dos campos do ClienteConjuge já está sendo feito pela rotina processaCalculoEstudo

    if (!values.alterarIdadesAutomaticasCliente) {
        values.alterarIdadesAutomaticasCliente = false
    }

    if (!values.alterarPercRespAutomaticoCliente) {
        values.alterarPercRespAutomaticoCliente = false
    }

    if (!values.alterarIdadesAutomaticasConjuge) {
        values.alterarIdadesAutomaticasConjuge = false
    }
}

export const calculaClienteConjugePercRespConjuge = ( values ) => {
    if (values.percRespCliente === 0 || values.percRespCliente) {
        values.percRespConjuge = calcDifPerc100(values.percRespCliente)
    } else {
        values.percRespConjuge = ''
    }
}

export const processaAlteracaoClienteConjugePercRespCliente = ( values ) => {
    calculaClienteConjugePercRespConjuge(values)
}