const grauInstrucaoList = [
    {
        id: 10,
        descricao: 'Ensino Fundamental Incompleto',
        ordem: 10,
    },
    {
        id: 20,
        descricao: 'Ensino Fundamental Completo',
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Ensino Médio Incompleto',
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Ensino Médio Completo',
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Superior Incompleto',
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Superior Completo',
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'Pós-graduação/Especialização',
        ordem: 70,
    },
    {
        id: 80,
        descricao: 'Mestrado',
        ordem: 80,
    },
    {
        id: 90,
        descricao: 'Doutorado',
        ordem: 90,
    },
    {
        id: 100,
        descricao: 'Pós-Doutorado',
        ordem: 100,
    },
]

export default grauInstrucaoList