import HARDCODE from '../../business-const/HardCode/HardCode'

import AuthService from '../../business-api/AuthService'

import ArquivoService from '../../business-api/ArquivoService'

import ConvertePDFBase64ToTextService from '../../business-api/ConvertePDFBase64ToTextService'

import VwArquivoAcessoService from '../../business-api/VwArquivoAcessoService'

import {
    mensagemErroErro
} from '../../business-components/Toastr/Toastr'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import formList from '../../business-rules/List/formList'

import tipoArquivoList from '../../business-rules/List/Arquivo/tipoArquivoList'



export const getArquivoList = async ( variaveisGlobais, idUsuario, grupoArquivo, tipoArquivo, instituicaoFinanceira,
    extensaoArquivo, statusProcessamento, idConfiguracaoAcesso, idUsuarioAcesso, idClienteAcesso, numeroCotacaoAcesso,
        numeroPropostaAcesso, numeroApoliceAcesso, numeroConsorcioAcesso ) => {

    const vwArquivoAcessoService = new VwArquivoAcessoService()

    try {
        const response = await vwArquivoAcessoService.buscarArquivoList(variaveisGlobais,
            {
                idUsuario: idUsuario,
                grupoArquivo: grupoArquivo,
                tipoArquivo: tipoArquivo,
                instituicaoFinanceira: instituicaoFinanceira,
                extensaoArquivo: extensaoArquivo,
                statusProcessamento: statusProcessamento,
                idConfiguracaoAcesso: idConfiguracaoAcesso,
                idUsuarioAcesso: idUsuarioAcesso,
                idClienteAcesso: idClienteAcesso,
                numeroCotacaoAcesso: numeroCotacaoAcesso,
                numeroPropostaAcesso: numeroPropostaAcesso,
                numeroApoliceAcesso: numeroApoliceAcesso,
                numeroConsorcioAcesso: numeroConsorcioAcesso,
            }
        )

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getArquivoConteudo = async (variaveisGlobais, idUsuario, idArquivo) => {

    const arquivoService = new ArquivoService()

    try {
        const response =
            await arquivoService.buscarArquivoByIdMandatorioConteudo(variaveisGlobais, idUsuario, idArquivo)
            
        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return {}
    }
}

export const getArquivoByGrupoArquivoTipoArquivoConteudo = async (variaveisGlobais, idUsuario, grupoArquivo, tipoArquivo,
    idConfiguracaoAcesso, idUsuarioAcesso, idClienteAcesso) => {

    const arquivoService = new ArquivoService()

    try {
        const response = await arquivoService.buscarArquivoByGrupoArquivoTipoArquivoConteudo(variaveisGlobais, idUsuario, 
            grupoArquivo, tipoArquivo, idConfiguracaoAcesso, idUsuarioAcesso, idClienteAcesso)
            
        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return {}
    }
}

export const putArquivoArquivoAcesso = async ( variaveisGlobais, idUsuario, arquivo, conteudo ) => {

    const arquivoService = new ArquivoService()

    try {
        const arquivoArquivoAcesso = {
            idUsuario: idUsuario,
            bucket: HARDCODE.conexao.bucket,
            id: arquivo.id,
            idArquivo: arquivo.idArquivo,
            idConfiguracaoOwner: arquivo.idConfiguracaoOwner,
            idUsuarioOwner: arquivo.idUsuarioOwner,
            grupoArquivo: arquivo.grupoArquivo,
            tipoArquivo: arquivo.tipoArquivo,
            instituicaoFinanceira: arquivo.instituicaoFinanceira,
            nomeArquivo: arquivo.nomeArquivo,
            extensaoArquivo: arquivo.extensaoArquivo,
            conteudo: conteudo,
            statusProcessamento: arquivo.statusProcessamento,
            idConfiguracaoAcesso: arquivo.idConfiguracaoAcesso,
            idUsuarioAcesso: arquivo.idUsuarioAcesso,
            idClienteAcesso: arquivo.idClienteAcesso,
            numeroCotacaoAcesso: arquivo.numeroCotacaoAcesso,
            numeroPropostaAcesso: arquivo.numeroPropostaAcesso,
            numeroApoliceAcesso: arquivo.numeroApoliceAcesso,
            numeroConsorcioAcesso: arquivo.numeroConsorcioAcesso,
        }
        
        if (arquivo.id) {

            await arquivoService.atualizarArquivo(variaveisGlobais, arquivoArquivoAcesso)

        } else {

           await arquivoService.salvarArquivoArquivoAcesso(variaveisGlobais, arquivoArquivoAcesso)
        }
        
        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const putArquivoStatusProcessamento = async ( variaveisGlobais, idUsuario, arquivo, statusProcessamento, registrosProcessados,
    errosProcessamento ) => {

    const arquivoService = new ArquivoService()

    try {
        const arquivoArquivoAcesso = {
            idUsuario: idUsuario,
            bucket: HARDCODE.conexao.bucket,
            id: arquivo.id,
            idArquivo: arquivo.idArquivo,
            idConfiguracaoOwner: arquivo.idConfiguracaoOwner,
            idUsuarioOwner: arquivo.idUsuarioOwner,
            statusProcessamento: statusProcessamento,
            registrosProcessados: registrosProcessados,
            errosProcessamento: errosProcessamento,
        }
        
        await arquivoService.atualizarArquivoStatusProcessamento(variaveisGlobais, arquivoArquivoAcesso)
        
        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}

export const calculaListaTipoArquivo = ( idFormList ) =>{

    let listaTipoArquivo = []

    const linhaFormList = pesquisaList(formList, idFormList)

    tipoArquivoList?.forEach( (linhaTipoArquivoList, index) => {

        if (
                linhaTipoArquivoList.filtro === 'T' ||
                (
                    linhaTipoArquivoList.filtro === 'P' &&
                    linhaFormList.informaPerfilCliente === "S"
                ) ||
                (
                    linhaTipoArquivoList.filtro === 'C' &&
                    linhaFormList.informaTipoCarteiraInvestimentoCliente === "S"
                )
        ) {

            listaTipoArquivo.push(
                {
                    grupoArquivo: linhaTipoArquivoList.grupoArquivo,
                    tipoArquivo: linhaTipoArquivoList.id,
                }
            )
        }
    })

    return listaTipoArquivo
}

export const putListaTipoArquivo = async ( variaveisGlobais, idUsuario, idConfiguracaoOwnerNULL, idUsuarioOwnerNULL,
    idConfiguracaoAcessoNULL, idUsuarioAcessoNULL, idClienteAcessoNULL, listaTipoArquivo ) => {

    const arquivoService = new ArquivoService()

    try {
        const arquivoListaTipoArquivo = {
            idUsuario: idUsuario,
            idConfiguracaoOwner: idConfiguracaoOwnerNULL,
            idUsuarioOwner: idUsuarioOwnerNULL,
            idConfiguracaoAcesso: idConfiguracaoAcessoNULL,
            idUsuarioAcesso: idUsuarioAcessoNULL,
            idClienteAcesso: idClienteAcessoNULL,
            jsonListaTipoArquivo: JSON.stringify({listaTipoArquivo: listaTipoArquivo}),
        }
        
        await arquivoService.salvarAtualizarListaTipoArquivo(variaveisGlobais, arquivoListaTipoArquivo)
        
        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}



export const deleteArquivoArquivoAcesso = async ( variaveisGlobais, idUsuario, idArquivo ) => {

    const arquivoService = new ArquivoService()

    try {
        await arquivoService.deletarArquivoArquivoAcesso(variaveisGlobais, idUsuario, idArquivo)

        return true

    } catch (erro) {

        mensagemErroErro(erro)

        return false
    }
}



export const putConverter = async ( variaveisGlobais, PDFBase64 ) => {

    const convertePDFBase64ToTextService = new ConvertePDFBase64ToTextService()

    let PDFTxt
    let mensagemErro

    try {

        const response = await convertePDFBase64ToTextService.converter(variaveisGlobais,
            AuthService.obterUsuarioLogado(variaveisGlobais), PDFBase64)

        PDFTxt = response.data
        mensagemErro = ''

    } catch (erro) {

        PDFTxt = ''
        mensagemErro = mensagemErroErro(erro)
    }

    return { PDFTxt, mensagemErro }
}