import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import {
    calculaValorMensal,
} from '../cliente-form/ClienteService'

import {
    incrementaContSeguroVidaProposta,
} from './SeguroVidaPropostaServiceProposta'

import {
    incluiSeguroVidaPropostaCoberturaDefault,
} from './SeguroVidaPropostaServiceCobertura'

import {
    calculaTempoCoberturaSeguroVida,
    calculaValorAcumuladoCoberturaSeguroVida,
} from '../seguroVida-form/SeguroVidaService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    dadosCompletosLinhaSeguroVidaCobertura,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'



export const processaRegistrosDefaultIniciaisSeguroVidaProposta = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta = []
        }

        if (!values.seguroVidaProposta) {

            values.seguroVidaProposta = []
        }
        
        if (!values.seguroVidaPropostaCobertura) {

            values.seguroVidaPropostaCobertura = []
        }
        
        if (
                !pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta, familiar).id
        ) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaProposta.push(
                {
                    id: familiar
                }
            )

            incrementaContSeguroVidaProposta(values)

            values.seguroVidaProposta.push(
                {
                    id: values.contSeguroVidaProposta,
                    familiar: familiar,
                }
            )

            incluiSeguroVidaPropostaCoberturaDefault (values, values.contSeguroVidaProposta)
        }
    }
}



export const calculaTotaisSeguroVidaProposta = ( values ) => {

    let familiarSeguroVidaProposta = Number.parseInt(values.familiarSeguroVidaProposta)

    const indexStepsValorAcompanhamentoSeguroVida = HARDCODE.indexAcompanhamentoSeguroVidaProposta

    values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0
    values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0

    let indexStepsValorSeguroVidaProposta = ''

    values.stepsValorSeguroVidaProposta?.forEach( (linhaStepsValorSeguroVidaProposta, index) => {
        values.stepsValorSeguroVidaProposta[index] = 0
        values.stepsValor2SeguroVidaProposta[index] = 0
    })

    values.totalSeguroVidaProposta = []

    if (!values.familiarSeguroVidaProposta) {

        values.familiarSeguroVidaProposta = HARDCODE.idFamiliarCliente
    }

    let valorAcumulado
    let valorPremio
    
    let valorTotal
    let valorPremioTotal

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        valorTotal = 0
        valorPremioTotal = 0

        values.seguroVidaProposta?.forEach( (linhaSeguroVidaProposta, indexSeguroVidaProposta) => {

            if (
                    linhaSeguroVidaProposta.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaProposta)
            ) {

                values.seguroVidaPropostaCobertura?.forEach( (linhaSeguroVidaPropostaCobertura, indexSeguroVidaPropostaCobertura) => {

                    if (
                            linhaSeguroVidaPropostaCobertura.idSeguroVidaProposta === linhaSeguroVidaProposta.id &&
                            dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaPropostaCobertura, true)
                    ) {

                        let tempoCoberturaSeguroVida =
                            calculaTempoCoberturaSeguroVida(values, linhaSeguroVidaPropostaCobertura, linhaFamiliaresList)

                        valorAcumulado =
                            calculaValorAcumuladoCoberturaSeguroVida(linhaSeguroVidaPropostaCobertura, tempoCoberturaSeguroVida)

                        valorPremio =
                            calculaValorMensal(linhaSeguroVidaPropostaCobertura.valorPremio,
                                linhaSeguroVidaProposta.tipoPeriodicidadePagtoPremio)

                        if (pesquisaList(tipoCoberturaSeguroVidaList,
                            linhaSeguroVidaPropostaCobertura.tipoCoberturaSeguroVida).cobreMorte === "S") {

                            valorTotal += valorAcumulado
                        }

                        valorPremioTotal += valorPremio
                        
                        if (linhaSeguroVidaProposta.familiar === familiarSeguroVidaProposta) {

                            indexStepsValorSeguroVidaProposta = linhaSeguroVidaPropostaCobertura.grupoCoberturaSeguroVida

                            values.stepsValorSeguroVidaProposta[indexStepsValorSeguroVidaProposta] += valorAcumulado
                            values.stepsValor2SeguroVidaProposta[indexStepsValorSeguroVidaProposta] +=valorPremio
                        }
                    }
                })
            }
        })

        if (
                valorTotal !== 0 ||
                valorPremioTotal !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {
    
            values.totalSeguroVidaProposta.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    title: LABEL.morteQualquerCausa,
                    valor: valorTotal,
                    title2: LABEL.valorPremioTotal,
                    valor2: valorPremioTotal,
                }
            )

            values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorTotal
            values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorPremioTotal
        }
    })
}