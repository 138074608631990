import React from 'react'

import PATH from '../../business-const/Path/Path'



const dashboardRoutes = [
    {
        path: PATH.pageDashboardCliente,
        component: React.lazy(() => import('./dashboard-cliente-form/DashboardCliente')),
    },
    {
        path: PATH.pageDashboardFamilia,
        component: React.lazy(() => import('./dashboard-familia-form/DashboardFamilia')),
    },
    {
        path: PATH.pageDashboardConsorcio,
        component: React.lazy(() => import('./dashboard-consorcio-form/DashboardConsorcio')),
    },
    {
        path: PATH.pageDashboardProjetoVidaSonho,
        component: React.lazy(() => import('./dashboard-projetoVidaSonho-form/DashboardProjetoVidaSonho')),
    },
    {
        path: PATH.pageDashboardProposta,
        component: React.lazy(() => import('./dashboard-proposta-form/DashboardProposta')),
    },
    {
        path: PATH.pageDashboardApolice,
        component: React.lazy(() => import('./dashboard-apolice-form/DashboardApolice')),
    },
    {
        path: PATH.pageDashboardApoliceCobranca,
        component: React.lazy(() => import('./dashboard-apoliceCobranca-form/DashboardApoliceCobranca')),
    },
    {
        path: PATH.pageDashboardApoliceComissao,
        component: React.lazy(() => import('./dashboard-apoliceComissao-form/DashboardApoliceComissao')),
    },
    {
        path: PATH.pageDashboardAporte,
        component: React.lazy(() => import('./dashboard-aporte-form/DashboardAporte')),
    },
    
    {
        path: PATH.pageUsuarioEstatistica,
        component: React.lazy(() => import('./dashboard-usuario-form/UsuarioEstatistica')),
    },
]

export default dashboardRoutes
