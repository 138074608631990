import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'



export const incrementaContSeguroVidaProposta = ( values ) => {

    if (!values.contSeguroVidaProposta) {

        values.contSeguroVidaProposta = 0
    }

    values.contSeguroVidaProposta = values.contSeguroVidaProposta + 1
}

export const obtemContSeguroVidaProposta = ( values ) => {

    let contSeguroVidaProposta = ''

    if (!values.contSeguroVidaProposta) {

         incrementaContSeguroVidaProposta(values)

         contSeguroVidaProposta = values.contSeguroVidaProposta

    } else {

        contSeguroVidaProposta = 1

        while (contSeguroVidaProposta <= values.contSeguroVidaProposta) {

            if (!pesquisaList(values.seguroVidaProposta, contSeguroVidaProposta).id) {
                break
            }

            contSeguroVidaProposta++
        }

        if (contSeguroVidaProposta > values.contSeguroVidaProposta) {

            incrementaContSeguroVidaProposta(values)

            contSeguroVidaProposta = values.contSeguroVidaProposta
        }  
    }

    return contSeguroVidaProposta
}



export const processaValoresDefaultIniciaisSeguroVidaProposta = ( item ) => {

    if (!item.tipoPeriodicidadePagtoPremio) {

        item.tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal
    }

    if (!item.dataAssinatura) {

        item.dataAssinatura = null
    }
}



export const dadosCompletosSeguroVidaProposta = ( values ) => {

    let dadosCompletosSeguroVidaPropostaAux = true

    values.seguroVidaProposta?.forEach( (linhaSeguroVidaProposta, index) => {
        
        if (!dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaProposta)) {
            dadosCompletosSeguroVidaPropostaAux = false
        }
    })

    return dadosCompletosSeguroVidaPropostaAux
}