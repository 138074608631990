import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class ConvertePDFBase64ToTextService extends ApiService {

    constructor() {
        super(HARDCODE.apiConvertePDFBase64ToText)
    }

    converter(variaveisGlobais, idUsuario, pdfBase64) {

        const body = {
            convertePDFBase64ToTextDTO: {
                idUsuario: idUsuario,
                pdfBase64: pdfBase64.substring(28),
            }
        }
        
        return this.post(variaveisGlobais, HARDCODE.apiConvertePDFBase64ToTextConverter, body, false)
    }
}

export default ConvertePDFBase64ToTextService