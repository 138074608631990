// alterado - revisado

import React from 'react'

import {
    CircularProgress,
} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(({ palette, ...theme }) => ({
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}))

export const CircularProgressPadrao = props => {

    const classes = useStyles()

    return (
        <CircularProgress
            size={64}
            className={
                classes.buttonProgress
            }
            {...props}
        />
    )
}