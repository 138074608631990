// alterado - revisado

export const calculaTotalPanel = ( panel, descricaoTotalPanel ) => {

    let maxIndexPanel = panel.length - 1

    let totalPanel = {
        descricao: descricaoTotalPanel,
        valorAcumuladoPainel: 0,
        valorDespesaClientePainel: 0,
        valorReceitaClientePainel: 0,
        valorDespesaConjugePainel: 0,
        valorReceitaConjugePainel: 0,
        anosProtegerProtegido: 0,
    }

    let index = 0

    while (index < maxIndexPanel) {
        if (!panel[index].valorAcumuladoPainel && panel[index].valorAcumuladoPainel !== 0) {
            panel[index].valorAcumuladoPainel = 0
            panel[index].valorDespesaClientePainel = 0
            panel[index].valorReceitaClientePainel = 0
            panel[index].valorDespesaConjugePainel = 0
            panel[index].valorReceitaConjugePainel = 0
            panel[index].anosProtegerProtegido = 0
        }

        totalPanel.valorAcumuladoPainel += panel[index].valorAcumuladoPainel
        totalPanel.valorDespesaClientePainel += panel[index].valorDespesaClientePainel
        totalPanel.valorReceitaClientePainel += panel[index].valorReceitaClientePainel
        totalPanel.valorDespesaConjugePainel += panel[index].valorDespesaConjugePainel
        totalPanel.valorReceitaConjugePainel += panel[index].valorReceitaConjugePainel
        totalPanel.anosProtegerProtegido = Math.max(totalPanel.anosProtegerProtegido, panel[index].anosProtegerProtegido)

        index++
    }

    return totalPanel
}