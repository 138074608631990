// http://localhost:3000/session/resposta/?id=apn-suk-wix&email=alexpureza@yahoo.com.br

import {
    stringParaData,
} from '../../business-components/Date/Date'



const respostaSellFluxList = [
    {
        id: '15292',
        descricao: 'Novos Usuários',
        video: 'N',
        idSellFlux: 'cfc34923abd8b6492345ffa5b9f40f46', // HARDCODE.sellFluxNovosUsuarios
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: '',
        signature: null,
        validade: '',
    },
    {
        id: '15204',
        descricao: '1o e-mail da campanha de recuperação de 03/2023',
        video: 'N',
        idSellFlux: '80ef6a7e213de4ec82a410e1ee31b422',
        reiniciarFreetrack: 'S',
        mensagem: 'O seu período de Free-track foi reiniciado com sucesso.',
        redirect: '',
        signature: null,
        validade: stringParaData('2023-03-09 00:00:00'),
    },
    {
        id: '15983',
        descricao: 'Vídeo Boas-Vindas: Planejamento Financeiro para Solteiros',
        video: 'S',
        idSellFlux: 'c80743f12de3498a6cf0d7d10e732233',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/Cery26jQ9kY',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
    {
        id: '15984',
        descricao: 'Vídeo Boas-Vindas: Planejamento Financeiro para Casais sem Filhos',
        video: 'S',
        idSellFlux: '5ef9f5ae91440a6e6cb56e870819466a',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/ItmM9qVGmjU',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
    {
        id: '15995',
        descricao: 'Vídeo Boas-Vindas: Cadastro de Clientes',
        video: 'S',
        idSellFlux: '97fd09fc2eb8eefa24c6b551f68ff559',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/Nd9uROqe3FQ',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
    {
        id: '15996',
        descricao: 'Vídeo Boas-Vindas: Registro de Despesas do Cliente',
        video: 'S',
        idSellFlux: '754d8e7fbb2133ba4c8cae034bcfbbaa',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/uAvHwm71b',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
    {
        id: '15997',
        descricao: 'Vídeo Boas-Vindas: Lançamento das Receitas do Cliente',
        video: 'S',
        idSellFlux: '2e07f93d974e8cc17a717aacd0f0ffb0',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/qTMNsMrUqz0',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
    {
        id: '15998',
        descricao: 'Vídeo Boas-Vindas: Criando Relatórios para sua Próxima Reunião',
        video: 'S',
        idSellFlux: 'f861a1acaebb1618a4db3a6983ee2cd3',
        reiniciarFreetrack: 'N',
        mensagem: '',
        redirect: 'https://youtu.be/y4128jD2cO8',
        signature: null,
        validade: stringParaData('2100-12-31 00:00:00'),
    },
]

export default respostaSellFluxList