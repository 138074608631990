import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class RespostaSellFluxService extends ApiService {

    constructor() {
        super(HARDCODE.apiRespostaSellFlux)
    }

    respostaSellFlux(variaveisGlobais, idSellFlux, emailNULL, nomeNULL, celularNULL) {
        
        let params = `idSellFlux=${idSellFlux}`

        if (emailNULL) {

            params = `${params}&email=${emailNULL}`
        }

        if (nomeNULL) {

            params = `${params}&nome=${nomeNULL}`
        }

        if (nomeNULL) {

            params = `${params}&celular=${celularNULL}`
        }

        return this.get(variaveisGlobais, `${HARDCODE.apiRespostaSellFluxEnviarResposta}/?${params}`, true)
    }
}

export default RespostaSellFluxService