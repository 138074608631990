import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    ano,
    mesMesAno,
    anoMesAno,
    formataMesAnoComBarra,
    formataMesAnoSemBarra,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
    pesquisaDescricaoList,
 } from '../../../business-rules/List/ListService'

import tipoRecursoFinanceiroList from '../../../business-rules/List/tipoRecursoFinanceiroList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import titularList from '../../../business-rules/List/titularList'

import corretoraValoresList from '../../../business-rules/List/Investimentos/corretoraValoresList'

import {
    calculaValorMensal,
    //calculaTempoAcumulado,
    calculaAnoIdadeInformada,
    calculaIntervaloTempoAnosMorteAteVitalicio,
} from '../cliente-form/ClienteService'

import {
    dadosCompletosLinhaRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    calculaTaxaRetornoLiquida,
    //converteTaxaAnualEmMensal,
    //calculaValorPresenteValorFuturo,
} from './EstudoServiceMatematicaFinanceira'

import {
    consideraProjetoVidaSonho,
} from './EstudoServiceProjetoVidaSonho'

import {
    inicializaSerieGrafico,
    calculaIndexGraficoAnosIdades,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

import {
    formataValorTipoPeriodicidade,
    calculaIdadeCorrespondenteAno,
} from './EstudoService'



export const consideraRecursoFinanceiro = (values, linhaRecursosFinanceiros) => {

    return dadosCompletosLinhaRecursosFinanceiros(linhaRecursosFinanceiros) &&
        !pesquisaList(values.exclusaoFamiliares, linhaRecursosFinanceiros.familiar).id &&
        !pesquisaList(values.exclusaoDespesasGarantias,
            LABEL.recursosFinanceiros + '/' + linhaRecursosFinanceiros.id).id
}
                    
const calculaValorAcumuladoJaRealizadoPVS = (values) => {

    let valorAcumulacaoJaRealizadoPVS = 0

    if (!values.naoConsiderarValorAcumulacaoJaRealizadoPVS) {
        
        values.projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, index) => {

            if (consideraProjetoVidaSonho(values, linhaProjetoVidaSonho)) {

                valorAcumulacaoJaRealizadoPVS +=
                    calculaValorMensal(linhaProjetoVidaSonho.valorAcumulacaoJaRealizado, null)
            }
        })
    }

    let recursosFinanceirosAux = []
    
    values.recursosFinanceiros?.forEach( (linhaRecursosFinanceiros, index) => {

        recursosFinanceirosAux.push(
            {...linhaRecursosFinanceiros}
        )

        recursosFinanceirosAux[index].valorReservadoPVS = 0
    })

    if (valorAcumulacaoJaRealizadoPVS > 0) {

        recursosFinanceirosAux?.forEach( (linhaRecursosFinanceirosAux, index) => {

            linhaRecursosFinanceirosAux.ordem = pesquisaList(tipoRecursoFinanceiroList,
                linhaRecursosFinanceirosAux.tipoRecursoFinanceiro).ordemConsumo
        })

        recursosFinanceirosAux = sortAsc(recursosFinanceirosAux)

        let ultIndex

        recursosFinanceirosAux?.forEach( (linhaRecursosFinanceirosAux, index) => {

            if (consideraRecursoFinanceiro(values, linhaRecursosFinanceirosAux)) {
            
                if (
                        valorAcumulacaoJaRealizadoPVS > 0 &&
                        pesquisaList(tipoRecursoFinanceiroList,
                            linhaRecursosFinanceirosAux.tipoRecursoFinanceiro).totalizaEntradaDados === "S"
                ) {

                    const valor = round(linhaRecursosFinanceirosAux.valor, 2)

                    linhaRecursosFinanceirosAux.valorReservadoPVS =
                        valorAcumulacaoJaRealizadoPVS > valor
                            ?
                                valor
                            :
                                valorAcumulacaoJaRealizadoPVS

                    valorAcumulacaoJaRealizadoPVS -= linhaRecursosFinanceirosAux.valorReservadoPVS

                    ultIndex = index

                } else {
                    
                    linhaRecursosFinanceirosAux.valorReservadoPVS = 0
                }
            }
        })

        if (ultIndex && valorAcumulacaoJaRealizadoPVS > 0) {

            recursosFinanceirosAux[ultIndex].valorReservadoPVS -= valorAcumulacaoJaRealizadoPVS
        }
    }

    return recursosFinanceirosAux
}

export const acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa = (values, linhaTipoRecursoFinanceiro, saldoCalculoMediaPonderadaTaxa,
    taxaRetornoAnualReal/*, anoFinal*/) => {

    if (
        values.utilizarPercRentabilidadeAnualRecursosFinanceiros &&
        linhaTipoRecursoFinanceiro.mediaPonderadaTaxaJuros === "S"
    ) {

        //const taxaRetornoMensalReal = converteTaxaAnualEmMensal(taxaRetornoAnualReal)
        
        //const qtdeMeses = calculaTempoAcumulado(values, HARDCODE.idTipoPeriodicidadeMensal, null, ano(values.dataSimulacaoEstudo), null, anoFinal)

        //const saldoCalculoMediaPonderadaTaxaVP = calculaValorPresenteValorFuturo(saldoCalculoMediaPonderadaTaxa, taxaRetornoMensalReal, qtdeMeses) 

        values.totalRecursosFinanceirosCalculoMediaPonderadaTaxa += saldoCalculoMediaPonderadaTaxa//saldoCalculoMediaPonderadaTaxaVP

        values.totalRecursosFinanceirosVezesTaxaCalculoMediaPonderadaTaxa +=
            (saldoCalculoMediaPonderadaTaxa/*saldoCalculoMediaPonderadaTaxaVP*/ * taxaRetornoAnualReal)
    }
}

export const calculaRealocacaoCarteiraInvestimentos = (values, estudo, idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos,
    mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
        mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, anoFinal, valorAporteDividendosContribuicao,
            tipoPeriodicidadeAporteDividendos) => {

    let linhaTipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos
    let linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos
    let linhaTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos
    let descricaoTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos
    let indexGraficoRecursosFinanceirosAcumuladosOrigemRealocacaoCarteiraInvestimentos
    let taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos
    let taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos
    let saldoOrigemRealocacaoCarteiraInvestimentos
    let saldoCalculoMediaPonderadaTaxaOrigemRealocacaoCarteiraInvestimentos

    linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos = pesquisaList(values.recursosFinanceiros,
        idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos)

    linhaTipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos =
        pesquisaList(tipoRecursoFinanceiroList, linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.tipoRecursoFinanceiro)

    if (linhaTipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos.atualizaTaxaJuros === 'S') {

        if (
                values.utilizarPercRentabilidadeAnualRecursosFinanceiros &&
                (
                    linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.percRentabilidadeAnualRecursoFinanceiro ||
                    linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.percRentabilidadeAnualRecursoFinanceiro === 0
                )
        ) {

            taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos =
                calculaTaxaRetornoLiquida(
                    /*round(*/linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.percRentabilidadeAnualRecursoFinanceiro/*, 2)*/,
                        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.taxaInflacaoAnualRecursoFinanceiro ||
                        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.taxaInflacaoAnualRecursoFinanceiro === 0
                            ?
                                linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.taxaInflacaoAnualRecursoFinanceiro
                            :
                                values.taxaInflacaoAnual,
                        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.tipoMomentoTributacaoIR
                            :
                                values.tipoMomentoTributacaoIR,
                        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.percIR
                            :
                                values.percIR,
                        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos
                                    .tempoMinimoEntreAportesResgatesCalculoIRAnos
                            :
                                values.tempoMinimoEntreAportesResgatesCalculoIRAnos
                )
            taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos =
                taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos

        } else {

            taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos = values.taxaRetornoAnualReal
            taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos =
                values.taxaRetornoAnualRealAposAposentadoria
        }

    } else {
        
        taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos = 0
        taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos = 0
    }

    linhaTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos = pesquisaList(tipoRecursoFinanceiroList,
        linhaTipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos.tipoRecursoFinanceiroGrafico)

    descricaoTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos =
        linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.descricaoOutros
            ?
                linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.descricaoOutros
            :
                linhaTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos.descricao

    indexGraficoRecursosFinanceirosAcumuladosOrigemRealocacaoCarteiraInvestimentos = 
        inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
            linhaTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos,
                linhaTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos.id + '-' +
                    (linhaRecursosFinanceirosOrigemRealocacaoCarteiraInvestimentos.naoInventariar ? 'S' : 'N') + '-' +
                        descricaoTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos,
                            descricaoTipoRecursoFinanceiroGraficoOrigemRealocacaoCarteiraInvestimentos)

    saldoOrigemRealocacaoCarteiraInvestimentos =
        calculaGraficoAcumulacaoMensal(mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
            mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, 0,
                valorAporteDividendosContribuicao * -1,
                    tipoPeriodicidadeAporteDividendos, estudo.current.graficoAnos,
                        estudo.current.graficoRecursosFinanceirosAcumulados[
                            indexGraficoRecursosFinanceirosAcumuladosOrigemRealocacaoCarteiraInvestimentos].serie,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos,
                                        taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos,
                                            true, null, null, null, null, null)

    if (mesFinalAporteDividendosContribuicao < 12) {

        const indexGraficoAnos =
            calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinalAporteDividendosContribuicao)

        estudo.current.graficoRecursosFinanceirosAcumulados[
            indexGraficoRecursosFinanceirosAcumuladosOrigemRealocacaoCarteiraInvestimentos]
                .serie[indexGraficoAnos] -= saldoOrigemRealocacaoCarteiraInvestimentos

        estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldoOrigemRealocacaoCarteiraInvestimentos
    }

    saldoCalculoMediaPonderadaTaxaOrigemRealocacaoCarteiraInvestimentos = 
        calculaGraficoAcumulacaoMensal(mesFinalAporteDividendosContribuicao === 12 ? 1 : mesFinalAporteDividendosContribuicao + 1,
            mesFinalAporteDividendosContribuicao === 12 ? anoFinalAporteDividendosContribuicao + 1 : anoFinalAporteDividendosContribuicao,
                null, anoFinal, saldoOrigemRealocacaoCarteiraInvestimentos, 0, null,
                    estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                        indexGraficoRecursosFinanceirosAcumuladosOrigemRealocacaoCarteiraInvestimentos].serie,
                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                values, estudo, taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos,
                                    taxaRetornoAnualRealAposAposentadoriaOrigemRealocacaoCarteiraInvestimentos,
                                        true, null, null, null, null, null)

    acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos,
        saldoCalculoMediaPonderadaTaxaOrigemRealocacaoCarteiraInvestimentos,
            taxaRetornoAnualRealOrigemRealocacaoCarteiraInvestimentos/*,anoFinal*/)
}

export const temAporteDividendos = (linhaTipoRecursoFinanceiro, linhaRecursosFinanceiros) => {

    return linhaTipoRecursoFinanceiro.informaAporteDividendos === "S" &&
    (
        linhaRecursosFinanceiros.valorAporteDividendos ||
        linhaRecursosFinanceiros.valorAporteEmpresa
    )
}

const calculaAnoFinalAporteDividendosContribuicao = (values, linhaRecursosFinanceiros, linhaTitularList) => {

    let anoFinalAporteDividendosContribuicao

    if (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente) {

        anoFinalAporteDividendosContribuicao =
            calculaAnoIdadeInformada(values, values.idadeVaiAposentarCliente, linhaTitularList.idadeDataSimulacao)

    } else {

        anoFinalAporteDividendosContribuicao = 
            calculaAnoIdadeInformada(values, values.idadeVaiAposentarConjuge, linhaTitularList.idadeDataSimulacao)
    }

    return anoFinalAporteDividendosContribuicao
}

export const calculaValorAporteDividendos = (linhaRecursosFinanceiros) => {

    return (linhaRecursosFinanceiros.valorAporteDividendos
        ?
            round(linhaRecursosFinanceiros.valorAporteDividendos, 2)
        :
            0
    ) + 
    (linhaRecursosFinanceiros.valorAporteEmpresa
        ?
            round(linhaRecursosFinanceiros.valorAporteEmpresa, 2)
        :
            0
    )
}

/*
export const calculaRecursosFinanceirosDetalhamentoObservacao = ( linhaRecursosFinanceiros ) => {

    return linhaRecursosFinanceiros.observacao
        ?
            linhaRecursosFinanceiros.observacao
        :
            linhaRecursosFinanceiros.instituicao
                ?
                    linhaRecursosFinanceiros.instituicao
                :
                    linhaRecursosFinanceiros.nomeAcao
                        ?
                            linhaRecursosFinanceiros.nomeAcao
                        :
                            linhaRecursosFinanceiros.nomeFundo
}
*/

export const incluiAportePlanejadoAposentadoria = (estudo, id, descricao, mesInicial, anoInicial, idadeInicial, mesFinal, anoFinal, idadeFinal,
    tipoPeriodicidadeAporteDividendos, valorPercAporteDividendosContribuicao) => {

    estudo.current.aportePlanejadoAposentadoria.push(
        {
            id: id,
            descricao: descricao,
            mesAnoInicialAporteDividendos:
                formataMesAnoComBarra(mesInicial, anoInicial),
            idadeInicialAporteDividendos: idadeInicial,
            mesAnoFinalAporteDividendos:
                formataMesAnoComBarra(mesFinal, anoFinal),
            idadeFinalAporteDividendos: idadeFinal,
            descricaoTipoPeriodicidadeAporteDividendos:
                pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidadeAporteDividendos).descricao,
            valorPercAporteDividendosContribuicao: valorPercAporteDividendosContribuicao
        }
    )
}

const limpaDadosAdicionaisRecursosFinanceiros = (values, index) => {

    values.recursosFinanceiros[index].descricaoTipoRecursoFinanceiro = ''
    values.recursosFinanceiros[index].nomeCurtoFamiliar = ''
    values.recursosFinanceiros[index].mesAnoFinalAporteDividendosControleAportes = ''
}


export const processaRecursoFinanceiro = (values, estudo, linhaRecursosFinanceiros, index) => {

    let linhaTipoRecursoFinanceiro
    let descricaoTipoRecursoFinanceiro

    let titularListAux = titularList(values, 'completo')
    let linhaTitularList
    let nomeCurtoFamiliar

    let anoInicial = ano(values.dataSimulacaoEstudo)
    let anoFinal = values.anoFinalVitalicioClienteConjuge
    let anoFinalAporteDividendosContribuicaoOficial
    let mesInicialAporteDividendosContribuicao
    let anoInicialAporteDividendosContribuicao
    let mesFinalAporteDividendosContribuicao
    let anoFinalAporteDividendosContribuicao

    let linhaTipoRecursoFinanceiroGrafico
    let descricaoTipoRecursoFinanceiroGrafico
    let indexGraficoRecursosFinanceirosAcumulados

    let taxaRetornoAnualReal
    let taxaRetornoAnualFgtsReal
    let taxaRetornoAnualRealAposAposentadoria
    let taxaRetornoAnualFgtsRealAposAposentadoria

    let valor
    let valorReservadoPVS
    let saldoNaoReservado
    let valorAporteDividendosContribuicao
    let valorAcumulado
    
    let linhaReceitaMensal

    let saldo

    let saldoCalculoMediaPonderadaTaxa

    const recursosFinanceirosAux = calculaValorAcumuladoJaRealizadoPVS(values)



    linhaTipoRecursoFinanceiro =
        pesquisaList(tipoRecursoFinanceiroList, linhaRecursosFinanceiros.tipoRecursoFinanceiro)

    descricaoTipoRecursoFinanceiro =
        linhaRecursosFinanceiros.descricaoOutros
            ?
                linhaRecursosFinanceiros.descricaoOutros
            :
                linhaTipoRecursoFinanceiro.descricao

    linhaTitularList = pesquisaList(titularListAux, linhaRecursosFinanceiros.familiar)

    nomeCurtoFamiliar = linhaTitularList.descricao

    if (linhaTipoRecursoFinanceiro.atualizaTaxaJuros === 'S') {
        
        if (
                values.utilizarPercRentabilidadeAnualRecursosFinanceiros &&
                (
                    linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro || 
                    linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro === 0
                )
        ) {

            taxaRetornoAnualReal =
                calculaTaxaRetornoLiquida(
                    /*round(*/linhaRecursosFinanceiros.percRentabilidadeAnualRecursoFinanceiro/*, 2)*/,
                        linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro ||
                        linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro === 0
                            ?
                                linhaRecursosFinanceiros.taxaInflacaoAnualRecursoFinanceiro
                            :
                                values.taxaInflacaoAnual,
                        linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                            :
                                values.tipoMomentoTributacaoIR,
                        linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceiros.percIR
                            :
                                values.percIR,
                        linhaRecursosFinanceiros.tipoMomentoTributacaoIR
                            ?
                                linhaRecursosFinanceiros.tempoMinimoEntreAportesResgatesCalculoIRAnos
                            :
                                values.tempoMinimoEntreAportesResgatesCalculoIRAnos
                )
            taxaRetornoAnualRealAposAposentadoria = taxaRetornoAnualReal

        } else {

            taxaRetornoAnualReal = values.taxaRetornoAnualReal
            taxaRetornoAnualRealAposAposentadoria = values.taxaRetornoAnualRealAposAposentadoria
        }

        taxaRetornoAnualFgtsReal = values.taxaRetornoAnualFgtsReal
        taxaRetornoAnualFgtsRealAposAposentadoria = values.taxaRetornoAnualFgtsRealAposAposentadoria

    } else {
        
        taxaRetornoAnualReal = 0
        taxaRetornoAnualFgtsReal = 0
        taxaRetornoAnualRealAposAposentadoria = 0
        taxaRetornoAnualFgtsRealAposAposentadoria = 0
    }

    if (linhaTipoRecursoFinanceiro.totalizaEntradaDados === "S") {

        valor = round (linhaRecursosFinanceiros.valor, 2)

        const linhaRecursosFinanceirosAux = pesquisaList(recursosFinanceirosAux, linhaRecursosFinanceiros.id)
            
        valorReservadoPVS = linhaRecursosFinanceirosAux.valorReservadoPVS
        saldoNaoReservado = valor - valorReservadoPVS

    } else {

        valor = 0
        valorReservadoPVS = 0
        saldoNaoReservado = 0
    }
    
    valorAporteDividendosContribuicao = 0

    if (
            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal ||
            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente ||
            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarConjuge
    ) {

        linhaTipoRecursoFinanceiroGrafico = pesquisaList(tipoRecursoFinanceiroList,
            linhaTipoRecursoFinanceiro.tipoRecursoFinanceiroGrafico)

        descricaoTipoRecursoFinanceiroGrafico =
            linhaRecursosFinanceiros.chaveIntegracao1 && linhaRecursosFinanceiros.instituicaoFinanceira
                ?
                    pesquisaDescricaoList(corretoraValoresList(), linhaRecursosFinanceiros.instituicaoFinanceira) +
                        ' - ' + linhaTipoRecursoFinanceiroGrafico.descricao
                :
                    linhaRecursosFinanceiros.descricaoOutros
                        ?
                            linhaRecursosFinanceiros.descricaoOutros
                        :
                            linhaTipoRecursoFinanceiroGrafico.descricao

        indexGraficoRecursosFinanceirosAcumulados =
            inicializaSerieGrafico(values, estudo.current.graficoRecursosFinanceirosAcumulados,
                linhaTipoRecursoFinanceiroGrafico, linhaTipoRecursoFinanceiroGrafico.id + '-' +
                    (linhaRecursosFinanceiros.naoInventariar ? 'S' : 'N') + '-' +
                        descricaoTipoRecursoFinanceiroGrafico, descricaoTipoRecursoFinanceiroGrafico)

        if (linhaTipoRecursoFinanceiro.totalizaEntradaDados === "S") {

            if (
                    linhaRecursosFinanceiros.tipoRecursoFinanceiro === HARDCODE.idTipoRecursoFinanceiroFgtsSaldo
            ) {

                anoFinalAporteDividendosContribuicao =
                    calculaAnoFinalAporteDividendosContribuicao(values, linhaRecursosFinanceiros, linhaTitularList)

                saldo = calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinalAporteDividendosContribuicao,
                    saldoNaoReservado, 0, null, estudo.current.graficoAnos,
                        estudo.current.graficoRecursosFinanceirosAcumulados[
                            indexGraficoRecursosFinanceirosAcumulados].serie,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, taxaRetornoAnualFgtsReal,
                                        taxaRetornoAnualFgtsRealAposAposentadoria, true, null, null, null, null, null)

                calculaGraficoAcumulacaoMensal(null, anoFinalAporteDividendosContribuicao + 1, null, anoFinal, saldo, 0, null,
                    estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                        indexGraficoRecursosFinanceirosAcumulados].serie,
                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                values, estudo, taxaRetornoAnualReal,
                                    taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)

            } else {

                saldoCalculoMediaPonderadaTaxa =
                    calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, saldoNaoReservado, 0, null,
                        estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal,
                                            taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)

                acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiro,
                    saldoCalculoMediaPonderadaTaxa, taxaRetornoAnualReal/*, anoFinal*/)
            }
        }

        if (temAporteDividendos(linhaTipoRecursoFinanceiro, linhaRecursosFinanceiros)) {
        
            mesInicialAporteDividendosContribuicao = mesMesAno(linhaRecursosFinanceiros.mesAnoInicialAporteDividendos)
            anoInicialAporteDividendosContribuicao = anoMesAno(linhaRecursosFinanceiros.mesAnoInicialAporteDividendos)

            if (linhaRecursosFinanceiros.aporteDividendosAteAposentadoria) {

                mesFinalAporteDividendosContribuicao = 12
                anoFinalAporteDividendosContribuicao =
                    linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente ||
                    linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal
                        ?
                            values.anoFinalVaiAposentarCliente
                        :
                            values.anoFinalVaiAposentarConjuge

            } else {

                mesFinalAporteDividendosContribuicao = mesMesAno(linhaRecursosFinanceiros.mesAnoFinalAporteDividendos)
                anoFinalAporteDividendosContribuicao = anoMesAno(linhaRecursosFinanceiros.mesAnoFinalAporteDividendos)
            }

            valorAporteDividendosContribuicao = calculaValorAporteDividendos(linhaRecursosFinanceiros)

            saldo = calculaGraficoAcumulacaoMensal(mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, 0,
                    valorAporteDividendosContribuicao,
                        linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos, estudo.current.graficoAnos,
                            estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal,
                                            taxaRetornoAnualRealAposAposentadoria, true, null, null,
                                                null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

            if (linhaRecursosFinanceiros.aporteOriundoReceitaMensal) {

                calculaGraficoAcumulacaoMensal(mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                    mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, 0,
                        linhaRecursosFinanceiros.valorAporteDividendos ? round(linhaRecursosFinanceiros.valorAporteDividendos, 2) : 0,
                            linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos, estudo.current.graficoAnos,
                                estudo.current.graficoAporteAnualTotal, null, values, estudo, 0, 0, false, null, null, null, null, null)
            }

            if (mesFinalAporteDividendosContribuicao < 12) {

                const indexGraficoAnos =
                    calculaIndexGraficoAnosIdades(estudo.current.graficoAnos,
                        anoFinalAporteDividendosContribuicao)

                estudo.current.graficoRecursosFinanceirosAcumulados[
                    indexGraficoRecursosFinanceirosAcumulados].serie[indexGraficoAnos] -= saldo

                estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldo
            }

            saldoCalculoMediaPonderadaTaxa =
                calculaGraficoAcumulacaoMensal(mesFinalAporteDividendosContribuicao === 12 ? 1 : mesFinalAporteDividendosContribuicao + 1,
                    mesFinalAporteDividendosContribuicao === 12 ? anoFinalAporteDividendosContribuicao + 1 : anoFinalAporteDividendosContribuicao, null,
                        anoFinal, saldo, 0, null,
                            estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                                indexGraficoRecursosFinanceirosAcumulados].serie,
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                        values, estudo, taxaRetornoAnualReal,
                                            taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)

            acumulaTotalRecursosFinanceirosCalculoMediaPonderadaTaxa(values, linhaTipoRecursoFinanceiro,
                saldoCalculoMediaPonderadaTaxa, taxaRetornoAnualReal/*, anoFinal*/)

            if (linhaRecursosFinanceiros.aporteOriundoRealocacaoCarteiraInvestimentos) {

                calculaRealocacaoCarteiraInvestimentos(values, estudo, 
                    linhaRecursosFinanceiros.idRecursoFinanceiroOrigemRealocacaoCarteiraInvestimentos,
                        mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                            mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao,
                                anoFinal, valorAporteDividendosContribuicao,
                                    linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos)
            }

            if (!linhaRecursosFinanceiros.aporteOriundoRealocacaoCarteiraInvestimentos) {
                
                incluiAportePlanejadoAposentadoria(estudo, linhaRecursosFinanceiros.id,
                    descricaoTipoRecursoFinanceiroGrafico/*LABEL.emAndamento*/,
                        mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                            calculaIdadeCorrespondenteAno(values, estudo, anoInicialAporteDividendosContribuicao, null),
                                mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao,
                                    calculaIdadeCorrespondenteAno(values, estudo, anoFinalAporteDividendosContribuicao, null),
                                        linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos, valorAporteDividendosContribuicao)
            }

            // Campos do controle de aportes: no caso dos Recursos Financeiros são calculados sempre, pois independem do cenário Principal
            if (index !== null) {

                values.recursosFinanceiros[index].descricaoTipoRecursoFinanceiro = descricaoTipoRecursoFinanceiro
                values.recursosFinanceiros[index].nomeCurtoFamiliar = nomeCurtoFamiliar
                values.recursosFinanceiros[index].mesAnoFinalAporteDividendosControleAportes = 
                    formataMesAnoSemBarra(mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao)
            }

        } else {

            if (index !== null) {
            
                limpaDadosAdicionaisRecursosFinanceiros(values, index)
            }
        }
        
        if (
                linhaRecursosFinanceiros.tipoRecursoFinanceiro === 
                    HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao
        ) {
            
            linhaReceitaMensal =
                pesquisaList(values.receitaMensal, linhaRecursosFinanceiros.idReceitaMensal)
            
            anoFinalAporteDividendosContribuicaoOficial =
                calculaAnoFinalAporteDividendosContribuicao(values, linhaRecursosFinanceiros, linhaTitularList)
            
            if (linhaReceitaMensal.alterarTipoCalculoAcumulado) {

                mesInicialAporteDividendosContribuicao = mesMesAno(linhaReceitaMensal.mesAnoInicial)
                anoInicialAporteDividendosContribuicao = anoMesAno(linhaReceitaMensal.mesAnoInicial)
                mesFinalAporteDividendosContribuicao = mesMesAno(linhaReceitaMensal.mesAnoFinal)
                anoFinalAporteDividendosContribuicao = anoMesAno(linhaReceitaMensal.mesAnoFinal)

            } else {

                mesInicialAporteDividendosContribuicao = null
                anoInicialAporteDividendosContribuicao = anoInicial
                mesFinalAporteDividendosContribuicao = 12
                anoFinalAporteDividendosContribuicao = anoFinalAporteDividendosContribuicaoOficial
                    
            }

            valorAporteDividendosContribuicao = round(linhaRecursosFinanceiros.valor, 2)

            saldo = calculaGraficoAcumulacaoMensal(mesInicialAporteDividendosContribuicao, anoInicialAporteDividendosContribuicao,
                mesFinalAporteDividendosContribuicao, anoFinalAporteDividendosContribuicao, 0,
                    valorAporteDividendosContribuicao, linhaReceitaMensal.tipoPeriodicidadeRecebto, estudo.current.graficoAnos,
                        estudo.current.graficoRecursosFinanceirosAcumulados[
                            indexGraficoRecursosFinanceirosAcumulados].serie,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, taxaRetornoAnualFgtsReal,
                                        taxaRetornoAnualFgtsRealAposAposentadoria, true, null, null,
                                            null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

            if (mesFinalAporteDividendosContribuicao < 12) {

                const indexGraficoAnos =
                    calculaIndexGraficoAnosIdades(estudo.current.graficoAnos,
                        anoFinalAporteDividendosContribuicao)

                estudo.current.graficoRecursosFinanceirosAcumulados[
                    indexGraficoRecursosFinanceirosAcumulados].serie[indexGraficoAnos] -= saldo

                estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldo
            }

            saldo = calculaGraficoAcumulacaoMensal(mesFinalAporteDividendosContribuicao === 12 ? 1 : mesFinalAporteDividendosContribuicao + 1,
                mesFinalAporteDividendosContribuicao === 12 ? anoFinalAporteDividendosContribuicao + 1 : anoFinalAporteDividendosContribuicao,
                    null, anoFinalAporteDividendosContribuicaoOficial, saldo, 0, null,
                        estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                            indexGraficoRecursosFinanceirosAcumulados].serie,
                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                    values, estudo, taxaRetornoAnualFgtsReal,
                                        taxaRetornoAnualFgtsRealAposAposentadoria, true, null, null, null, null, null)
            
            calculaGraficoAcumulacaoMensal(null, anoFinalAporteDividendosContribuicaoOficial + 1,
                null, anoFinal, saldo, 0, null,
                    estudo.current.graficoAnos, estudo.current.graficoRecursosFinanceirosAcumulados[
                        indexGraficoRecursosFinanceirosAcumulados].serie,
                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                values, estudo, taxaRetornoAnualReal,
                                    taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)
        }
        
        valorAcumulado = valor

        if (valor > 0) {
            
            estudo.current.recursosFinanceirosDetalhado.push(
                {
                    ordem: valor * -1,
                    descricao: descricaoTipoRecursoFinanceiro,
                    valor: valor,
                }
            )
        }

    } else {

        if (index !== null) {

            limpaDadosAdicionaisRecursosFinanceiros(values, index)
        }

        if (temAporteDividendos(linhaTipoRecursoFinanceiro, linhaRecursosFinanceiros)) {

            valorAporteDividendosContribuicao = calculaValorAporteDividendos(linhaRecursosFinanceiros)
        }
        
        if (linhaRecursosFinanceiros.tipoRecursoFinanceiro === HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao) {

            valorAporteDividendosContribuicao = round (linhaRecursosFinanceiros.valor, 2)
        }

        valorAcumulado = 0
    }

    return { linhaTipoRecursoFinanceiro, descricaoTipoRecursoFinanceiro, nomeCurtoFamiliar, valor,
        valorReservadoPVS, saldoNaoReservado, valorAporteDividendosContribuicao, valorAcumulado, linhaReceitaMensal }
}



export const calculaGarantiasRecursosFinanceiros = (values, estudo, report) => {
    let recursosFinanceirosTemp = []
    let recursosFinanceiros = []
    estudo.current.recursosFinanceirosDetalhado = []
    estudo.current.valorRecursosFinanceirosDetalhado = 0
    let panelGarantiasAtuaisRecursosFinanceiros

    if (values.recursosFinanceiros) {
        let valorGarantiaCliente
        let valorGarantiaConjuge

        let valorTotal = 0
        let valorAcumuladoTotal = 0
        let valorReservadoPVSTotal = 0
        let saldoNaoReservadoTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorGarantiaConjugeTotal = 0

        values.totalRecursosFinanceirosCalculoMediaPonderadaTaxa = 0
        values.totalRecursosFinanceirosVezesTaxaCalculoMediaPonderadaTaxa = 0

        values.recursosFinanceiros.forEach( (linhaRecursosFinanceiros, index) => {

            if (consideraRecursoFinanceiro(values, linhaRecursosFinanceiros)){

                const { linhaTipoRecursoFinanceiro, descricaoTipoRecursoFinanceiro, nomeCurtoFamiliar, valor,
                    valorReservadoPVS, saldoNaoReservado, valorAporteDividendosContribuicao, valorAcumulado, linhaReceitaMensal } =
                        processaRecursoFinanceiro(values, estudo, linhaRecursosFinanceiros, index) 

                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                if (
                        (
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal ||
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCliente ||
                            linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarConjuge
                        ) &&
                        linhaTipoRecursoFinanceiro.totalizaEntradaDados === "S"
                ) {

                    if (!linhaRecursosFinanceiros.naoConsiderarGarantiaMorteInvalidezCliente) {

                        valorGarantiaCliente = saldoNaoReservado
                    }

                    if (!linhaRecursosFinanceiros.naoConsiderarGarantiaMorteInvalidezConjuge) {

                        valorGarantiaConjuge = saldoNaoReservado
                    }
                }

                recursosFinanceirosTemp.push({
                    ordem: ("0000" + (linhaRecursosFinanceiros.familiar === HARDCODE.idFamiliarCasal ? 0 : linhaRecursosFinanceiros.familiar)).slice(-4) +
                            linhaRecursosFinanceiros.tipoRecursoFinanceiro,
                    familiar: linhaRecursosFinanceiros.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar, 
                    descricaoTipoRecursoFinanceiro: descricaoTipoRecursoFinanceiro,
                    valor: valor,
                    valorReservadoPVS: valorReservadoPVS,
                    saldoNaoReservado: saldoNaoReservado,
                    valorAporteDividendosContribuicao: valorAporteDividendosContribuicao,
                    tipoPeriodicidadeAporteDividendos:
                        linhaRecursosFinanceiros.tipoRecursoFinanceiro === 
                            HARDCODE.idTipoRecursoFinanceiroFgtsContribuicao
                                ?
                                    linhaReceitaMensal.tipoPeriodicidadeRecebto
                                :
                                    linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos,
                    valorAcumulado: valorAcumulado,
                    valorGarantiaCliente: valorGarantiaCliente,
                    valorGarantiaConjuge: valorGarantiaConjuge,
                })

                valorTotal += valor
                valorAcumuladoTotal += valorAcumulado
                valorReservadoPVSTotal += valorReservadoPVS
                saldoNaoReservadoTotal += saldoNaoReservado
                valorGarantiaClienteTotal += valorGarantiaCliente
                valorGarantiaConjugeTotal += valorGarantiaConjuge
            }
        })

        recursosFinanceirosTemp = sortAsc(recursosFinanceirosTemp)

        estudo.current.graficoRecursosFinanceirosAcumulados = sortAsc(estudo.current.graficoRecursosFinanceirosAcumulados)

        let familiarAnt = null

        recursosFinanceirosTemp.forEach( (linhaRecursosFinanceiros, index) => {

            if (linhaRecursosFinanceiros.familiar !== familiarAnt) {

                familiarAnt = linhaRecursosFinanceiros.familiar

                let valor = 0
                let valorAcumulado = 0
                let valorReservadoPVS = 0
                let saldoNaoReservado = 0
                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                recursosFinanceirosTemp.forEach( (linhaRecursoFinanceiroTotal, index) => {

                    if (linhaRecursoFinanceiroTotal.familiar === linhaRecursosFinanceiros.familiar) {

                        valor += linhaRecursoFinanceiroTotal.valor
                        valorAcumulado += linhaRecursoFinanceiroTotal.valorAcumulado
                        valorReservadoPVS += linhaRecursoFinanceiroTotal.valorReservadoPVS
                        saldoNaoReservado += linhaRecursoFinanceiroTotal.saldoNaoReservado
                        valorGarantiaCliente += linhaRecursoFinanceiroTotal.valorGarantiaCliente
                        valorGarantiaConjuge += linhaRecursoFinanceiroTotal.valorGarantiaConjuge
                    }
                })

                recursosFinanceiros.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaRecursosFinanceiros.nomeCurtoFamiliar,
                            report),
                    valor:
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report),
                    valorAcumulado:
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorReservadoPVS:
                        formataTableCellBodyExibeDadosSubTotalValor(valorReservadoPVS, report),
                    saldoNaoReservado:
                        formataTableCellBodyExibeDadosSubTotalValor(saldoNaoReservado, report),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report),
                })
            }

            recursosFinanceiros.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("          " + 
                        linhaRecursosFinanceiros.descricaoTipoRecursoFinanceiro, report),
                valor:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valor, report),
                valorAporteDividendosContribuicao:
                    formataValorTipoPeriodicidade(linhaRecursosFinanceiros.valorAporteDividendosContribuicao, 2, false, false,
                        linhaRecursosFinanceiros.tipoPeriodicidadeAporteDividendos, false, report),
                valorAcumulado:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorAcumulado, report),
                valorReservadoPVS:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorReservadoPVS, report),
                saldoNaoReservado:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.saldoNaoReservado, report),
                valorGarantiaCliente:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorGarantiaCliente, report),
                valorGarantiaConjuge:
                    formataTableCellBodyExibeDadosValor(linhaRecursosFinanceiros.valorGarantiaConjuge, report),
            })
        })

        recursosFinanceiros.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valor:
                formataTableCellBodyExibeDadosTotalValor(valorTotal, report),
            valorAcumulado:
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorReservadoPVS:
                formataTableCellBodyExibeDadosTotalValor(valorReservadoPVSTotal, report),
            valorReservadoPVSTotal: valorReservadoPVSTotal,
            saldoNaoReservado:
                formataTableCellBodyExibeDadosTotalValor(saldoNaoReservadoTotal, report),
            valorGarantiaCliente:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report),
            valorGarantiaConjuge:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report),
        })



        values.reservaInicialEstudo = saldoNaoReservadoTotal



        estudo.current.recursosFinanceirosDetalhado = sortAsc(estudo.current.recursosFinanceirosDetalhado)

        valorTotal = 0

        estudo.current.recursosFinanceirosDetalhado.forEach( (linhaRecursoFinanceiro, index) => {

            valorTotal += linhaRecursoFinanceiro.valor

            estudo.current.recursosFinanceirosDetalhado[index].nivel = HARDCODE.reportNivelDados
        })

        if (valorTotal > 0) {

            estudo.current.recursosFinanceirosDetalhado.push({
                nivel: HARDCODE.reportNivelDadosTotal,
                descricao: LABEL.total,
                valor: valorTotal,
            })

            estudo.current.valorRecursosFinanceirosDetalhado = valorTotal
        }


    
        panelGarantiasAtuaisRecursosFinanceiros = {
            descricao: LABEL.recursosFinanceiros,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: valorGarantiaClienteTotal,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: valorGarantiaConjugeTotal,
            anosProtegerProtegido: calculaIntervaloTempoAnosMorteAteVitalicio(values),
        }

    } else {

        panelGarantiasAtuaisRecursosFinanceiros = {
            descricao: LABEL.recursosFinanceiros,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.recursosFinanceiros = recursosFinanceiros

    estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoRecursosFinanceiros] = panelGarantiasAtuaisRecursosFinanceiros
}