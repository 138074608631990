import AuthService from '../../business-api/AuthService'

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import LABELESPECIAL from '../../business-const/Label/LabelEspecial'

import {
    CLASSNAME
} from '../../business-const/Style/Style'

import {
    formataData,
    formataMesAnoTextoComBarra,
} from '../../business-components/Date/Date'

import {
    round
} from '../../business-components/round/round'

//import { 
//    IsMdDownScreen,
//} from '../../business-components/Service/GlobalService'

import {
    GridContainer,
    GridItem5,
    GridItem7,
} from '../../business-components/Grid/Grid'

import {
    converteTaxaAnualEmMensal
} from '../../views/clientes/estudo-form/EstudoServiceMatematicaFinanceira'



export const nvl = ( dado1, dado2 ) => {

    if (dado1) {

        return dado1

    } else {

        return dado2
    }
}

export const espacoAntes = ( texto1, texto2 ) => {

    if (texto1) {

        return texto1.trim() + ' ' + texto2.trim()

    } else {

        return texto2.trim()
    }
}


export const moedaPrefixSuffix = () => {

    const moedaPrefixSuffix = AuthService.obterMoedaPrefixSuffix()

    return !moedaPrefixSuffix || moedaPrefixSuffix === undefined ? {prefix: LABELESPECIAL.moedaReal, suffix: ''} : moedaPrefixSuffix
}

export const formataValor = ( valor, decimais, semMoeda ) => {

    const { prefix, suffix } = moedaPrefixSuffix()
    
    let valorFormatado = ''

    if (valor || valor === 0) {

        if (valor === LABEL.tracoXtraco) {

            valorFormatado = valor

        } else {
            
            if (!decimais) {
                if (valor % 1 === 0) {
                    decimais = 0
                } else {
                    decimais = 2
                }
            }

            valorFormatado =
                (semMoeda ? "" : prefix) + 
                    valor.toLocaleString(HARDCODE.location,
                        {minimumFractionDigits: decimais, maximumFractionDigits: decimais}) + (semMoeda ? "" : suffix)
        }
    }

    return valorFormatado
}

export const formataNumero = ( valor ) => {

    return formataValor(valor, 0, true)
}

export const formataPerc = ( valor, decimais, semPerc ) => {

    return valor || valor === 0
        ?
            formataValor(valor, decimais || decimais === 0 ? decimais : 2, true) + (semPerc ? '' : LABEL.perc)
        :
            valor
}

export const formataCpf = ( cpf ) => {

    return cpf ? ('' + cpf).replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4") : cpf
}

export const formataTelefone = ( telefone ) => {

    return telefone ? telefone.replace(/(\d{2})(\d{4})(\d{4})/, "($1) $2-$3") : telefone
}

export const formataCelular = ( celular ) => {

    return celular ? celular.replace(/(\d{2})(\d{5})(\d{4})/, "($1) $2-$3") : celular
}


const alignTextoValorPerc = 'left' // mudança da fonte: text-16



export const formataTableCellHeadExibeDadosTitulo = ( conteudo, report, align, aumentoLetra, aumentoLetraReport, values ) => {

    return (
        <span
            align={align ? align : "left"}
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDados}
        >
            {conteudo}
            {/* // mudança da fonte
            <br/>
            <br/>
            */}
        </span>
    )

    /*
    return IsMdDownScreen()
        ?
            <span
                align={align ? align : "left"}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo}
                <br/>
                <br/>
            </span>
        : 
            <span
                align={align ? align : "left"}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo}
                <br/>
                <br/>
            </span>
    */
}

export const formataTableCellHeadExibeDadosTexto = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    let conteudo1 = conteudo.split(' ').slice(0, 1)
    let conteudo2 = conteudo.split(' ').length > 1 ? conteudo.split(' ').slice(1, 2) : '' // mudança da fonte: tratamento > 1
    let conteudo3 = conteudo.split(' ').length > 2 ? conteudo.split(' ').slice(2, 3) : '' // mudança da fonte: tratamento > 2

    if (conteudo1.toString() === '%') {
        conteudo1 = conteudo1 + " " + conteudo2
        conteudo2 = conteudo.split(' ').length > 2 ? conteudo.split(' ').slice(2, 3) : '' // mudança da fonte: tratamento > 2
        conteudo3 = conteudo.split(' ').length > 2 ? conteudo.substr(conteudo1.toString().length + conteudo2.toString().length + 2) : '' // mudança da fonte: tratamento > 2
    }

    return (
        <span
            align={alignTextoValorPerc}
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDados}
        >
            {conteudo1}
            {conteudo2 && ( // mudança da fonte
                <div
                    className='mt--3'
                >
                    {conteudo2}
                    {conteudo3 && ( // mudança da fonte
                        <div
                            className='mt--3'
                        >
                            {conteudo3}
                        </div>
                    )}
                </div>
            )}
        </span>
    )

    /*
    return IsMdDownScreen()
        ?
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
        : 
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
    */
}

export const formataTableCellHeadExibeDadosValor = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    const conteudo1 = conteudo.split(' ').slice(0, 1)
    const conteudo2 = conteudo.split(' ').length > 1 ? conteudo.split(' ').slice(1, 2) : '' // mudança da fonte: tratamento > 1
    const conteudo3 = conteudo.split(' ').length > 2 ? conteudo.substr(conteudo1.toString().length + conteudo2.toString().length + 2) : '' // mudança da fonte: tratamento > 2

    return (
        <span
            align={alignTextoValorPerc}
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDados}
        >
            {conteudo1}
            {conteudo2 && ( // mudança da fonte
                <div
                    className='mt--3'
                >
                    {conteudo2}
                    {conteudo3 && ( // mudança da fonte
                        <div
                            className='mt--3'
                        >
                            {conteudo3}
                        </div>
                    )}
                </div>
            )}
        </span>
    )

    /*
    return IsMdDownScreen()
        ?
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
        : 
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
    */
}

export const formataTableCellHeadExibeDadosValorDestaque3 = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    const conteudo1 = conteudo.split(' ').slice(0, 1)
    const conteudo2 = conteudo.split(' ').slice(1, 2)
    const conteudo3 = conteudo.substr(conteudo1.toString().length + conteudo2.toString().length + 2)

    return (
        <span
            align={alignTextoValorPerc}
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDados}
        >
            {conteudo1}
            {conteudo2 && ( // mudança da fonte
                <div
                    className='mt--3'
                >
                    {conteudo2}
                    {conteudo3 && ( // mudança da fonte
                        <div
                            className={'mt--3 ' +
                                CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDadosDestaque
                            }
                        >
                            {conteudo3}
                        </div>
                    )}
                </div>
            )}
        </span>
    )

    /*
    return IsMdDownScreen()
        ?
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className={'mt--3 ' + CLASSNAME(report).formataTableCellHeadExibeDadosDestaque}
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
        : 
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && ( // mudança da fonte
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && ( // mudança da fonte
                            <div
                                className={'mt--3 ' + CLASSNAME(report).formataTableCellHeadExibeDadosDestaque}
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
    */
}

export const formataTableCellHeadExibeDadosQuebraEspecial = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => { // mudança da fonte

    const conteudo1 = conteudo.split('<br/>').slice(0, 1)
    const conteudo2 = conteudo.split('<br/>').length > 1 ? conteudo.split('<br/>').slice(1, 2) : ''
    const conteudo3 = conteudo.split('<br/>').length > 2 ? conteudo.substr(conteudo1.toString().length + conteudo2.toString().length + 10) : ''

    return (
        <span
            align={alignTextoValorPerc}
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellHeadExibeDados}
        >
            {conteudo1}
            {conteudo2 && (
                <div
                    className='mt--3'
                >
                    {conteudo2}
                    {conteudo3 && (
                        <div
                            className='mt--3'
                        >
                            {conteudo3}
                        </div>
                    )}
                </div>
            )}
        </span>
    )

    /*
    return IsMdDownScreen()
        ?
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && (
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && (
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
        : 
            <span
                align={alignTextoValorPerc}
                className={CLASSNAME(report).formataTableCellHeadExibeDados}
            >
                {conteudo1}
                {conteudo2 && (
                    <div
                        className='mt--3'
                    >
                        {conteudo2}
                        {conteudo3 && (
                            <div
                                className='mt--3'
                            >
                                {conteudo3}
                            </div>
                        )}
                    </div>
                )}
            </span>
    */
}

export const formataTableCellBodyExibeDadosTitulo = ( conteudo, report, observacao, margemLeft,
    aumentoLetra, aumentoLetraReport, values ) => {

    return observacao
        ?
            <div
                className={
                    CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosObservacao +
                        (margemLeft ? ' ml-' + margemLeft: '')
                }
            >
                {conteudo}
            </div>
        :
            <div
                className={
                    CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados +
                        (margemLeft ? ' ml-' + margemLeft: '')
                }
            >
                {conteudo}
            </div>
}

export const formataTableCellBodyExibeDadosTituloDestaque2Titulos = ( conteudo, conteudo2, report, aumentoLetra,
    aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
        >
            <span 
                className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            >
                {conteudo ? conteudo : ""}
            </span>

            <span
                className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque}
            >
                {conteudo2 ? ' (' + conteudo2 + ')' : ""}
            </span>
        </div>
}

export const formataTableCellBodyExibeDadosTexto = ( conteudo, report, bold, aumentoLetra, aumentoLetraReport, values ) => {

    if (bold) {

        return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            align={alignTextoValorPerc}
        >
            <b>
                {conteudo}
            </b>
        </div> 

    } else {

        return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            align={alignTextoValorPerc}
        >
            {conteudo}
        </div>
    }
}

export const formataTableCellBodyExibeDadosTextoIdade = ( conteudo, idade, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
        className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
        align={alignTextoValorPerc}
    >
        {conteudo}
        <b>
            {' (' + idade + ')'} 
        </b>
    </div>
}

export const formataTableCellBodyExibeDadosValor = ( conteudo, report, bold, semMoeda, aumentoLetra, aumentoLetraReport, values ) => {

    if (bold) {

        return <div
            className={
                conteudo >= 0 || conteudo === LABEL.tracoXtraco
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosValorNegativo
            }
            align={alignTextoValorPerc}
        >
            <b>
                {conteudo ? formataValor(conteudo, 2, semMoeda) : ""}
            </b>
        </div>

    } else {
        
        return <div
            className={
                conteudo >= 0 || conteudo === LABEL.tracoXtraco
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosValorNegativo
            }
            align={alignTextoValorPerc}
        >
            {conteudo ? formataValor(conteudo, 2, semMoeda) : ""}
        </div>
    }
}

export const formataTableCellBodyExibeDadosValorDestaque = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque}
            align={alignTextoValorPerc}
        >
            {conteudo ? formataValor(conteudo, 2) : ""}
        </div>
}

export const formataTableCellBodyExibeDadosValorDestaque2Valores = ( conteudo, conteudo2, report,
    aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            align={alignTextoValorPerc}
        >
            <span className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}>
                {conteudo ? formataValor(conteudo, 2) : ""}
            </span>

            <br/>

            <span className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque}>
                {conteudo2 ? formataValor(conteudo2, 2) : ""}
            </span>
        </div>
}

export const formataTableCellBodyExibeDadosValorDestaqueValorTexto = ( conteudo, decimais, semMoeda, destaque, conteudo2,
    report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div 
            className={
                destaque
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            align={alignTextoValorPerc}
        >
            <span className={
                destaque
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados}
            >
                {conteudo ? formataValor(conteudo, decimais, semMoeda) : ""}
            </span>

            <br/>

            <span className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosDestaque}>
                {conteudo2 ? conteudo2 : ""}
            </span>
        </div>
}

export const formataTableCellBodyExibeDadosPerc = ( conteudo, report, bold, semPerc, aumentoLetra, aumentoLetraReport, values ) => {

    if (bold) {

        return <div
            className={
                conteudo >= 0
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosValorNegativo
            }
            align={alignTextoValorPerc}
        >
                <b>
                    {conteudo ? formataPerc(conteudo, 2, semPerc) : ""}
                </b>
        </div>

    } else {

        return <div
            className={
                conteudo >= 0
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDados
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosValorNegativo
            }
            align={alignTextoValorPerc}
        >
            {conteudo ? formataPerc(conteudo, 2, semPerc) : ""}
        </div>
    }
}

export const formataTableCellBodyExibeDadosSubTotalTitulo = ( conteudo, report, margemLeft, aumentoLetra,
    aumentoLetraReport, values ) => {

    return <div
            className={
                CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal +
                    (margemLeft ? ' ml-' + margemLeft: '')
            }
        >
            {conteudo}
        </div>
}

export const formataTableCellBodyExibeDadosSubTotalTexto = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal}
            align={alignTextoValorPerc}
        >
            {conteudo}
        </div>
}

export const formataTableCellBodyExibeDadosSubTotalValor = ( conteudo, report, semMoeda, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal}
            align={alignTextoValorPerc}
        >
            {conteudo ? formataValor(conteudo, 2, semMoeda) : ""}
        </div>
}

export const formataTableCellBodyExibeDadosSubTotal0Titulo = ( conteudo, report, margemBottomMenor,
    aumentoLetra, aumentoLetraReport, values) => {

    return <div
            className={
                margemBottomMenor
                    ?
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal0MargemBottomMenor
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal0
                }
        >
            {conteudo}
        </div>
}

//export const formataTableCellBodyExibeDadosSubTotal0Texto = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

//    return <div
//            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal0}
//            align={alignTextoValorPerc}
//        >
//            {conteudo}
//        </div>
//}

export const formataTableCellBodyExibeDadosSubTotal0Valor = ( conteudo, report, margemBottomMenor, semMoeda, aumentoLetra,
    aumentoLetraReport, values) => {

    return <div
            className={
                margemBottomMenor
                    ?
                        CLASSNAME(report, aumentoLetra,
                            aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal0MargemBottomMenor
                    :
                        CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal0
            }
            align={alignTextoValorPerc}
        >
            {conteudo ? formataValor(conteudo, 2, semMoeda) : ""}
        </div>
}

export const formataTableCellBodyExibeDadosSubTotalPerc = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosSubTotal}
            align={alignTextoValorPerc}
        >
            {conteudo ? formataPerc(conteudo) : ""}
        </div>
}



export const formataTableCellBodyExibeDadosTotalTitulo = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosTotal}
        >
            {conteudo}
        </div>
}

export const formataTableCellBodyExibeDadosTotalTexto = ( conteudo, report, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosTotal}
            align={alignTextoValorPerc}
        >
            {conteudo}
        </div>
}

export const formataTableCellBodyExibeDadosTotalValor = ( conteudo, report, semMoeda, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosTotal}
            align={alignTextoValorPerc}
        >
            {conteudo ? formataValor(conteudo, 2, semMoeda) : ""}
        </div>
}

export const formataTableCellBodyExibeDadosTotalPerc = ( conteudo, report, semPerc, aumentoLetra, aumentoLetraReport, values ) => {

    return <div
            className={CLASSNAME(report, aumentoLetra, aumentoLetraReport, values).formataTableCellBodyExibeDadosTotal}
            align={alignTextoValorPerc}
        >
            {conteudo ? formataPerc(conteudo, 2, semPerc) : ""}
        </div>
}



export const formataPanelRadioGroupHelpText = ( conteudo ) => {

    return <span className={CLASSNAME().formataPanelRadioGroupHelpText}> {conteudo} </span>
}



export const formataEstudoHelpText = ( conteudo ) => {

    return <span className={CLASSNAME().formataEstudoHelpText}> {conteudo} </span>
}



export const formataCabecalhoList = ( cabecalho ) => {
    return <>
        <div className={CLASSNAME().formataListCabecalho} align='left'> {cabecalho} </div>
    </>
}

export const formataIdentificacaoUsuarioList = ( identificacaoUsuario ) => {
    return <>
        <span className={CLASSNAME().formataListIdentificacaoUsuario}> {identificacaoUsuario} </span>
    </>
}

export const formataTituloTextoList = ( titulo, texto ) => {
    return texto
        ?
            <>
                <span className={CLASSNAME().formataListValor}> {texto} </span>
                <br/>
                <span className={CLASSNAME().formataListTitulo}> {titulo} </span>
            </>
        :
            ''
}

export const formataTituloTextoSeparadorList = ( titulo1, titulo2, texto1, texto2, separador ) => {
    return texto1
        ?
            <>
                <span className={CLASSNAME().formataListValor}> {texto1 + separador + ' ' + texto2} </span>
                <br/>
                <span className={CLASSNAME().formataListTitulo}> {titulo1 + separador + ' ' + titulo2} </span>
            </>
        :
            ''
}

export const formataTituloValorList = ( titulo, valor ) => {
    return valor
        ?
            <>
                <span className={CLASSNAME().formataListValor}> {formataValor(valor, 2)} </span>
                <br/>
                <span className={CLASSNAME().formataListTitulo}> {titulo} </span>
            </>
        :
            ''
}

export const formataTextoList = ( texto ) => {
    return texto
        ?
            <span className={CLASSNAME().formataListValor}> {texto} </span>
        :
            ''
}



export const formataPrimeiraReuniaoLinhaColuna = ( linhaColuna ) => {

    return <span
        className={CLASSNAME().formataPrimeiraReuniaoLinhaColuna}
        dangerouslySetInnerHTML={{ __html: linhaColuna + " "}}
    />
}

export const formataPrimeiraReuniaoLabelValor = ( label, valor ) => {

    return <div>
        <span className={CLASSNAME().formataPrimeiraReuniaoLabelValorLabel}> {label + ": "} </span>
        <br/>
        <span className={CLASSNAME().formataPrimeiraReuniaoLabelValorValor}>
            {formataValor(round(valor, 2), 2)}
        </span>
    </div>
}

export const formataPrimeiraReuniaoLabel = ( label ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoLabel}> {label + ": "} </span>
}

export const formataPrimeiraReuniaoTexto = ( texto ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoTextoValorNumeroData}> {texto} </span>
}

export const formataPrimeiraReuniaoTextoDestaque = ( texto ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoTextoDestaque}> {texto} </span>
}

export const formataPrimeiraReuniaoValor = ( valor ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoTextoValorNumeroData}>
            {formataValor(round(valor, 2), 2)}
        </span>
}

export const formataPrimeiraReuniaoNumero = ( numero ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoTextoValorNumeroData}>
            {formataNumero(numero)}
        </span>
}

export const formataPrimeiraReuniaoData = ( data ) => {
    return <span className={CLASSNAME().formataPrimeiraReuniaoTextoValorNumeroData}>
            {formataData(data)}
        </span>
}

export const formataPrimeriaReuniaoLinkManterReservaManterPadraoVida = ( valor ) => {

    return <span className={CLASSNAME().formataPrimeriaReuniaoLinkManterReservaManterPadraoVida}> {formataValor(round(valor, 2), 2)} </span>
}

export const formataPrimeiraReuniaoLinkManterReservaSemNovosAportes = ( valor ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoLinkManterReservaSemNovosAportes}> {formataValor(round(valor, 2), 2)} </span>
}

export const formataPrimeiraReuniaoLinkConsumirReservaManterPadraoVida = ( valor ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoLinkConsumirReservaManterPadraoVida}> {formataValor(round(valor, 2), 2)} </span>
}

export const formataPrimeiraReuniaoLinkConsumirReservaSemNovosAportes = ( valor ) => {

    return <span className={CLASSNAME().formataPrimeiraReuniaoLinkConsumirReservaSemNovosAportes}> {formataValor(round(valor, 2), 2)} </span>
}



export const formataReportTitulo = ( titulo ) => {

    return <span className={CLASSNAME().formataReportTitulo}> {titulo} </span>
}

export const formataReportTituloDestaque = ( texto ) => {

    return <span
        className={CLASSNAME().formataReportTituloDestaque}
        dangerouslySetInnerHTML={{ __html: texto}}
    />
}

export const formataReportSubTitulo = ( texto, tituloBoldPreto /* mudança da fonte */ ) => {

    return <span className={tituloBoldPreto ? CLASSNAME().formataReportSubTituloBoldPreto : CLASSNAME().formataReportSubTitulo}> {texto} </span>
}

export const formataReportTituloColuna = ( titulo ) => {

    return <span className={CLASSNAME().formataReportTituloColuna}> {titulo} </span>
}

export const formataReportTituloLinha = ( titulo ) => {

    return <span className={CLASSNAME().formataReportTituloLinha}> {titulo} </span>
}

export const formataReportTituloLinhaTotal = ( titulo ) => {

    return <span className={CLASSNAME().formataReportTituloLinhaTotal}> {titulo} </span>
}

export const formataReportTexto = ( texto ) => {

    return <span className={CLASSNAME().formataReportTextoValorNumeroPercData}> {texto} </span>
}

export const formataReportTextoTotal = ( texto ) => {

    return <span className={CLASSNAME().formataReportTextoTotal}> {texto} </span>
}


export const formataReportTextoMesAnoInicialFinal = ( mesAnoInicial, mesAnoFinal ) => {

    return <span
            className={CLASSNAME().formataReportTextoValorNumeroPercData}
            dangerouslySetInnerHTML={{
                __html: formataMesAnoTextoComBarra(mesAnoInicial) + LABEL.espaco + LABEL.espaco +
                    LABEL.traco + LABEL.espaco + LABEL.espaco + formataMesAnoTextoComBarra(mesAnoFinal)
            }}
        />
}

export const formataReportValor = ( valor ) => {

    return <span className={valor >= 0 ? CLASSNAME().formataReportTextoValorNumeroPercData : CLASSNAME().formataReportTextoValorNegativo}>
            {formataValor(round(valor, 2), 2, true)}
        </span>
}

export const formataReportValorSubTotal = ( valor ) => {

    return <span className={CLASSNAME().formataReportValorSubTotal}>
            {formataValor(round(valor, 2), 2, true)}
        </span>
}

export const formataReportValorTotal = ( valor ) => {

    return <span className={CLASSNAME().formataReportValorTotal}>
            {formataValor(round(valor, 2), 2, true)}
        </span>
}

export const formataReportNumero = ( numero ) => {

    return <span className={CLASSNAME().formataReportTextoValorNumeroPercData}>
            {formataNumero(numero)}
        </span>
}

export const formataReportPerc = ( perc ) => {

    return <span className={CLASSNAME().formataReportTextoValorNumeroPercData}>
            {formataPerc(perc)}
        </span>
}

export const formataReportData = ( data ) => {
    return <span className={CLASSNAME().formataReportTextoValorNumeroPercData}>
            {formataData(data)}
        </span>
}



export const formataEstudoDiagnosticoAposentadoria = ( titulo, valor ) => {

    return <>
        <span
            className={CLASSNAME().formataEstudoDiagnosticoAposentadoriaTitulo}
            dangerouslySetInnerHTML={{ __html: titulo + " "}}
        />

        {valor || valor === 0
            ?
                <span className={CLASSNAME().formataEstudoDiagnosticoAposentadoriaValor}> {formataValor(valor, 2)} </span>
            :
                ''
        }
        </>
}

export const formataEstudoDiagnosticoAposentadoriaResumo = ( titulo, report ) => {

    return <span
            className={
                report
                    ?
                        CLASSNAME().formataEstudoDiagnosticoAposentadoriaResumoReport
                    :
                        CLASSNAME().formataEstudoDiagnosticoAposentadoriaResumo
            }
            dangerouslySetInnerHTML={{ __html: titulo + " "}}
        />
}

export const formataEstudoPrevidenciaPrivadaResumo = ( titulo, report ) => {

    return <span
            className={
                report
                    ?
                        CLASSNAME().formataEstudoPrevidenciaPrivadaResumoReport
                    :
                        CLASSNAME().formataEstudoPrevidenciaPrivadaResumo
            }
            dangerouslySetInnerHTML={{ __html: titulo + " "}}
        />
}



export const formataEstudoHipotesesRetorno = ( titulo1, titulo2 ) => {
    return <>
        <span
            dangerouslySetInnerHTML={{ __html: titulo1 + ": "}}
        />
        <span
            className={CLASSNAME().formataEstudoHipotesesRetorno}
            dangerouslySetInnerHTML={{ __html: titulo2 + " "}}
        />
    </>
}



export const formataAssinaturaTitulo = ( titulo ) => {
    return <>
        <span className={CLASSNAME().formataAssinaturaTitulo}> {titulo} </span>
    </>
}

export const formataAssinaturaTexto = ( texto ) => {
    return <>
        <span className={CLASSNAME().formataAssinaturaTexto}> {texto} </span>
    </>
}



export const formataReportConferenciaDados = ( label, conteudo ) => {
    return <>
        <span className={CLASSNAME().formataReportConferenciaDadosLabel}> {label + ": "} </span>
        <span className={CLASSNAME().formataReportConferenciaDadosConteudo}> {conteudo} </span>
    </>
}



export const formataReportCapacidadePoupancaNecessidadeAporteAposentadoria = ( label, valor, report ) => {
    return <GridContainer>
        <GridItem7>
            <span
                className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaLabel}
            >
                {label}
            </span>
        </GridItem7>

        <GridItem5
            align='left' // mudança fonte: 'right'
        >
            {valor >= 0
                ?
                    <span className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor}> {formataValor(valor, 2)} </span>
                :
                    <span className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorNegativo}> {formataValor(valor, 2)} </span>
            }
        </GridItem5>
    </GridContainer>
}

export const formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaIdade = ( label, idade, report ) => {

    return <GridContainer>
        <GridItem7>
            <span className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaLabel}> {label} </span>
        </GridItem7>

        <GridItem5
            align='left' // mudança fonte: 'right'
        >
            <span className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor + (idade ? '' : ' mr-3')}> {idade ? idade + ' ' + LABEL.anos : '-'} </span>
        </GridItem5>
    </GridContainer>
}

export const formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaTaxa = ( label, taxa1, taxa2, report ) => {

    const Taxa1AnoMes = () => {

        return (
            !taxa2
                ?
                    <>
                        <span
                            className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorLigth + ' ml-3'}
                        >
                            {'('}
                        </span>
                        {taxa1 >= 0
                            ?
                                <span
                                    className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorLigth}
                                >
                                    {formataValor(converteTaxaAnualEmMensal(taxa1), 2, true) + '% a.m.'}
                                </span>
                            :
                                <span
                                    className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorLigth}
                                >
                                    {formataValor(converteTaxaAnualEmMensal(taxa1), 2, true) + '% a.m.'}
                                </span>
                        }
                        <span
                            className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorLigth}
                        >
                            {')'}
                        </span>
                    </>
                :
                    ''
        )
    }

    const Taxa2 = () => {

        return (
            taxa2 || taxa2 === 0
                ?
                    <>
                        <span
                            className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor}
                        >
                            {' / '}
                        </span>
                        {taxa2 >= 0
                            ?
                                <span
                                    className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor}
                                >
                                    {formataValor(taxa2, 2, true) + '% a.a.'}
                                </span>
                            :
                                <span
                                    className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorNegativo}
                                >
                                    {formataValor(taxa2, 2, true) + '% a.a.'}
                                </span>
                        }
                    </>
                :
                    ''
        )
    }

    return <GridContainer>
        <GridItem7>
            <span
                className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaLabel}
            >
                {label}
            </span>
        </GridItem7>

        <GridItem5
            align='left' // mudança fonte: 'right'
        >
            {taxa1 >= 0
                ?
                    <>

                        <span
                            className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValor}
                        >
                            {formataValor(taxa1, 2, true) + '% a.a.'}
                        </span>

                        <Taxa2/>

                        <Taxa1AnoMes/>
                    </>
                :
                    <>
                        <span
                            className={CLASSNAME(report).formataReportCapacidadePoupancaNecessidadeAporteAposentadoriaValorNegativo}
                        >
                            {formataValor(taxa1, 2, true) + '% a.a.'}
                        </span>

                        <Taxa2/>

                        <Taxa1AnoMes/>
                    </>
            }
        </GridItem5>
    </GridContainer>
}