import Signin from './Signin'
import NotFound from './NotFound'
import ResetSenha from './ResetSenha'
import Resposta from './Resposta'
import Signup from './Signup'

import PATH from '../../business-const/Path/Path'

const sessionRoutes = [
    {
        path: PATH.pageSignup,
        component: Signup,
    },
    {
        path: PATH.pageSignin,
        component: Signin,
    },
    {
        path: PATH.pageResetSenha,
        component: ResetSenha,
    },
    {
        path: PATH.pageResposta,
        component: Resposta,
    },
    {
        path: PATH.pageVideo,
        component: Resposta,
    },
    {
        path: PATH.page404,
        component: NotFound,
    },
]

export default sessionRoutes
