import LABEL from '../../business-const/Label/Label'

import {
    capitalize,
} from '../String/String'



export const  comparaArraysJSON = (array1, array2) => {

    let igual = false
    
    if ((!array1 && !array2) || (array1 && array2 && array1.length === array2.length &&
        array1.every((value, index) => JSON.stringify(value) === JSON.stringify(array2[index])))) {

        igual = true
    }

    return igual
}

export const  arrayNULL = (array) => {

    return array && array.every((value, index) => value === null)
}

export const  arraySoma = (array1, array2) => {

    let array = []

    array1?.forEach( (linhaArray1, indexArray1) => {

        array.push(
            (array1[indexArray1] ? array1[indexArray1] : 0) + (array2[indexArray1] ? array2[indexArray1] : 0) 
        )
    })

    return array
}

export const  arrayMaiorValor = (array, maiorValorInicial) => {

    let maiorValor = maiorValorInicial ? maiorValorInicial : 0

    array?.forEach( (linhaArray, indexArray) => {

        if (linhaArray > maiorValor) {

            maiorValor = linhaArray
        }
        
    })

    return maiorValor
}

export const  arrayMenorValor = (array, menorValorInicial) => {

    let menorValor = menorValorInicial ? menorValorInicial : 0

    array?.forEach( (linhaArray, indexArray) => {

        if (linhaArray < menorValor) {

            menorValor = linhaArray
        }
        
    })

    return menorValor
}


export const arrayAte = (array, indAte) => {

    let arrayAteAux = []

    if (indAte || indAte === 0) {

        let ind = 0

        while (ind <= indAte) {

            arrayAteAux.push(

                array[ind]
            )

            ind++
        }

    } else {

        arrayAteAux = [...array]
    }

    return arrayAteAux
}

export const totalArray = (array) => {

    let total = 0

    array?.forEach( (linha, ind) => {

        if (linha) {

            total += linha
        }
    })

    return total
}

export const gerarArray = (valor, tamanho) => {

    const array = []

    while (tamanho > 0) {

        array.push(valor)

        tamanho--
    }

    return array
}

export const gerarArraySerieBarArea = (name, data) => {

    const serieBarArea = []

    data?.forEach( (linha, ind) => {

        const dataAux = []

        data?.forEach( (linha2, ind2) => {

            dataAux.push(
                ind === ind2
                    ?
                        linha
                    :
                        null
            )
        })

        serieBarArea.push(
            {
                name: name,
                type: 'bar',
                data: dataAux,
            },
        )
    })

    return serieBarArea
}

export const capitalizeArray = (array) => {

    let arrayNovo = []

    if (array) {

        array.forEach( (linha, ind) => {

            arrayNovo.push(
                capitalize(linha)
            )
        })

    } else {

        arrayNovo = array

    }

    return arrayNovo
}

const moverArray = (array, nomeArrayNULL, setFieldValueNULL, direcao, indAtual) => {

    const novoArray = []

    array?.forEach( (linha, ind) => {

        if (direcao === 1) {

            if (ind === indAtual - 1) {

                novoArray.push(array[indAtual])
            }

            if (ind !== indAtual) {

                novoArray.push(linha)
            }

        } else {

            if (ind === indAtual) {
                
                novoArray.push(array[ind + 1])
            }

            if (ind !== indAtual + 1) {

                novoArray.push(linha)
            }
        }
    })

    if (setFieldValueNULL) {

        setFieldValueNULL(nomeArrayNULL, novoArray)

    } else {

        return novoArray
    }
}

export const moverArrayParaCima = (array, nomeArrayNULL, setFieldValueNULL, indAtual) => {

    return moverArray(array, nomeArrayNULL, setFieldValueNULL, 1, indAtual)
}

export const moverArrayParaBaixo = (array, nomeArrayNULL, setFieldValueNULL, indAtual) => {

    return moverArray(array, nomeArrayNULL, setFieldValueNULL, 2, indAtual)
}



export const obtemTextoArray = (array, indInicial, indFinal) => {

    let texto = ''

    while (indInicial <= indFinal) {

        texto += (texto ? ' ' : '') + array[indInicial].trim()

        indInicial++
    }

    return texto
}

export const indexCampoComecaTerminaComNumero = (array) => {

    let indexNumero = ''

    let index = 0

    while (index <= array.length - 1) {

       if (!isNaN(array[index].substring(0, 1)) && !isNaN(array[index].substring(array[index].length - 1))) {

            indexNumero = index
       }

        index++
    }

    return indexNumero
}


export const arrayConverteZeroNULL = (array) => {

    const arrayConvertido = []

    array?.forEach( (linha, ind) => {

        arrayConvertido.push(
            linha
                ?
                    linha
                :
                    null
        )
    })

    return arrayConvertido
}




export const adicionaNaoInformar = (array) => {

    return [
        ...array,
        {
            id: null,
            descricao: LABEL.naoInformar,
            ordem: 9999,
        }
    ]
}