// alterado - revisado

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

export const processaRegistrosDefaultIniciaisContato = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisContato) {

            values.familiaresProcessouRegistrosDefaultIniciaisContato = []
        }

        if (!values.contatos) {
            
            values.contatos = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisContato, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisContato.push(
                {
                    id: familiar
                }
            )

            if (values.contatos.filter(contato => contato.familiar === familiar).length === 0) {

                values.contatos.push(
                    {
                        familiar: familiar,
                    }
                )
            }
        }
    }
}



export const incluirContato = (json, familiar, tipoContato, contato) => {

    let indexContatos = 0

    let jaCadastrou = false

    while (indexContatos < json.contatos.length) {

        const linhaContatos = json.contatos[indexContatos]

        if (linhaContatos.familiar === familiar && linhaContatos.tipoContato === tipoContato && linhaContatos.contato === contato) {

            jaCadastrou = true

            indexContatos = json.contatos.length
        }

        indexContatos++
    }

    if (!jaCadastrou) {

        json.contatos.push(
            {
                familiar: familiar,
                tipoContato: tipoContato,
                contato: contato,
            }
        )
    }
}