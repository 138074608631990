import HARDCODE from '../../business-const/HardCode/HardCode'



const ufList = [
    {
        id: 'AC', 
        descricao: 'Acre',
        ordem: 10,
        percInventarioItcmd: 4,
    },
    {
        id: 'AL', 
        descricao: 'Alagoas',
        ordem: 20,
        percInventarioItcmd: 4,
    },
    {
        id: 'AP', 
        descricao: 'Amapá',
        ordem: 30,
        percInventarioItcmd: 4,
    },
    {
        id: 'AM', 
        descricao: 'Amazonas',
        ordem: 40,
        percInventarioItcmd: 2,
    },
    {
        id: 'BA', 
        descricao: 'Bahia',
        ordem: 50,
        percInventarioItcmd: 8,
    },
    {
        id: 'CE', 
        descricao: 'Ceará',
        ordem: 60,
        percInventarioItcmd: 8,
    },
    {
        id: 'DF', 
        descricao: 'Distrito Federal',
        ordem: 70,
        percInventarioItcmd: 6,
    },
    {
        id: 'ES', 
        descricao: 'Espírito Santo',
        ordem: 80,
        percInventarioItcmd: 4,
    },
    {
        id: 'GO', 
        descricao: 'Goiás',
        ordem: 90,
        percInventarioItcmd: 8, //4,
    },
    {
        id: 'MA', 
        descricao: 'Maranhão',
        ordem: 100,
        percInventarioItcmd: 4,
    },
    {
        id: 'MT', 
        descricao: 'Mato Grosso',
        ordem: 110,
        percInventarioItcmd: 8,
    },
    {
        id: 'MS', 
        descricao: 'Mato Grosso do Sul',
        ordem: 120,
        percInventarioItcmd: 6,
    },
    {
        id: 'MG', 
        descricao: 'Minas Gerais',
        ordem: 130,
        percInventarioItcmd: 5,
    },
    {
        id: 'PA', 
        descricao: 'Pará',
        ordem: 140,
        percInventarioItcmd: 4,
    },
    {
        id: 'PB', 
        descricao: 'Paraíba',
        ordem: 150,
        percInventarioItcmd: 8,
    },
    {
        id: 'PR', 
        descricao: 'Paraná',
        ordem: 160,
        percInventarioItcmd: 4,
    },
    {
        id: 'PE', 
        descricao: 'Pernambuco',
        ordem: 170,
        percInventarioItcmd: 5,
    },
    {
        id: 'PI', 
        descricao: 'Piauí',
        ordem: 180,
        percInventarioItcmd: 4,
    },
    {
        id: 'RR', 
        descricao: 'Roraima',
        ordem: 190,
        percInventarioItcmd: 4,
    },
    {
        id: 'RO', 
        descricao: 'Rondônia',
        ordem: 200,
        percInventarioItcmd: 4,
    },
    {
        id: 'RJ', 
        descricao: 'Rio de Janeiro',
        ordem: 210,
        percInventarioItcmd: 8,
    },
    {
        id: 'RN', 
        descricao: 'Rio Grande do Norte',
        ordem: 220,
        percInventarioItcmd: 4,
    },
    {
        id: 'RS', 
        descricao: 'Rio Grande do Sul',
        ordem: 230,
        percInventarioItcmd: 6,
    },
    {
        id: 'SC', 
        descricao: 'Santa Catarina',
        ordem: 240,
        percInventarioItcmd: 8,
    },
    {
        id: 'SP', 
        descricao: 'São Paulo',
        ordem: 250,
        percInventarioItcmd: 4,
    },
    {
        id: 'SE', 
        descricao: 'Sergipe',
        ordem: 260,
        percInventarioItcmd: 4,
    },
    {
        id: 'TO', 
        descricao: 'Tocantins',
        ordem: 270,
        percInventarioItcmd: 4,
    },
    {
        id: HARDCODE.ufExterior,
        descricao: 'Exterior',
        ordem: 900,
        percInventarioItcmd: 8,
    },
]

export default ufList