import HARDCODE from '../business-const/HardCode/HardCode'

import ApiService from './ApiService'

import AuthService from './AuthService'



class FamiliaService extends ApiService {

    constructor() {
        super(HARDCODE.apiFamilia)
    }

    identificaSexo(variaveisGlobais, nome) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&nome=${nome}`

        return this.get(variaveisGlobais, `${HARDCODE.apiFamiliaIdentificaSexo}/?${params}`, false)
    }
}

export default FamiliaService