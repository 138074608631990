// alterado - importante

import React from 'react'

import PATH from '../business-const/Path/Path'

const viewsRoutes = [
        {
            path: PATH.pageConfiguracaoForm,
            component: React.lazy(() =>
                import('./configuracao/ConfiguracaoForm')),
        },
        {
            path: PATH.pageClienteList,
            component: React.lazy(() => import('./clientes/ClienteList')),
        },
        {
            path: PATH.pageClienteListRedirect,
            component: React.lazy(() => import('./clientes/ClienteListRedirect')),
        },
        {
            path: PATH.pageAporteList,
            component: React.lazy(() => import('./clientes/AporteList')),
        },
        {
            path: PATH.pageImportarClienteExcelForm,
            component: React.lazy(() => import('./clientes/importarClienteExcel-form/ImportarClienteExcelForm')),
        },
        {
            path: PATH.pageIntegracaoClienteArquivoList,
            component: React.lazy(() => import('./clientes/integracaoClienteArquivo-list/IntegracaoClienteArquivoList')),
        },
        {
            path: PATH.pageTransferirFormRedirect,
            component: React.lazy(() => import('./clientes/transferir-form/TransferirFormRedirect')),
        },
        {
            path: PATH.pageClienteForm,
            component: React.lazy(() => import('./clientes/cliente-form/ClienteForm')),
        },
        {
            path: PATH.pageVersaoFormRedirect,
            component: React.lazy(() => import('./clientes/versao-form/VersaoFormRedirect')),
        },
        {
            path: PATH.pageApoliceForm,
            component: React.lazy(() => import('./pesquisa/apolices/ApoliceForm')),
        },
        {
            path: PATH.pageVideoForm,
            component: React.lazy(() => import('./videos/video-form/VideoForm')),
        },
        {
            path: PATH.pageTreinamentoForm,
            component: React.lazy(() => import('./treinamentos/treinamento-form/TreinamentoForm')),
        },
        {
            path: PATH.pageFintracksForm,
            component: React.lazy(() => import('./fin-tracks/fin-tracks-form/FintracksForm')),
        },
        //{
        //    path: '/pages/view-customer',
        //    component: React.lazy(() =>
        //        import('./clientes/customer-viewer/CustomerViewer')
        //    ),
        //},
        //{
        //    path: '/pages/product-list',
        //    component: React.lazy(() => import('./products/ProductList')),
        //},
        //{
        //    path: '/pages/new-product',
        //    component: React.lazy(() => import('./products/ProductForm')),
        //},
        //{
        //    path: '/pages/view-product',
        //    component: React.lazy(() => import('./products/ProductViewer')),
        //},
        //{
        //    path: '/pages/order-list',
        //    component: React.lazy(() => import('./orders/OrderList')),
        //},
]

export default viewsRoutes
