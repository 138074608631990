// alterado - revisado

const tipoDiaDiaList = [
    {
        id: 10,
        descricao: 'Diversas',
        ordem: 10,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 10,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 20,
        descricao: 'Água',
        ordem: 20,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 30,
        descricao: 'Alimentação',
        ordem: 30,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 30,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 40,
        descricao: 'Aluguel',
        ordem: 40,
        tipoPeriodicidadePagtoDefault: "M",
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "S",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 50,
        descricao: 'Assinaturas',
        ordem: 50,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 60,
        descricao: 'Autônomo',
        ordem: 60,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 40,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 70,
        descricao: 'Clube',
        ordem: 70,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 50,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 80,
        descricao: 'Combustível',
        ordem: 80,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 60,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 90,
        descricao: 'Condomínio',
        ordem: 90,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 100,
        descricao: 'Cuidados com a Beleza',
        ordem: 100,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 70,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 110,
        descricao: 'Empregados',
        ordem: 110,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 40,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 120,
        descricao: 'Esportes/Hobbies',
        ordem: 120,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 50,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 130,
        descricao: 'Farmácia',
        ordem: 130,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 80,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 140,
        descricao: 'Gás',
        ordem: 140,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 150,
        descricao: 'IPTU',
        ordem: 150,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 160,
        descricao: 'IPVA',
        ordem: 160,
        tipoPeriodicidadePagtoDefault: 'A',
        grupoDespesa: 60,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 170,
        descricao: 'Luz',
        ordem: 170,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 180,
        descricao: 'Manutenção Carro',
        ordem: 180,
        tipoPeriodicidadePagtoDefault: 'A',
        grupoDespesa: 60,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 190,
        descricao: 'Médico/Dentista',
        ordem: 190,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 80,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 200,
        descricao: 'Pensão com Dependente',
        ordem: 200,
        tipoPeriodicidadePagtoDefault: "M",
        grupoDespesa: 90,
        pensaoDependente: "S",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 210,
        descricao: 'Prêmio Seguro - Carro',
        ordem: 210,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 60,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 220,
        descricao: 'Prêmio Seguro - Vida',
        ordem: 220,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 100,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 230,
        descricao: 'Prêmio Seguro - Saúde',
        ordem: 230,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 80,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 240,
        descricao: 'Prêmio Seguro - Outros',
        ordem: 240,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 10,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 250,
        descricao: 'Restaurante',
        ordem: 250,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 30,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 260,
        descricao: 'Telefone',
        ordem: 260,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 270,
        descricao: 'Terapias',
        ordem: 270,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 70,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 275,
        descricao: 'Transporte',
        ordem: 275,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 105,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 280,
        descricao: 'TV/Internet',
        ordem: 280,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 20,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 290,
        descricao: 'Viagens',
        ordem: 290,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 110,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
    {
        id: 300,
        descricao: 'Desp.Adic.em caso de Morte',
        ordem: 300,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 900,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "S",
        invalidez: "N",
    },
    {
        id: 310,
        descricao: 'Desp.Adic.em caso de Invalidez',
        ordem: 310,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 900,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "S",
    },
    {
        id: 900, // HARDCODE.idTipoDiaDiaOutros
        descricao: 'Outros',
        ordem: 900,
        tipoPeriodicidadePagtoDefault: 'M',
        grupoDespesa: 900,
        pensaoDependente: "N",
        informaValorCompra: "N",
        morte: "N",
        invalidez: "N",
    },
]

export default tipoDiaDiaList