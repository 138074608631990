import logoSvg from './logo.svg'

export const SvgImageParceirosMetLifeLogo = ( props ) => {
    return (
        <img
            className='w-full'
            width="50px"
            height="50px"
            src={logoSvg}
            alt={''}
            {...props}
        />
    )
}