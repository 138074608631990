import LABEL from '../../../business-const/Label/Label'

import {
    arrayAte,
    gerarArray,
    adicionaNaoInformar,
} from '../../../business-components/Array/Array'

import {
    mesMesAno,
    anoMesAno,
    formataMesAnoComBarra,
    formataAnoMesSemBarra,
    calculaMaiorMesAno,
} from '../../../business-components/Date/Date'

import {
    nvl,
    formataValor,
} from '../../../business-components/Formata/Formata'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import {
    dadosCompletosLinhaFinanciamento,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    graficoTransformaZeroNull,
    calculaIndexGraficoAnosIdades,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

import {
    obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento,
    calculaValoresMesesFinanciamento,
} from './EstudoServiceDespesasFinanciamento'



export const processaValoresDefaultIniciaisComparacaoFinanciamentos = ( values ) => {
    
    // O valor default do campo objComparacaoFinanciamentos foi implementado em
    // ClienteService - inicializaAjustaInclusoesAlteracoesExclusoesJsonInfoclient
}

export const financiamentoList = (values, idFinanciamento) => {

    const financiamentoListAux = []

    values.financiamento?.forEach( (linhaFinanciamento, index) => {

        if (linhaFinanciamento.id !== idFinanciamento && dadosCompletosLinhaFinanciamento(linhaFinanciamento)) {

            const { descricaoTipoFinanciamento } =
                obtemLinhaTipoFinanciamentoDescricaoTipoFinanciamento(linhaFinanciamento)

            financiamentoListAux.push(
                {
                    id: linhaFinanciamento.id,
                    descricao: descricaoTipoFinanciamento +
                        LABEL.traco + formataValor(round(linhaFinanciamento.valorParcela, 2), 2)
                }
            )
        }
    })

    return adicionaNaoInformar(financiamentoListAux)
}



const calculaGraficoFinanciamento = (values, estudo, linhaFinanciamento, graficoFinanciamento, fluxoCaixaMensalFinanciamento) => {

    const { valorMensal, valorAmortizacao, mesInicial, anoInicial, mesFinal, anoFinal } =
        calculaValoresMesesFinanciamento(values, linhaFinanciamento, true)

    let maiorMes = mesFinal
    let maiorAno = anoFinal

    if (linhaFinanciamento.tabelaSAC) {

        // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
            valorAmortizacao, null, estudo.current.graficoAnos, graficoFinanciamento, null, values, estudo, 0, 0, false,
                linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual, null, null, fluxoCaixaMensalFinanciamento)

    } else {

        // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
        calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
            valorMensal, null, estudo.current.graficoAnos, graficoFinanciamento, null, values, estudo, 0, 0, false,
                null, linhaFinanciamento.taxaAnual, linhaFinanciamento.mesAnoPrimeiroReajuste, null, fluxoCaixaMensalFinanciamento)
    }

    if (linhaFinanciamento.possuiParcelasAdicionais && linhaFinanciamento.parcelasAdicionais) {
        
        linhaFinanciamento.parcelasAdicionais?.forEach( (item, ind) => {

            // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento
            calculaGraficoAcumulacaoMensal(mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal), 0,
                    round(item.valorParcela, 2), item.tipoPeriodicidadePagto, estudo.current.graficoAnos,
                        graficoFinanciamento, null, values, estudo, 0, 0, false, null, linhaFinanciamento.taxaAnual,
                            linhaFinanciamento.mesAnoPrimeiroReajuste, null, fluxoCaixaMensalFinanciamento)

            const { mes, ano } = calculaMaiorMesAno(maiorMes, maiorAno, mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal))

            maiorMes = mes
            maiorAno = ano
        })
    }

    return { maiorMes: maiorMes, maiorAno: maiorAno }
}

const calculaGraficoEconomia = (values, estudo, graficoEcomoniaPerdaFinanciamentoSugerido, mesInicial, anoInicial, mesFinal, anoFinal,
    valor, fluxoCaixaMensalEconomiaPerda, mesFinalTotal, anoFinalTotal) => {

    // se essa chamada for alterada, revisar a chamada correspondente em calculaDespesasFinanciamento

    let saldo = calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
        valor, null, estudo.current.graficoAnos,
            graficoEcomoniaPerdaFinanciamentoSugerido, null, values, estudo, values.taxaRetornoAnualReal,
                values.taxaRetornoAnualRealAposAposentadoria, true, null, null,
                    null, null/*estudo.current.evolucaoReservaFinanceiraEstudo*/, fluxoCaixaMensalEconomiaPerda)

    if (
            anoFinal < values.anoFinalVitalicioClienteConjuge ||
            (
                anoFinal === values.anoFinalVitalicioClienteConjuge &&
                mesFinal < 12
            )
    ) {

        if (mesFinal < 12) {

            const indexGraficoAnos =
                calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)

            graficoEcomoniaPerdaFinanciamentoSugerido[indexGraficoAnos] -= saldo
        }

        calculaGraficoAcumulacaoMensal(mesFinal === 12 ? 1 : mesFinal + 1,
            mesFinal === 12 ? anoFinal + 1 : anoFinal, mesFinalTotal,
                anoFinalTotal, saldo, 0, null, estudo.current.graficoAnos,
                    graficoEcomoniaPerdaFinanciamentoSugerido, null, values, estudo, values.taxaRetornoAnualReal,
                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, fluxoCaixaMensalEconomiaPerda)
    }
}

export const calculaGraficoComparacaoFinanciamento = (values, estudo) => {

    if (
            values.objComparacaoFinanciamentos.idFinanciamentoAlternativo &&
            values.objComparacaoFinanciamentos.idFinanciamentoSugerido &&
            (
                !values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo ||
                values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo.length === 6
            )
    ) {

        let indexGraficoAnos = calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, values.anoFinalVitalicioClienteConjuge)

        let graficoFinanciamentoSugerido = gerarArray(0, indexGraficoAnos + 1)
        let graficoFinanciamentoAlternativo = [...graficoFinanciamentoSugerido]
        let graficoEcomoniaPerdaFinanciamentoSugerido = [...graficoFinanciamentoSugerido]
        const graficoEcomoniaFinanciamentoSugerido = []
        const graficoPerdaFinanciamentoSugerido = []
        let fluxoCaixaMensalFinanciamentoSugerido = []
        let fluxoCaixaMensalFinanciamentoAlternativo = []
        let fluxoCaixaMensalEconomiaPerda = []
        let idadesFinanciamento
        let anosFinanciamento

        const linhaFinanciamentoSugerido =
            pesquisaList(values.financiamento, values.objComparacaoFinanciamentos.idFinanciamentoSugerido)

        const linhaFinanciamentoAlternativo =
            pesquisaList(values.financiamento, values.objComparacaoFinanciamentos.idFinanciamentoAlternativo)

        if (linhaFinanciamentoSugerido.id && linhaFinanciamentoAlternativo.id) {

            const maiorMesAnoFinanciamentoSugerido = calculaGraficoFinanciamento(values, estudo,
                linhaFinanciamentoSugerido, graficoFinanciamentoSugerido, fluxoCaixaMensalFinanciamentoSugerido)

            const maiorMesAnoFinanciamentoAlternativo = calculaGraficoFinanciamento(values, estudo,
                linhaFinanciamentoAlternativo, graficoFinanciamentoAlternativo, fluxoCaixaMensalFinanciamentoAlternativo)

            const { ano, mes } = calculaMaiorMesAno(maiorMesAnoFinanciamentoSugerido.maiorMes,
                maiorMesAnoFinanciamentoSugerido.maiorAno, maiorMesAnoFinanciamentoAlternativo.maiorMes,
                maiorMesAnoFinanciamentoAlternativo.maiorAno)

            const mesFinal = mes
            const anoFinal = ano

            estudo.current.graficoAnos?.forEach( (linha, ind) => {

                if (linha <= anoFinal) {

                    let mes = 1

                    if (linha === estudo.current.anoAtual) {

                        mes = estudo.current.mesAtual
                    }

                    while (mes <= 12) {

                        const mesAno = formataMesAnoComBarra(mes, linha)
                        const valorFinanciamentoSugerido = nvl(pesquisaList(fluxoCaixaMensalFinanciamentoSugerido, mesAno).valor, 0)
                        const valorFinanciamentoAlternativo = nvl(pesquisaList(fluxoCaixaMensalFinanciamentoAlternativo, mesAno).valor, 0)

                        if (valorFinanciamentoSugerido !== 0 || valorFinanciamentoAlternativo !== 0) {

                            let saldo
                            
                            if (
                                    values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo &&
                                    formataAnoMesSemBarra(mes, linha) <=
                                        formataAnoMesSemBarra(mesMesAno(values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo),
                                            anoMesAno(values.objComparacaoFinanciamentos.mesAnoPagoMesmoTempo))
                            ) {

                                saldo = valorFinanciamentoSugerido * -1

                            } else {

                                if (valorFinanciamentoSugerido === 0) {

                                    saldo = valorFinanciamentoAlternativo

                                } else {

                                    if (valorFinanciamentoAlternativo === 0) {

                                        saldo = valorFinanciamentoSugerido * -1

                                    } else {

                                        saldo = valorFinanciamentoAlternativo - valorFinanciamentoSugerido
                                    }
                                }
                            }

                            calculaGraficoEconomia(values, estudo, graficoEcomoniaPerdaFinanciamentoSugerido, mes,
                                linha, mes, linha, saldo, fluxoCaixaMensalEconomiaPerda, mesFinal, anoFinal)
                        }

                        mes++
                    }
                }
            })

            indexGraficoAnos = calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)
            
            graficoFinanciamentoSugerido = arrayAte(graficoFinanciamentoSugerido, indexGraficoAnos)
            graficoFinanciamentoAlternativo = arrayAte(graficoFinanciamentoAlternativo, indexGraficoAnos)
            graficoEcomoniaPerdaFinanciamentoSugerido = arrayAte(graficoEcomoniaPerdaFinanciamentoSugerido, indexGraficoAnos)
            idadesFinanciamento = arrayAte(estudo.current.graficoIdades, indexGraficoAnos)
            anosFinanciamento = arrayAte(estudo.current.graficoAnos, indexGraficoAnos)

            graficoEcomoniaPerdaFinanciamentoSugerido?.forEach( (linha, ind) => {

                if (linha >= 0) {

                    graficoEcomoniaFinanciamentoSugerido.push(linha)
                    graficoPerdaFinanciamentoSugerido.push(null)

                    if (ind === 1 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] < 0) {

                        graficoPerdaFinanciamentoSugerido[ind] = graficoEcomoniaFinanciamentoSugerido[ind]
                        
                    } else {

                        if (ind > 0 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] < 0) {

                            graficoEcomoniaFinanciamentoSugerido[ind - 1] = graficoPerdaFinanciamentoSugerido[ind - 1]
                        }
                    }


                } else {

                    graficoEcomoniaFinanciamentoSugerido.push(null)
                    graficoPerdaFinanciamentoSugerido.push(linha)

                    if (ind > 0 && graficoEcomoniaPerdaFinanciamentoSugerido[ind - 1] >= 0) {

                        graficoPerdaFinanciamentoSugerido[ind - 1] = graficoEcomoniaFinanciamentoSugerido[ind - 1]
                    }
                }
            })

            let indFluxoMensal = 0

            fluxoCaixaMensalEconomiaPerda?.forEach( (linha, ind) => {

                const linhaFluxoCaixaMensalFinanciamentoSugerido =
                    pesquisaList(fluxoCaixaMensalFinanciamentoSugerido, linha.id)

                if (linhaFluxoCaixaMensalFinanciamentoSugerido.id) {

                    fluxoCaixaMensalEconomiaPerda[ind].financiamentoSugerido =
                        linhaFluxoCaixaMensalFinanciamentoSugerido.valor

                    indFluxoMensal = ind
                }



                const linhaFluxoCaixaMensalFinanciamentoAlternativo =
                    pesquisaList(fluxoCaixaMensalFinanciamentoAlternativo, linha.id)

                if (linhaFluxoCaixaMensalFinanciamentoAlternativo.id) {

                    fluxoCaixaMensalEconomiaPerda[ind].financiamentoAlternativo =
                        linhaFluxoCaixaMensalFinanciamentoAlternativo.valor

                    indFluxoMensal = ind
                }
            })
            
            fluxoCaixaMensalEconomiaPerda = arrayAte(fluxoCaixaMensalEconomiaPerda, indFluxoMensal)

            graficoTransformaZeroNull(graficoFinanciamentoSugerido)
            graficoTransformaZeroNull(graficoFinanciamentoAlternativo)

            estudo.current.graficoFinanciamentoSugerido = graficoFinanciamentoSugerido
            estudo.current.graficoFinanciamentoAlternativo = graficoFinanciamentoAlternativo
            estudo.current.graficoEcomoniaFinanciamentoSugerido = graficoEcomoniaFinanciamentoSugerido
            estudo.current.graficoPerdaFinanciamentoSugerido = graficoPerdaFinanciamentoSugerido
            estudo.current.fluxoCaixaMensalEconomiaPerda = fluxoCaixaMensalEconomiaPerda
            estudo.current.idadesFinanciamento = idadesFinanciamento
            estudo.current.anosFinanciamento = anosFinanciamento
        }
    }
}