import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import NUMMIN from '../../NumMinMax/NumMin'

import NUMMAX from '../../NumMinMax/NumMax'

import TAMMAX from '../../TamMax/TamMax'

import {
    formataArrayData,
    formataAnoMesInteiroComBarra,
} from '../../../business-components/Date/Date'

import {
    formataValor,
    formataPerc,
    formataCpf,
    formataCelular,
} from '../../../business-components/Formata/Formata'

//import {
//    IconButtonDocument,
//} from '../../../business-components/IconButton/IconButton'

//import {
//    CustomizedDialogsPopup,
//} from '../../../business-components/Dialog/CustomizeDialogs'

import {
    pesquisaDescricaoList,
    incluiCampoList,
} from '../ListService'

import sexoList from '../sexoList'

import parentescoList from '../parentescoList'

import tipoPeriodicidadeList from '../tipoPeriodicidadeList'

import tipoFormaPagtoPremioList from '../tipoFormaPagtoPremioList'

import seguradoraList from './seguradoraList'



export const camposApoliceComissaoList = ( list, columnsExibe, grafico, filtroList, filtroLists ) => {

    let camposListAux = []

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoUsuario',
        label: LABEL.usuario,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 10,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoGestorLider',
        label: LABEL.gestorLider,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 20,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroApolice',
        label: LABEL.numeroApolice,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroApolice,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 30,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroProposta',
        label: LABEL.numeroProposta,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroProposta,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 40,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nome',
        label: LABEL.nomeSegurado,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.nome,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 50,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cpf',
        label: LABEL.cpf,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCpf,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCpf(list[dataIndex].cpf))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 60,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nascimento',
        label: LABEL.nascimento,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].nascimento))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 70,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'sexo',
        label: LABEL.sexo,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.sexoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(sexoList(true), list[dataIndex].sexo))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 80,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'parentesco',
        label: LABEL.parentesco,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.parentescoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(parentescoList(true), list[dataIndex].parentesco))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 90,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'profissao',
        label: LABEL.profissao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.profissaoLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 100,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'email',
        label: LABEL.email,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeEmail,
        tamMax: HARDCODE.email,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 110,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'celular',
        label: LABEL.celular,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCelular,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCelular(list[dataIndex].celular))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 120,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cidadeEnderecoResidencial',
        label: LABEL.cidade,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.cidadeEnderecoResidencialLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 130,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'seguradora',
        label: LABEL.seguradora,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.seguradoraLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(seguradoraList(), list[dataIndex].seguradora))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 140,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroParcela',
        label: LABEL.numeroParcela,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.qtdeParcelas,
        numMax: NUMMAX.qtdeParcelas,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 150,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataGeracaoComissao',
        label: LABEL.dataGeracaoComissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataGeracaoComissao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 160,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesGeracaoComissao',
        label: LABEL.anoMesGeracaoComissao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesGeracaoComissao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 170,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoPeriodicidadePagtoPremio',
        label: LABEL.periodicidadePagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoPeriodicidadePagtoPremioLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoPeriodicidadeList(true, true), list[dataIndex].tipoPeriodicidadePagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 180,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoFormaPagtoPremio',
        label: LABEL.formaPagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoFormaPagtoPremioLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoFormaPagtoPremioList, list[dataIndex].tipoFormaPagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 190,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorBaseCalculoComissao',
        label: LABEL.valorBaseCalculoComissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorBaseCalculoComissao, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 200,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percComissao',
        label: LABEL.percComissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataPerc(list[dataIndex].percComissao, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 210,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorComissao',
        label: LABEL.valorComissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorComissao, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 220,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'percComissaoCoCorretagem',
        label: LABEL.percComissaoCoCorretagem,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typePercentual,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataPerc(list[dataIndex].percComissaoCoCorretagem, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 230,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorComissaoCoCorretagem',
        label: LABEL.valorComissaoCoCorretagem,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorComissaoCoCorretagem, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 240,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorComissaoTotal',
        label: LABEL.valorComissaoTotal,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorComissaoTotal, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 250,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroCobertura',
        label: LABEL.numeroCobertura,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.numeroCobertura,
        numMax: NUMMAX.numeroCobertura,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 260,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'codigoCobertura',
        label: LABEL.codigoCobertura,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.codigoCobertura,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 270,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'descricaoCobertura',
        label: LABEL.descricaoCobertura,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.descricaoCobertura,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 280,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'links',
        label: LABEL.links,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    <CustomizedDialogsPopup
                        maxWidth="lg"
                        popupTitle={LABEL.extratoApolice}
                        tooltipTitle={LABEL.extratoApolice}
                        PopupButton={IconButtonDocument}
                        popupComponent={
                            <PopupComponent/>
                        }
                    />
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 290,
    })
    */

    return camposListAux
}

export default camposApoliceComissaoList