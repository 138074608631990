// alterado - revisado

import { useMediaQuery } from '@material-ui/core'

import { useTheme } from '@material-ui/core/styles'

export const IsXsDownScreen = () => {
    const theme = useTheme()

    return useMediaQuery(theme.breakpoints.down('xs'))
}

export const IsSmDownScreen = () => {
    const theme = useTheme()

    return useMediaQuery(theme.breakpoints.down('sm'))
}

export const IsMdDownScreen = () => {
    const theme = useTheme()

    return useMediaQuery(theme.breakpoints.down('md'))
}