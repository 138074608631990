import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    mesMesAno,
    anoMesAno,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoFinanciamentoList from '../../../business-rules/List/tipoFinanciamentoList'

import {
    tituloCliente,
    tituloConjuge,
} from '../cliente-form/ClienteService'

import {
    calculaValorMensal,
} from '../cliente-form/ClienteService'

import {
    calcDifPerc100,
    calculaValorAcumulado,
 } from '../cliente-form/ClienteService'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'



export const incrementaContFinanciamento = ( values ) => {

    if (!values.contFinanciamento) {

        values.contFinanciamento = 0
    }

    values.contFinanciamento++
}



export const processaRegistrosDefaultIniciaisFinanciamento = ( values, familiar ) => {

    if (familiar) {
        if (!values.familiaresProcessouRegistrosDefaultIniciaisFinanciamento) {
            values.familiaresProcessouRegistrosDefaultIniciaisFinanciamento = []
        }

        if (!values.financiamento) {
            values.financiamento = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisFinanciamento, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisFinanciamento.push(
                {
                    id: familiar
                }
            )

            incrementaContFinanciamento(values)
            values.financiamento.push(
                {
                    id: values.contFinanciamento,
                    familiar: familiar,
                }
            )
        }
    }
}



const calculaFinanciamentoGrupoDespesa = ( item ) => {

    if (!item.grupoDespesa && item.tipoFinanciamento) {
        
        item.grupoDespesa = pesquisaList(tipoFinanciamentoList, item.tipoFinanciamento).grupoDespesa
    }
}

const calculaFinanciamentoZeraCamposNaoInformados = ( item ) => {

    const linhaTipoFinanciamentoList = pesquisaList(tipoFinanciamentoList, item.tipoFinanciamento)

    if (linhaTipoFinanciamentoList.informaTabelaSAC !== "S") {

        item.tabelaSAC = ''
    }

    if (linhaTipoFinanciamentoList.informaConsorcio !== "S") {

        item.consorcio = ''
    }

    if (!item.consorcio) {

        item.mesAnoPrimeiroReajuste = ''
    }

    if (linhaTipoFinanciamentoList.informaTemSeguro !== "S") {

        item.temSeguro = ''

        calculaFinanciamentoPercSeguroClienteConjuge(item)
    }
}

export const processaAlteracaoFinanciamentoTipoFinanciamento = ( item ) => {

    calculaFinanciamentoGrupoDespesa(item)

    calculaFinanciamentoZeraCamposNaoInformados(item)
}



export const calculaValorAmortizacaoTabelaSAC = (valorSaldoDevedor, qtdeParcelas) => {

    return valorSaldoDevedor / qtdeParcelas
}

export const calculaValorParcelaTabelaSAC = (valorAmortizacao, qtdeParcelas, taxaAnual) => {

    return round(valorAmortizacao +
        ((valorAmortizacao * qtdeParcelas) *
            (Math.pow(1 + (taxaAnual / 100), 1 / 12) - 1)), 2)
}

export const processaCalculoFinanciamentoTabelaSAC = ( values, linhaFinanciamento ) => {

    if (linhaFinanciamento.tabelaSAC) {

        if (linhaFinanciamento.valorSaldoDevedor && linhaFinanciamento.qtdeParcelas && linhaFinanciamento.taxaAnual) {

            const valorAmortizacao =
                calculaValorAmortizacaoTabelaSAC(linhaFinanciamento.valorSaldoDevedor, linhaFinanciamento.qtdeParcelas)

            linhaFinanciamento.valorParcela =
                calculaValorParcelaTabelaSAC(valorAmortizacao, linhaFinanciamento.qtdeParcelas, linhaFinanciamento.taxaAnual)

            linhaFinanciamento.valorTotalParcelas = 0

            let qtdeParcelas = linhaFinanciamento.qtdeParcelas

            while (qtdeParcelas > 0) {

                linhaFinanciamento.valorTotalParcelas +=
                    calculaValorParcelaTabelaSAC(valorAmortizacao, qtdeParcelas, linhaFinanciamento.taxaAnual)

                qtdeParcelas--
            }

            if (linhaFinanciamento.possuiParcelasAdicionais && informouParcelasAdicionais(linhaFinanciamento.parcelasAdicionais)) {

                linhaFinanciamento?.parcelasAdicionais.forEach( (item, ind) => {
                    
                    linhaFinanciamento.valorTotalParcelas += calculaValorAcumulado (values, item.valorParcela,
                        item.tipoPeriodicidadePagto, mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                            mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal)
                    )
                })
            }

        } else {

            linhaFinanciamento.valorParcela = ''
            linhaFinanciamento.valorTotalParcelas = ''
        }
    }
}



export const tituloFinanciamentoPercSeguroCliente = (values) => {

    return "% " + tituloCliente(values)
}

export const tituloFinanciamentoPercSeguroConjuge = (values) => {

    return "% " + tituloConjuge(values)
}

export const tituloFinanciamentoAlterarPercSeguroConjuge = (values) => {

    return LABEL.alterarPercSeguroConjuge + " " + tituloConjuge(values)
}


const calculaFinanciamentoPercSeguroClienteConjuge = ( item ) => {
    if (item.temSeguro === "S") {
        if (
                item.familiar === HARDCODE.idFamiliarCliente ||
                item.familiar === HARDCODE.idFamiliarConjuge
        ) {
            if (!item.alterarPercSeguroConjuge) {
                if (item.percSeguroCliente || item.percSeguroCliente === 0) {

                    item.percSeguroConjuge = calcDifPerc100(item.percSeguroCliente)
                } else {
        
                    item.percSeguroConjuge = ''
                }
            }
            item.percSeguro = ''
        } else {
            item.percSeguroCliente = ''
            item.percSeguroConjuge = ''
            item.alterarPercSeguroConjuge = ''
        }
    } else {
        item.percSeguroCliente = ''
        item.percSeguroConjuge = ''
        item.alterarPercSeguroConjuge = ''
        item.percSeguro = ''
    }
}

export const processaAlteracaoFinanciamentoTemSeguro = ( item ) => {

    calculaFinanciamentoPercSeguroClienteConjuge(item)
}

export const processaAlteracaoFinanciamentoPercSeguroCliente = ( item ) => {

    calculaFinanciamentoPercSeguroClienteConjuge(item)
}



const calculaFinanciamentoMesAnoInicial = ( item ) => {

    if (!item.financiamentoFuturo) {

        item.mesAnoInicial = ''
        item.financiamentoFuturoAindaNaoContratado = ''
    }
}

export const processaAlteracaoFinanciamentoFinanciamentoFuturo = ( item ) => {

    calculaFinanciamentoMesAnoInicial(item)
}



const informouParcelasAdicionais = (parcelasAdicionais) => {

    let informou = parcelasAdicionais ? true : false

    parcelasAdicionais?.forEach( (item, ind) => {

        if (!item.mesAnoInicial || !item.mesAnoFinal || !item.tipoPeriodicidadePagto || !item.valorParcela) {

            informou = false
        }
    })

    return informou
}

export const dadosCompletosLinhaFinanciamento = ( linhaFinanciamento ) => {

    return  linhaFinanciamento.valorParcela &&
            linhaFinanciamento.qtdeParcelas &&
            (
                linhaFinanciamento.temSeguro !== "S" ||
                (
                    (
                        linhaFinanciamento.familiar === HARDCODE.idFamiliarCliente ||
                        linhaFinanciamento.familiar === HARDCODE.idFamiliarConjuge
                    ) &&
                    (
                        linhaFinanciamento.percSeguroCliente ||
                        linhaFinanciamento.percSeguroCliente === 0
                    )
                ) ||
                (
                    linhaFinanciamento.familiar !== HARDCODE.idFamiliarCliente &&
                    linhaFinanciamento.familiar !== HARDCODE.idFamiliarConjuge &&
                    (
                        linhaFinanciamento.percSeguro ||
                        linhaFinanciamento.percSeguro === 0
                    )
                )
            ) &&
            (
                !linhaFinanciamento.consorcio ||
                (
                    linhaFinanciamento.taxaAnual &&
                    linhaFinanciamento.mesAnoPrimeiroReajuste
                )
            ) &&
            (
                !linhaFinanciamento.financiamentoFuturo ||
                (
                    linhaFinanciamento.financiamentoFuturo &&
                    linhaFinanciamento.mesAnoInicial
                )
            ) &&
            (
                !linhaFinanciamento.financiamentoSeraQuitadoAntesUltimaParcela ||
                (
                    linhaFinanciamento.financiamentoSeraQuitadoAntesUltimaParcela &&
                    linhaFinanciamento.ultimaParcelaFinanciamento
                )
            ) &&
            (
                !linhaFinanciamento.possuiParcelasAdicionais ||
                (
                    linhaFinanciamento.possuiParcelasAdicionais && informouParcelasAdicionais(linhaFinanciamento.parcelasAdicionais)
                )
            )
}

export const calculaTotaisFinanciamento = ( values ) => {

    const indexStepsValorFluxoCaixa = HARDCODE.indexFluxoCaixaFinanciamento

    values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 0

    values.totalFinanciamento = []

    let valorTotalParcelas = 0

    let valorTotalParcelasTotal = 0

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {

        valorTotalParcelasTotal = 0

        values.financiamento?.forEach( (linhaFinanciamento, index) => {

            if (linhaFinanciamento.familiar === linhaFamiliaresList.id) {

                if (linhaFinanciamento.tabelaSAC) {

                    valorTotalParcelas = round(linhaFinanciamento.valorTotalParcelas, 2)

                } else {

                    if (linhaFinanciamento.valorParcela && linhaFinanciamento.qtdeParcelas) {
                        
                        valorTotalParcelas = calculaValorMensal(linhaFinanciamento.valorParcela, null) * linhaFinanciamento.qtdeParcelas

                        //calculaMesAnoFinal = (mesInicial, anoInicial, meses)
                    } else {

                        valorTotalParcelas = 0
                    }

                    if (linhaFinanciamento.possuiParcelasAdicionais && informouParcelasAdicionais(linhaFinanciamento.parcelasAdicionais)) {

                        linhaFinanciamento?.parcelasAdicionais.forEach( (item, ind) => {
                            
                            valorTotalParcelas += calculaValorAcumulado (values, item.valorParcela, item.tipoPeriodicidadePagto,
                                mesMesAno(item.mesAnoInicial), anoMesAno(item.mesAnoInicial),
                                    mesMesAno(item.mesAnoFinal), anoMesAno(item.mesAnoFinal),
                            )
                        })
                    }

                    values.financiamento[index].valorTotalParcelas = valorTotalParcelas
                }

                if (
                        dadosCompletosLinhaFinanciamento(linhaFinanciamento) &&
                        !linhaFinanciamento.financiamentoFuturoAindaNaoContratado
                ) {

                    valorTotalParcelasTotal += valorTotalParcelas
                }
            }
        })

        if (
                valorTotalParcelasTotal !== 0 ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
                linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        ) {

            values.totalFinanciamento.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    valor: valorTotalParcelasTotal,
                }
            )

            values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] =
                values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] + valorTotalParcelasTotal
        }
    })
}



export const dadosCompletosFinanciamento = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.financiamento?.forEach( (linhaFinanciamento, index) => {

            if (
                    linhaFinanciamento.familiar === linhaFamiliaresList.id &&
                    linhaFinanciamento.tipoFinanciamento &&
                    !dadosCompletosLinhaFinanciamento(linhaFinanciamento)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos, pesquisaDescricaoList(tipoFinanciamentoList, linhaFinanciamento.tipoFinanciamento))
            }
        })
    })

    return dadosIncompletos
}