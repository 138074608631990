import React from 'react'

import clsx from 'clsx'

import {
    Card,
    Divider,
} from '@material-ui/core'

import {
    GridContainer,
    GridItem4,
    GridItem8,
} from '../Grid/Grid'



const classNameCardGridDashboardCard = 'p-5 mb-3 flex-column justify-center items-center border-radius-padrao'
const classNameCardGridDashboardAgenda = 'mt-0 mb-0 border-radius-padrao'
const classNameCardGridDashboardGrafico = 'mt-0 mb-0 border-radius-padrao'
const classNameCardGridDivHeaderEntradaDados = 'p-2 mb-2 bg-cardGridDivHeaderEntradaDados'
const classNameCardGridEntradaDados = "mt-2 mb-2 bg-cardGridEntradaDados border-radius-padrao"
const classNameCardGridEstudo = "mt-4 mb-4 border-radius-padrao"
const classNameCardGridTreinamento = "mt-4 mb-4"
const classNameCardGridDivider = "h-2px mb-2"
const classNameCardGridDividerEntradaDados = "h-2px mb-2"
const classNameCardGridDividerElementos = "bg-gray h-2px mt-1 mb-3"
const classNameCardGridDivHeader = "p-3"
const classNameCardGridDivHeaderDestaque1 = clsx('bg-cardGridDivHeaderDestaque1 mb-2', classNameCardGridDivHeader)
const classNameCardGridDivHeaderDestaqueAlerta = clsx('bg-cardGridDivHeaderDestaqueAlerta', classNameCardGridDivHeader)
const classNameCardGridDivHeaderResumoCabecalho = 'p-3 bg-cardGridDivHeaderResumoCabecalho'
const classNameCardGridDivH4 = "m-0"
const classNameCardGridDivH4Destaque = clsx('text-28 font-light', classNameCardGridDivH4)//clsx('text-24 text-white font-medium', classNameCardGridDivH4) // mudança da fonte: text-30
const classNameCardGridDivH4DestaqueReport = clsx('text-28 font-medium', classNameCardGridDivH4)
const classNameCardGridDivEntradaDados = 'text-20 mt-3 mb-3'
const classNameCardGridDivBody = "ml-2 mr-2"
const classNameCardGridDivBodyResumo = "mt-2 ml-2 mr-2"
const classNameCardGridDivBodyResumoTotal = "mt-4 ml-2 mr-2 bg-cardGridDivBodyResumoTotal"
const classNameCardGridDivBodyResumoTotalGeral = "mt-4 ml-2 mr-2 bg-cardGridDivBodyResumoTotalGeral"
const classNameCardGridDivBodyEntradaDados = "ml-2 mr-2 bg-cardGridEntradaDados"
const classNameCardGridEntradaDadosHeaderBodyHeader = 'text-18 font-bold text-cardGridEntradaDadosHeaderBody bg-cardGridEntradaDadosHeaderBody' //'ml-3 mt-2 mb-5 font-ligth font-underline'
const classNameCardGridEntradaDadosHeaderBodyHeaderTitle = 'ml-3 mt-3 mb-2'
const classNameCardGridEntradaDadosHeaderBodyBody = 'ml-2 mt-5 mr-2 mb-3 bg-cardGridEntradaDados'

export const CardGrid = props => {
    return (
        <Card
            elevation={1}
            {...props}
        />
    )
}

export const CardGridDashboardCard = props => {

    const { className, ...rest } = props
    
    return (
        <CardGrid
            className={classNameCardGridDashboardCard + (className ? ' ' + className : '')}
            {...rest}
        />
    )
}

export const CardGridDashboardAgenda = props => {
    return (
        <CardGrid
            className={classNameCardGridDashboardAgenda}
            {...props}
        />
    )
}

export const CardGridDashboardGrafico = props => {
    return (
        <CardGrid
            className={classNameCardGridDashboardGrafico}
            {...props}
        />
    )
}

export const CardGridDivHeaderEntradaDados = props => {
    return (
        <div 
            className={classNameCardGridDivHeaderEntradaDados}
            {...props}
        />
    )
}

export const CardGridEntradaDados = props => {
    
    const { className, ...rest } = props
    
    return (
        <CardGrid
            className={classNameCardGridEntradaDados + (className ? ' ' + className : '')}
            {...rest}
        />
    )
}

export const CardGridEstudo = props => {
    return (
        <CardGrid
            className={classNameCardGridEstudo}
            {...props}
        />
    )
}

export const CardGridTreinamento = props => {
    return (
        <CardGrid
            className={classNameCardGridTreinamento}
            {...props}
        />
    )
}

export const CardGridDivider = props => {

    const {className, ...rest} = props

    return (
        <Divider 
            className={classNameCardGridDivider + (className ? ' ' + className : '')}
            {...rest}
        />
    )
}

export const CardGridDividerElementos = props => {

    const {className, ...rest} = props

    return (
        <Divider 
            className={classNameCardGridDividerElementos + (className ? ' ' + className : '')}
            light={true}
            {...rest}
        />
    )
}

export const CardGridDividerEntradaDados = props => {

    const {className, ...rest} = props

    return (
        <Divider 
            className={classNameCardGridDividerEntradaDados + (className ? ' ' + className : '')}
            {...rest}
        />
    )
}

export const CardGridDivHeader = props => {
    return (
        <div 
            className={classNameCardGridDivHeader}
            {...props}
        />
    )
}

export const CardGridDivHeaderDestaque1 = props => {
    return (
        <div 
            className={classNameCardGridDivHeaderDestaque1}
            {...props}
        />
    )
}

export const CardGridDivHeaderDestaqueAlerta = props => {
    return (
        <div 
            className={classNameCardGridDivHeaderDestaqueAlerta}
            {...props}
        />
    )
}

export const CardGridDivHeaderResumoCabecalho = props => {
    return (
        <div
            className={classNameCardGridDivHeaderResumoCabecalho}
            {...props}
        />
    )
}

export const CardGridDivH4 = props => {
    return (
        <h4 
            className={classNameCardGridDivH4}
            {...props}
        >
        </h4>
    )
}

export const CardGridDivEntradaDados = props => {
    return (
        <CardGridDivH4 
            className={classNameCardGridDivEntradaDados}
            {...props}
        />
    )
}

export const CardGridDivH4Destaque = props => {

    const {report, ...rest} = props

    return (
        <CardGridDivH4 
            className={report ? classNameCardGridDivH4DestaqueReport : classNameCardGridDivH4Destaque}
            {...rest}
        />
    )
}

export const CardGridDivBody = props => {

    const { className, ...rest } = props

    return (
        <div 
            className={classNameCardGridDivBody + (className ? ' ' + className : '')} 
            {...rest}
        />
    )
}

export const CardGridDivBodyResumo = props => {

    const {className, ...rest} = props

    return (
        <div 
            className={clsx(classNameCardGridDivBodyResumo, className)} 
            {...rest}
        />
    )
}

export const CardGridDivBodyResumoTotal = props => {

    return (
        <div 
            className={classNameCardGridDivBodyResumoTotal} 
            {...props}
        />
    )
}

export const CardGridDivBodyResumoTotalGeral = props => {

    return (
        <div 
            className={classNameCardGridDivBodyResumoTotalGeral} 
            {...props}
        />
    )
}

export const CardGridDivBodyEntradaDados = props => {
    return (
        <div 
            className={classNameCardGridDivBodyEntradaDados} 
            {...props}
        />
    )
}

export const CardGridEntradaDadosHeaderBody = props => {

    const { title, component, ...rest } = props

    return (
        <>
            {title && (
                component
                    ?
                        <GridContainer
                            className={classNameCardGridEntradaDadosHeaderBodyHeader}
                        >
                            <GridItem8>
                                <div
                                    className={classNameCardGridEntradaDadosHeaderBodyHeaderTitle}
                                >
                                    {title}
                                </div>
                            </GridItem8>
                            <GridItem4>
                                {component}
                            </GridItem4>
                        </GridContainer>
                    :
                        <GridContainer
                            className={classNameCardGridEntradaDadosHeaderBodyHeader}
                        >
                            <div
                                className={classNameCardGridEntradaDadosHeaderBodyHeaderTitle}
                            >
                                {title}
                            </div>
                        </GridContainer>
            )}

            <div
                className={classNameCardGridEntradaDadosHeaderBodyBody}
                {...rest}
            />
        </>
    )
}

export const CardGridEntradaDadosHeaderBodyDivider = props => {

    return (
        <div
            className='mt-2'
        >
            <CardGridEntradaDadosHeaderBody
                {...props}
            />
        </div>
    )
}