import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class ClienteAgenteService extends ApiService {

    constructor() {
        super(HARDCODE.apiClienteAgente)
    }

    async incluirAtualizarClienteAgenteList(variaveisGlobais, idUsuario, idArquivo, seguradora, jsonClienteAgenteList) {

        const clienteAgenteListDTO = {
            idUsuario: idUsuario,
            idArquivo: idArquivo,
            seguradora: seguradora,
	        jsonClienteAgenteList: jsonClienteAgenteList,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiClienteAgenteIncluirAtualizarClienteAgenteList,
            clienteAgenteListDTO, false)
    }
}

export default ClienteAgenteService