
import React from 'react'

import AuthService from '../../business-api/AuthService'

import MESSAGE from '../../business-const/Message/Message'

import {
    ImgLogoSigninSideNav,
} from '../../business-components/Img/Img'

import {
    SvgImageFintracks,
} from '../../business-components/SvgImage/SvgImage'

import {
    WhatsApp
} from '../../business-components/WhatsApp/WhatsApp'

import {
    senhaOK,
} from './SessionService'




export const LogoSession = ({ variaveisGlobais }) => {

    return (

        <div
            className="p-5 flex justify-center items-center h-full bg-loginCard"
        >
            {
                AuthService.obterLogo(variaveisGlobais) || AuthService.obterLogoLoginSidenav(variaveisGlobais)
                    ?
                        <ImgLogoSigninSideNav
                            src={
                                AuthService.obterLogoLoginSidenav(variaveisGlobais)
                                    ?
                                        AuthService.obterLogoLoginSidenav(variaveisGlobais) 
                                    :
                                        AuthService.obterLogo(variaveisGlobais)
                            }
                        />
                    :
                        <SvgImageFintracks />
            }
        </div>
    )
}

export const TituloSession = ( props ) => {

    return (
        <h1
            className='mb-5'
        >
            {props.titulo}
        </h1>
    )
}

export const PadraoSenha = ({ senha }) => {

    return (
        !senhaOK(senha) && (
            <div
                className='ml-4 mt-1 mb-1'
                dangerouslySetInnerHTML={{ __html: MESSAGE().formatoPadraoSenha}}
            />
        )
    )
}

export const WhatsAppSession = () => {

    return (
        <div
            className= 'mt-10'
            align='right'
        >
            <WhatsApp/>
        </div>
    )
}