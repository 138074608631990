import ContaInvestimentosService from '../../../business-api/ContaInvestimentosService'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'



export const processaRegistrosDefaultIniciaisContaInvestimentos = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos) {

            values.familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos = []
        }

        if (!values.contasinvestimentos) {
            
            values.contasinvestimentos = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisContaInvestimentos.push(
                {
                    id: familiar
                }
            )

            if (values.contasinvestimentos.filter(contato => contato.familiar === familiar).length === 0) {

                values.contasinvestimentos.push(
                    {
                        familiar: familiar,
                    }
                )
            }
        }
    }
}



export const getContaInvestimentos = async ( variaveisGlobais, instituicaoFinanceira, numeroConta ) => {

    const contaInvestimentosService = new ContaInvestimentosService()

    try {
        const response = await contaInvestimentosService.buscarContaInvestimentos(variaveisGlobais, instituicaoFinanceira, numeroConta)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return false
    }
}