import HARDCODE from '../../business-const/HardCode/HardCode'



const statusIntegracaoList = [
    {
        id: HARDCODE.idStatusIntegracaoProcessamentoPendente,
        descricao: 'Processamento Pendente',
        ordem: 10,
    },
    {
        id: HARDCODE.idStatusIntegracaoIncluidoAtualizado,
        descricao: 'Incluído/Atualizado',
        ordem: 20,
    },
    {
        id: HARDCODE.idStatusIntegracaoCpfNaoEncontrado,
        descricao: 'CPF não Encontrado',
        ordem: 30,
    },
    {
        id: HARDCODE.idStatusIntegracaoCpfDuplicado,
        descricao: 'CPF Duplicado',
        ordem: 40,
    },
    //{
    //    id: HARDCODE.idStatusIntegracaoFamiliarNaoClienteNemConjuge,
    //    descricao: 'Familiar não é o Cliente nem o Cônjuge',
    //    ordem: 50,
    //},
    {
        id: HARDCODE.idStatusIntegracaoNaoVeioNaIntegracao,
        descricao: 'Não veio na Integração',
        ordem: 60,
    },
    {
        id: HARDCODE.idStatusIntegracaoOcorreuErroAtualizacaoCliente,
        descricao: 'Ocorreu erro na atualização do cliente',
        ordem: 70,
    },
]

export default statusIntegracaoList