import ApoliceComissaoService from '../../../business-api/ApoliceComissaoService'

import ClienteService from '../../../business-api/ClienteService'

import VwClienteAcessoApoliceComissaoService from '../../../business-api/VwClienteAcessoApoliceComissaoService'

//import HARDCODE from '../../../business-const/HardCode/HardCode'

//import {
//    formataAnoMesInteiroComBarra,
//} from '../../../business-components/Date/Date'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

//import {
//    round
//} from '../../../business-components/round/round'

//import {
//    pesquisaList,
//    pesquisaIndexList,
//} from '../../../business-rules/List/ListService'

//import statusParcelaList from '../../../business-rules/List/SeguroVida/statusParcelaList'

//import {
//    sortAsc
//} from '../../../business-components/Sort/Sort'



export const getApoliceComissaoList = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAcessoApoliceComissaoService = new VwClienteAcessoApoliceComissaoService()

    try {
        const response = await vwClienteAcessoApoliceComissaoService.buscarApoliceComissaoList(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getSumApoliceComissao = async ( variaveisGlobais, campoAgrupamento, filtroList ) => {

    const vwClienteAcessoApoliceComissaoService = new VwClienteAcessoApoliceComissaoService()

    try {
        const response = await vwClienteAcessoApoliceComissaoService.sumApoliceComissao(variaveisGlobais, campoAgrupamento, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}



export const putApoliceComissaoList = async ( variaveisGlobais, idArquivo, seguradora, jsonApoliceComissaoList) => {

    const apoliceComissaoService = new ApoliceComissaoService()

    try {

        const response = await apoliceComissaoService.atualizarSalvarDeletarApoliceComissaoList(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idArquivo, seguradora,
                JSON.stringify(jsonApoliceComissaoList))

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)

        return 0
    }
}



//export const trataCobrancassList = (list, ordenarPorAgrupamento, agrupamentoAnoMes, tipoValorAgrupamento, tipoList, primeiros) => {