// alterado - revisado

const tipoRegimeBensList = [
    {
        id: 10, 
        descricao: 'Comunhão parcial de bens',
        ordem: 10,
    },
    {
        id: 20, 
        descricao: 'Comunhão Universal de Bens',
        ordem: 20,
    },
    {
        id: 30, 
        descricao: 'Separação de bens',
        ordem: 30,
    },
    {
        id: 40, 
        descricao: 'Participação final nos Aquestos',
        ordem: 40,
    },
]

export default tipoRegimeBensList




