import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import NUMMIN from '../../NumMinMax/NumMin'

import NUMMAX from '../../NumMinMax/NumMax'

import TAMMAX from '../../TamMax/TamMax'

import {
    formataArrayData,
    formataAnoMesInteiroComBarra,
    //formataMesDiaInteiroDiaMesComBarra,
} from '../../../business-components/Date/Date'

import {
    formataValor,
    formataCpf,
    formataCelular,
} from '../../../business-components/Formata/Formata'

//import {
//    IconButtonDocument,
//} from '../../../business-components/IconButton/IconButton'

//import {
//    CustomizedDialogsPopup,
//} from '../../../business-components/Dialog/CustomizeDialogs'

import {
    pesquisaDescricaoList,
    incluiCampoList,
} from '../ListService'

//import sexoList from '../sexoList'

//import parentescoList from '../parentescoList'

import tipoPeriodicidadeList from '../tipoPeriodicidadeList'

import tipoFormaPagtoPremioList from '../tipoFormaPagtoPremioList'

import seguradoraList from './seguradoraList'

import statusApoliceList from './statusApoliceList'

import simNaoList from '../simNaoList'

import bancoList from '../bancoList'

import statusParcelaList from './statusParcelaList'

//import tipoBaixaParcelaList from '../SeguroVida/tipoBaixaParcelaList'



export const camposApoliceCobrancaList = ( list, columnsExibe, grafico, filtroList, filtroLists ) => {

    let camposListAux = []

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoUsuario',
        label: LABEL.usuario,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 10,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoGestorLider',
        label: LABEL.gestorLider,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 15,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroApolice',
        label: LABEL.numeroApolice,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroApolice,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 20,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroProposta',
        label: LABEL.numeroProposta,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroProposta,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 30,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroCotacao',
        label: LABEL.numeroCotacao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.numeroCotacao,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 40,
    })
    */

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nome',
        label: LABEL.nomeSegurado,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.nome,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 50,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cpf',
        label: LABEL.cpf,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCpf,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCpf(list[dataIndex].cpf))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 60,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nascimento',
        label: LABEL.nascimento,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].nascimento))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 70,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'mesDiaNascimento',
        label: LABEL.diaMesNascimento,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeDiaMes,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
         options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataMesDiaInteiroDiaMesComBarra(list[dataIndex].mesDiaNascimento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 80,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'sexo',
        label: LABEL.sexo,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.sexoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(sexoList(true), list[dataIndex].sexo))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 90,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'parentesco',
        label: LABEL.parentesco,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.parentescoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(parentescoList(true), list[dataIndex].parentesco))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 100,
    })
    */

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'profissao',
        label: LABEL.profissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.profissaoLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 110,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'email',
        label: LABEL.email,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeEmail,
        tamMax: HARDCODE.email,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 120,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'celular',
        label: LABEL.celular,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCelular,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCelular(list[dataIndex].celular))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 130,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cidadeEnderecoResidencial',
        label: LABEL.cidade,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.cidadeEnderecoResidencialLists,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 140,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'seguradora',
        label: LABEL.seguradora,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectInteiro,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.seguradoraLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(seguradoraList(), list[dataIndex].seguradora))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 150,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataEmissao',
        label: LABEL.dataEmissao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataEmissao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 160,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesEmissao',
        label: LABEL.anoMesEmissao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesEmissao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 170,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'mesDiaEmissao',
        label: LABEL.diaMesEmissao,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeDiaMes,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataMesDiaInteiroDiaMesComBarra(list[dataIndex].mesDiaEmissao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 175,
    })
    */

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'statusApolice',
        label: LABEL.statusApolice,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.statusApoliceLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(statusApoliceList, list[dataIndex].statusApolice))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 180,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorPremioAnualizado',
        label: LABEL.valorPremioAnualizado,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorPremioAnualizado, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 190,
    })
    */

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoPeriodicidadePagtoPremio',
        label: LABEL.periodicidadePagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoPeriodicidadePagtoPremioLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoPeriodicidadeList(false, true), list[dataIndex].tipoPeriodicidadePagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 200,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoFormaPagtoPremio',
        label: LABEL.tipoFormaPagto,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoFormaPagtoPremioLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoFormaPagtoPremioList, list[dataIndex].tipoFormaPagtoPremio))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 210,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'banco',
        label: LABEL.banco,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.bancoLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(bancoList(true), list[dataIndex].banco))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 220,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'agencia',
        label: LABEL.agencia,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.agencia,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 230,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'conta',
        label: LABEL.conta,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeString,
        tamMax: TAMMAX.conta,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 240,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'diaVencimento',
        label: LABEL.diaVencimento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.dia,
        numMax: NUMMAX.dia,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 250,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroParcela',
        label: LABEL.numeroParcela,
        filtro: 'N',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.qtdeParcelas,
        numMax: NUMMAX.qtdeParcelas,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 260,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'statusParcela',
        label: LABEL.statusParcela,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.statusParcelaLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(statusParcelaList, list[dataIndex].statusParcela))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 270,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'tipoBaixaParcela',
        label: LABEL.tipoBaixaParcela,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.tipoBaixaParcelaLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(tipoBaixaParcelaList, list[dataIndex].tipoBaixaParcela))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 280,
    })
    */

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataVencimento',
        label: LABEL.dataVencimento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataVencimento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 290,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesVencimento',
        label: LABEL.anoMesVencimento,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesVencimento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 300,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataCompetencia',
        label: LABEL.dataCompetencia,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataCompetencia))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 310,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataInicioVigencia',
        label: LABEL.dataInicioVigencia,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataInicioVigencia))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 320,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataFimVigencia',
        label: LABEL.dataFimVigencia,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataFimVigencia))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 330,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataPagamento',
        label: LABEL.dataPagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataPagamento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 340,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'anoMesPagamento',
        label: LABEL.anoMesPagamento,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeInteiro,
        tamMax: null,
        numMin: NUMMIN.anoMes,
        numMax: NUMMAX.anoMes,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataAnoMesInteiroComBarra(list[dataIndex].anoMesPagamento))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 350,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataCorte',
        label: LABEL.dataCorte,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeData,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayData(list[dataIndex].dataCorte))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 340,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorAPagar',
        label: LABEL.valorAPagar,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorAPagar, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 350,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'valorPago',
        label: LABEL.valorPago,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeValor,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataValor(list[dataIndex].valorPago, 2))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 350,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'producaoPropria',
        label: LABEL.producaoPropria,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: simNaoList,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(simNaoList, list[dataIndex].producaoPropria))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 370,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'links',
        label: LABEL.links,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    <CustomizedDialogsPopup
                        maxWidth="lg"
                        popupTitle={LABEL.extratoApolice}
                        tooltipTitle={LABEL.extratoApolice}
                        PopupButton={IconButtonDocument}
                        popupComponent={
                            <PopupComponent/>
                        }
                    />
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 210,
    })
    */

    return camposListAux
}

export default camposApoliceCobrancaList