import HARDCODE from '../../../business-const/HardCode/HardCode'



const statusApoliceList = [
    {
        id: HARDCODE.idStatusApoliceAtiva,
        name: HARDCODE.idStatusApoliceAtiva,
        descricao: 'Ativa',
        label: 'Ativa',
        ativa: 'S',
        ordem: 10,
    },
    {
        id: HARDCODE.idStatusApoliceCancelada,
        name: HARDCODE.idStatusApoliceCancelada,
        descricao: 'Cancelada',
        label: 'Cancelada',
        ativa: 'N',
        ordem: 20,
    },
    {
        id: HARDCODE.idStatusApoliceRejeitada,
        name: HARDCODE.idStatusApoliceRejeitada,
        descricao: 'Rejeitada',
        label: 'Rejeitada',
        ativa: 'N',
        ordem: 30,
    },
    {
        id: HARDCODE.idStatusApoliceConvertida,
        name: HARDCODE.idStatusApoliceConvertida,
        descricao: 'Convertida',
        label: 'Convertida',
        ativa: 'N',
        ordem: 40,
    },
    {
        id: HARDCODE.idStatusApoliceSubstituida,
        name: HARDCODE.idStatusApoliceSubstituida,
        descricao: 'Substituída',
        label: 'Substituída',
        ativa: 'N',
        ordem: 50,
    },
    {
        id: HARDCODE.idStatusApoliceAnulada,
        name: HARDCODE.idStatusApoliceAnulada,
        descricao: 'Anulada',
        label: 'Anulada',
        ativa: 'N',
        ordem: 60,
    },
    {
        id: HARDCODE.idStatusApoliceTerminada,
        name: HARDCODE.idStatusApoliceTerminada,
        descricao: 'Terminada',
        label: 'Terminada',
        ativa: 'N',
        ordem: 70,
    },
    {
        id: HARDCODE.idStatusApoliceSinistrada,
        name: HARDCODE.idStatusApoliceSinistrada,
        descricao: 'Sinistrada',
        label: 'Sinistrada',
        ativa: 'N',
        ordem: 80,
    },
]

export default statusApoliceList
