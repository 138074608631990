const PATH = {
    pageSignup: '/session/signup',
    pageSignin: '/session/signin',
    pageResetSenha: '/session/reset-senha',
    pageResposta: '/resposta',
    pageVideo: '/video',
    page404: '/session/404',



    pageDashboardCliente: '/dashboard/cliente',
    pageAgenda: '/agenda',
    pageDashboardFamilia: '/dashboard/familia',
    pageDashboardConsorcio: '/dashboard/consorcio',
    pageDashboardProjetoVidaSonho: '/dashboard/projetoVidaSonho',
    pageDashboardProposta: '/dashboard/proposta',
    pageDashboardApolice: '/dashboard/apolice',
    pageDashboardApoliceCobranca: '/dashboard/apoliceCobranca',
    pageDashboardApoliceComissao: '/dashboard/apoliceComissao',
    pageDashboardAporte: '/dashboard/aporte',
    pageUsuarioEstatistica: '/dashboard/usuarioEstatistica',
    pageConfiguracaoForm: '/pages/configuracao-form',
    pageClienteList: '/pages/cliente-list',
    pageClienteListRedirect: '/pages/cliente-list-redirect',
    pageAporteList: '/pages/aporte-list',
    pageImportarClienteExcelForm: '/pages/importarClienteExcel-form',
    pageIntegracaoClienteArquivoList: '/pages/integracaoClienteArquivo-list',
    pageTransferirFormRedirect: '/pages/transferir-form',
    pageClienteForm: '/pages/cliente-form',
    pageVersaoFormRedirect: '/pages/versao-form',
    pageApoliceForm: '/pages/apolice-form',
    pageVideoForm: '/pages/video-form',
    pageTreinamentoForm: '/pages/treinamento-form',
    pageFintracksForm: '/pages/fin-tracks',
}

export default PATH