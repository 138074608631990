import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    dataAtualSemHora,
    primeiroDiaDataInformada,
    ultimoDiaMesAtual,
    somaDiasData,
} from '../../../business-components/Date/Date'

import {
    formataAnoMesInteiroComBarra,
} from '../../../business-components/Date/Date'

import {
    pesquisaList,
    pesquisaIndexList,
} from '../../../business-rules/List/ListService'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    objetoFiltroList
} from './FiltroService'



export const inicializaControleDashboard = (idDataNULL, camposListNULL, dataFinalHoje, filtroAdicional) => {

    let filtroList

    if (idDataNULL) {

        filtroList = [
            objetoFiltroList(idDataNULL, primeiroDiaDataInformada(somaDiasData(dataAtualSemHora(), -182)),
                dataFinalHoje ? dataAtualSemHora() : ultimoDiaMesAtual(), '', camposListNULL),
        ]

    } else {

        filtroList = []
    }

    if (filtroAdicional) {
        
        filtroList.push(
            objetoFiltroList(filtroAdicional, null, null, 'S', camposListNULL),
        )
    }

    return {
        filtroList: filtroList,
        tabIndexDashboard: 0,
        columnsExibe: [],
        columnsNaoExibe: [],
        idQuebraExcel: '',
    }
}



export const trataList = (list, ordenarPorAgrupamento, agrupamentoAnoMes, tipoList, primeiros, tabela, tabelaCampo, tabelaDescricao) => {

    const graficoLista = {
        grafico: {
            data: [],
            data2: [],
            categories: [],
        },
        lista: [],
        listaClick: [],
        tabela: tabela ? tabela : [],
        tabelaDescricao: tabelaDescricao,
    }

    let agrupamento

    let indexLista

    list?.forEach((linhaList, indexList) => {

        agrupamento =
            tipoList
                ?
                    pesquisaList(tipoList, linhaList.agrupamento).descricao
                :
                    linhaList.agrupamento

        indexLista = pesquisaIndexList(graficoLista.lista, agrupamento)

        if (indexLista === null) {
            
            graficoLista.lista.push(
                {
                    id: agrupamento,
                    descricao: agrupamentoAnoMes ? formataAnoMesInteiroComBarra(agrupamento) : agrupamento,
                    filtro: linhaList.agrupamento,
                    qtde: linhaList.qtde,
                    valor: linhaList.valor,
                }
            )

            indexLista = graficoLista.lista.length - 1

        } else {

            graficoLista.lista[indexLista].qtde += linhaList.qtde
            graficoLista.lista[indexLista].valor += linhaList.valor
        }

        if (tabelaCampo) {

            if (!graficoLista.lista[indexLista]['qtde' + linhaList[tabelaCampo]]) {

                graficoLista.lista[indexLista]['qtde' + linhaList[tabelaCampo]] = 0
                graficoLista.lista[indexLista]['valor' + linhaList[tabelaCampo]] = 0
            }

            graficoLista.lista[indexLista]['qtde' + linhaList[tabelaCampo]] += linhaList.qtde
            graficoLista.lista[indexLista]['valor' + linhaList[tabelaCampo]] += linhaList.valor
        }

        graficoLista.lista[indexLista].ordem =
            ordenarPorAgrupamento
                ?
                    agrupamento
                :
                    graficoLista.lista[indexLista].valor * -1
    })

    graficoLista.lista = sortAsc(graficoLista.lista)

    let indexGrafico = 0

    const tamGraficoLista = graficoLista.lista.length

    graficoLista.lista?.forEach((linhaLista, indexLista) => {

        if (
                (primeiros && indexGrafico < HARDCODE.qtdeBarrasGraficoDashboard) ||
                (!primeiros && indexLista > (tamGraficoLista - 1 - HARDCODE.qtdeBarrasGraficoDashboard))

        ) {

            graficoLista.grafico.data.push(
                linhaLista.valor
            )
            
            graficoLista.grafico.data2.push(
                linhaLista.qtde
            )
            
            graficoLista.grafico.categories.push(
                linhaLista.descricao
            )

            graficoLista.listaClick.push(
                linhaLista
            )
        }
        
        indexGrafico++
    })

    return graficoLista
}