// alterado - revisado

import {
    SvgIcon,
} from '@material-ui/core'

import { ReactComponent as sucessaoVitaliciaSvg } from './sucessaoVitalicia.svg'

import { ReactComponent as composicaoInventarioSvg } from './composicaoInventario.svg'

import { ReactComponent as totalSvg } from './total.svg'

import { ReactComponent as despesasTotaisSvg } from './despesasTotais.svg'

import { ReactComponent as garantiasAtuaisSvg } from './garantiasAtuais.svg'

import { ReactComponent as iconButtonCalculadoraSvg } from './iconButtonCalculadora.svg'



export const SvgIconSucessaoVitalicia = ( props ) => {
    return (
        <SvgIcon
            component={sucessaoVitaliciaSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}

export const SvgIconComposicaoInventario = ( props ) => {
    return (
        <SvgIcon
            component={composicaoInventarioSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}

export const SvgIconTotal = ( props ) => {
    return (
        <SvgIcon
            component={totalSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}

export const SvgIconDespesasTotais = ( props ) => {
    return (
        <SvgIcon
            component={despesasTotaisSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}

export const SvgIconGarantiasAtuais = ( props ) => {
    return (
        <SvgIcon
            component={garantiasAtuaisSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}

export const SvgIconButtonCalculadora = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonCalculadoraSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}