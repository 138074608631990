// alterado - revisado

import React from 'react'

import {
    ListRadioGroup,
} from '../../business-components/RadioGroup/RadioGroup'

import {
    radioGroupFormListPrimeiraReuniaoAprofundamento,
    radioGroupFormListSimplificadoCompleto,
} from '../List/radioGroupFormList'



export const FormListRadioGroupPrimeiraReuniaoAprofundamento = props => {
    return (
        <ListRadioGroup
            list={radioGroupFormListPrimeiraReuniaoAprofundamento}
            {...props}
        />
    )
}



export const FormListRadioGroupSimplificadoCompleto = props => {
    return (
        <ListRadioGroup
            list={radioGroupFormListSimplificadoCompleto}
            {...props}
        />
    )
}