import HARDCODE from '../../business-const/HardCode/HardCode'



const tipoFormaPagtoPremioList = [
    {
        id: HARDCODE.idTipoFormaPagtoPremioCartaoCredito,
        name: HARDCODE.idTipoFormaPagtoPremioCartaoCredito,
        descricao: 'Cartão de Crédito',
        label: 'Cartão de Crédito',
        ordem: 10,
    },
    {
        id: HARDCODE.idTipoFormaPagtoPremioDebito,
        name: HARDCODE.idTipoFormaPagtoPremioDebito,
        descricao: 'Débito',
        label: 'Débito',
        ordem: 20,
    },
    {
        id: HARDCODE.idTipoFormaPagtoPremioBoleto,
        name: HARDCODE.idTipoFormaPagtoPremioBoleto,
        descricao: 'Boleto',
        label: 'Boleto',
        ordem: 30,
    },
    {
        id: HARDCODE.idTipoFormaPagtoPremioPix,
        name: HARDCODE.idTipoFormaPagtoPremioPix,
        descricao: 'Pix',
        label: 'Pix',
        ordem: 40,
    },
    {
        id: HARDCODE.idTipoFormaPagtoPremioSinglePremium,
        name: HARDCODE.idTipoFormaPagtoPremioSinglePremium,
        descricao: 'Single Premium',
        label: 'Single Premium',
        ordem: 50,
    },
    {
        id: HARDCODE.idTipoFormaPagtoPremioNaoInformado,
        name: HARDCODE.idTipoFormaPagtoPremioNaoInformado,
        descricao: 'Não Informado',
        label: 'Não Informado',
        ordem: 60,
    },
]

export default tipoFormaPagtoPremioList
