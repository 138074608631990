import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class IntegracaoService extends ApiService {

    constructor() {
        super(HARDCODE.apiIntegracao)
    }

    async obterTokenIntegracaoInterno(variaveisGlobais, bodyDTO) {

        return this.post(variaveisGlobais, HARDCODE.apiIntegracaoObterTokenIntegracaoInterno, bodyDTO, false)
    }

    async autenticarTokenIntegracao(variaveisGlobais, credenciais) {

        const body = {
            logAcessoApiDTO: await usuarioInfo(),
            email: credenciais.email,
            tokenIntegracao: credenciais.tokenIntegracao,
            acessoCliente: credenciais.acessoCliente
        }

        return this.post(variaveisGlobais, HARDCODE.apiIntegracaoAutenticarTokenIntegracao, body, true)
    }

}

export default IntegracaoService