import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    obtemTextoArray,
} from '../../../business-components/Array/Array'

import {
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    identificaTipoCoberturaSeguroVida,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoPDFAvulso,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
} from './IntegracaoClienteArquivoService'



export const importaApoliceOmint = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replaceAll(fimLinha + 'NÚMERO DE APÓLICE' + espaco, fimLinha + 'NÚMERO DA APÓLICE' + espaco)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NÚMERO DA APÓLICE' + espaco, espaco,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'NÚMERO DA PROPOSTA' + espaco, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'DATA DE EMISSÃO' + espaco, espaco, 
        HARDCODE.importacaoDataEmissao, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao) {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Início' + espaco, espaco, 
            HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)
    }
    


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NOME COMPLETO DATA DE NASCIMENTO CPF' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'NOME CIVIL DATA DE NASCIMENTO CPF' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)
    }

    let linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    let tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, obtemTextoArray(linhaDados, 0, tamLinhaDados - 3),
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 2],
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'ENDEREÇO N° COMPLEMENTO' + fimLinha, fimLinha,
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'BAIRRO CIDADE UF CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    tamLinhaDados = linhaDados.length

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 1],
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDFAvulso (jsonRegistros, linhaDados[tamLinhaDados - 2],
    //    HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '02 Endereço Residencial' + fimLinha + 'Cidade' + fimLinha, fimLinha,
    //    HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)
        
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro' + fimLinha, fimLinha,
    //    HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)



    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
    //    HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
    //    HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
    //    HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
    //    HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)

   

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice = HARDCODE.idStatusApoliceAtiva
    //obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
    //    HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
        'COBERTURAS BÁSICAS CAPITALSEGURADO INÍCIO DE VIGÊNCIA FIM DE VIGÊNCIA PRÊMIO LÍQUIDO' + fimLinha,
            fimLinha + 'PRÊMIO LÍQUIDO' + espaco, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
            'COBERTURAS CAPITAL SEGURADO INÍCIO DE VIGÊNCIA FIM DE VIGÊNCIA PRÊMIO LÍQUIDO' + fimLinha,
                fimLinha + 'PRÊMIO LÍQUIDO' + espaco, HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)
    }
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
        'PERIODICIDADE DO PAGAMENTO FORMA DE PAGAMENTO PRÊMIO ANUAL IOF PRÊMIO ANUAL TOTAL DIA DE VENCIMENTO' + fimLinha, espaco,
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
            'Periodicidade de pagamento Forma de Pagamento Prêmio total da vigência IOF Prêmio total da vigência com IOF Dia de Vencimento' + fimLinha, espaco,
                HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll('R$', 'R$' + espaco)
    }



    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'INVALIDEZ PERMANENTE' + espaco, espaco + 'INVALIDEZ PERMANENTE' + espaco)

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'IPA MAJORADA', espaco + 'IPA MAJORADA')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'MORTE – COBERTURA TEMPORÁRIA', espaco + 'MORTE – COBERTURA TEMPORÁRIA')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'DOENÇAS GRAVES', espaco + 'DOENÇAS GRAVES')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'DIÁRIA DE INTERNAÇÃO HOSPITALAR', espaco + 'DIÁRIA DE INTERNAÇÃO HOSPITALAR')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'MORTE ACIDENTAL', espaco + 'MORTE ACIDENTAL')
    
    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'FUNERAL FAMILIAR', espaco + 'FUNERAL FAMILIAR')

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
            'FUNERAL INDIVIDUAL', espaco + 'FUNERAL INDIVIDUAL')
    

    const linhasCobertura = []
    let indexLinhasCobertura = 0
    //console.log(jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas)

    const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
    let indexLinhasPosicaoAux = 0

    const coberturas = []

    let dadosCobertura

    let numeroCobertura
    let codigoCobertura
    let descricaoCobertura
    let tipoCoberturaSeguroVida
    let valorCS
    let valorPremioLiquido
    let valorIof
    let valorPremio
    let classeAjusteRisco
    let valorPremioExtra
    let duracaoPremioExtra
    let dataTerminoCobertura
    let valorRendaMensal

    while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

        if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('R$') !== -1) {

            linhasCobertura.push(linhasCoberturaAux[indexLinhasPosicaoAux])
        }

        indexLinhasPosicaoAux += 1
    }
    //console.log(linhasCobertura)

    while (indexLinhasCobertura < linhasCobertura.length) {

        dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

        let tamDadosCobertura = dadosCobertura.length

        if (dadosCobertura[0] === 'R$') {

            descricaoCobertura = obtemTextoArray(dadosCobertura, 6, tamDadosCobertura - 1)

            valorCS = trataValorTexto(dadosCobertura[1])
            valorPremio = trataValorTexto(dadosCobertura[5])

            dataTerminoCobertura = trataData(dadosCobertura[3])

        } else {

            descricaoCobertura = obtemTextoArray(dadosCobertura, 0, tamDadosCobertura - 7)

            valorCS = trataValorTexto(dadosCobertura[tamDadosCobertura - 5])
            valorPremio = trataValorTexto(dadosCobertura[tamDadosCobertura - 1])

            dataTerminoCobertura = trataData(dadosCobertura[tamDadosCobertura - 3])
        }

        tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
            jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

        incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
            HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
        
        indexLinhasCobertura++
    }

    jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}