import familiaresList from '../../../business-rules/List/familiaresList'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import {
    grupoCoberturaSeguroVidaList
 } from '../../../business-rules/List/SeguroVida/grupoCoberturaSeguroVidaList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    dadosCompletosLinhaSeguroVidaCobertura,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'



export const incrementaContSeguroVidaCotacaoCobertura = ( values ) => {
    if (!values.contSeguroVidaCotacaoCobertura) {
        values.contSeguroVidaCotacaoCobertura = 0
    }
    values.contSeguroVidaCotacaoCobertura = values.contSeguroVidaCotacaoCobertura + 1
}



export const incluiSeguroVidaCotacaoCoberturaDefault = ( values, idSeguroVidaCotacao ) => {

    grupoCoberturaSeguroVidaList?.forEach( (linhaGrupoCoberturaSeguroVidaList, index) => {

        incrementaContSeguroVidaCotacaoCobertura(values)
        values.seguroVidaCotacaoCobertura.push(
            {
                id: values.contSeguroVidaCotacaoCobertura,
                idSeguroVidaCotacao: idSeguroVidaCotacao,
                grupoCoberturaSeguroVida: linhaGrupoCoberturaSeguroVidaList.id,
            }
        )
    })
}



export const excluirCotacaoCoberturas = (values, idSeguroVidaCotacao) => {

    let index = values.seguroVidaCotacaoCobertura.length - 1

    while (index >= 0) {
        
        if (values.seguroVidaCotacaoCobertura[index].idSeguroVidaCotacao === idSeguroVidaCotacao) {

            values.seguroVidaCotacaoCobertura.splice(index, 1)
        }

        index -= 1
    }
}



export const dadosCompletosSeguroVidaCotacaoCobertura = ( values ) => {
    
    let dadosIncompletos = ''
    let familiares = []
    let linhaSeguroVidaCotacao

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.seguroVidaCotacaoCobertura?.forEach( (linhaSeguroVidaCotacaoCobertura, index) => {

            linhaSeguroVidaCotacao = pesquisaList(values.seguroVidaCotacao,
                linhaSeguroVidaCotacaoCobertura.idSeguroVidaCotacao)

            if (
                    linhaSeguroVidaCotacao.familiar === linhaFamiliaresList.id &&
                    linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida &&
                    !dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCotacaoCobertura, true)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos,
                        (linhaSeguroVidaCotacao.numeroCotacao
                            ?
                                linhaSeguroVidaCotacao.numeroCotacao + ": "
                            :
                                ""
                        ) +
                            pesquisaDescricaoList(grupoCoberturaSeguroVidaList,
                                linhaSeguroVidaCotacaoCobertura.grupoCoberturaSeguroVida) + " - " +
                                    pesquisaDescricaoList(tipoCoberturaSeguroVidaList,
                                        linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida))
            }
        })
    })

    return dadosIncompletos
}