// alterado - revisado

import React from 'react'

import { Fab, Icon } from '@material-ui/core'

import HARDCODE from '../../business-const/HardCode/HardCode'

import ICON from '../../business-const/Icon/Icon'



export const UploadFile = props => {

    const onInputClick = (event) => {
        
        event.target.value = ''
    }

    return (
        <>
            <label
                htmlFor={props.id}
            >
                <Fab
                    className={"capitalize" + (props.disabled ? "" : " bg-upload text-white")}
                    //color="primary"
                    component="span"
                    variant="extended"
                >
                    <div className="flex items-center">
                        <Icon
                            className="pr-8"
                        >
                            {ICON.uploadFile}
                        </Icon>
                        
                        <span
                            className='text-12'
                        >
                            {props.label}
                        </span>
                    </div>
                </Fab>
            </label>

            <input
                id={props.id}
                className="hidden"
                type="file"
                accept={props.accept}
                onClick={onInputClick}
                onChange={props.onChange}
                multiple={props.multiple}
                disabled={props.disabled}
            />
        </>
    )
}

export const getBase64 = async ( file ) => {

    return new Promise(resolve => {
        let baseURL = ""
        
        // Make new FileReader
        let reader = new FileReader()
    
        // Convert the file to base64 text
        reader.readAsDataURL(file)
        //reader.readAsBinaryString(file)
        //reader.readAsArrayBuffer(file)
    
        // on reader load somthing...
        reader.onload = () => {
            
            // Make a fileInfo Object
            baseURL = reader.result
            resolve(baseURL)
        }
    })
}

export const getBlobFromBase64 = async ( conteudo, XLSXPDF ) => {

    const blob =
        atob(
            conteudo.substring(
                XLSXPDF === HARDCODE.PDF
                    ?
                        HARDCODE.PDFApplicationCompleto.length
                    :
                        HARDCODE.XLSXApplicationCompleto.length
            )
        )

    return blob
}