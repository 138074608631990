import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

class EmailService extends ApiService {

    constructor() {
        super(HARDCODE.apiEmails)
    }

    confirmacaoCadastroUsuario(variaveisGlobais, email) {
        let params = `email=${email}`

        return this.get(variaveisGlobais, `${HARDCODE.apiEmailsConfirmacaoCadastroUsuario}/?${params}`, true)
    }

    resetSenhaUsuario(variaveisGlobais, email) {
        let params = `email=${email}`

        return this.get(variaveisGlobais, `${HARDCODE.apiEmailsResetSenhaUsuario}/?${params}`, true)
    }
    
    validacaoLogin(variaveisGlobais, email) {
        let params = `email=${email}`

        return this.get(variaveisGlobais, `${HARDCODE.apiEmailsValidacaoLogin}/?${params}`, true)
    }
}

export default EmailService