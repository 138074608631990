// alterado - revisado

import React from 'react'

import {
    RadioGroup,
    Radio,
    FormControlLabel,
} from '@material-ui/core'

import {
    formataValor,
    formataPanelRadioGroupHelpText,
} from '../Formata/Formata'

import {
    GridContainerS3,
    GridItem2sm6xs6,
    GridItem12,
} from '../Grid/Grid'

import {
    CardGrid,
    CardGridEntradaDados,
    CardGridDivBodyEntradaDados,
} from '../Card/Card'



export const RadioGroupPadrao = props => {
    return (
        <RadioGroup
            row
            className="mt-1"
            {...props}
        />
    )
}

export const RadioPadrao = props => {
    return (
        <Radio
            color="secondary"
            {...props}
        />
    )
}

export const FormControlLabelPadrao = props => {
    return (
        <FormControlLabel
            control={
                <RadioPadrao /> 
            }
            labelPlacement="end"
            {...props}
        />
    )
}

export const ListRadioGroup = props => {
    return (
        <RadioGroupPadrao
            {...props}
        >
            {props.list.map(
                (item, ind) => (
                    <FormControlLabelPadrao
                        value={item.id}
                        key={item.id}
                        label={item.descricao}
                        disabled={props.disabled}
                    />
                )
            )}
        </RadioGroupPadrao>
    )
}

export const PanelRadioGroup = props => {

    const { helpText, ...rest } = props

    const classNameCardGridEntradaDados = "bg-panelRadioGroup mt-2 mb-4 border-radius-padrao"
    const classNameGridContainerS3 = "bg-panelRadioGroup mt-3 mb-3"
    const classNameCardGrid = "p-5 flex-column justify-center items-center border-radius-padrao"
    const classNameFormControlLabelPadrao = "text-muted"
    const classNameTitle = "mt-1 text-10 text-muted font-light"
    const classNameValor = "mt-1 text-15"
    const classNameTitle2 = "mt-1 text-10 text-muted font-light"
    const classNameValor2 = "mt-1 text-12 font-medium"
    const classNameSubTotalTitle = "mt-1 text-11 text-muted font-light"
    const classNameSubTotalValor = "mt-1 text-13"

    return (
        <RadioGroupPadrao
            {...rest}
        >
            <GridItem12>
                <CardGridEntradaDados
                    className={classNameCardGridEntradaDados}
                >
                    <CardGridDivBodyEntradaDados>
                        <GridContainerS3
                            className={classNameGridContainerS3}
                        >
                            {rest.list?.map(
                                (item, ind) => (
                                    <GridItem2sm6xs6
                                        key={item.id}
                                    >
                                        <CardGrid
                                            elevation={3}
                                            className={classNameCardGrid}
                                        >
                                            <FormControlLabelPadrao
                                                className={classNameFormControlLabelPadrao}
                                                value={item.id}
                                                label={item.descricao}
                                            />
                                            {item.valor && item.title 
                                                ?
                                                    <h3
                                                        className={classNameTitle}
                                                    >
                                                        {item.title}
                                                    </h3>
                                                : 
                                                    ''
                                            }
                                            {item.valor 
                                                ?
                                                    <h3
                                                        className={classNameValor}
                                                    >
                                                        {formataValor(item.valor, 2)}
                                                    </h3>
                                                : 
                                                    ''
                                            }
                                            {item.valor2 || (item.valor && item.valor2 === 0)
                                                ?
                                                    <>
                                                        <h6
                                                            className={classNameTitle2}
                                                        >
                                                            {item.title2}
                                                        </h6>
                                                        <h6
                                                            className={classNameValor2}
                                                        >
                                                            {formataValor(item.valor2, 2)}
                                                        </h6>
                                                    </>
                                                : 
                                                    ''
                                            }
                                            {
                                                (
                                                    item.subTotal1Title &&
                                                    item.subTotal1Valor > 0
                                                ) ||
                                                (
                                                    item.subTotal2Title &&
                                                    item.subTotal2Valor > 0
                                                )
                                                    ?
                                                        <br/>
                                                    :
                                                        ''
                                            }
                                            {item.subTotal1Title &&
                                                item.subTotal1Valor > 0
                                                ?
                                                    <>
                                                        <h3
                                                            className={classNameSubTotalTitle}
                                                        >
                                                            {item.subTotal1Title}
                                                        </h3>

                                                        <h3
                                                            className={classNameSubTotalValor}
                                                        >
                                                            {formataValor(item.subTotal1Valor, 2)}
                                                        </h3>
                                                    </>
                                                : 
                                                    ''
                                            }
                                            {item.subTotal2Title &&
                                                item.subTotal2Valor > 0
                                                ?
                                                    <>
                                                        <h3
                                                            className={classNameSubTotalTitle}
                                                        >
                                                            {item.subTotal2Title}
                                                        </h3>

                                                        <h3
                                                            className={classNameSubTotalValor}
                                                        >
                                                            {formataValor(item.subTotal2Valor, 2)}
                                                        </h3>
                                                    </>
                                                : 
                                                    ''
                                            }
                                        </CardGrid>
                                    </GridItem2sm6xs6>
                                )
                            )}
                            {helpText
                                ?
                                    <GridItem12>
                                        {formataPanelRadioGroupHelpText(helpText)}
                                    </GridItem12>
                                :
                                    ''
                            }
                        </GridContainerS3>
                    </CardGridDivBodyEntradaDados>
                </CardGridEntradaDados>
            </GridItem12>
        </RadioGroupPadrao>
    )
}