import HARDCODE from '../business-const/HardCode/HardCode'

import LABELESPECIAL from '../business-const/Label/LabelEspecial'

import LocalStorageService from './LocalStorageService'

import UsuarioService from './UsuarioService'

import {
    dataAtual,
    formataData,
} from '../business-components/Date/Date'

import {
    pesquisaList
} from '../business-rules/List/ListService'

import tipoMoedaList from '../business-rules/List/tipoMoedaList'



const UL = '_ul'
const PALETA_CORES = '_paleta_cores'
const LOGO = '_logo'
const LOGO_TOPBAR = '_logo_topbar'
const LINK_LOGO_TOPBAR = '_link_logo_topbar'
const TAMANHO_LOGO = '_tamanho_logo'
const TAMANHO_LOGO_TOPBAR = '_tamanho_logo_topbar'
const LOGO_LOGIN = '_logo_login'
//const BACKGROUND = '_background'
const LOGO_CAPA_RELATORIO = '_logo_capa_relatorio'
const LOGO_CAPA_RELATORIO_PAISAGEM = '_logo_capa_relatorio_paisagem'
const LOGO_PAGINA_RELATORIO = '_logo_pagina_relatorio'
const LOGO_PAGINA_RELATORIO_PAISAGEM = '_logo_pagina_relatorio_paisagem'
const TEXTO_MORTE = '_texto_morte'
const TIPO_IDIOMA = '_tipo_idioma'
const TIPO_MOEDA = '_tipo_moeda'
const MOEDA_PREFIX_SUFFIX = '_moeda_prefix_suffix'
const USUARIO_LOGADO = '_usuario_logado'
const EMAIL_USUARIO_LOGADO = '_email_usuario_logado'
const IDENTIFICACAO_USUARIO_LOGADO = '_identificacao_usuario_logado'
const TOKEN = '_n'
const DATA_INICIO_FREETRACK_USUARIO_LOGADO = '_data_inicio_freetrack_usuario_logado'
const USUARIO_SELECIONADO = '_usuario_selecionado'



export default class AuthService {

    static isUsuarioLogadoSelecionado(variaveisGlobais) {

        const usuarioLogado = this.obterUsuarioLogado(variaveisGlobais)//LocalStorageService.obterItem(USUARIO_LOGADO)

        const usuarioSelecionado = this.obterUsuarioSelecionado(variaveisGlobais)//LocalStorageService.obterItem(USUARIO_SELECIONADO)

        return usuarioLogado && usuarioSelecionado
    }
    

    
    static isUsuarioLogadoFintracks(variaveisGlobais) {

        if (pesquisaList(HARDCODE.emailsFintracks, this.obterEmailUsuarioLogado(variaveisGlobais)).id) {

            return true

        } else {

            return false
        }
    }
    

    
    static isUsuarioLogadoPrototipo(variaveisGlobais) {

        if (pesquisaList(HARDCODE.emailsPrototipo, this.obterEmailUsuarioLogado(variaveisGlobais)).id) {

            return true

        } else {

            return false
        }
    }
    


    static usuarioPrecisaLogarNovamente(variaveisGlobais) {

        const dataAtualAux = formataData(dataAtual())
        const dataUltimoLogin = this.obterUltimoLogin(variaveisGlobais)

        if (dataUltimoLogin === null || dataUltimoLogin !== dataAtualAux) {
            
            const usuarioService = new UsuarioService()

            this.setarUltimoLogin(HARDCODE.sessaoExpirou)

            usuarioService.logout(variaveisGlobais, true)

            return true

        } else {

            return false
        }
    }



    static validaTokenLogarNovamente(variaveisGlobais, response) {

        if (response.data === HARDCODE.tokenInvalido) {
            
            const usuarioService = new UsuarioService()

            this.setarUltimoLogin(HARDCODE.sessaoExpirouToken)

            usuarioService.logout(variaveisGlobais, true)

            return true

        } else {

            return false
        }
    }


    static urlLinkLogoTopbar(variaveisGlobais) {

        return  (
            !AuthService.obterLinkLogoTopbar(variaveisGlobais).includes(HARDCODE.http) &&
            !AuthService.obterLinkLogoTopbar(variaveisGlobais).includes(HARDCODE.https)
                ?
                    HARDCODE.https
                :
                    ''
        ) + AuthService.obterLinkLogoTopbar(variaveisGlobais)
    }



    static removerUltimoLogin() {

        LocalStorageService.removerItem(UL)
    }

    static setarUltimoLogin(ul) {

        LocalStorageService.setarItem(UL, ul)
    }

    static obterUltimoLogin(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).ultimoLogin

        } else {

            return LocalStorageService.obterItem(UL)
        }
    }



    static setarLogo(logo) {

        LocalStorageService.setarItem(LOGO, logo)
    }

    static obterLogo(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).logo

        } else {

            return LocalStorageService.obterItem(LOGO)
        }
    }



    static setarLogoTopbar(logoTopbar) {

        LocalStorageService.setarItem(LOGO_TOPBAR, logoTopbar)
    }

    static obterLogoTopbar(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).logoTopbar

        } else {

            return LocalStorageService.obterItem(LOGO_TOPBAR)
        }
    }



    static setarLinkLogoTopbar(linkLogoTopbar) {

        LocalStorageService.setarItem(LINK_LOGO_TOPBAR, linkLogoTopbar)
    }

    static obterLinkLogoTopbar(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).linkLogoTopbar

        } else {

            return LocalStorageService.obterItem(LINK_LOGO_TOPBAR)
        }
    }



    static setarTamanhoLogo(tamanhoLogo) {

        LocalStorageService.setarItem(TAMANHO_LOGO, tamanhoLogo)
    }

    static obterTamanhoLogo(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).tamanhoLogo

        } else {

            return LocalStorageService.obterItem(TAMANHO_LOGO)
        }
    }



    static setarTamanhoLogoTopbar(tamanhoLogoTopbar) {

        LocalStorageService.setarItem(TAMANHO_LOGO_TOPBAR, tamanhoLogoTopbar)
    }

    static obterTamanhoLogoTopbar(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).tamanhoLogoTopbar

        } else {
        
            return LocalStorageService.obterItem(TAMANHO_LOGO_TOPBAR)
        }
    }



    static setarLogoLoginSidenav(logoLoginSidenav) {

        LocalStorageService.setarItem(LOGO_LOGIN, logoLoginSidenav)
    }

    static obterLogoLoginSidenav(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).logoLoginSidenav

        } else {
        
            return LocalStorageService.obterItem(LOGO_LOGIN)
        }
    }



    //static setarBackground(background) {

    //    LocalStorageService.setarItem(BACKGROUND, background)
    //}

    //static obterBackground(variaveisGlobais) {

    //    if (this.obterAcessoCliente(variaveisGlobais)) {

    //        return this.obterAcessoClienteChaves(variaveisGlobais).background

    //    } else {
        
    //        return LocalStorageService.obterItem(BACKGROUND)
    //    }
    //}



    static setarBackgroundCapaRelatorio(backgroundCapaRelatorio) {

        LocalStorageService.setarItem(LOGO_CAPA_RELATORIO, backgroundCapaRelatorio)
    }

    static obterBackgroundCapaRelatorio(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).backgroundCapaRelatorio

        } else {
        
            return LocalStorageService.obterItem(LOGO_CAPA_RELATORIO)
        }
    }



    static setarBackgroundCapaRelatorioPaisagem(backgroundCapaRelatorioPaisagem) {

        LocalStorageService.setarItem(LOGO_CAPA_RELATORIO_PAISAGEM, backgroundCapaRelatorioPaisagem)
    }

    static obterBackgroundCapaRelatorioPaisagem(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).backgroundCapaRelatorioPaisagem

        } else {
        
            return LocalStorageService.obterItem(LOGO_CAPA_RELATORIO_PAISAGEM)
        }
    }



    static setarBackgroundPaginaRelatorio(backgroundPaginaRelatorio) {

        LocalStorageService.setarItem(LOGO_PAGINA_RELATORIO, backgroundPaginaRelatorio)
    }

    static obterBackgroundPaginaRelatorio(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).backgroundPaginaRelatorio

        } else {
        
            return LocalStorageService.obterItem(LOGO_PAGINA_RELATORIO)
        }
    }



    static setarBackgroundPaginaRelatorioPaisagem(backgroundPaginaRelatorioPaisagem) {

        LocalStorageService.setarItem(LOGO_PAGINA_RELATORIO_PAISAGEM, backgroundPaginaRelatorioPaisagem)
    }

    static obterBackgroundPaginaRelatorioPaisagem(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).backgroundPaginaRelatorioPaisagem

        } else {
        
            return LocalStorageService.obterItem(LOGO_PAGINA_RELATORIO_PAISAGEM)
        }
    }



    static setarPaletaCores(paletaCores) {

        LocalStorageService.setarItem(PALETA_CORES, paletaCores)
    }

    static obterPaletaCores() {

        return LocalStorageService.obterItem(PALETA_CORES)
    }



    static setarTextoMorte(textoMorte) {

        LocalStorageService.setarItem(TEXTO_MORTE, textoMorte)
    }

    static obterTextoMorte() {

        const textoMorte = LocalStorageService.obterItem(TEXTO_MORTE)

        return textoMorte && textoMorte !== 'undefined' ? textoMorte : LABELESPECIAL.morte
    }



    static setarTipoIdioma(tipoIdioma) {

        LocalStorageService.setarItem(TIPO_IDIOMA, tipoIdioma ? tipoIdioma : HARDCODE.tipoIdiomaDefault)
    }

    static obterTipoIdioma() {

        return LocalStorageService.obterItem(TIPO_IDIOMA)
    }



    static setarTipoMoeda(tipoMoeda) {

        LocalStorageService.setarItem(TIPO_MOEDA, tipoMoeda ? tipoMoeda : HARDCODE.tipoMoedaDefault)
    }

    static obterTipoMoeda() {

        return LocalStorageService.obterItem(TIPO_MOEDA)
    }



    static setarMoedaPrefixSuffix(tipoMoeda) {

        LocalStorageService.setarItem(MOEDA_PREFIX_SUFFIX,
            pesquisaList(tipoMoedaList, tipoMoeda ? tipoMoeda : HARDCODE.tipoMoedaDefault).prefixSuffix)
    }

    static obterMoedaPrefixSuffix() {

        return LocalStorageService.obterItem(MOEDA_PREFIX_SUFFIX)
    }



    static removerUsuarioLogado() {

        LocalStorageService.removerItem(USUARIO_LOGADO)
    }

    static setarUsuarioLogado(usuario) {

        LocalStorageService.setarItem(USUARIO_LOGADO, usuario)
    }

    static obterUsuarioLogado(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).usuarioLogado

        } else {

            return LocalStorageService.obterItem(USUARIO_LOGADO)
        }
    }
    


    static removerEmailUsuarioLogado() {

        LocalStorageService.removerItem(EMAIL_USUARIO_LOGADO)
    }

    static setarEmailUsuarioLogado(email) {

        LocalStorageService.setarItem(EMAIL_USUARIO_LOGADO, email)
    }

    static obterEmailUsuarioLogado(variaveisGlobais) {


        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).emailUsuarioLogado

        } else {

            return LocalStorageService.obterItem(EMAIL_USUARIO_LOGADO)
        }
    }



    static removerIdentificacaoUsuarioLogado() {

        LocalStorageService.removerItem(IDENTIFICACAO_USUARIO_LOGADO)
    }

    static setarIdentificacaoUsuarioLogado(identificacao) {

        LocalStorageService.setarItem(IDENTIFICACAO_USUARIO_LOGADO, identificacao)
    }

    static obterIdentificacaoUsuarioLogado(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).identificacaoUsuarioLogado

        } else {

            return LocalStorageService.obterItem(IDENTIFICACAO_USUARIO_LOGADO)
        }
    }



    static removerToken() {

        LocalStorageService.removerItem(TOKEN)
    }

    static setarToken(token) {

        LocalStorageService.setarItem(TOKEN, token)
    }

    static obterToken(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).token

        } else {

            return LocalStorageService.obterItem(TOKEN)
        }
    }


    
    static removerDataInicioFreetrackUsuarioLogado() {

        LocalStorageService.removerItem(DATA_INICIO_FREETRACK_USUARIO_LOGADO)
    }

    static setarDataInicioFreetrackUsuarioLogado(dataHoraInclusao) {

        LocalStorageService.setarItem(DATA_INICIO_FREETRACK_USUARIO_LOGADO, dataHoraInclusao)
    }

    static obterDataInicioFreetrackUsuarioLogado(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).dataInicioFreetrackUsuarioLogado

        } else {

            return LocalStorageService.obterItem(DATA_INICIO_FREETRACK_USUARIO_LOGADO)
        }
    }

    
    
    static removerUsuarioSelecionado() {

        LocalStorageService.removerItem(USUARIO_SELECIONADO)
    }

    static setarUsuarioSelecionado(usuario) {

        LocalStorageService.setarItem(USUARIO_SELECIONADO, usuario)
    }

    static obterUsuarioSelecionado(variaveisGlobais) {

        if (this.obterAcessoCliente(variaveisGlobais)) {

            return this.obterAcessoClienteChaves(variaveisGlobais).usuarioSelecionado

        } else {

            return LocalStorageService.obterItem(USUARIO_SELECIONADO)
        }
    }
    
    static usuarioSelecionadoListArray(variaveisGlobais) {

        let usuarioSelecionadoListAux = []

        this.obterUsuarioSelecionado(variaveisGlobais)?.forEach( (linhaUsuarioSelecionado, index) => {

            usuarioSelecionadoListAux.push(
                {
                    id: linhaUsuarioSelecionado.id,
                }
            )
        })

        return usuarioSelecionadoListAux
    }

    static usuarioSelecionadoList(variaveisGlobais) {

        return encodeURIComponent(JSON.stringify(this.usuarioSelecionadoListArray(variaveisGlobais)))
    }

    static usuarioClienteSelecionadoList(usuarioClienteSelecionado) {

        let usuarioClienteSelecionadoListAux = [
            {
                id: usuarioClienteSelecionado
            }
        ]

        return encodeURIComponent(JSON.stringify(usuarioClienteSelecionadoListAux))
    }
    


    static setarAcessoCliente(variaveisGlobais, acessoCliente) {

        variaveisGlobais.acessoCliente = acessoCliente
    }

    static obterAcessoCliente(variaveisGlobais) {

        return variaveisGlobais.acessoCliente
    }

    static setarAcessoClienteChaves(variaveisGlobais, acessoClienteChaves) {

        variaveisGlobais.acessoClienteChaves = acessoClienteChaves
    }

    static obterAcessoClienteChaves(variaveisGlobais) {

        return variaveisGlobais.acessoClienteChaves
    }



    static setarExibirListaAgenda(variaveisGlobais, exibirListaAgenda) {

        variaveisGlobais.exibirListaAgenda = exibirListaAgenda
    }

    static obterExibirListaAgenda(variaveisGlobais) {

        return variaveisGlobais.exibirListaAgenda
    }
    


    static setarExibirListaAniversario(variaveisGlobais, exibirListaAniversario) {

        variaveisGlobais.exibirListaAniversario = exibirListaAniversario
    }

    static obterExibirListaAniversario(variaveisGlobais) {

        return variaveisGlobais.exibirListaAniversario
    }
    


    static setarExibirListaAporte(variaveisGlobais, exibirListaAporte) {

        variaveisGlobais.exibirListaAporte = exibirListaAporte
    }

    static obterExibirListaAporte(variaveisGlobais) {

        return variaveisGlobais.exibirListaAporte
    }
    


    static setarDashboardFamilia(variaveisGlobais, dashboardFamilia) {

        variaveisGlobais.dashboardFamilia = dashboardFamilia
    }

    static obterDashboardFamilia(variaveisGlobais) {

        return variaveisGlobais.dashboardFamilia
    }



    static setarDashboardConsorcio(variaveisGlobais, dashboardConsorcio) {

        variaveisGlobais.dashboardConsorcio = dashboardConsorcio
    }

    static obterDashboardConsorcio(variaveisGlobais) {

        return variaveisGlobais.dashboardConsorcio
    }



    static setarDashboardProjetoVidaSonho(variaveisGlobais, dashboardProjetoVidaSonho) {

        variaveisGlobais.dashboardProjetoVidaSonho = dashboardProjetoVidaSonho
    }

    static obterDashboardProjetoVidaSonho(variaveisGlobais) {

        return variaveisGlobais.dashboardProjetoVidaSonho
    }



    static setarDashboardProposta(variaveisGlobais, dashboardProposta) {

        variaveisGlobais.dashboardProposta = dashboardProposta
    }

    static obterDashboardProposta(variaveisGlobais) {

        return variaveisGlobais.dashboardProposta
    }

    

    static setarDashboardApolice(variaveisGlobais, dashboardApolice) {

        variaveisGlobais.dashboardApolice = dashboardApolice
    }

    static obterDashboardApolice(variaveisGlobais) {

        return variaveisGlobais.dashboardApolice
    }



    static setarDashboardApoliceCobranca(variaveisGlobais, dashboardApoliceCobranca) {

        variaveisGlobais.dashboardApoliceCobranca = dashboardApoliceCobranca
    }

    static obterDashboardApoliceCobranca(variaveisGlobais) {

        return variaveisGlobais.dashboardApoliceCobranca
    }

    

    static setarDashboardApoliceComissao(variaveisGlobais, dashboardApoliceComissao) {

        variaveisGlobais.dashboardApoliceComissao = dashboardApoliceComissao
    }

    static obterDashboardApoliceComissao(variaveisGlobais) {

        return variaveisGlobais.dashboardApoliceComissao
    }



    static setarDashboardClienteAgente(variaveisGlobais, dashboardClienteAgente) {

        variaveisGlobais.dashboardClienteAgente = dashboardClienteAgente
    }

    static obterDashboardClienteAgente(variaveisGlobais) {

        return variaveisGlobais.dashboardClienteAgente
    }



    static setarDataInicialAporte(variaveisGlobais, dataInicialAporte) {

        variaveisGlobais.dataInicialAporte = dataInicialAporte
    }

    static obterDataInicialAporte(variaveisGlobais) {

        return variaveisGlobais.dataInicialAporte
    }
    


    static setarDataFinalAporte(variaveisGlobais, dataFinalAporte) {

        variaveisGlobais.dataFinalAporte = dataFinalAporte
    }

    static obterDataFinalAporte(variaveisGlobais) {

        return variaveisGlobais.dataFinalAporte
    }



    static setarExibirStatusAportePendente(variaveisGlobais, exibirStatusAportePendente) {

        variaveisGlobais.exibirStatusAportePendente = exibirStatusAportePendente
    }

    static obterExibirStatusAportePendente(variaveisGlobais) {

        return variaveisGlobais.exibirStatusAportePendente
    }
    


    static setarExibirStatusAporteNaoRealizado(variaveisGlobais, exibirStatusAporteNaoRealizado) {

        variaveisGlobais.exibirStatusAporteNaoRealizado = exibirStatusAporteNaoRealizado
    }

    static obterExibirStatusAporteNaoRealizado(variaveisGlobais) {

        return variaveisGlobais.exibirStatusAporteNaoRealizado
    }



    static setarExibirStatusAporteRealizado(variaveisGlobais, exibirStatusAporteRealizado) {

        variaveisGlobais.exibirStatusAporteRealizado = exibirStatusAporteRealizado
    }

    static obterExibirStatusAporteRealizado(variaveisGlobais) {

        return variaveisGlobais.exibirStatusAporteRealizado
    }



    static setarFiltroIntegracao(variaveisGlobais, filtroIntegracao) {

        variaveisGlobais.filtroIntegracao = filtroIntegracao
    }

    static obterFiltroIntegracao(variaveisGlobais) {

        return variaveisGlobais.filtroIntegracao
    }
}