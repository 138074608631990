import React from 'react'

import { 
    Icon,
    IconButton,
    Tooltip,
} from '@material-ui/core'

import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

import { 
    IsXsDownScreen
} from '../Service/GlobalService'

import ICON from '../../business-const/Icon/Icon'

import LABEL from '../../business-const/Label/Label'

import {
    COLOR
} from '../../business-const/Color/Color'

import {
    moverArrayParaCima,
    moverArrayParaBaixo,
} from '../../business-components/Array/Array'

import { 
    CustomizedDialogsConfirmacao,
    CustomizedDialogsIconButtonHelp,
} from '../../business-components/Dialog/CustomizeDialogs'

import {
    SvgIconButtonEditar,
    SvgIconButtonTransferir,
    SvgIconButtonClearEntradaDados,
    SvgIconButtonClear,
    SvgIconButtonVersao,
    SvgIconButtonImprimir,
    SvgIconButtonExpandMore,
    SvgIconButtonExpandLess,
    SvgIconButtonWhatsapp,
    SvgIconButtonPDF,
    SvgIconButtonExcel,
    SvgIconButtonDocument,
    SvgIconButtonConfiguracao,
    SvgIconButtonMaisInformacoes,
} from '../SvgIcon/SvgIconGlobal/SvgIconGlobal'
import {
    SvgIconButtonCalculadora,
} from '../SvgIcon/SvgIconEstudo/SvgIconEstudo'

       

export const IconButtonHelp = ( props ) => {

    const { align, ...rest } = props 
    
    return (
        <div
            align={align ? align : "left"}
        >
            <CustomizedDialogsIconButtonHelp
                {...rest}
            />
        </div>
    )
}


       
export const IconButtonExcluirRegistro = ( props ) => {
    
    return (
        <div align="left">
            <CustomizedDialogsConfirmacao
                ObjetoAcao={IconButtonClearEntradaDados}
                confirmacaoTitle={LABEL.confirmaExclusao}
                {...props}
            />
        </div>
    )
}



export const IconButtonPadraoTooltip = ( props ) => {

   const {tooltipTitle, SvgIconButton, svgIconButtonColor, svgIconButtonFontSize, ...rest} = props

    return (
        props.disabled
            ?
                <IconButton
                    className="mt-0"
                    size="small"
                    {...rest}
                >
                    <SvgIconButton
                        style={{
                            color: rest.disabled ? '' : svgIconButtonColor
                        }}
                        fontSize={svgIconButtonFontSize ? svgIconButtonFontSize : "large"}
                    />
                </IconButton>
            :
                <Tooltip
                    title={tooltipTitle}
                >
                    <IconButton
                        className="mt-0"
                        size="small"
                        {...rest}
                    >
                        <SvgIconButton
                            style={{
                                color: rest.disabled ? '' : svgIconButtonColor
                            }}
                            fontSize={svgIconButtonFontSize ? svgIconButtonFontSize : "large"}
                        />
                    </IconButton>
                </Tooltip>
    )
}



export const IconButtonEditar = ( props ) => {

    const { align, ...rest } = props

    return (
        <div
            align={align ? align : "right"}
        >
            <IconButtonPadraoTooltip
                className="mt-1"
                tooltipTitle={LABEL.iconButtonEditar}
                SvgIconButton={SvgIconButtonEditar}
                svgIconButtonColor={COLOR().iconButtonEditar}
                svgIconButtonFontSize="medium"
                {...rest}
            />
        </div>
    )
}

export const IconButtonEditarLeft = ( props ) => {

    return (
        <IconButtonEditar
            align='left'
            {...props}
        />
    )
}



export const IconButtonVerDetalhes = ( props ) => {

    return (
        <div
            align='left'
        >
            <IconButtonPadraoTooltipIcon
                className="mt-1"
                tooltipTitle={LABEL.iconButtonVerDetalhes}
                iconButtonColor={COLOR().iconButtonVerDetalhes}
                iconButtonIcon={ICON.verDetalhes}
                {...props}
            />
        </div>
    )
}


export const IconButtonTransferir = ( props ) => {

    return (
        <div
            align="right"
        >
            <IconButtonPadraoTooltip
                className="mt-1"
                tooltipTitle={LABEL.iconButtonTransferir}
                SvgIconButton={SvgIconButtonTransferir}
                svgIconButtonColor={COLOR().iconButtonTransferir}
                svgIconButtonFontSize="medium"
                {...props}
            />
        </div>
    )
}



export const IconButtonClearEntradaDados = ( props ) => {

    const { align, className, ...rest } = props

    return (
        <div
            align={align ? align : "right"}
        >
            <IconButtonPadraoTooltip
                className={className ? className : "mt-1"}
                tooltipTitle={LABEL.iconButtonClearEntradaDados}
                SvgIconButton={SvgIconButtonClearEntradaDados}
                svgIconButtonColor={props.disabled ? COLOR().disabled : COLOR().iconButtonClearEntradaDados}
                svgIconButtonFontSize="medium"
                {...rest}
            />
        </div>
    )
}

export const IconButtonClearEntradaDadosSubmit = ( props ) => {

    return (
        <IconButtonClearEntradaDados
            type="submit"
            {...props}
        />
    )
}



export const IconButtonClearDialog = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonClearDialog}
            SvgIconButton={SvgIconButtonClear}
            svgIconButtonColor={COLOR().iconButtonClearDialog}
            svgIconButtonFontSize="medium"
            {...props}
        />
    )
}



export const IconButtonClear = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonClear}
            SvgIconButton={SvgIconButtonClear}
            svgIconButtonColor={COLOR().iconButtonClear}
            svgIconButtonFontSize="medium"
            {...props}
        />
    )
}



export const IconButtonVersao = ( props ) => {

    return (
        <div
            align="right"
        >
            <IconButtonPadraoTooltip
                className="mt-3"
                //type="submit"
                tooltipTitle={LABEL.iconButtonVersao}
                SvgIconButton={SvgIconButtonVersao}
                svgIconButtonColor={COLOR().iconButtonVersao}
                svgIconButtonFontSize="medium"
                {...props}
            />
        </div>
    )
}



export const IconButtonVersaoDisabled = ( props ) => {

    return (
        <IconButtonVersao
            disabled={true}
            {...props}
        />
    )
}



export const IconButtonEderecos = ( props ) => {

    return (
        <div
            align='center'
        >
        <IconButtonPadraoTooltipIcon
            className="mt-1"
            tooltipTitle={LABEL.enderecos}
            iconButtonColor={COLOR().iconButtonEnderecos}
            iconButtonIcon={ICON.enderecos}
            {...props}
        />
        </div>
    )
}



export const IconButtonExpandMore = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonExpandMore}
            SvgIconButton={SvgIconButtonExpandMore}
            svgIconButtonColor={COLOR().iconButtonExpandMore}
            {...props}
        />
    )
}

export const IconButtonExpandMoreAll = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonExpandMoreAll}
            SvgIconButton={KeyboardDoubleArrowDownIcon}
            svgIconButtonColor={COLOR().iconButtonExpandMore}
            {...props}
        />
    )
}

export const IconButtonExpandLess = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonExpandLess}
            SvgIconButton={SvgIconButtonExpandLess}
            svgIconButtonColor={COLOR().iconButtonExpandLess}
            {...props}
        />
    )
}

export const IconButtonExpandLessAll = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonExpandLessAll}
            SvgIconButton={KeyboardDoubleArrowUpIcon}
            svgIconButtonColor={COLOR().iconButtonExpandLess}
            {...props}
        />
    )
}

// Ver exemplo de uso ..\fin-tracks\fin-tracks-modelos\Antes Alterar Popup Panel Despesas Totais / Garantias Atuais e Despesas\EstudoFormComposicaoInventario.jsx
export const IconButtonExpandMoreLess = ( props ) => {

    const onClick = () => {

        let count = 0

        props.lista?.forEach( (linhaLista, indLista) => {

            if (linhaLista.expanded) {

                count++
            }
        })

        if (count === 1) {

            props.lista?.forEach( (linhaLista, indLista) => {

                if (linhaLista.expanded && linhaLista.id !== props.item.id) {

                    linhaLista.expanded = false
                }
            })
        }

        props.setFieldValue(props.name, !props.item.expanded)
    }

    const onClickAll = () => {

        props.lista?.forEach( (linhaLista, indPerguntas) => {

            if (linhaLista.id !== props.item.id) {

                linhaLista.expanded = props.item.expanded ? false : true
            }
        })

        props.setFieldValue(props.name, !props.item.expanded)
    }

    return (

        <div
            align={props.align ? props.align : "right"}
            className={props.className}
        >
            {!props.item.expanded
                ?
                    <>
                        <IconButtonExpandMore
                            onClick={() => onClick()}
                        />
                        <IconButtonExpandMoreAll
                            onClick={() => onClickAll()}
                        />
                    </>
                :
                    <>
                        <IconButtonExpandLess
                            onClick={() => onClick()}
                        />
                         <IconButtonExpandLessAll
                            onClick={() => onClickAll()}
                        />
                    </>
            }
        </div>
    )
}



export const IconButtonWhatsapp = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            tooltipTitle={LABEL.iconButtonWhatsapp}
            SvgIconButton={SvgIconButtonWhatsapp}
            svgIconButtonColor={COLOR().iconButtonWhatsapp}
            svgIconButtonFontSize="small"
            {...props}
        />
    )
}



export const IconButtonPDF = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            SvgIconButton={SvgIconButtonPDF}
            svgIconButtonColor={COLOR().iconButtonPDF}
            {...props}
        />
    )
}



export const IconButtonExcel = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            SvgIconButton={SvgIconButtonExcel}
            svgIconButtonColor={COLOR().iconButtonExcel}
            {...props}
        />
    )
}



export const IconButtonDocument = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            SvgIconButton={SvgIconButtonDocument}
            svgIconButtonColor={COLOR().iconButtonDocument}
            {...props}
        />
    )
}



export const IconButtonConfiguracao = ( props ) => {

    const { popupButtonDestaque, ...rest } = props

    return (
        <IconButtonPadraoTooltip
            className="mt-1"
            SvgIconButton={SvgIconButtonConfiguracao}
            svgIconButtonColor={popupButtonDestaque ? COLOR().iconButtonConfiguracao : ""}
            svgIconButtonFontSize="small"
            {...rest}
        />
    )
}

export const IconButtonConfiguracaoCliente = ( props ) => {

    return (
        <div
            style={{
                borderRadius: 30,
                background: 'gainsboro',
                width: '40px',
                height: '30px',
            }}
        >
            <div
                align="center"
                style={{
                    padding: 2,
                }}
            >
                <IconButtonConfiguracao
                    {...props}
                    className=""
                />
            </div>
        </div>
    )
}



export const IconButtonMaisInformacoes = ( props ) => {

    return (
        <IconButtonPadraoTooltip
            className="mt-1"
            SvgIconButton={SvgIconButtonMaisInformacoes}
            svgIconButtonColor={COLOR().iconButtonMaisInformacoes}
            svgIconButtonFontSize="small"
            {...props}
        />
    )
}



export const IconButtonPadraoTooltipIcon = ( props ) => {

    const {tooltipTitle, iconButtonColor, iconButtonIcon, ...rest} = props

    return (
        <Tooltip
            title={tooltipTitle}
        >
            <IconButton
                size="small"
                {...rest}
            >
                <Icon
                    style={{
                        color: iconButtonColor
                    }}
                >
                    {iconButtonIcon}
                </Icon>
            </IconButton>
        </Tooltip>
    )
}



export const IconButtonVideoTrilhaConhecimento = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            className={IsXsDownScreen() ? "mt-0 mb-0" : "mt-2 mb-0"}
            tooltipTitle={LABEL.trilhaConhecimento}
            iconButtonColor={COLOR().iconButtonTrilhaConhecimento}
            iconButtonIcon={ICON.trilhaConhecimento}
            {...props}
        />
    )
}



export const IconButtonTreinamento = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            className={IsXsDownScreen() ? "mt-0 mb-0" : "mt-2 mb-0"}
            tooltipTitle={LABEL.treinamentos}
            iconButtonColor={COLOR().iconButtonTreinamento}
            iconButtonIcon={ICON.treinamentos}
            {...props}
        />
    )
}



export const IconButtonCalendario = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            className="mt--4 mb--4"
            tooltipTitle={LABEL.agenda}
            iconButtonColor={COLOR().iconButtonAgenda}
            iconButtonIcon={ICON.agenda}
            {...props}
        />
    )
}



export const IconButtonVisualizarTabela = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            //className={IsXsDownScreen() ? "mt-0 mb-0" : "mt-2 mb-0"}
            tooltipTitle={LABEL.buttonVisualizarTabela}
            iconButtonColor={COLOR().iconButtonVisualizarTabela}
            iconButtonIcon={ICON.visualizarTabela}
            {...props}
        />
    )
}



export const IconButtonFixo = ( props ) => {

    const { bottom, ...rest } = props

    return (
        <div
            style={{
                position: 'fixed',
                right: '30px',
                bottom: bottom,
                zIndex: 99,
                transition: 'all 0.15s ease',
                borderRadius: 30,
                background: 'gainsboro',
                width: '55px',
                height: '55px',
            }}
        >
            <div
                align="center"
                style={{
                    padding: 8,
                }}
            >
                <IconButtonPadraoTooltip
                    {...rest}
                />
            </div>
        </div>
    )
}

export const IconButtonModoApresentacaoBase = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            tooltipTitle={LABEL.iconButtonModoApresentacao}
            iconButtonColor={COLOR().iconButtonModoApresentacao}
            iconButtonIcon={ICON.modoApresentacao}
            {...props}
        />
    )
}

export const IconButtonModoApresentacao = ( props ) => {

    return (
        <IconButtonFixo
            bottom={'305px'}
            tooltipTitle={LABEL.iconButtonModoApresentacao}
            SvgIconButton={IconButtonModoApresentacaoBase}
            //svgIconButtonColor={COLOR().modoApresentacao}
            {...props}
        />
    )
}

export const IconButtonModoApresentacaoSetas = ( props ) => {

    const {values, modoApresentacaoAnterior, modoApresentacaoProxima, ...rest} = props

    return (
        <div
            className='mr-1'
            style={{
                position: 'fixed',
                right: '30px',
                bottom: '255px',
                borderRadius: 30,
                background: 'gainsboro',
                width: '90px',
                height: '42px',
            }}
        >
            <div
                align="center"
                style={{
                    padding: 2,
                }}
            >
                <IconButtonPadraoTooltipIcon
                    className={'mt-1'}
                    tooltipTitle={LABEL.modoApresentacaoPaginaAnterior}
                    iconButtonColor={COLOR().iconModoApresentacaoPaginaAnteriorProximaPagina}
                    iconButtonIcon={ICON.modoApresentacaoPaginaAnterior}
                    onClick={() => modoApresentacaoAnterior()}
                    {...rest}
                />
                <IconButtonPadraoTooltipIcon
                    className={'mt-1'}
                    tooltipTitle={LABEL.modoApresentacaoProximaPagina}
                    iconButtonColor={COLOR().iconModoApresentacaoPaginaAnteriorProximaPagina}
                    iconButtonIcon={ICON.modoApresentacaoProximaPagina}
                    onClick={() => modoApresentacaoProxima()}
                    {...rest}
                />
            </div>
        </div>
    )
}



export const IconButtonFiltrar = ( props ) => {

    return (
        <IconButtonPadraoTooltipIcon
            className="mr-2"
            tooltipTitle={LABEL.iconButtonFiltrar}
            iconButtonColor={COLOR().iconButtonFiltrar}
            iconButtonIcon={ICON.filtrar}
            {...props}
        />
    )
}

export const IconButtonFiltrarSetado = ( props ) => {

    return (
        <IconButtonFiltrar
            iconButtonColor={COLOR().iconButtonFiltrarSetado}
            {...props}
        />
    )
}

export const IconButtonImprimir = ( props ) => {

    return (
        <IconButtonFixo
            bottom={'235px'}
            tooltipTitle={LABEL.iconButtonImprimir}
            SvgIconButton={SvgIconButtonImprimir}
            svgIconButtonColor={COLOR().iconButtonImprimir}
            {...props}
        />
    )
}

export const IconButtonEstudoRevisaoDados = ( props ) => {

    return (
        <IconButtonFixo
            bottom={'165px'}
            SvgIconButton={SvgIconButtonEditar}
            svgIconButtonColor={COLOR().iconButtonRevisaoDados}
            {...props}
        />
    )
}

export const IconButtonEstudoParametros = ( props ) => {

    return (
        <IconButtonFixo
            bottom={'95px'}
            SvgIconButton={SvgIconButtonConfiguracao}
            svgIconButtonColor={COLOR().iconButtonConfiguracao}
            {...props}
        />
    )
}

export const IconButtonSelecionarCenariosTipoMorteInvalidez = ( props ) => {

    return (
        <IconButtonFixo
            bottom={'25px'}
            SvgIconButton={SvgIconButtonCalculadora}
            svgIconButtonColor={COLOR().iconButtonSelecionarCenariosTipoMorteInvalidez}
            {...props}
        />
    )
}



export const IconButtonMoveParaCima = ({ fontSize, array, nomeArray, setFieldValue, ind }) => {

    return (
        <Tooltip
            title={LABEL.moverParaCima}
        >
            <IconButton
                className='p-0'
                onClick={() =>
                    moverArrayParaCima(array, nomeArray, setFieldValue, ind)
                }
                disabled={ind === 0}
            >
                <Icon
                    className={ind === 0 ? "text-muted" : "text-black"}
                    fontSize={fontSize ? fontSize : "medium"}
                >
                    {ICON.moverParaCima}
                </Icon>
            </IconButton>
        </Tooltip>
    )
}

export const IconButtonMoveParaBaixo = ({ fontSize, array, nomeArray, setFieldValue, ind }) => {

    return (
        <Tooltip
            title={LABEL.moverParaBaixo}
        >
            <IconButton
                className='p-0'
                onClick={() =>
                    moverArrayParaBaixo(array, nomeArray,
                        setFieldValue, ind)
                }
                disabled={ind === array.length - 1}
            >
                <Icon
                    className={ind === array.length - 1 ? "text-muted" : "text-black"}
                    fontSize={fontSize ? fontSize : "medium"}
                >
                    {ICON.moverParaBaixo}
                </Icon>
            </IconButton>
        </Tooltip>
    )
}

export const IconButtonMoveParaCimaParaBaixo = ({ className, fontSize, array, nomeArray, setFieldValue, ind }) => {

    return (
        <div
            className={className}
        >
            <IconButtonMoveParaCima
                fontSize={fontSize}
                array={array}
                nomeArray={nomeArray}
                setFieldValue={setFieldValue}
                ind={ind}
            />

            <IconButtonMoveParaBaixo
                fontSize={fontSize}
                array={array}
                nomeArray={nomeArray}
                setFieldValue={setFieldValue}
                ind={ind}
            />
        </div>
    )
}