// www.app.fin-tracks.com/session/signin/?signature=zaed-ji5g-fptb-epl0
// www.app.fin-tracks.com/session/signin/?signature=nbcs-tgqi-j6de-weda

import HARDCODE from '../../../business-const/HardCode/HardCode'



const codigoPromocionalList = [
    //{
    //    id: 'c655581bfb7aa369a60d5db44d50fa66beae8b4406fc88fa25d415bb80457be8ab3aca003e7194a9f9288526839502560a5a2332e8780c873e09c30f748020daebd57d8ced28ab8a2dc419506181be0d71b6890febe5304507ba3c7eb01d55c18a7bb3cc9db50f600e5ada3c7f687bcfab88bb9e7f329558fe56811acff60a210515144099d0611f02d31c054104f1a346f5a4b0f5fee92c635a1e0c0db6cf12b4d3d0808e970160276c3dc8fef0b50a3008d609a8f7',
    //    //descricao: 'AddGroup',
    //    grupo: 'ADDGROUP',
    //    subgrupo: 'DEZ22',
    //    dataAssinaturaAte: '2022-12-31 00:00:00',
    //    ordem: 10,
    //},
    //{
    //    id: 'oq9r-hfm5-j0yb-rlsn',
    //    //descricao: 'AddGroup',
    //    grupo: 'ADDGROUP',
    //    subgrupo: 'DEZ22',
    //    dataAssinaturaAte: '2022-12-31 00:00:00',
    //    ordem: 20,
    //},
    //{
    //    id: 'x1rb-w3ew-ch8y-poqa',
    //    //descricao: 'Cintia Germano - Auroque',
    //    grupo: 'CINTIAAUROQUE',
    //    subgrupo: 'DEZ22',
    //    dataAssinaturaAte: '2022-12-31 00:00:00',
    //    ordem: 30,
    //},
    //{
    //    id: '5adf-yp1q-s4xi-vdwu',
    //    //descricao: '',
    //    grupo: 'FRANCISCO',
    //    subgrupo: 'DEZ22',
    //    dataAssinaturaAte: '2022-12-31 00:00:00',
    //    ordem: 40,
    //},
    //{
    //    id: 'bzw3-j1my-rp9a-jbcm',
    //    //descricao: 'MV Magneto',
    //    grupo: 'MVMAGNETO',
    //    subgrupo: 'MAR23',
    //    dataAssinaturaAte: '2023-03-31 00:00:00',
    //    ordem: 50,
    //},
    //{
    //    id: 'd2yi-jod7-fw0u-epdh',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS',
    //    subgrupo: 'ABR23',
    //    dataAssinaturaAte: '2023-04-30 00:00:00',
    //    ordem: 60,
    //},
    //{
    //    id: 'o7yq-t1jg-psg8-fjzk',
    //    //descricao: 'Gustavo Chelotti',
    //    grupo: 'CHELOTTI',
    //    subgrupo: 'MAR23',
    //    dataAssinaturaAte: '2023-03-31 00:00:00',
    //    ordem: 70,
    //},
    //{
    //    id: 'b1mh-wqix-qe9j-xa0y',
    //    //descricao: 'Sincor-SP',
    //    grupo: 'SINCOR-SP',
    //    subgrupo: 'NOV23',
    //    dataAssinaturaAte: '2023-11-30 00:00:00',
    //    ordem: 80,
    //},
    //{
    //    id: 'dfva-xrex-yoza-wlofg',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-01',
    //    subgrupo: 'DEZ22',
    //    dataAssinaturaAte: '2022-12-31 00:00:00',
    //    ordem: 90,
    //},
    //{
    //    id: 'sxct-ckvx-gu9w-pyge',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-01',
    //    subgrupo: 'JAN23',
    //    dataAssinaturaAte: '2023-01-31 00:00:00',
    //    ordem: 100,
    //},
    //{
    //    id: 'hk8x-ydoc-rp7b-8hfq',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-01',
    //    subgrupo: 'FEV23',
    //    dataAssinaturaAte: '2023-02-28 00:00:00',
    //    ordem: 110,
    //},
    //{
    //    id: 'hk7x-ydxc-rp1b-5hfh',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-01',
    //    subgrupo: 'MAR23',
    //    dataAssinaturaAte: '2023-03-31 00:00:00',
    //    ordem: 111,
    //},
    //{
    //    id: 'zk0x-idxc-pp3b-7hfo',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-01',
    //    subgrupo: 'ABR23',
    //    dataAssinaturaAte: '2023-04-30 00:00:00',
    //    ordem: 112,
    //},
    //{
    //    id: 'pnh5-mxuj-s0hi-fuow',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-JP',
    //    subgrupo: 'ABR23',
    //    dataAssinaturaAte: '2023-04-30 00:00:00',
    //    ordem: 120,
    //},
    //{
    //    id: 'oe3w-bzof-in2x-lmez',
    //    //descricao: '',
    //    grupo: 'FIN-TRACKS-FA',
    //    subgrupo: 'ABR23',
    //    dataAssinaturaAte: '2023-04-30 00:00:00',
    //    ordem: 130,
    //},
    /*
    {
        id: 'd29i-jo17-fwuu-epzh',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 150,
    },
    {
        id: 'oe1w-bzpf-ip2x-lmaz',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 160,
    },
    {
        id: 'pni5-mxzj-s0ht-fu1w',
        //descricao: '',
        grupo: 'FIN-TRACKS-JP',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 170,
    },
    {
        id: 'hk9x-ydzc-rp1a-6hxh',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 180,
    },
    {
        id: 'vciz-af8i-xgk1-bnmy',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 185,
    },
    {
        id: 'buw4-j1mr-rt9a-jbvm',
        //descricao: 'MV Magneto',
        grupo: 'MVMAGNETO',
        subgrupo: 'MAI23',
        dataAssinaturaAte: '2023-05-31 00:00:00',
        ordem: 190,
    },
    {
        id: 'daoi-jo2i-foiu-epbh',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 200,
    },
    {
        id: 'ge1w-bopa-ipr4-ljsz',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 210,
    },
    {
        id: 'pn7b-m7zj-s9mt-qu7w',
        //descricao: '',
        grupo: 'FIN-TRACKS-JP',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 220,
    },
    {
        id: 'rx9x-ye5c-r0ca-6hdw',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 230,
    },
    {
        id: 'tevo-ptbd-njtm-mha3',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 235,
    },
    {
        id: 'bly4-jrr3-rt9a-j2gm',
        //descricao: '',
        grupo: 'MVMAGNETO',
        subgrupo: 'JUN23',
        dataAssinaturaAte: '2023-06-30 00:00:00',
        ordem: 240,
    },
    {
        id: 'zadi-ji4i-fpru-eplq',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        subgrupo: 'SET23',
        dataAssinaturaAte: '2023-09-30 00:00:00',
        ordem: 200,
    },
    {
        id: 'unhe-lpoij-4euj-ko4w',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        subgrupo: 'SET23',
        dataAssinaturaAte: '2023-09-30 00:00:00',
        ordem: 210,
    },
    {
        id: 'jevm-qybn-8ske-kwd2',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        subgrupo: 'SET23',
        dataAssinaturaAte: '2023-09-30 00:00:00',
        ordem: 230,
    },
    {
        id: 'jqbd-t5if-p0rc-ol3v',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        subgrupo: 'SET23',
        dataAssinaturaAte: '2023-09-30 00:00:00',
        ordem: 235,
    },
    {
        id: 'ncru-7kpq-8juw-apev',
        //descricao: '',
        grupo: 'MVMAGNETO',
        subgrupo: 'SET23',
        dataAssinaturaAte: '2023-09-30 00:00:00',
        ordem: 240,
    },
    {
        id: 'jsut-hp5w-pxks-deal',
        //descricao: '',
        grupo: 'ALEANDRO',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 250,
    },
    {
        id: 'b2vg-7jue-djkb-zgbf',
        //descricao: '',
        grupo: 'CEDIRIAN',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 260,
    },
    {
        id: 'zaei-ji5i-fptu-epls',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 60,
    },
    {
        id: 'ushe-lp9ij-4euvj-kf4w',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 70,
    },
    {
        id: 'h6qx-plef-0iga-l6tx',
        //descricao: '',
        grupo: 'FIN-TRACKS-JP',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 80,
    },
    {
        id: 'jeom-q2bn-lske-kw72',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 90,
    },
    {
        id: 'jqed-t5uf-p9rc-oh3v',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 100,
    },
    {
        id: 'aher-otbq-5tfs-lwa7',
        //descricao: '',
        grupo: 'FIN-TRACKS-03',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 110,
    },
    {
        id: 'ncpu-7geq-i9uw-awqv',
        //descricao: '',
        grupo: 'MVMAGNETO',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 120,
    },
    {
        id: 'o9iq-taug-ikod-hgei',
        //descricao: 'Gustavo Chelotti',
        grupo: 'CHELOTTI',
        subgrupo: 'DEZ23',
        dataAssinaturaAte: '2023-12-31 00:00:00',
        ordem: 130,
    },
     {
        id: 'b1mh-wqix-qe9j-xa0y',
        //descricao: 'Sincor-SP',
        grupo: 'SINCOR-SP',
        subgrupo: 'NOV23',
        dataAssinaturaAte: '2023-11-30 00:00:00',
        ordem: 10,
    },
    {
        id: 'zart-p9ub-tqlf-rbs8',
        //descricao: '',
        grupo: 'PLANEJAR',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 20,
    },
    {
        id: 'zart-pyu7-tqlf-io3d',
        //descricao: '',
        grupo: 'PLANEJAR-01',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 30,
    },
    {
        id: 'zart-njg5-tqlf-lpa5',
        //descricao: '',
        grupo: 'PLANEJAR-02',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 40,
    },{
        id: 'zart-hyui-tqlf-e4w6',
        //descricao: '',
        grupo: 'PLANEJAR-03',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 50,
    },
    {
        id: 'zaed-ji5c-fptb-epla',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 60,
    },
    {
        id: 'ushd-lp9ic-4euvb-kf4a',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 70,
    },
    {
        id: 'h6qd-plec-0igb-l6ta',
        //descricao: '',
        grupo: 'FIN-TRACKS-JP',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 80,
    },
    {
        id: 'jeod-q2bc-lskb-kw7a',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 90,
    },
    {
        id: 'jqed-t5uc-p9rb-oh3a',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 100,
    },
    {
        id: 'ahed-otbc-5tfb-lwaa',
        //descricao: '',
        grupo: 'FIN-TRACKS-03',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 110,
    },
    {
        id: 'ncpd-7gec-i9ub-awqa',
        //descricao: '',
        grupo: 'MVMAGNETO',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 120,
    },
    {
        id: 'o9id-tauc-ikob-hgea',
        //descricao: 'Gustavo Chelotti',
        grupo: 'CHELOTTI',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 130,
    },
    {
        id: 'p0ty-jeys-hqes-2gmx',
        //descricao: 'Cintia Germano',
        grupo: 'CINTIAGERMANO',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 140,
    },
    {
        id: 'p0ty-jeys-hqes-2gmy',
        //descricao: 'Cintia Germano - 02',
        grupo: 'CINTIAGERMANO-02',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 142,
    },
    {
        id: 'bnef-p9ub-koi8-rbs8',
        //descricao: '',
        grupo: 'FERNANDA',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 150,
    },
    {
        id: 'oi3e-pyu7-j8fr-io3d',
        //descricao: '',
        grupo: 'FERNANDA-01',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 160,
    },
    {
        id: 'cao7-njg5-href-lpa5',
        //descricao: '',
        grupo: 'FERNANDA-02',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 170,
    },{
        id: 'lpou-hyui-ht6w-e4w6',
        //descricao: '',
        grupo: 'FERNANDA-03',
        subgrupo: 'MAR24',
        dataAssinaturaAte: '2024-03-31 00:00:00',
        ordem: 180,
    },
    */
    {
        id: 'b1mh-wqid-qe9j-xa00',
        //descricao: 'Sincor-SP',
        grupo: 'SINCOR-SP',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 10,
    },
    {
        id: 'b1sh-wqi2-qr9j-xao0',
        //descricao: 'Sincor-SP',
        grupo: 'SINCOR-SP-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 10,
    },
    {
        id: 'ab9i-uegs-qplw-mv3t',
        //descricao: 'ABAAI',
        grupo: 'ABAAI',
        //subgrupo: 'JAN25',
        //dataAssinaturaAte: '2025-01-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 15,
    },
    {
        id: 'zart-p9uy-tqlf-rbs0',
        //descricao: '',
        grupo: 'PLANEJAR',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 20,
    },
    {
        id: 'zart-pyuj-tqlf-io30',
        //descricao: '',
        grupo: 'PLANEJAR-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 30,
    },
    {
        id: 'zart-njgr-tqlf-lpa0',
        //descricao: '',
        grupo: 'PLANEJAR-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 40,
    },{
        id: 'zart-hyul-tqlf-e4w0',
        //descricao: '',
        grupo: 'PLANEJAR-03',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 50,
    },
    {
        id: 'zaed-ji5g-fptb-epl0',
        //descricao: '',
        grupo: 'FIN-TRACKS',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 60,
    },
    {
        id: 'ushd-lp9ic-4euvb-kf40',
        //descricao: '',
        grupo: 'FIN-TRACKS-FA',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 70,
    },
    {
        id: 'h6qd-plet-0igb-l6t0',
        //descricao: '',
        grupo: 'FIN-TRACKS-JP',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 80,
    },
    {
        id: 'jeod-q2be-lskb-kw70',
        //descricao: '',
        grupo: 'FIN-TRACKS-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 90,
    },
    {
        id: 'jqed-t5uw-p9rb-oh30',
        //descricao: '',
        grupo: 'FIN-TRACKS-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 100,
    },
    {
        id: 'ahed-otbu-5tfb-lwa0',
        //descricao: '',
        grupo: 'FIN-TRACKS-03',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 110,
    },
    {
        id: 'ncpd-7gea-i9ub-awq0',
        //descricao: '',
        grupo: 'MVMAGNETO',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 120,
    },
    {
        id: 'o9id-taud-ikob-hge0',
        //descricao: 'Gustavo Chelotti',
        grupo: 'CHELOTTI',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 130,
    },
    {
        id: 'p0ty-jeyq-hqes-2gm0',
        //descricao: 'Cintia Germano',
        grupo: 'CINTIAGERMANO',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 140,
    },
    {
        id: 'p0ty-jeyq-hqes-2gm0',
        //descricao: 'Cintia Germano - 02',
        grupo: 'CINTIAGERMANO-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 142,
    },
    {
        id: 'bnef-p9ue-koi8-rbs0',
        //descricao: '',
        grupo: 'FERNANDA',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 150,
    },
    {
        id: 'oi3e-pyuh-j8fr-io30',
        //descricao: '',
        grupo: 'FERNANDA-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 160,
    },
    {
        id: 'cao7-njgo-href-lpa0',
        //descricao: '',
        grupo: 'FERNANDA-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 170,
    },
    {
        id: 'lpou-hyuf-ht6w-e4w0',
        //descricao: '',
        grupo: 'FERNANDA-03',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 180,
    },
    {
        id: 'bner-p0ue-kor8-rty0',
        //descricao: '',
        grupo: 'MINERVINI',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 190,
    },
    {
        id: 'oi8e-pheh-j9or-ijj0',
        //descricao: '',
        grupo: 'MINERVINI-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 200,
    },
    {
        id: 'c6y7-nqao-hrut-ljai',
        //descricao: '',
        grupo: 'MINERVINI-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 210,
    },
    {
        id: 'ater-z0ye-ko8i-uehd',
        //descricao: '',
        grupo: 'PAVAO',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 220,
    },
    {
        id: 'ueyh-p7uh-aaor-ytw0',
        //descricao: '',
        grupo: 'PAVAO-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 230,
    },
    {
        id: 'nvxa-ujet-h78t-lpot',
        //descricao: '',
        grupo: 'PAVAO-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 240,
    },
    {
        id: 'uiue-p8hq-iupw-lofp',
        //descricao: '',
        grupo: 'FELIX',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 245,
    },
    {
        id: 'ueue-p9hq-iuvw-lofz',
        //descricao: '',
        grupo: 'FELIX-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 250,
    },
    {
        id: 'iyte-olmn-yhgw-6yhw',
        //descricao: '',
        grupo: 'FELIX-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 260,
    },
    {
        id: 'hbtg-lqsa-5rte-oyrc',
        //descricao: '',
        grupo: 'FIN-TRACKS-PREV-FA',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        tipoLicenca: HARDCODE.tipoLicencaPrevidenciaPrivada,
        ordem: 270,
    },
    {
        id: 'hyef-8jke-axwq-lbdf',
        //descricao: '',
        grupo: 'FIN-TRACKS-PREV-MV',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        tipoLicenca: HARDCODE.tipoLicencaPrevidenciaPrivada,
        ordem: 280,
    },
    {
        id: 'jyge-9iue-leha-plew',
        //descricao: '',
        grupo: 'FIN-TRACKS-PREV-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        tipoLicenca: HARDCODE.tipoLicencaPrevidenciaPrivada,
        ordem: 290,
    },
    {
        id: 'nbcs-tgqi-j6de-weda',
        //descricao: '',
        grupo: 'FIN-TRACKS-PREV-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        tipoLicenca: HARDCODE.tipoLicencaPrevidenciaPrivada,
        ordem: 300,
    },
    {
        id: 'gfrs-pol5-qwnc-jtel',
        //descricao: '',
        grupo: 'EXPERT-XP',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 310,
    },
    {
        id: 'juoe-hgwq-lpoe-4dvb',
        //descricao: '',
        grupo: 'MANTUAN-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 310,
    },
    {
        id: 'jjue-fqpl-nbaw-9uts',
        //descricao: '',
        grupo: 'BIANCA-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 320,
    },
    {
        id: 'jhne-powq-adby-5tgy',
        //descricao: '',
        grupo: 'ALBERTO-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 320,
    },
    {
        id: 'kjud-hnte-ki8p-wapk',
        //descricao: '',
        grupo: 'BRITIS',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 330,
    },
    {
        id: 'kjod-hkee-ka8x-wept',
        //descricao: '',
        grupo: 'BRITIS-01',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 340,
    },
    {
        id: 'jekq-ponv-4res-2qft',
        //descricao: '',
        grupo: 'BRITIS-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 350,
    },
    {
        id: 'jekq-ponv-4res-2qft',
        //descricao: '',
        grupo: 'RODRIGO',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 360,
    },
    {
        id: 'dhju-1eft-tgyu-zasm',
        //descricao: '',
        grupo: 'PIETRO-02',
        //subgrupo: 'DEZ24',
        //dataAssinaturaAte: '2024-12-31 00:00:00',
        subgrupo: 'MAR25',
        dataAssinaturaAte: '2025-03-31 00:00:00',
        ordem: 370,
    },
 ]
 
 export default codigoPromocionalList