import React from 'react'
import { Redirect } from 'react-router-dom'

import PATH from './business-const/Path/Path'

import dashboardRoutes from './views/dashboard/DashboardRoutes'
import calendarRoutes from './views/agenda/AgendaRoutes'
import viewsRoutes from './views/viewsRoutes'

const redirectRoute = [
    {
        path: '/',
        exact: true,
        component: () => <Redirect to={PATH.pageDashboardCliente} />,
    },
]

const errorRoute = [
    {
        component: () => <Redirect to={PATH.page404} />,
    },
]

const routes = [
    ...dashboardRoutes,
    ...calendarRoutes,
    ...viewsRoutes,
    ...redirectRoute,
    ...errorRoute,
]

export default routes
