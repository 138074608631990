const bancoList = (listaCompleta) => {

    const bancoAuxList = []

    bancoAuxList.push(
        {
            id: '001', 
            descricao: 'Banco do Brasil',
            ordem: 'Banco do Brasil',
        },
        {
            id: '756', 
            descricao: 'BANCOOB',
            ordem: 'BANCOOB',
        },
        {
            id: '237', 
            descricao: 'Bradesco',
            ordem: 'Bradesco',
        },
        {
            id: '745', 
            descricao: 'Citibank',
            ordem: 'Citibank',
        },
        {
            id: '269', 
            descricao: 'HSBC',
            ordem: 'HSBC',
        },
        {
            id: '341', 
            descricao: 'Itaú / Unibanco',
            ordem: 'Itaú / Unibanco',
        },
        {
            id: '422', 
            descricao: 'Safra',
            ordem: 'Safra',
        },
        {
            id: '033', 
            descricao: 'Santander',
            ordem: 'Santander',
        },
    )

    if (listaCompleta) {

        bancoAuxList.push(
            {
                id: 'XXX', // HARDCODEBACKEND.idBancoNaoInformadob
                descricao: 'Não Informado',
                ordem: 'XXX',
            },
        )
    }

    return bancoAuxList
}

export default bancoList