// alterado - revisado

const tipoMultiploSalarioSeguroVidaEmGrupo = [
    {
        id: 10,
        descricao: '12 salários',
        ordem: 10,
        multiploSalarioBruto: 12,
    },
    {
        id: 20,
        descricao: '18 salários',
        ordem: 20,
        multiploSalarioBruto: 18,
    },
    {
        id: 30,
        descricao: '24 salários',
        ordem: 30,
        multiploSalarioBruto: 24,
    },
    {
        id: 40,
        descricao: '36 salários',
        ordem: 40,
        multiploSalarioBruto: 36,
    },
    {
        id: 50,
        descricao: '48 salários',
        ordem: 50,
        multiploSalarioBruto: 48,
    },
    {
        id: 900,
        descricao: 'Informar valor',
        ordem: 900,
        multiploSalarioBruto: '',
    },
]

export default tipoMultiploSalarioSeguroVidaEmGrupo