const statusGrupoConsorcioList = [
    {
        id: 'E',
        descricao: 'Em Andamento',
        ordem: 10,
    },
    {
        id: 'N',
        descricao: 'Novo',
        ordem: 20,
    },
]

export default statusGrupoConsorcioList