const simNaoList = [
    {
        id: 'S', 
        descricao: 'Sim',
        ordem: 10,
    },
    {
        id: 'N', 
        descricao: 'Não',
        ordem: 20,
    },
]

export default simNaoList