import instituicaoFinanceiraList from '../instituicaoFinanceiraList'



const administradoraConsorcioList = () => {

    return instituicaoFinanceiraList.filter(linhaInstituicaoFinanceiraList =>
        linhaInstituicaoFinanceiraList.administradoraConsorcio === 'S')
}

export default administradoraConsorcioList