// alterado - revisado

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import familiaresList from '../../business-rules/List/familiaresList'

import {
    nomeCurtoCliente,
    nomeCurtoConjuge,
} from '../String/nomeCurto'

import {
    calculaIdadesDataSimulacao,
} from '../../views/clientes/cliente-form/ClienteService'



const titularList = ( values, modelo ) => {

    calculaIdadesDataSimulacao(values)

    let titularList = []

    let nomeCurtoClienteAux = nomeCurtoCliente(values)

    let nomeCurtoConjugeAux = nomeCurtoConjuge(values)

    if (modelo === 'simples') {
        if (nomeCurtoClienteAux) {
            titularList.push ({
                id: HARDCODE.idFamiliarCliente,
                descricao: nomeCurtoClienteAux,
                ordem: 10,
                idade: values.idadeCliente,
                idadeDataSimulacao: values.idadeDataSimulacaoCliente,
            })
        }

        if (nomeCurtoConjugeAux) {
            titularList.push ({
                id: HARDCODE.idFamiliarConjuge,
                descricao: nomeCurtoConjugeAux,
                ordem: 20,
                idade: values.idadeConjuge,
                idadeDataSimulacao: values.idadeDataSimulacaoConjuge,
            })
        }
    }
    
    if (nomeCurtoClienteAux && nomeCurtoConjugeAux) {
        titularList.push ({
            id: HARDCODE.idFamiliarCasal,
            descricao: LABEL.casal,
            ordem: modelo === 'simples' ? 30 : 0,
            idade: '',
            idadeDataSimulacao: '',
        })
    }

    if (modelo === 'completo') {
        familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
            titularList.push ({
                id: linhaFamiliaresList.id,
                descricao: linhaFamiliaresList.descricao,
                ordem: linhaFamiliaresList.ordem,
                idade: linhaFamiliaresList.idade,
                idadeDataSimulacao: linhaFamiliaresList.idadeDataSimulacao,
            })
        })
    }

    return titularList
}

export default titularList