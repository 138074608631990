import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    formataValor,
} from '../../../business-components/Formata/Formata'

import {
    ano,
    mes,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    converteTaxaAnualEmMensal,
} from './EstudoServiceMatematicaFinanceira'

import {
    calculaIndexGraficoAnosIdades,
    calculaJaAposentou,
    calculaAnosDepoisAposentar,
    calculaMesesDepoisAposentar,
    calculaMesesAteAposentar,
} from './EstudoServiceCalculo'


export const calcDeficitDepoisAposentar = ( deficitDepoisAposentar, fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, valorAnt, qtdeMeses) => {

    if (!deficitDepoisAposentar && deficitDepoisAposentar !== 0) {

        deficitDepoisAposentar = (((valorAnt * -1) / 12) /
            (1 - (1 / (1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada))))

    } else {

        //P = F/(1+i)n + M.[(1+i)n - 1]/[(1+i)n+1 - (1+i)n]

        deficitDepoisAposentar =
            deficitDepoisAposentar / Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, qtdeMeses) + 
                ((valorAnt / 12) * -1) * (Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, qtdeMeses) - 1) /
                    (Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, qtdeMeses + 1) - 
                        Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, qtdeMeses)
                    )
    }

    return deficitDepoisAposentar
}

export const calculaIndexAposentadoria = (values, estudo) => {

    return calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, values.anoFinalVaiAposentarCliente)
}

export const calculaDiagnosticoAposentadoria = ( values, estudo ) => {
    
    const anoAtual = ano(values.dataSimulacaoEstudo)
    const mesAtual = mes(values.dataSimulacaoEstudo)

    const jaAposentou = calculaJaAposentou(values, anoAtual)
    
    const index = estudo.current.graficoLiquidezFinanceiraAcumulada.length - 1
    const recursosFinanceirosAcumuladosAux =
        jaAposentou 
            ?
                values.stepsValorPatrimonio[HARDCODE.indexPatrimonioRecursosFinanceiros]
            :
                estudo.current.graficoRecursosFinanceirosAcumuladosTotal[calculaIndexAposentadoria(values, estudo)/*index*/]
    const recursosFinanceirosAcumulados = estudo.current.graficoRecursosFinanceirosAcumuladosTotal[index]
    const despesasAcumuladasExcedentes = estudo.current.graficoDespesasAcumuladasExcedentes[index] * -1
    const valorLiquidezFinanceiraAcumulada = estudo.current.graficoLiquidezFinanceiraAcumulada[index]

    const anosDepoisAposentar = calculaAnosDepoisAposentar(values, anoAtual, jaAposentou) 
    const mesesDepoisAposentar = calculaMesesDepoisAposentar(mesAtual, jaAposentou, anosDepoisAposentar)

    const mesesAteAposentar = calculaMesesAteAposentar(values, anoAtual, mesAtual, jaAposentou)

    const fatorTaxaRetornoMensalRealPonderada = converteTaxaAnualEmMensal(values.taxaRetornoAnualRealPonderada) / 100
    const fatorTaxaRetornoMensalRealAposAposentadoriaPonderada = converteTaxaAnualEmMensal(values.taxaRetornoAnualRealAposAposentadoriaPonderada) / 100

    values.valorPresenteReservaInicioAposentadoria = /*recursosFinanceirosAcumulados ? recursosFinanceirosAcumulados : 0//*/
        round(recursosFinanceirosAcumulados / Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar), 2)

    if (Math.abs(values.valorPresenteReservaInicioAposentadoria - recursosFinanceirosAcumuladosAux) <= 1) {

        values.valorPresenteReservaInicioAposentadoria = recursosFinanceirosAcumuladosAux
    }

    values.valorPresenteNecessidadesInicioAposentadoria = round(despesasAcumuladasExcedentes / 
        Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar), 2)

    if (valorLiquidezFinanceiraAcumulada < -1) {
        //values.valorLiquidezFinanceiraDescoberta = valorLiquidezFinanceiraAcumulada  * -1

        values.valorPresenteDeficitReservaInicioAposentadoria = values.valorPresenteNecessidadesInicioAposentadoria - 
            values.valorPresenteReservaInicioAposentadoria//round(values.valorLiquidezFinanceiraDescoberta / 
            //Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar), 2)

        values.valorPresenteSuperavitReservaInicioAposentadoria = 0

        const valorPresenteDeficitSimulacao = values.valorPresenteDeficitReservaInicioAposentadoria/*(values.valorLiquidezFinanceiraDescoberta / 
            Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar))*/ /
                (jaAposentou ? 1 : Math.pow(1 + fatorTaxaRetornoMensalRealPonderada, mesesAteAposentar))

        if (jaAposentou) {

            values.valorPresenteDeficitSimulacaoMensal = 0

        } else {

            if (fatorTaxaRetornoMensalRealPonderada === 0) {
            
                values.valorPresenteDeficitSimulacaoMensal =
                    round(valorPresenteDeficitSimulacao / mesesAteAposentar, 2)

            } else {
            
                values.valorPresenteDeficitSimulacaoMensal =
                    round(values.valorPresenteDeficitReservaInicioAposentadoria *
                        (fatorTaxaRetornoMensalRealPonderada / (Math.pow(1 + fatorTaxaRetornoMensalRealPonderada, mesesAteAposentar) - 1)) *
                            (1 / (1 + fatorTaxaRetornoMensalRealPonderada)), 2)
            }

            if (values.valorPresenteDeficitSimulacaoMensal < 0) {

                values.valorPresenteDeficitSimulacaoMensal = 0
            }
        }

    } else {

        values.valorPresenteDeficitReservaInicioAposentadoria = 0

        values.valorPresenteSuperavitReservaInicioAposentadoria = values.valorPresenteReservaInicioAposentadoria - 
            values.valorPresenteNecessidadesInicioAposentadoria//round(valorLiquidezFinanceiraAcumulada / 
            //Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar), 2)

        values.valorPresenteDeficitSimulacaoMensal = 0

        values.tipoDiagnosticoAposentadoriaChart = HARDCODE.tipoDiagnosticoAposentadoriaChartSituacaoAtual
    }



    //const meses = 12 - mes(values.dataSimulacaoEstudo) + 1
    //values.receitaMensalMediaAtual = round(estudo.current.graficoReceitaAnualTotalMedia[0] / meses, 2)
    //values.despesaMensalMediaAtual = round(estudo.current.graficoDespesaAnualTotalMedia[0] / meses, 2)
    //values.capacidadePoupancaMensalMediaAtual = values.receitaMensalMediaAtual - values.despesaMensalMediaAtual



    let idadeDe
    let idadeAte
    let mesesIdade
    let receitaAposentadoriaMensal
    let despesaAposentadoriaMensal
    let despesasExcedentesMensal

    estudo.current.graficoAnos?.forEach( (linhaGraficoAnos, index) => {

        if (linhaGraficoAnos > values.anoFinalVaiAposentarCliente) {

            if (linhaGraficoAnos === anoAtual) {

                mesesIdade = estudo.current.mesesAnoAtual // meses

            } else {

                mesesIdade = 12
            }

            if (!idadeDe) {

                idadeDe = estudo.current.graficoIdades[index]
                receitaAposentadoriaMensal = round(estudo.current.graficoReceitaAnualTotal[index] / mesesIdade, 2)
                despesaAposentadoriaMensal = round(estudo.current.graficoDespesaAnualTotal[index] / mesesIdade, 2)
                despesasExcedentesMensal = round(estudo.current.graficoBalancoAnualTotal[index] * -1 / mesesIdade, 2)
            }

            if (
                    receitaAposentadoriaMensal !== round(estudo.current.graficoReceitaAnualTotal[index] / mesesIdade, 2) ||
                    despesaAposentadoriaMensal !== round(estudo.current.graficoDespesaAnualTotal[index] / mesesIdade, 2) ||
                    despesasExcedentesMensal !== round(estudo.current.graficoBalancoAnualTotal[index] * -1 / mesesIdade, 2)
            ) {

                estudo.current.necessidadeAposentadoria.push(
                    {
                        idadeDe: idadeDe,
                        idadeAte: idadeAte,
                        receitaAposentadoriaMensal: receitaAposentadoriaMensal,
                        despesaAposentadoriaMensal: despesaAposentadoriaMensal,
                        despesasExcedentesMensal: despesasExcedentesMensal < 0 ? LABEL.tracoXtraco : despesasExcedentesMensal,
                    }
                )
                
                idadeDe = estudo.current.graficoIdades[index]
                receitaAposentadoriaMensal = round(estudo.current.graficoReceitaAnualTotal[index] / mesesIdade, 2) 
                despesaAposentadoriaMensal = round(estudo.current.graficoDespesaAnualTotal[index] / mesesIdade, 2) 
                despesasExcedentesMensal = round(estudo.current.graficoBalancoAnualTotal[index] * -1 / mesesIdade, 2) 
            }

            idadeAte = estudo.current.graficoIdades[index]
        }
    })

    estudo.current.necessidadeAposentadoria.push(
        {
            idadeDe: idadeDe,
            idadeAte: idadeAte,
            receitaAposentadoriaMensal: receitaAposentadoriaMensal,
            despesaAposentadoriaMensal: despesaAposentadoriaMensal,
            despesasExcedentesMensal: despesasExcedentesMensal < 0 ? LABEL.tracoXtraco : despesasExcedentesMensal,
        }
    )

    values.necessidadeAposentadoria = ''

    let necessidadeAposentadoriaAnt = ''

    estudo.current.necessidadeAposentadoria?.forEach( (linhaNecessidadeAposentadoria, indexNecessidadeAposentadoria) => {

        if (
                linhaNecessidadeAposentadoria.despesasExcedentesMensal !== LABEL.tracoXtraco &&
                linhaNecessidadeAposentadoria.despesasExcedentesMensal !== necessidadeAposentadoriaAnt
        ) {

            if (values.necessidadeAposentadoria) {

                values.necessidadeAposentadoria += ' / '
            }

            values.necessidadeAposentadoria += formataValor(linhaNecessidadeAposentadoria.despesasExcedentesMensal, 2)

            necessidadeAposentadoriaAnt = linhaNecessidadeAposentadoria.despesasExcedentesMensal
        }
    })

    

    let deficitDepoisAposentar

    let indexAux = estudo.current.graficoBalancoAnualTotal.length - 1
    let qtdeMeses
    let valorAnt = ''

    while (indexAux >= 0) {

        if (estudo.current.graficoAnos[indexAux] > values.anoFinalVaiAposentarCliente) {

            const valor = estudo.current.graficoBalancoAnualTotalComTracarMetaAporte/*graficoBalancoAnualTotal*/[indexAux] > 0 ? 0 :
                estudo.current.graficoBalancoAnualTotalComTracarMetaAporte/*graficoBalancoAnualTotal*/[indexAux]
            //let valor = 0
            //estudo.current.graficoConsumoReservaAposentadoria?.forEach( (linhaGraficoConsumoReservaAposentadoria, indexGraficoConsumoReservaAposentadoria) => {
            //
            //    valor += linhaGraficoConsumoReservaAposentadoria.serie[indexAux] > 0 ? (linhaGraficoConsumoReservaAposentadoria.serie[indexAux] / 12) * -1 : 0
            //})

            if (!valorAnt || /*estudo.current.graficoBalancoAnualTotal[indexAux]*/valor !== valorAnt) {

                if (valorAnt || valorAnt === 0) {

                    deficitDepoisAposentar = calcDeficitDepoisAposentar(deficitDepoisAposentar,
                        fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, valorAnt, qtdeMeses)
                }

                valorAnt = /*estudo.current.graficoBalancoAnualTotal[indexAux]*/valor
                qtdeMeses = 0
            }
    
            qtdeMeses += 12 - (jaAposentou && estudo.current.graficoAnos[indexAux] === anoAtual ? 12/*mesAtual - 1*/ : 0)
        }

        indexAux--
    }

    deficitDepoisAposentar = calcDeficitDepoisAposentar(deficitDepoisAposentar,
        fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, valorAnt, qtdeMeses)

    if (jaAposentou) {
        
        values.valorAporteMensalPreservarReservaAposentadoria = 0

    } else {

        values.valorAporteMensalPreservarReservaAposentadoria =
            round((deficitDepoisAposentar - values.valorPresenteReservaInicioAposentadoria) *
                (fatorTaxaRetornoMensalRealPonderada / (Math.pow(1 + fatorTaxaRetornoMensalRealPonderada, mesesAteAposentar) - 1)) *
                    (1 / (1 + fatorTaxaRetornoMensalRealPonderada)), 2)
    }

    if (
            !values.valorAporteMensalPreservarReservaAposentadoria ||
            values.valorAporteMensalPreservarReservaAposentadoria < 0
    ) {

        values.valorAporteMensalPreservarReservaAposentadoria = 0
    }



    if (jaAposentou) {

        values.valorDepositoUnicoViverRentabilidade = round(deficitDepoisAposentar - values.valorPresenteReservaInicioAposentadoria, 2)

    } else {
        
        values.valorDepositoUnicoViverRentabilidade =
            round((deficitDepoisAposentar - values.valorPresenteReservaInicioAposentadoria) /
                Math.pow(1 + fatorTaxaRetornoMensalRealPonderada, mesesAteAposentar), 2)
    }

    if (!values.valorDepositoUnicoViverRentabilidade || values.valorDepositoUnicoViverRentabilidade <= 0.01) {

        values.valorDepositoUnicoViverRentabilidade = 0
    }



    if (jaAposentou) {

        values.valorDepositoUnicoManterPadraoVida = values.valorPresenteDeficitReservaInicioAposentadoria
            //round(((valorLiquidezFinanceiraAcumulada * -1) /
                //Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar)), 2)

    } else {
        
        values.valorDepositoUnicoManterPadraoVida =
            round(values.valorPresenteDeficitReservaInicioAposentadoria/*((valorLiquidezFinanceiraAcumulada * -1) /
                Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar))*/ /
                    Math.pow(1 + fatorTaxaRetornoMensalRealPonderada, mesesAteAposentar), 2)
    }
    
    if (!values.valorDepositoUnicoManterPadraoVida || values.valorDepositoUnicoManterPadraoVida < 0) {

        values.valorDepositoUnicoManterPadraoVida = 0
    }
 


    values.valorRetiradaMensalMaximaPreservarReservaAposentadoria =
        round(values.valorPresenteReservaInicioAposentadoria -
            (values.valorPresenteReservaInicioAposentadoria / 
                (1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada)), 2)

    if (values.valorRetiradaMensalMaximaPreservarReservaAposentadoria < 0) {

        values.valorRetiradaMensalMaximaPreservarReservaAposentadoria = 0
    }



    if (fatorTaxaRetornoMensalRealAposAposentadoriaPonderada === 0) {
        
        values.valorRetiradaMensalMaximaConsumirReservaAposentadoria =
            round(values.valorPresenteReservaInicioAposentadoria / mesesDepoisAposentar, 2)
    
    } else {

        values.valorRetiradaMensalMaximaConsumirReservaAposentadoria =
            round((values.valorPresenteReservaInicioAposentadoria *
                Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar - 1) *
                    fatorTaxaRetornoMensalRealAposAposentadoriaPonderada) /
                        (Math.pow(1 + fatorTaxaRetornoMensalRealAposAposentadoriaPonderada, mesesDepoisAposentar) - 1), 2)
    }

    if (values.valorRetiradaMensalMaximaConsumirReservaAposentadoria < 0) {

        values.valorRetiradaMensalMaximaConsumirReservaAposentadoria = 0
    }
}