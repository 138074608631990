export const round = (num, places) => {
	if (!("" + num).includes("e")) {
		return +(Math.round(num + "e+" + places)  + "e-" + places);
	} else {
		let arr = ("" + num).split("e");
		let sig = ""
		if (+arr[1] + places > 0) {
			sig = "+";
		}

		return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + places)) + "e-" + places);
	}
}

export const roundEscala = (num) => {


	let escala = 0

	let numEscala

	if (Math.abs(num) < 10) {

		escala = 1

	} else {

		if (Math.abs(num) < 100) {

			escala = 2

		} else {

			if (Math.abs(num) < 1000) {

				escala = 3
	
			} else {

				if (Math.abs(num) < 10000) {

					escala = 4
		
				} else {
					
					if (Math.abs(num) < 100000) {

						escala = 5
			
					} else {

						if (Math.abs(num) < 1000000) {

							escala = 6
				
						} else {

							if (Math.abs(num) < 10000000) {

								escala = 7
					
							} else {
								
								if (Math.abs(num) < 100000000) {

									escala = 8
								}
							}
						}
					}
				}
			}
		}
	}

	if (escala > 0) {

		if (escala === 1) {

			numEscala = num + (num > 0 ? 1 : -1)

		} else {

			numEscala = (parseInt(num / (Math.pow(10, escala - 2))) + (num > 0 ? 2 : -2)) * Math.pow(10, escala - 2)
		}

	} else {

		numEscala = num
	}

	return numEscala
}