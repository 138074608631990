// alterado - revisado

import familiaresList from '../../../business-rules/List/familiaresList'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import {
    grupoCoberturaSeguroVidaList
 } from '../../../business-rules/List/SeguroVida/grupoCoberturaSeguroVidaList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import statusCoberturaList from '../../../business-rules/List/SeguroVida/statusCoberturaList'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'



export const incrementaContSeguroVidaCobertura = ( values ) => {
    if (!values.contSeguroVidaCobertura) {
        values.contSeguroVidaCobertura = 0
    }
    values.contSeguroVidaCobertura = values.contSeguroVidaCobertura + 1
}

export const obtemContSeguroVidaCobertura = ( values ) => {

    let contSeguroVidaCobertura = ''

    if (!values.contSeguroVidaCobertura) {
         incrementaContSeguroVidaCobertura(values)
         contSeguroVidaCobertura = values.contSeguroVidaCobertura

    } else {
        contSeguroVidaCobertura = 1

        while (contSeguroVidaCobertura <= values.contSeguroVidaCobertura) {
            if (!pesquisaList(values.seguroVidaCobertura, contSeguroVidaCobertura).id) {
                break
            }

            contSeguroVidaCobertura++
        }

        if (contSeguroVidaCobertura > values.contSeguroVidaCobertura) {
            incrementaContSeguroVidaCobertura(values)
            contSeguroVidaCobertura = values.contSeguroVidaCobertura
        }  
    }

    return contSeguroVidaCobertura
}



export const incluiSeguroVidaCoberturaDefault = ( values, idSeguroVidaApolice ) => {

    grupoCoberturaSeguroVidaList?.forEach( (linhaGrupoCoberturaSeguroVidaList, index) => {

        incrementaContSeguroVidaCobertura(values)
        values.seguroVidaCobertura.push(
            {
                id: values.contSeguroVidaCobertura,
                idSeguroVidaApolice: idSeguroVidaApolice,
                grupoCoberturaSeguroVida: linhaGrupoCoberturaSeguroVidaList.id,
            }
        )
    })
}



export const excluirCoberturas = (values, idSeguroVidaApolice) => {

    let index = values.seguroVidaCobertura.length - 1

    while (index >= 0) {
        
        if (values.seguroVidaCobertura[index].idSeguroVidaApolice === idSeguroVidaApolice) {

            values.seguroVidaCobertura.splice(index, 1)
        }

        index -= 1
    }
}



const calculaSeguroVidaCoberturaPrazoRestanteAnos = ( item ) => {

    const linhaTipoCoberturaSeguroVida = pesquisaList(tipoCoberturaSeguroVidaList, item.tipoCoberturaSeguroVida)

    if (
            linhaTipoCoberturaSeguroVida.informaDataTerminoCobertura === "N" &&
            linhaTipoCoberturaSeguroVida.informaDataTerminoCoberturaOpcional === "N"
    ) {
        item.dataTerminoCobertura = null
    }
}

const calculaSeguroVidaCoberturaTipoPrazoRecebtoRenda = ( item ) => {
    if (pesquisaList(tipoCoberturaSeguroVidaList, item.tipoCoberturaSeguroVida).informaTipoPrazoRecebtoRenda === "N") {
        item.tipoPrazoRecebtoRenda = ''
    }
}


export const processaAlteracaoSeguroVidaCoberturaTipoCoberturaSeguroVida = ( item ) => {
    calculaSeguroVidaCoberturaPrazoRestanteAnos(item)
    calculaSeguroVidaCoberturaTipoPrazoRecebtoRenda(item)
}



export const dadosCompletosLinhaSeguroVidaCobertura = ( linhaSeguroVidaCobertura, cotacaoProposta ) => {

    const linhaTipoCoberturaSeguroVidaList = pesquisaList(tipoCoberturaSeguroVidaList, 
        linhaSeguroVidaCobertura.tipoCoberturaSeguroVida)

    return  linhaSeguroVidaCobertura.valorCS &&
            (linhaSeguroVidaCobertura.valorPremio || linhaSeguroVidaCobertura.valorPremio === 0) &&
            (
                linhaTipoCoberturaSeguroVidaList.informaCSSobrevivencia === "N" ||
                linhaSeguroVidaCobertura.valorCSSobrevivencia
            ) &&
            (
                linhaTipoCoberturaSeguroVidaList.informaDataTerminoCobertura === "N" ||
                linhaSeguroVidaCobertura.dataTerminoCobertura ||
                cotacaoProposta
            ) &&
            (
                linhaTipoCoberturaSeguroVidaList.informaTipoPrazoRecebtoRenda === "N" ||
                linhaSeguroVidaCobertura.tipoPrazoRecebtoRenda
            )
}



export const dadosCompletosSeguroVidaCobertura = ( values ) => {
    
    let dadosIncompletos = ''
    let familiares = []
    let linhaSeguroVidaApolice

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.seguroVidaCobertura?.forEach( (linhaSeguroVidaCobertura, index) => {

            linhaSeguroVidaApolice = pesquisaList(values.seguroVidaApolice,
                linhaSeguroVidaCobertura.idSeguroVidaApolice)

            if (
                    linhaSeguroVidaApolice.familiar === linhaFamiliaresList.id &&
                    linhaSeguroVidaCobertura.tipoCoberturaSeguroVida &&
                    !dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCobertura, false)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos,
                        (linhaSeguroVidaApolice.numeroApolice
                            ?
                                linhaSeguroVidaApolice.numeroApolice + ": "
                            :
                                ""
                        ) +
                            pesquisaDescricaoList(grupoCoberturaSeguroVidaList,
                                linhaSeguroVidaCobertura.grupoCoberturaSeguroVida) + " - " +
                                    pesquisaDescricaoList(tipoCoberturaSeguroVidaList,
                                        linhaSeguroVidaCobertura.tipoCoberturaSeguroVida))
            }
        })
    })

    return dadosIncompletos
}



export const seguroVidaCoberturaAtiva = ( statusCobertura ) => {

    let ativa = true
    
    if (statusCobertura) {

        if (pesquisaList(statusCoberturaList, statusCobertura).ativa === 'N') {

            ativa = false
        }
    }

    return ativa
}