export const trataMudancaNaoExibirMorteAteParaExibirMorteAte = ( values ) => {

    if (!values.exibirMorteAte) {

        values.naoExibirMorteVitalicia = false

        if (values.morteInvalidezEstudo === "A") {

            values.morteInvalidezEstudo = "M"
        }
    }
}

export const processaValoresDefaultIniciaisConfiguracaoProtecao = ( values ) => {
    
    if (!values.naoAplicarTaxaJurosSeguroVida) {

        values.naoAplicarTaxaJurosSeguroVida = false
    }
    
    if (!values.naoExibirProtecaoRiscos) {

        values.naoExibirProtecaoRiscos = false
    }

    if (!values.exibirMorteAte) {

        values.exibirMorteAte = false
    }
    
    if (!values.naoExibirMorteVitalicia) {

        values.naoExibirMorteVitalicia = false
    }
    
    if (!values.naoExibirMorteSucessaoVitalicia) {

        values.naoExibirMorteSucessaoVitalicia = false
    }

    if (!values.naoExibirDITDITA) {

        values.naoExibirDITDITA = false
    }
    
    if (!values.naoExibirDoencasGraves) {

        values.naoExibirDoencasGraves = false
    }

    if (!values.naoExibirInvalidez) {

        values.naoExibirInvalidez = false
    }
    
    if (!values.naoExibirFuneral) {

        values.naoExibirFuneral = false
    }
    
    if (!values.naoExibirDespesasAcumuladasSemDiaDia) {

        values.naoExibirDespesasAcumuladasSemDiaDia = false
    }
}