// alterado - revisado

const tipoFormaPagtoList = (jsonConfiguracao) => {
    
    return [
        {
            id: 'C', // HARDCODE.idTipoFormaPagtoCartaoCredito
            descricao:
                jsonConfiguracao.tituloSubTotal1InputDiaDia
                    ?
                        jsonConfiguracao.tituloSubTotal1InputDiaDia
                    :
                        'Cartão de Crédito',
            ordem: 10,
        },
        {
            id: 'D', // HARDCODE.idTipoFormaPagtoDebitoDinheiro
            descricao:
                jsonConfiguracao.tituloSubTotal2InputDiaDia
                    ?
                        jsonConfiguracao.tituloSubTotal2InputDiaDia
                    :
                        'Débito/Dinheiro',
            ordem: 20,
        },
    ]
}

export default tipoFormaPagtoList
