import HARDCODE from '../../../business-const/HardCode/HardCode'



const pessoaFisicaJuridicaList = [
    {
       id: HARDCODE.idPessoaFisica,
       descricao: 'Pessoa Física',
       ordem: 10,
    },
    {
       id:  HARDCODE.idPessoaJuridica,
       descricao: 'Pessoa Jurídica',
       ordem: 20,
    },
 ]
 
 export default pessoaFisicaJuridicaList