import HARDCODE from '../../business-const/HardCode/HardCode'

import familiaresList from './familiaresList'

const familiaList = ( values ) => {
    let familiaList = familiaresList(values)

    familiaList.push ({
        id: HARDCODE.idFamiliarFamilia,
        descricao: 'Família', // HARDCODEBACKEND.descricaoFamiliarFamilia
        ordem: HARDCODE.idFamiliarFamilia,
        idade: '',
        parentesco: '',
        idadeDataSimulacao: '',
    })

    return familiaList
}

export default familiaList