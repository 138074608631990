import HARDCODE from '../../business-const/HardCode/HardCode'

const formPrimeiraReuniaoInvestimento40List = [
    {
        id: 10,
        descricao: 'Possui receita mensal?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeReceitaMensal,
        compoeCompleto: true,
        ordem: 10,
    },
    {
        id: 20,
        descricao: 'Possui despesas do dia a dia?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeDiaDia,
        compoeCompleto: true,
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Possui despesas com educação?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeEducacao,
        compoeCompleto: true,
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Possui financiamentos?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeFinanciamento,
        compoeCompleto: true,
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Possui projetos de vida/sonho?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeProjetoVidaSonho,
        compoeCompleto: true,
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Possui patrimônio imobilizado?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypePatrimonioImobilizado,
        compoeCompleto: true,
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'Possui recursos financeiros?',
        default: true,
        grupoPergunta: '',
        type: HARDCODE.formTypeRecursosFinanceiros,
        compoeCompleto: true,
        ordem: 70,
    },    
    {
        id: 90,
        descricao: 'Qual é o valor do seu patrimônio imobilizado?',
       descricaoResumida: 'Patrimônio (Imobilizado)',
        default: false,
        grupoPergunta: '',
        type: 'ValorPerc',
        atualiza: HARDCODE.formListAtualizaPatrimonioImobilizado,
        ordem: 90,
    },
    {
       id: 100,
        descricao: 'Qual o valor total dos seus investimentos?',
        descricaoResumida: 'Recursos Financeiros',
        default: false,
        grupoPergunta: '',
        type: 'ValorPerc',
        atualiza: HARDCODE.formListAtualizaRecursosFinanceiros,
        ordem: 100,
    },
    {
        id: 110,
        descricao: 'Qual é a sua receita mensal?',
        descricaoResumida: 'Receita Anual',
        default: false,
        grupoPergunta: '',
        type: 'ValorValor',
        atualiza: HARDCODE.formListAtualizaReceitaMensal,
        ordem: 110,
    },
    {
        id: 120,
        descricao: 'Qual é a sua despesa mensal?',
        descricaoResumida: 'Despesa Mensal',
        default: false,
        grupoPergunta: '',
        type: 'ValorValor',
        atualiza: HARDCODE.formListAtualizaDiaDia,
        ordem: 120,
    },
]
 
export default formPrimeiraReuniaoInvestimento40List