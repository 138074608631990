import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    round
} from '../../../business-components/round/round'

import {
    mesMesAno,
    anoMesAno,
    formataMesAnoTextoComBarra,
} from '../../../business-components/Date/Date'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTituloDestaque2Titulos,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import tipoDiaDiaList from '../../../business-rules/List/tipoDiaDiaList'

import familiaresList from '../../../business-rules/List/familiaresList'
    
import {
    dadosCompletosConjuge,
    calculaValorMensal,
    calculaTempoMesAnoInicialFinal,
    calculaIntervaloTempoAnos,
} from '../cliente-form/ClienteService'

import {
    possuiFamiliares,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosLinhaDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    calculaDescricao,
    calculaDescricaoGrupoDespesa,
    formataValorTipoPeriodicidade,
} from './EstudoService'

import {
    inicializaSerieGraficoDespesa,
    inicializaSerieGrafico,
    incluiDespesaMensalDetalhadaTemp,
    calculaIndexGraficoAnosIdades,
    calculaGraficoValorPresente,
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'

export const calculaDespesasDiaDiaDespesasTemporarias = (values, estudo, report) => {
    let diaDiaDespesasTemporariasTemp = []
    let diaDiaDespesasTemporarias = []
    let panelDespesasTotaisDiaDiaDespesasTemporarias

    if (values.diaDia) {
        let linhaTipoDiaDiaDespesasTemporaria = ''

        let nomeCurtoFamiliar = null
        let descricaoDiaDiaDespesasTemporarias = null

        let indexGraficoConsumoReservaAposentadoria
        let indexGraficoDespesaAnual
        let indexGraficoDespesaAnualMedia /**/

        let mesInicial
        let anoInicial = ''
        let mesFinal
        let anoFinal = ''
        
        let valor = 0 /**/
        let valorMensal = 0
        let maiorAnoFinal = ''
        let valorAcumulado = 0
        let valorCompra = 0
        let valorResponsabilidadeCliente = 0
        let valorResponsabilidadeConjuge = 0

        let valorMensalTotal = 0
        let valorAcumuladoTotal = 0
        let valorCompraTotal = 0
        let valorResponsabilidadeClienteTotal = 0
        let valorResponsabilidadeConjugeTotal = 0

        let saldo

        values.diaDia.forEach( (linhaDiaDiaDespesasTemporarias, index) => {

            linhaTipoDiaDiaDespesasTemporaria =
                pesquisaList(tipoDiaDiaList, linhaDiaDiaDespesasTemporarias.tipoDiaDia)
            
            if (
                    dadosCompletosLinhaDiaDia(linhaDiaDiaDespesasTemporarias) &&
                    linhaDiaDiaDespesasTemporarias.alterarPeriodoDiaDia &&
                    !pesquisaList(values.exclusaoFamiliares, linhaDiaDiaDespesasTemporarias.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.diaDia + '/' + linhaDiaDiaDespesasTemporarias.id).id &&
                    (
                        (
                            values.morteInvalidezEstudo === "I" &&
                            linhaTipoDiaDiaDespesasTemporaria.morte === "N"
                        ) ||
                        (
                            values.morteInvalidezEstudo !== "I" && 
                            linhaTipoDiaDiaDespesasTemporaria.invalidez === "N"
                        )
                    ) &&
                    (
                        (
                            (
                                values.morteInvalidezEstudo === "A" ||
                                values.morteInvalidezEstudo === "M"
                            ) &&
                            linhaTipoDiaDiaDespesasTemporaria.invalidez === "N"
                        ) ||
                        (
                            values.morteInvalidezEstudo !== "A" && 
                            values.morteInvalidezEstudo !== "M" && 
                            linhaTipoDiaDiaDespesasTemporaria.morte === "N"
                        )
                    )
            ) {

                nomeCurtoFamiliar = 
                    pesquisaDescricaoList(familiaresList(values), linhaDiaDiaDespesasTemporarias.familiar)

                descricaoDiaDiaDespesasTemporarias =
                    calculaDescricao(linhaTipoDiaDiaDespesasTemporaria.descricao, linhaDiaDiaDespesasTemporarias.descricaoDiversasOutros)

                valor = round(linhaDiaDiaDespesasTemporarias.valor, 2) /**/
                valorMensal = 
                    calculaValorMensal (linhaDiaDiaDespesasTemporarias.valor,
                        linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto)

                mesInicial = mesMesAno(linhaDiaDiaDespesasTemporarias.mesAnoInicial)
                anoInicial = anoMesAno(linhaDiaDiaDespesasTemporarias.mesAnoInicial)

                if (linhaDiaDiaDespesasTemporarias.diaDiaIraOcorrerAteExpectativaVida) {

                    mesFinal = 12

                    if (linhaDiaDiaDespesasTemporarias.despesaPessoal) {

                        anoFinal =
                            linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarCliente
                                ?
                                    values.anoFinalVitalicioCliente
                                :
                                    values.anoFinalVitalicioConjuge
    
                    } else {
    
                        anoFinal = values.anoFinalVitalicioClienteConjuge
                    }

                } else {

                    mesFinal = mesMesAno(linhaDiaDiaDespesasTemporarias.mesAnoFinal)
                    anoFinal = anoMesAno(linhaDiaDiaDespesasTemporarias.mesAnoFinal)
                }

                if (
                        linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarCliente ||
                        linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarConjuge
                ) {
                    
                    valorAcumulado = estudo.current.graficoDiaDiaDespesasTemporarias[0]
                    
                    calculaGraficoValorPresente(mesInicial, anoInicial, mesFinal, anoFinal, valor/*valorMensal*/, 
                        linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto/*null*/,
                            estudo.current.graficoAnos, estudo.current.graficoDiaDiaDespesasTemporarias,
                                estudo.current.graficoDespesasAcumuladas, values, estudo,
                                    values.naoAplicarTaxaJurosSeguroVida ? 0 : values.taxaRetornoAnualReal,
                                        values.naoAplicarTaxaJurosSeguroVida ? 0 :
                                            values.taxaRetornoAnualRealAposAposentadoria, null, null, null, true
                    )
                                
                    if (linhaTipoDiaDiaDespesasTemporaria.morte === "N" && linhaTipoDiaDiaDespesasTemporaria.invalidez === "N") {

                        if (linhaDiaDiaDespesasTemporarias.consumirRecursosFinanceirosEmVezDaReceitaMensal) {

                            saldo = calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                /*valorMensal*/valor * -1, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto/*null*/,
                                    estudo.current.graficoAnos,
                                        estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                values, estudo, values.taxaRetornoAnualReal,
                                                    values.taxaRetornoAnualRealAposAposentadoria, true, null, null,
                                                        null, estudo.current.evolucaoReservaFinanceiraEstudo, null)

                            if (
                                anoFinal < values.anoFinalVitalicioClienteConjuge ||
                                (
                                    anoFinal === values.anoFinalVitalicioClienteConjuge &&
                                    mesFinal < 12
                                )
                            ) {

                                if (mesFinal < 12) {

                                    const indexGraficoAnos =
                                        calculaIndexGraficoAnosIdades(estudo.current.graficoAnos, anoFinal)
        
                                    estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento[indexGraficoAnos] -= saldo
        
                                    estudo.current.graficoRecursosFinanceirosAcumuladosTotal[indexGraficoAnos] -= saldo
                                }

                                calculaGraficoAcumulacaoMensal(mesFinal === 12 ? 1 : mesFinal + 1,
                                    mesFinal === 12 ? anoFinal + 1 : anoFinal, null,
                                        values.anoFinalVitalicioClienteConjuge, saldo, 0, null, estudo.current.graficoAnos,
                                            estudo.current.graficoRecursosFinanceirosAcumuladosDiaDiaFinanciamento,
                                                estudo.current.graficoRecursosFinanceirosAcumuladosTotal,
                                                    values, estudo, values.taxaRetornoAnualReal,
                                                        values.taxaRetornoAnualRealAposAposentadoria, true, null, null, null, null, null)
                            }

                            indexGraficoConsumoReservaAposentadoria =
                                inicializaSerieGrafico(values, estudo.current.graficoConsumoReservaAposentadoria, linhaTipoDiaDiaDespesasTemporaria,
                                    LABEL.diaDia + LABEL.traco + descricaoDiaDiaDespesasTemporarias,
                                        LABEL.diaDia + LABEL.traco + descricaoDiaDiaDespesasTemporarias)

                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valor/*valorMensal*/, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto/*null*/, estudo.current.graficoAnos,
                                    estudo.current.graficoConsumoReservaAposentadoria[indexGraficoConsumoReservaAposentadoria].serie,
                                        null, values, estudo, 0, 0, false, null, null, null, null, null)
                        } else {

                            //calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                            //    valor/*valorMensal*/, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto/*null*/, estudo.current.graficoAnos,
                            //        estudo.current.graficoDespesaAnualTotal, null, values, estudo, 0, 0, false, null, null, null, null, null)

                            indexGraficoDespesaAnual =
                                inicializaSerieGrafico(values, estudo.current.graficoDespesaAnual,
                                    inicializaSerieGraficoDespesa(linhaDiaDiaDespesasTemporarias,
                                        linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesaOutras,
                                            linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesaOutras), null, null)
        
                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valor/*valorMensal*/, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto/*null*/,
                                    estudo.current.graficoAnos,
                                        estudo.current.graficoDespesaAnual[indexGraficoDespesaAnual].serie,
                                            estudo.current.graficoDespesaAnualTotal/*null*/, values, estudo, 0, 0, false,
                                                null, null, null, null, null)

                            incluiDespesaMensalDetalhadaTemp(values, estudo, linhaDiaDiaDespesasTemporarias,
                                descricaoDiaDiaDespesasTemporarias,
                                    valor, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto, valorMensal, null,
                                        mesInicial, anoInicial, mesFinal, anoFinal)

                            /**/
                            indexGraficoDespesaAnualMedia =
                                inicializaSerieGrafico(values, estudo.current.graficoDespesaAnualMedia,
                                    inicializaSerieGraficoDespesa(linhaDiaDiaDespesasTemporarias,
                                        linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesaOutras,
                                            linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesaOutras), null, null)

                            calculaGraficoAcumulacaoMensal(mesInicial, anoInicial, mesFinal, anoFinal, 0,
                                valorMensal, null, estudo.current.graficoAnos,
                                    estudo.current.graficoDespesaAnualMedia[indexGraficoDespesaAnualMedia].serie,
                                        estudo.current.graficoDespesaAnualTotalMedia,
                                            values, estudo, 0, 0, false, null, null, null, null, null)
                            /**/
                        }
                    }
                    
                    if (!maiorAnoFinal || anoFinal > maiorAnoFinal) {

                        maiorAnoFinal = anoFinal
                    }

                    valorAcumulado = estudo.current.graficoDiaDiaDespesasTemporarias[0] - valorAcumulado

                } else {
                    
                    valorAcumulado = 0
                }

                valorCompra = linhaDiaDiaDespesasTemporarias.valorCompra ? 
                    round(linhaDiaDiaDespesasTemporarias.valorCompra, 2) : 0
                
                if (
                        (
                            !dadosCompletosConjuge(values) &&
                            !possuiFamiliares(values) &&
                            (
                                values.morteInvalidezEstudo === "A" ||
                                values.morteInvalidezEstudo === "M"
                            ) &&
                            linhaTipoDiaDiaDespesasTemporaria.pensaoDependente !== "S"
                        ) ||
                        (
                            linhaDiaDiaDespesasTemporarias.familiar !== HARDCODE.idFamiliarCliente &&
                            linhaDiaDiaDespesasTemporarias.familiar !== HARDCODE.idFamiliarConjuge
                        )
                ) {

                    valorResponsabilidadeCliente = 0
                    valorResponsabilidadeConjuge = 0

                } else {

                    if  (
                            (
                                linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarCliente &&
                                linhaDiaDiaDespesasTemporarias.despesaPessoal &&
                                (
                                    values.morteInvalidezEstudo === "A" ||
                                    values.morteInvalidezEstudo === "M"
                                )
                            ) ||
                            (
                                linhaDiaDiaDespesasTemporarias.familiar !== HARDCODE.idFamiliarCliente &&
                                (
                                    linhaTipoDiaDiaDespesasTemporaria.morte === "S" ||
                                    linhaTipoDiaDiaDespesasTemporaria.invalidez === "S"
                                )
                            )
                    ) {

                        valorResponsabilidadeCliente = 0

                    } else {

                        if (
                                linhaDiaDiaDespesasTemporarias.valorCompra &&
                                (
                                    linhaDiaDiaDespesasTemporarias.titularRespValorCompra ===
                                        HARDCODE.idFamiliarCliente  ||
                                    linhaDiaDiaDespesasTemporarias.titularRespValorCompra ===
                                        HARDCODE.idFamiliarCasal
                                )
                        ) {

                                valorResponsabilidadeCliente = valorCompra

                        } else {

                            if (valorCompra > 0 && valorCompra < valorAcumulado) {

                                valorResponsabilidadeCliente = valorCompra

                            } else {

                                if (linhaTipoDiaDiaDespesasTemporaria.pensaoDependente === "S") {

                                    if (linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarCliente) {

                                        valorResponsabilidadeCliente = valorAcumulado

                                    } else {

                                        valorResponsabilidadeCliente = 0
                                    }

                                } else {

                                    valorResponsabilidadeCliente = valorAcumulado
                                }
                            }
                        }
                    }
                        
                    if (
                            (
                                linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarConjuge &&
                                linhaDiaDiaDespesasTemporarias.despesaPessoal &&
                                (
                                    values.morteInvalidezEstudo === "A" ||
                                    values.morteInvalidezEstudo === "M"
                                )
                            ) ||
                            (
                                linhaDiaDiaDespesasTemporarias.familiar !== HARDCODE.idFamiliarConjuge &&
                                (
                                    linhaTipoDiaDiaDespesasTemporaria.morte === "S" ||
                                    linhaTipoDiaDiaDespesasTemporaria.invalidez === "S"
                                )
                            )
                    ) {

                        valorResponsabilidadeConjuge = 0

                    } else {

                        if (
                                linhaDiaDiaDespesasTemporarias.valorCompra &&
                                (
                                    linhaDiaDiaDespesasTemporarias.titularRespValorCompra ===
                                        HARDCODE.idFamiliarConjuge ||
                                    linhaDiaDiaDespesasTemporarias.titularRespValorCompra ===
                                        HARDCODE.idFamiliarCasal
                                )
                        ) {

                                valorResponsabilidadeConjuge = valorCompra

                        } else {

                            if (valorCompra > 0 && valorCompra < valorAcumulado) {

                                valorResponsabilidadeConjuge = valorCompra

                            } else {

                                if (linhaTipoDiaDiaDespesasTemporaria.pensaoDependente === "S") {

                                    if (linhaDiaDiaDespesasTemporarias.familiar === HARDCODE.idFamiliarConjuge) {

                                        valorResponsabilidadeConjuge = valorAcumulado

                                    } else {

                                        valorResponsabilidadeConjuge = 0
                                    }

                                } else {

                                    valorResponsabilidadeConjuge = valorAcumulado
                                }
                            }
                        }
                    }
                }
            
                diaDiaDespesasTemporariasTemp.push({
                    ordem: ("0000" + linhaDiaDiaDespesasTemporarias.familiar).slice(-4) + 
                        linhaDiaDiaDespesasTemporarias.anoFinal,
                    familiar: linhaDiaDiaDespesasTemporarias.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricao: descricaoDiaDiaDespesasTemporarias,
                    despesaPessoal: linhaDiaDiaDespesasTemporarias.despesaPessoal,
                    valor: valor,/**/
                    tipoPeriodicidadePagto: linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto,/**/
                    valorMensal: valorMensal,
                    descricaoGrupoDespesa:
                        calculaDescricaoGrupoDespesa(linhaDiaDiaDespesasTemporarias.grupoDespesa,
                            linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesaOutras),
                    mesAnoInicial: linhaDiaDiaDespesasTemporarias.mesAnoInicial,
                    mesAnoFinal: linhaDiaDiaDespesasTemporarias.mesAnoFinal,
                    tempo: /**/
                        calculaTempoMesAnoInicialFinal(values, linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto,
                            linhaDiaDiaDespesasTemporarias.mesAnoInicial, linhaDiaDiaDespesasTemporarias.mesAnoFinal),
                    valorAcumulado: valorAcumulado,
                    valorCompra: valorCompra,
                    valorResponsabilidadeCliente: valorResponsabilidadeCliente,
                    valorResponsabilidadeConjuge: valorResponsabilidadeConjuge,
                    id: linhaDiaDiaDespesasTemporarias.id,
                    valorAcumuladoDependente:
                        !linhaDiaDiaDespesasTemporarias.despesaPessoal && linhaTipoDiaDiaDespesasTemporaria.pensaoDependente === "S"
                            ?
                                valorAcumulado
                            :
                                '',
                    anoFinal: anoFinal,
                })

                valorMensalTotal =
                    valorMensalTotal + valorMensal
                valorAcumuladoTotal =
                    valorAcumuladoTotal + valorAcumulado
                valorCompraTotal =
                    valorCompraTotal + valorCompra
                valorResponsabilidadeClienteTotal =
                    valorResponsabilidadeClienteTotal + valorResponsabilidadeCliente
                valorResponsabilidadeConjugeTotal = 
                    valorResponsabilidadeConjugeTotal + valorResponsabilidadeConjuge
            }
        })

        diaDiaDespesasTemporariasTemp = sortAsc(diaDiaDespesasTemporariasTemp)

        let familiarAnt = null

        diaDiaDespesasTemporariasTemp.forEach( (linhaDiaDiaDespesasTemporarias, index) => {
            if (linhaDiaDiaDespesasTemporarias.familiar !== familiarAnt) {
                familiarAnt = linhaDiaDiaDespesasTemporarias.familiar

                valorMensal = 0
                valorAcumulado = 0
                valorCompra = 0
                valorResponsabilidadeCliente = 0
                valorResponsabilidadeConjuge = 0

                diaDiaDespesasTemporariasTemp.forEach( (linhaDiaDiaDespesasTemporariasTotal, index) => {
                    if (linhaDiaDiaDespesasTemporariasTotal.familiar === linhaDiaDiaDespesasTemporarias.familiar) {
                        valorMensal = valorMensal + 
                            linhaDiaDiaDespesasTemporariasTotal.valorMensal
                        valorAcumulado = valorAcumulado + 
                            linhaDiaDiaDespesasTemporariasTotal.valorAcumulado
                        valorCompra = valorCompra + 
                            linhaDiaDiaDespesasTemporariasTotal.valorCompra
                        valorResponsabilidadeCliente = valorResponsabilidadeCliente + 
                            linhaDiaDiaDespesasTemporariasTotal.valorResponsabilidadeCliente
                        valorResponsabilidadeConjuge = valorResponsabilidadeConjuge + 
                            linhaDiaDiaDespesasTemporariasTotal.valorResponsabilidadeConjuge
                    }
                })

                diaDiaDespesasTemporarias.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaDiaDiaDespesasTemporarias.nomeCurtoFamiliar,
                            report),
                    valorMensal: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorMensal, report),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorCompra: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorCompra, report),
                    valorResponsabilidadeCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeCliente, report),
                    valorResponsabilidadeConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeConjuge, report),
                })
            }

            diaDiaDespesasTemporarias.push({
                familiar: linhaDiaDiaDespesasTemporarias.familiar,
                descricao: 
                    linhaDiaDiaDespesasTemporarias.despesaPessoal
                        ?
                            formataTableCellBodyExibeDadosTituloDestaque2Titulos("          " + 
                                linhaDiaDiaDespesasTemporarias.descricao, LABEL.pessoal, report)
                        :
                            formataTableCellBodyExibeDadosTitulo("          " + 
                                linhaDiaDiaDespesasTemporarias.descricao, report),
                descricaoGrupoDespesa: formataTableCellBodyExibeDadosTitulo(linhaDiaDiaDespesasTemporarias.descricaoGrupoDespesa, report),
                valorMensal:
                    linhaDiaDiaDespesasTemporarias.valor === linhaDiaDiaDespesasTemporarias.valorMensal
                        ?
                            formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasTemporarias.valorMensal, report)
                        :
                            formataValorTipoPeriodicidade(linhaDiaDiaDespesasTemporarias.valor, 2, false, false,
                                linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto, false, report),
                mesAnoInicial: 
                    formataTableCellBodyExibeDadosTexto(
                        formataMesAnoTextoComBarra(linhaDiaDiaDespesasTemporarias.mesAnoInicial), report),
                mesAnoFinal: 
                    formataTableCellBodyExibeDadosTexto(
                        formataMesAnoTextoComBarra(linhaDiaDiaDespesasTemporarias.mesAnoFinal), report),
                tempo:/**/
                    formataValorTipoPeriodicidade(linhaDiaDiaDespesasTemporarias.tempo, 0, true, false,
                        linhaDiaDiaDespesasTemporarias.tipoPeriodicidadePagto, true, report),
                valorAcumulado: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasTemporarias.valorAcumulado, report),
                valorCompra: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasTemporarias.valorCompra, report),
                valorResponsabilidadeCliente: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasTemporarias.valorResponsabilidadeCliente, report),
                valorResponsabilidadeConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaDiaDiaDespesasTemporarias.valorResponsabilidadeConjuge, report),
                id: linhaDiaDiaDespesasTemporarias.id,
                valorAcumuladoDependente: linhaDiaDiaDespesasTemporarias.valorAcumuladoDependente,
                anoFinal: linhaDiaDiaDespesasTemporarias.anoFinal,
            })
        })

        diaDiaDespesasTemporarias.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valorMensal:
                formataTableCellBodyExibeDadosTotalValor(valorMensalTotal, report),
            valorAcumulado: 
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorCompra: 
                formataTableCellBodyExibeDadosTotalValor(valorCompraTotal, report),
            valorResponsabilidadeCliente: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeClienteTotal, report),
            valorResponsabilidadeConjuge: 
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeConjugeTotal, report),
        })

         panelDespesasTotaisDiaDiaDespesasTemporarias = {
            descricao: LABEL.diaDiaDespesasTemporariasDescricaoCurta,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: valorResponsabilidadeClienteTotal,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: valorResponsabilidadeConjugeTotal,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: calculaIntervaloTempoAnos(values, maiorAnoFinal),
        }

    } else {

        panelDespesasTotaisDiaDiaDespesasTemporarias = {
            descricao: LABEL.diaDiaDespesasTemporariasDescricaoCurta,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.diaDiaDespesasTemporarias = diaDiaDespesasTemporarias

    estudo.current.panelDespesasTotais[HARDCODE.indexEstudoDiaDiaDespesasTemporarias] = panelDespesasTotaisDiaDiaDespesasTemporarias
}