import React, { useState, useContext } from 'react'

import AppContext from '../../contexts/AppContext'

import useSingleton from '../../business-components/useSingleton/useSingleton'

//import {
//  Checkbox,
//  FormControlLabel,
//} from '@material-ui/core'

import { ValidatorForm } from 'react-material-ui-form-validator'

//import { makeStyles } from '@material-ui/core/styles'

import history from 'history.js'

import { useLocation } from "react-router-dom"

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import TAMMAX from '../../business-rules/TamMax/TamMax'

import EmailService from '../../business-api/EmailService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    mensagemErroErroTexto,
} from '../../business-components/Toastr/Toastr'

import {
    CardGridEntradaDados,
} from '../../business-components/Card/Card'

import {
    GridContainer,
    GridContainerLogin,
    GridItem1sm3xs3,
    GridItem4sm12xs12,
    GridItem6sm12xs12,
    GridItem7sm10xs10,
    GridItem7sm12xs12,
} from '../../business-components/Grid/Grid'

import {
    TextValidatorText,
    TextValidatorEmail,
    TextValidatorCelular,
    TextValidatorSenha,
} from '../../business-components/TextValidator/TextValidator'

import {
    ButtonCadastrar,
    ButtonCancelar,
} from '../../business-components/Button/Button'

import {
    CircularProgressPadrao,
} from '../../business-components/CircularProgress/CircularProgress'

import {
    LinkPadraoSubmitEnviarPinEmail,
} from '../../business-components/Link/Link'

//import {
//    ImgLogoSigninBackground,
//} from '../../business-components/Img/Img'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import codigoPromocionalList from '../../business-rules/List/Assinatura/codigoPromocionalList'

import {
    LogoSession,
    TituloSession,
    PadraoSenha,
    WhatsAppSession,
} from './SessionComp'

import {
    postRespostaSellFlux,
    senhaOK,
} from './SessionService'

/*
const useStyles = makeStyles(({ palette, ...theme }) => ({
    labelLink: {
        color: palette.primary.main,
        textDecoration: 'underline',
    },
}))
*/



const Signup = () => {

    const location = useLocation()

    const [loadingButtonCadastrar, setLoadingButtonCadastrar] = useState(false)

    const [loadingLinkEnviarPinEmail, setLoadingLinkEnviarPinEmail] = useState(false)

    const [state, setState] = useState({})

    const [signature, setSignature] = useState(null)

    const [message, setMessage] = useState('')

    const { variaveisGlobais } = useContext(AppContext)

    const emailService = new EmailService()

    const usuarioService = new UsuarioService()

    useSingleton(() => {
        setState({
            ...state, 
            email: location.email
        })

        const queryParams = new URLSearchParams(window.location.search)

        let signatureAux = queryParams.get('signature')

        if (signatureAux === null) {
            signatureAux = location.signature

            if (signatureAux === undefined) {
                signatureAux = null
            }
        }

        setSignature(signatureAux)
    })

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value,
        })
        setMessage('')
    }

    const handleFormSubmit = async (event) => {

        if (state.senha === state.repetirSenha) {

            if (state.email === state.emailEnviado) {

                setLoadingButtonCadastrar(true)

                let tipoLicenca = ''

                if (signature) {

                    tipoLicenca = pesquisaList(codigoPromocionalList, signature).tipoLicenca
                }

                try {
                    await usuarioService.salvar(variaveisGlobais,
                        {
                            usuarioDTO: {
                                email: state.email,
                                senha: state.senha,
                                jsonUsuario:  JSON.stringify({
                                    nome: state.nome,
                                    celular: state.celular
                                }),
                                tipoLicenca: tipoLicenca,
                            },
                            pin: state.pin
                        }
                    )

                    await postRespostaSellFlux(variaveisGlobais, HARDCODE.sellFluxNovosUsuarios, state.email, state.nome, state.celular)

                    history.push({
                        pathname: PATH.pageSignin,
                        email: state.email,
                        signature: signature,
                    })

                } catch (erro) {

                    setLoadingButtonCadastrar(false)
                    setMessage(mensagemErroErroTexto(erro))
                }

            } else {

                setMessage(MESSAGE().emailDiferenteEnviado)
            }

        } else {

            setMessage(MESSAGE().senhasDifererentes)
        }
    }

    const informouDadosCadastro = () => {

        return state.email && state.nome && state.celular && state.senha && state.repetirSenha
    }

    const enviarPinEmail = async () => {

        setLoadingLinkEnviarPinEmail(true)

        try {
            await emailService.confirmacaoCadastroUsuario(variaveisGlobais, state.email)

            setLoadingLinkEnviarPinEmail(false)

            setMessage(MESSAGE().pinEnviadoComSucesso)

            setState({
                ...state,
                emailEnviado: state.email,
            })

        } catch (erro) {

            setLoadingLinkEnviarPinEmail(false)
            
            setMessage(mensagemErroErroTexto(erro))
        }
    }

    let { email, nome, celular, senha, repetirSenha, pin/*, agreement */} = state

    return (
        <GridContainer
            className="justify-center items-center max-w-full min-h-full-screen bg-login"
        >
            {/*
            <ImgLogoSigninBackground
                variaveisGlobais={variaveisGlobais}
                values={null}
            />
            */}
            
            <GridItem7sm10xs10
                className="position w-full"
            >
                <CardGridEntradaDados>
                    <GridContainerLogin>
                        <GridItem6sm12xs12>
                            <LogoSession
                                variaveisGlobais={variaveisGlobais}
                            />
                        </GridItem6sm12xs12>

                        <GridItem6sm12xs12>
                            <div
                                className="p-6 h-full"
                            >
                                <TituloSession
                                    titulo={LABEL.cadastreSe}
                                />

                                <ValidatorForm
                                    onSubmit={handleFormSubmit}
                                >
                                    <TextValidatorEmail
                                        name="email"
                                        label={LABEL.email}
                                        value={email || ''}
                                        onChange={handleChange}
                                    />

                                    <TextValidatorText
                                        name="nome"
                                        label={LABEL.nome}
                                        value={nome || ''}
                                        onChange={handleChange}
                                        tamMax={TAMMAX.nome}
                                    />

                                    <TextValidatorCelular
                                        name="celular"
                                        label={LABEL.celular}
                                        value={celular || ''}
                                        onChange={handleChange}
                                    />
                                    <TextValidatorSenha
                                        name="senha"
                                        label={LABEL.senha}
                                        value={senha || ''}
                                        onChange={handleChange}
                                    />

                                    <PadraoSenha
                                        senha={senha}
                                    />

                                    <TextValidatorSenha
                                        name="repetirSenha"
                                        label={LABEL.repetirSenha}
                                        value={repetirSenha || ''}
                                        onChange={handleChange}
                                    />
                                    
                                    {informouDadosCadastro()
                                        ?
                                            <GridContainer>
                                                <GridItem4sm12xs12>
                                                    <TextValidatorText
                                                        name="pin"
                                                        label={LABEL.pin}
                                                        value={pin || ''}
                                                        onChange={handleChange}
                                                        tamMax={TAMMAX.pin}
                                                    />
                                                </GridItem4sm12xs12>

                                                <GridItem1sm3xs3 />

                                                <GridItem7sm12xs12>
                                                    <div
                                                        className="relative"
                                                    >
                                                        <LinkPadraoSubmitEnviarPinEmail
                                                            onClick={enviarPinEmail}
                                                            disabled={loadingLinkEnviarPinEmail}
                                                        />
                                                        {loadingLinkEnviarPinEmail && (
                                                            <CircularProgressPadrao />
                                                        )}
                                                    </div>
                                                </GridItem7sm12xs12>
                                            </GridContainer>
                                        :
                                            ''
                                    }
                                    {/*
                                    <FormControlLabel
                                        className="mb-4"
                                        name="agreement"
                                        onChange={(e) =>
                                            handleChange({
                                                target: {
                                                    name: 'agreement',
                                                    value: e.target.checked,
                                                },
                                            })
                                        }
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={agreement || false}
                                            />
                                        }
                                        label={
                                            <>
                                                Eu li e concordo com os{' '}
                                                <a
                                                    href="/"
                                                    className={classes.labelLink}
                                                >
                                                    termos do serviço.
                                                </a>
                                            </>
                                        }
                                    />
                                    */}

                                    {message && (
                                        <p
                                            className="text-error"
                                        >
                                            {message}
                                        </p>
                                    )}

                                    <div
                                        className="flex items-center"
                                    >
                                        <div
                                            className="relative"
                                        >
                                            <ButtonCadastrar
                                                disabled={loadingButtonCadastrar || !informouDadosCadastro() || !pin || !senhaOK(senha)}
                                            />
                                            {loadingButtonCadastrar && (
                                                <CircularProgressPadrao />
                                            )}
                                        </div>

                                        <GridItem1sm3xs3 />

                                        <ButtonCancelar
                                            onClick={() =>
                                                history.push({
                                                    pathname: PATH.pageSignin,
                                                    email: state.email,
                                                    signature: signature,
                                                })
                                            }
                                        />
                                    </div>
                                </ValidatorForm>

                                <WhatsAppSession />
                            </div>
                        </GridItem6sm12xs12>
                    </GridContainerLogin>
                </CardGridEntradaDados>
            </GridItem7sm10xs10>
        </GridContainer>
    )
}

export default Signup
