// alterado - revisado

import HARDCODE from '../../business-const/HardCode/HardCode'

import {
    formataData,
} from '../../business-components/Date/Date'

import {
    formataCpf,
    formataTelefone,
    formataCelular,
} from '../../business-components/Formata/Formata'

import {
    sortDesc
} from '../../business-components/Sort/Sort'

import {
    nomeCurtoCliente,
    nomeCurtoConjuge,
    nomeCurtoFamiliar,
} from '../String/nomeCurto'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../business-rules/List/ListService'

import sexoList from '../../business-rules/List/sexoList'

import parentescoList from '../../business-rules/List/parentescoList'

import grauInstrucaoList from '../../business-rules/List/grauInstrucaoList'

import tipoRegimeBensList from '../../business-rules/List/tipoRegimeBensList'

import tipoContatoList from '../../business-rules/List/tipoContatoList'

//import tipoIRList from '../../business-rules/List/tipoIRList'

import {
    dadosCompletosFamiliar,
} from '../../views/clientes/cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosCliente,
    dadosCompletosConjuge,
    calculaIdadesDataSimulacao,
} from '../../views/clientes/cliente-form/ClienteService'



const calculaContatosDescricao = ( id, contatos ) => {

    let contatosDescricao = ''

    let formato

    const separador = '<br/>&nbsp&nbsp&nbsp&nbsp&nbsp&nbsp'

    contatos?.forEach( (contato, ind) => {

        if (contato.familiar === id) {

            if (contato.contato) {

                contatosDescricao = contatosDescricao + separador
                
                formato = pesquisaList(tipoContatoList, contato.tipoContato).formato
                
                if (formato === "telef") {

                    contatosDescricao = contatosDescricao + formataTelefone(contato.contato)

                } else {

                    if (formato === "cel") {

                        contatosDescricao = contatosDescricao + formataCelular(contato.contato)

                    } else {

                        contatosDescricao = contatosDescricao + contato.contato
                    }
                }
            }
        }
    })

    return contatosDescricao.substring(5)
}



const familiaresList = ( values ) => {

    calculaIdadesDataSimulacao(values)

    let familiaresList = []

    let nomeCurtoClienteAux = nomeCurtoCliente(values)

    let nomeCurtoConjugeAux = nomeCurtoConjuge(values)

    if (dadosCompletosCliente(values)) {
        familiaresList.push ({
            id: HARDCODE.idFamiliarCliente,
            descricao: nomeCurtoClienteAux,
            ordem: HARDCODE.idFamiliarCliente,
            nome: values.nomeCliente,
            cpf: values.cpfCliente,
            cpfFormatado: formataCpf(values.cpfCliente),
            nascimento: values.nascimentoCliente,
            nascimentoFormatado: formataData(values.nascimentoCliente),
            idade: values.idadeCliente,
            sexo: values.sexoCliente,
            sexoDescricao: pesquisaDescricaoList(sexoList(false), values.sexoCliente),
            parentesco: '',
            grauInstrucao: values.grauInstrucaoCliente,
            grauInstrucaoDescricao: pesquisaDescricaoList(grauInstrucaoList, values.grauInstrucaoCliente),
            formacao: values.formacaoCliente,
            profissao: values.profissaoClienteOutras? values.profissaoClienteOutras : values.profissaoCliente,
            empresa: values.empresaCliente,
            tipoRegimeBens: values.tipoRegimeBens,
            tipoRegimeBensDescricao: pesquisaDescricaoList(tipoRegimeBensList, values.tipoRegimeBens),
            contatosDescricao: calculaContatosDescricao(HARDCODE.idFamiliarCliente, values.contatos),
            idadeVaiAposentar: values.idadeVaiAposentarCliente,
            idadeExpectativaVida: values.idadeExpectativaVidaCliente,
            idadeDataSimulacao: values.idadeDataSimulacaoCliente,
            idadeSimulacaoMorte: values.idadeSimulacaoMorteCliente,
            receitaMensal: values.receitaMensalCliente,
            percResp: values.percRespCliente,
            //tipoIR: values.tipoIRCliente,
            //tipoIRDescricao: pesquisaDescricaoList(tipoIRList, values.tipoIRCliente),
            avatar: values.avatarCliente,
        })
    }

    if (dadosCompletosConjuge(values, true)) {
        familiaresList.push ({
            id: HARDCODE.idFamiliarConjuge,
            descricao: nomeCurtoConjugeAux,
            ordem: HARDCODE.idFamiliarConjuge,
            nome: values.nomeConjuge,
            cpf: values.cpfConjuge,
            cpfFormatado: formataCpf(values.cpfConjuge),
            nascimento: values.nascimentoConjuge,
            nascimentoFormatado: formataData(values.nascimentoConjuge),
            idade: values.idadeConjuge,
            sexo: values.sexoConjuge,
            sexoDescricao: pesquisaDescricaoList(sexoList(false), values.sexoConjuge),
            parentesco: '',
            grauInstrucao: values.grauInstrucaoConjuge,
            grauInstrucaoDescricao: pesquisaDescricaoList(grauInstrucaoList, values.grauInstrucaoConjuge),
            formacao: values.formacaoConjuge,
            profissao: values.profissaoConjugeOutras ? values.profissaoConjugeOutras : values.profissaoConjuge,
            empresa: values.empresaConjuge,
            tipoRegimeBens: '',
            tipoRegimeBensDescricao: '',
            contatosDescricao: calculaContatosDescricao(HARDCODE.idFamiliarConjuge, values.contatos),
            idadeVaiAposentar: values.idadeVaiAposentarConjuge,
            idadeExpectativaVida: values.idadeExpectativaVidaConjuge,
            idadeDataSimulacao: values.idadeDataSimulacaoConjuge,
            idadeSimulacaoMorte: values.idadeSimulacaoMorteConjuge,
            receitaMensal: values.receitaMensalConjuge,
            percResp: values.percRespConjuge,
            //tipoIR: values.tipoIRConjuge,
            //tipoIRDescricao: pesquisaDescricaoList(tipoIRList, values.tipoIRConjuge),
            avatar: values.avatarConjuge,
        })
    }

    let familiaresAux = []

    values.familiares?.forEach((item, ind) => {
        familiaresAux.push({
            familiar: item.familiar,
            nomeCurtoFamiliarAux: nomeCurtoFamiliar(item),
            ordem:
                (
                    item.parentescoFamiliar === HARDCODE.idParentescoFilhao ||
                    item.parentescoFamiliar === HARDCODE.idParentescoEnteadao
                )
                    ?
                        10000 - item.idadeFamiliar
                    :
                        1000 - item.familiar,
            nomeFamiliar: item.nomeFamiliar,
            nomeCurtoFamiliar: item.nomeCurtoFamiliar,
            cpfFamiliar: item.cpfFamiliar,
            nascimentoFamiliar: item.nascimentoFamiliar,
            idadeFamiliar: item.idadeFamiliar,
            sexoFamiliar: item.sexoFamiliar,
            parentescoFamiliar: item.parentescoFamiliar,
            idadeFamiliarDataSimulacao: item.idadeFamiliarDataSimulacao,
        })
    })
    
    familiaresAux = sortDesc(familiaresAux)

    familiaresAux?.forEach((item, ind) => {
        if (dadosCompletosFamiliar(item)) {

            familiaresList.push ({
                id: item.familiar,
                descricao: item.nomeCurtoFamiliarAux,
                ordem: item.familiar,
                nome: item.nomeFamiliar,
                cpf: item.cpfFamiliar,
                cpfFormatado: formataCpf(item.cpfFamiliar),
                nascimento: item.nascimentoFamiliar,
                nascimentoFormatado: formataData(item.nascimentoFamiliar),
                idade: item.idadeFamiliar,
                sexo: item.sexoFamiliar,
                sexoDescricao: pesquisaDescricaoList(sexoList(false), item.sexoFamiliar),
                parentesco: item.parentescoFamiliar,
                parentescoDescricao: pesquisaDescricaoList(parentescoList(false), item.parentescoFamiliar),
                grauInstrucao: '',
                grauInstrucaoDescricao: pesquisaDescricaoList(grauInstrucaoList, item.grauInstrucao),
                formacao: '',
                profissao: '',
                empresa: '',
                tipoRegimeBens: '',
                tipoRegimeBensDescricao: '',
                contatosDescricao: calculaContatosDescricao(item.familiar, values.contatos),
                idadeVaiAposentar: '',
                idadeExpectativaVida: '',
                idadeDataSimulacao: item.idadeFamiliarDataSimulacao,
                idadeSimulacaoMorte: '',
                receitaMensal: '',
                percResp: '',
                //tipoIR: '',
                //tipoIRDescricao: '',
            })
        }
    })

    return familiaresList
}

export default familiaresList