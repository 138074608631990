import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    round
} from '../../../business-components/round/round'



export const calculaTaxaRetornoLiquida = ( taxaJurosAnual, taxaInflacaoAnual, tipoMomentoTributacaoIR,
    percIR, tempoMinimoEntreAportesResgatesCalculoIRAnos ) => {

    if (
            tipoMomentoTributacaoIR && 
            (
                tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRComeCotas ||
                tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRVGBL ||
                tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRResgate
            ) &&
            (
                percIR || percIR === 0
            )
    ) {

        let tempoMinimoEntreAportesResgatesCalculoIRMeses

        if (tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRComeCotas) {

            tempoMinimoEntreAportesResgatesCalculoIRMeses = 6

        } else {

            if (
                    (
                        tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRVGBL ||
                        tipoMomentoTributacaoIR === HARDCODE.tipoMomentoTributacaoIRResgate
                    ) &&
                    tempoMinimoEntreAportesResgatesCalculoIRAnos
            ) {

                tempoMinimoEntreAportesResgatesCalculoIRMeses = tempoMinimoEntreAportesResgatesCalculoIRAnos * 12
            }
        }

        if (tempoMinimoEntreAportesResgatesCalculoIRMeses) {

            const taxaJurosMensal =
                (Math.pow(1 + ((((Math.pow(1 + (converteTaxaAnualEmMensal(taxaJurosAnual) / 100),
                    tempoMinimoEntreAportesResgatesCalculoIRMeses) -1) * 100) * (100 - percIR) / 100) / 100),
                        1 / tempoMinimoEntreAportesResgatesCalculoIRMeses) - 1) * 100

            taxaJurosAnual = converteTaxaMensalEmAnual(taxaJurosMensal)
        }
    }

    return round((((1 + (taxaJurosAnual / 100)) / (1 + (taxaInflacaoAnual / 100))) - 1) * 100, 2)
}

export const calculaTaxaJurosAnual = ( taxaRetornoAnual, taxaInflacaoAnual ) => {

    return round((((1 + (taxaRetornoAnual / 100)) * (1 + (taxaInflacaoAnual / 100))) - 1) * 100, 2)
}

export const converteTaxaAnualEmMensal = ( taxaAnual ) => {

    return (Math.pow(1 + (taxaAnual / 100), 1 / 12) - 1) * 100
}

export const converteTaxaMensalEmAnual = ( taxaMensal ) => {

    return (Math.pow(1 + (taxaMensal / 100), 12) - 1) * 100
}

export const converteTaxaAnualEmPeriodicidade = ( taxaAnual, fatorConversaoMensal ) => {

    return (Math.pow(1 + (taxaAnual / 100), fatorConversaoMensal / 12) - 1) * 100
}

export const calculaValorPresenteValorFuturo = ( valorFuturo, taxaMensal, qtdeMeses ) => {
    
    return  valorFuturo / Math.pow(1 + (taxaMensal / 100), qtdeMeses)
}

export const corrigeInflacaoAnual = ( valor, taxaInflacaoAnual, qtdeAnos ) => {
    
    return valor * Math.pow(1 + (taxaInflacaoAnual / 100), qtdeAnos)
}