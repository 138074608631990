import HARDCODE from '../../../business-const/HardCode/HardCode'



const statusCoberturaList = [
    {
        id: HARDCODE.idStatusCoberturaAtivaPagandoPremio,
        descricao: 'Ativa - Pagando Prêmio',
        ativa: 'S',
        ordem: 10,
    },
    {
        id: HARDCODE.idStatusCoberturaAtivaPremioPago,
        descricao: 'Ativa - Prêmio Pago',
        ativa: 'S',
        ordem: 20,
    },
    {
        id: HARDCODE.idStatusCoberturaAtivaPremioDispensado,
        descricao: 'Ativa - Prêmio Dispensado',
        ativa: 'S',
        ordem: 25,
    },
    {
        id: HARDCODE.idStatusCoberturaExpirada,
        descricao: 'Expirada',
        ativa: 'N',
        ordem: 30,
    },
    {
        id: HARDCODE.idStatusCoberturaConvertida,
        descricao: 'Convertida',
        ativa: 'N',
        ordem: 40,
    },
    {
        id: HARDCODE.idStatusCoberturaResgatada,
        descricao: 'Resgatada',
        ativa: 'N',
        ordem: 50,
    },
    {
        id: HARDCODE.idStatusCoberturaSaldada,
        descricao: 'Saldada',
        ativa: 'N',
        ordem: 55,
    },
    {
        id: HARDCODE.idStatusCoberturaCancelada,
        descricao: 'Cancelada',
        ativa: 'N',
        ordem: 60,
    },
    {
        id: HARDCODE.idStatusCoberturaSinistroEmAnalise,
        descricao: 'Sinistro em Análise',
        ativa: 'S',
        ordem: 70,
    },
    {
        id: HARDCODE.idStatusCoberturaBeneficioPago,
        descricao: 'Benefício Pago',
        ativa: 'N',
        ordem: 80,
    },
    {
        id: HARDCODE.idStatusCoberturaRejeitada,
        descricao: 'Rejeitada',
        ativa: 'N',
        ordem: 90,
    },
    {
        id: HARDCODE.idStatusCoberturaSubstituida,
        descricao: 'Substituída',
        ativa: 'N',
        ordem: 100,
    },
    {
        id: HARDCODE.idStatusCoberturaAnulada,
        descricao: 'Anulada',
        ativa: 'N',
        ordem: 110,
    },
]

export default statusCoberturaList