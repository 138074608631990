export const sortDesc = (lista) => {
    
    return lista.sort(function(a, b) {
        if (a.ordem > b.ordem) {
          return -1
        } 
        else 
        {
          return true
        }
    })
}

export const sortAsc = (lista) => {
    
    return lista.sort(function(a, b) {
        if (a.ordem < b.ordem) {
          return -1
        } 
        else 
        {
          return true
        }
    })
}

export const sortAscId = (lista) => {
    
  return lista.sort(function(a, b) {
      if (a.id < b.id) {
        return -1
      } 
      else 
      {
        return true
      }
  })
}

export const sortAscFamiliar = (lista) => {
    
  return lista.sort(function(a, b) {
      if (a.familiar < b.familiar) {
        return -1
      } 
      else 
      {
        return true
      }
  })
}