import HARDCODE from '../../business-const/HardCode/HardCode'



const tipoPeriodicidadeList = (listaCompleta, incluirOpcaoSinglePremium) => {
    
    const tipoPeriodicidadeListAux = [
        {
            id: HARDCODE.idTipoPeriodicidadeMensal, // HARDCODEBACKEND.idTipoPeriodicidadeMensal
            name: HARDCODE.idTipoPeriodicidadeMensal,
            descricao: 'Mensal',
            label: 'Mensal',
            descricaoTempo: '',
            fatorConversaoMensal: 1, // HARDCODEBACKEND.fatorConversaoMensalMensal
            mesesFluxoCaixa: [
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
                [{id: 1}, {id: 2}, {id: 3}, {id: 4}, {id: 5}, {id: 6}, {id: 7}, {id: 8}, {id: 9}, {id: 10}, {id: 11}, {id: 12}],
            ],
            ordem: 10,
        },
        {
            id: HARDCODE.idTipoPeriodicidadeAnual, // HARDCODEBACKEND.idTipoPeriodicidadeAnual
            name: HARDCODE.idTipoPeriodicidadeAnual,
            descricao: 'Anual',
            label: 'Anual',
            descricaoTempo: 'anos',
            fatorConversaoMensal: 12, // HARDCODEBACKEND.fatorConversaoMensalAnual
            mesesFluxoCaixa: [
                [{id: 1}],
                [{id: 2}],
                [{id: 3}],
                [{id: 4}],
                [{id: 5}],
                [{id: 6}],
                [{id: 7}],
                [{id: 8}],
                [{id: 9}],
                [{id: 10}],
                [{id: 11}],
                [{id: 12}],
            ],
            ordem: 20,
        },
        {
            id: HARDCODE.idTipoPeriodicidadeSemestral, // HARDCODEBACKEND.idTipoPeriodicidadeSemestral
            name: HARDCODE.idTipoPeriodicidadeSemestral,
            descricao: 'Semestral',
            label: 'Semestral',
            descricaoTempo: 'semestres',
            fatorConversaoMensal: 6, // HARDCODEBACKEND.fatorConversaoMensalSemestral
            mesesFluxoCaixa: [
                [{id: 1},  {id: 7}],
                [{id: 2},  {id: 8}],
                [{id: 3},  {id: 9}],
                [{id: 4},  {id: 10}],
                [{id: 5},  {id: 11}],
                [{id: 6},  {id: 12}],
                [{id: 7},  {id: 1}],
                [{id: 8},  {id: 2}],
                [{id: 9},  {id: 3}],
                [{id: 10}, {id: 4}],
                [{id: 11}, {id: 5}],
                [{id: 12}, {id: 6}],
            ],
            ordem: 30,
        },
        {
            id: 'Q', // HARDCODEBACKEND.idTipoPeriodicidadeQuadrimestral
            name: 'Q',
            descricao: 'Quadrimestral',
            label: 'Quadrimestral',
            descricaoTempo: 'quadrimestres',
            fatorConversaoMensal: 4, // HARDCODEBACKEND.fatorConversaoMensalQuadrimestral
            mesesFluxoCaixa: [
                [{id: 1},  {id: 5},  {id: 9}],
                [{id: 2},  {id: 6},  {id: 10}],
                [{id: 3},  {id: 7},  {id: 11}],
                [{id: 4},  {id: 8},  {id: 12}],
                [{id: 5},  {id: 9},  {id: 1}],
                [{id: 6},  {id: 10}, {id: 2}],
                [{id: 7},  {id: 11}, {id: 3}],
                [{id: 8},  {id: 12}, {id: 4}],
                [{id: 9},  {id: 1},  {id: 5}],
                [{id: 10}, {id: 2},  {id: 6}],
                [{id: 11}, {id: 3},  {id: 7}],
                [{id: 12}, {id: 4},  {id: 8}],
            ],
            ordem: 40,
        },
        {
            id: 'T', // HARDCODEBACKEND.idTipoPeriodicidadeTrimestral
            name: 'T',
            descricao: 'Trimestral',
            label: 'Trimestral',
            descricaoTempo: 'trimestres',
            fatorConversaoMensal: 3, // HARDCODEBACKEND.fatorConversaoMensalTrimestral
            mesesFluxoCaixa: [
                [{id: 1},  {id: 4},  {id: 7},  {id: 10}],
                [{id: 2},  {id: 5},  {id: 8},  {id: 11}],
                [{id: 3},  {id: 6},  {id: 9},  {id: 12}],
                [{id: 4},  {id: 7},  {id: 10}, {id: 1}],
                [{id: 5},  {id: 8},  {id: 11}, {id: 2}],
                [{id: 6},  {id: 9},  {id: 12}, {id: 3}],
                [{id: 7},  {id: 10}, {id: 1},  {id: 4}],
                [{id: 8},  {id: 11}, {id: 2},  {id: 5}],
                [{id: 9},  {id: 12}, {id: 3},  {id: 6}],
                [{id: 10}, {id: 1},  {id: 4},  {id: 7}],
                [{id: 11}, {id: 2},  {id: 5},  {id: 8}],
                [{id: 12}, {id: 3},  {id: 6},  {id: 9}],
            ],
            ordem: 50,
        },
        {
            id: 'B', // HARDCODEBACKEND.idTipoPeriodicidadeBimestral
            name: 'B',
            descricao: 'Bimestral',
            label: 'Bimestral',
            descricaoTempo: 'bimestres',
            fatorConversaoMensal: 2, // HARDCODEBACKEND.fatorConversaoMensalBimestral
            mesesFluxoCaixa: [
                [{id: 1},  {id: 3},  {id: 5},  {id: 7},  {id: 9},  {id: 11}],
                [{id: 2},  {id: 4},  {id: 6},  {id: 8},  {id: 10}, {id: 12}],
                [{id: 3},  {id: 5},  {id: 7},  {id: 9},  {id: 11}, {id: 1}],
                [{id: 4},  {id: 6},  {id: 8},  {id: 10}, {id: 12}, {id: 2}],
                [{id: 5},  {id: 7},  {id: 9},  {id: 11}, {id: 1},  {id: 3}],
                [{id: 6},  {id: 8},  {id: 10}, {id: 12}, {id: 2},  {id: 4}],
                [{id: 7},  {id: 9},  {id: 11}, {id: 1},  {id: 3},  {id: 5}],
                [{id: 8},  {id: 10}, {id: 12}, {id: 2},  {id: 4},  {id: 6}],
                [{id: 9},  {id: 11}, {id: 1},  {id: 3},  {id: 5},  {id: 7}],
                [{id: 10}, {id: 12}, {id: 2},  {id: 4},  {id: 6},  {id: 8}],
                [{id: 11}, {id: 1},  {id: 3},  {id: 5},  {id: 7},  {id: 9}],
                [{id: 12}, {id: 2},  {id: 4},  {id: 6},  {id: 8},  {id: 10}],
            ],
            ordem: 60,
        },
    ]

    if (incluirOpcaoSinglePremium) {

        tipoPeriodicidadeListAux.push(
            {
                id: 'U', // HARDCODEBACKEND.idTipoPeriodicidadeUnica
                name: 'U',
                descricao: 'Única',
                label: 'Única',
                descricaoTempo: null,
                fatorConversaoMensal: 12, // HARDCODEBACKEND.fatorConversaoMensalUnica
                mesesFluxoCaixa: null,
                ordem: 70,
            },
        )
    }

    if (listaCompleta) {

        tipoPeriodicidadeListAux.push(
            {
                id: 'N', // HARDCODEBACKEND.idTipoPeriodicidadeNaoInformada
                name: 'N',
                descricao: 'Não Informada',
                label: 'Não Informada',
                descricaoTempo: null,
                fatorConversaoMensal: null,
                mesesFluxoCaixa: null,
                ordem: 80,
            },
        )
    }

    return tipoPeriodicidadeListAux
}

export default tipoPeriodicidadeList
