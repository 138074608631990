import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



class VwClienteAcessoAporteService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoAporte)
    }

    async buscarClienteList(variaveisGlobais, idClienteNULL, dataPrevistaAporteFinalNULL, statusAporteNULL) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        if (idClienteNULL) {
            
            params = `${params}&idCliente=${idClienteNULL}`
        }

        if (dataPrevistaAporteFinalNULL) {
        
            params = `${params}&dataPrevistaAporteFinal=${dataPrevistaAporteFinalNULL}`
        }

        if (statusAporteNULL) {
        
            params = `${params}&statusAporte=${statusAporteNULL}`
        }

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoAporteBuscarClienteList}/?${params}`, paramsAux, false)
    }
}

export default VwClienteAcessoAporteService