import ClienteService from '../../../business-api/ClienteService'

import ClienteAgenteService from '../../../business-api/ClienteAgenteService'

import VwClienteAgenteService from '../../../business-api/VwClienteAgenteService'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'



export const getClienteAgenteList = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAgenteService = new VwClienteAgenteService()

    try {
        const response = await vwClienteAgenteService.buscarClienteAgenteList(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getCountClienteAgenteTotal = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAgenteService = new VwClienteAgenteService()

    try {
        const response = await vwClienteAgenteService.countClienteAgenteTotal(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return {}
    }
}

export const getCountClienteAgente = async ( variaveisGlobais, campoAgrupamento, filtroList ) => {

    const vwClienteAgenteService = new VwClienteAgenteService()

    try {
        const response = await vwClienteAgenteService.countClienteAgente(variaveisGlobais, campoAgrupamento, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}



export const putClienteAgenteList = async ( variaveisGlobais, idArquivo, seguradora, jsonClienteAgenteList) => {

    const clienteAgenteService = new ClienteAgenteService()

    try {

        const response = await clienteAgenteService.incluirAtualizarClienteAgenteList(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idArquivo, seguradora,
                JSON.stringify(jsonClienteAgenteList))

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)

        return 0
    }
}