export const radioGroupFormListPrimeiraReuniaoAprofundamento = [
    {
        id: 'P', 
        descricao: '1ª Reunião',
        ordem: 10,
    },
    {
        id: 'A', 
        descricao: 'Aprofundamento',
        ordem: 20,
    },
]



export const radioGroupFormListSimplificadoCompleto = [
    {
        id: 'P', 
        descricao: 'Simulação Financeira',
        ordem: 10,
    },
    {
        id: 'A', 
        descricao: 'Estudo',
        ordem: 20,
    },
]