// alterado - revisado

import React from 'react'

import NumberFormat from 'react-number-format'

export const NumberFormatPadrao = props => {
    const { inputRef, onChange, ...other } = props
  
    return (
        <NumberFormat
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value
                    }
                })
            }}
            isNumericString
            //displayType={'text'}
            //renderText={(value, props) => <b>{value}</b>}
            //customInput={TextField}
            //removeFormatting={value => <b>{value}</b>}
            {...other}
        />
    );

}