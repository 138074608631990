import React, { useState, useContext } from 'react'

import useSingleton from '../../business-components/useSingleton/useSingleton'

import { ValidatorForm } from 'react-material-ui-form-validator'

import history from 'history.js'

import { useLocation } from "react-router-dom"

import AppContext from '../../contexts/AppContext'

import LABEL from '../../business-const/Label/Label'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import TAMMAX from '../../business-rules/TamMax/TamMax'

import EmailService from '../../business-api/EmailService'

import UsuarioService from '../../business-api/UsuarioService'

import {
    mensagemErroErroTexto,
} from '../../business-components/Toastr/Toastr'

import {
    CardGridEntradaDados,
} from '../../business-components/Card/Card'

import {
    GridContainer,
    GridContainerLogin,
    GridItem1sm3xs3,
    GridItem4sm12xs12,
    GridItem6sm12xs12,
    GridItem7sm10xs10,
    GridItem7sm12xs12,
} from '../../business-components/Grid/Grid'

import {
    TextValidatorText,
    TextValidatorEmail,
    TextValidatorSenha,
} from '../../business-components/TextValidator/TextValidator'

import {
    ButtonSalvarSenha,
    ButtonCancelar,
} from '../../business-components/Button/Button'

import {
    CircularProgressPadrao,
} from '../../business-components/CircularProgress/CircularProgress'

import {
    LinkPadraoSubmitEnviarPinEmail,
} from '../../business-components/Link/Link'

//import {
//    ImgLogoSigninBackground,
//} from '../../business-components/Img/Img'

import {
    senhaOK,
} from './SessionService'

import {
    LogoSession,
    TituloSession,
    PadraoSenha,
    WhatsAppSession,
} from './SessionComp'



const ResetSenha = () => {

    const location = useLocation()

    const [loadingButtonSalvarSenha, setLoadingButtonSalvarSenha] = useState(false)

    const [loadingLinkEnviarPinEmail, setLoadingLinkEnviarPinEmail] = useState(false)

    const [state, setState] = useState({})

    const [signature, setSignature] = useState(null)

    const [message, setMessage] = useState('')

    const { variaveisGlobais } = useContext(AppContext)

    const emailService = new EmailService()

    const usuarioService = new UsuarioService()

    useSingleton(() => {
        setState({
            ...state, 
            email: location.email
        })

        setSignature(location.signature)
    })

    const handleChange = ({ target: { name, value } }) => {
        setState({
            ...state,
            [name]: value,
        })
        setMessage('')
    }

    const handleFormSubmit = async (event) => {

        if (state.senha === state.repetirSenha) {

            if (state.email === state.emailEnviado) {
            
                setLoadingButtonSalvarSenha(true)

                try {
                    await usuarioService.atualizarSenha(variaveisGlobais,
                        {
                            usuarioDTO: {
                                email: state.email,
                                senha: state.senha
                            },
                            pin: state.pin
                        }
                    )

                    history.push({
                        pathname: PATH.pageSignin,
                        email: state.email,
                        signature: signature,
                    })

                } catch (erro) {

                    setMessage(mensagemErroErroTexto(erro))
                    setLoadingButtonSalvarSenha(false)
                }

            } else {

                setMessage(MESSAGE().emailDiferenteEnviado)
            }

        } else {

            setMessage(MESSAGE().senhasDifererentes)
        }
    }
    
    const informouDadosNovaSenha = () => {

        return state.email && state.senha && state.repetirSenha
    }

    const enviarPinEmail = async () => {

        setLoadingLinkEnviarPinEmail(true)

        try {
            await emailService.resetSenhaUsuario(variaveisGlobais, state.email)

            setLoadingLinkEnviarPinEmail(false)

            setMessage(MESSAGE().pinEnviadoComSucesso)

            setState({
                ...state,
                emailEnviado: state.email,
            })

        } catch (erro) {

            setMessage(mensagemErroErroTexto(erro))

            setLoadingLinkEnviarPinEmail(false)
        }
    }

    let { email, senha, repetirSenha, pin } = state

    return (
        <GridContainer
            className="justify-center items-center max-w-full min-h-full-screen bg-login"
        >
            {/*
            <ImgLogoSigninBackground
                variaveisGlobais={variaveisGlobais}
                values={null}
            />
            */}

            <GridItem7sm10xs10
                className="position w-full"
            >
                <CardGridEntradaDados>
                    <GridContainerLogin>
                        <GridItem6sm12xs12>
                            <LogoSession
                                variaveisGlobais={variaveisGlobais}
                            />
                        </GridItem6sm12xs12>
                        <GridItem6sm12xs12>
                            <div
                                className="p-6 h-full"
                            >
                                <TituloSession
                                    titulo={LABEL.esqueceuSenha}
                                />

                                <ValidatorForm
                                    onSubmit={handleFormSubmit}
                                >
                                    <TextValidatorEmail
                                        name="email"
                                        label={LABEL.email}
                                        value={email || ''}
                                        onChange={handleChange}
                                    />

                                    <TextValidatorSenha
                                        name="senha"
                                        label={LABEL.senha}
                                        value={senha || ''}
                                        onChange={handleChange}
                                    />

                                    <PadraoSenha
                                        senha={senha}
                                    />

                                    <TextValidatorSenha
                                        name="repetirSenha"
                                        label={LABEL.repetirSenha}
                                        value={repetirSenha || ''}
                                        onChange={handleChange}
                                    />

                                    {informouDadosNovaSenha()
                                        ?
                                            <GridContainer>
                                                <GridItem4sm12xs12>
                                                    <TextValidatorText
                                                        name="pin"
                                                        label={LABEL.pin}
                                                        value={pin || ''}
                                                        onChange={handleChange}
                                                        tamMax={TAMMAX.pin}
                                                    />
                                                </GridItem4sm12xs12>

                                                <GridItem1sm3xs3 />

                                                <GridItem7sm12xs12>
                                                    <div
                                                        className="relative"
                                                    >
                                                        <LinkPadraoSubmitEnviarPinEmail
                                                            onClick={enviarPinEmail}
                                                            disabled={loadingLinkEnviarPinEmail}
                                                        />
                                                        {loadingLinkEnviarPinEmail && (
                                                            <CircularProgressPadrao />
                                                        )}
                                                    </div>
                                                </GridItem7sm12xs12>
                                            </GridContainer>
                                        :
                                            ''
                                    }

                                    {message && (
                                        <p
                                            className="text-error"
                                        >
                                            {message}
                                        </p>
                                    )}

                                    <div
                                        className="flex items-center"
                                    >
                                        <div
                                            className="relative"
                                        >
                                            <ButtonSalvarSenha
                                                disabled={
                                                    loadingButtonSalvarSenha ||
                                                    !informouDadosNovaSenha() ||
                                                    !pin ||
                                                    !senhaOK(senha)
                                                }
                                            />
                                            {loadingButtonSalvarSenha && (
                                                <CircularProgressPadrao />
                                            )}
                                        </div>

                                        <GridItem1sm3xs3 />

                                        <ButtonCancelar
                                            onClick={() =>
                                                history.push({
                                                    pathname: PATH.pageSignin,
                                                    email: state.email,
                                                    signature: signature,
                                                })
                                            }
                                        />
                                    </div>
                                </ValidatorForm>

                                <WhatsAppSession />
                            </div>
                        </GridItem6sm12xs12>
                    </GridContainerLogin>
                </CardGridEntradaDados>
            </GridItem7sm10xs10>
        </GridContainer>
    )
}

export default ResetSenha
