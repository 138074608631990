import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    pesquisaList,
    pesquisaIndexList,
} from '../../../business-rules/List/ListService'



const incluiList = (listsAux, list, agrupamento) => {

    const linhaList = list ? pesquisaList(list, agrupamento) : {descricao: agrupamento, ordem: agrupamento}

    listsAux.push(
        {
            id: agrupamento,
            value: agrupamento,
            descricao: linhaList.descricao,
            label: linhaList.descricao,
            ordem: linhaList.ordem,            
        }
    )

    return listsAux
}

export const calculaFiltroLists = (lists, list, id, filtroList) => {

    let listsAux = []

    lists?.forEach( (linhaLists, ind) => {

        if (!pesquisaList(listsAux, linhaLists.agrupamento).id) {

            listsAux = incluiList(listsAux, list, linhaLists.agrupamento) 
        }
    })

    const linhaFiltroList = pesquisaList(filtroList, id)

    if (linhaFiltroList.type === HARDCODE.typeListString && linhaFiltroList.idListString) {

        if (!pesquisaList(listsAux, linhaFiltroList.idListString).id) {

            listsAux = incluiList(listsAux, list, linhaFiltroList.idListString) 
        }
    }

    if (linhaFiltroList.type === HARDCODE.typeListMultiSelectInteiro) {
        
        linhaFiltroList.idListMultiSelectInteiro?.forEach( (linhaIdListMultiSelectInteiro, ind) => {

            if (!pesquisaList(listsAux, linhaIdListMultiSelectInteiro.id).id) {

                listsAux = incluiList(listsAux, list, linhaIdListMultiSelectInteiro.id) 
            }
        })
    }

    if (linhaFiltroList.type === HARDCODE.typeListMultiSelectString) {

        linhaFiltroList.idListMultiSelectString?.forEach( (linhaIdListMultiSelectString, ind) => {

            if (!pesquisaList(listsAux, linhaIdListMultiSelectString.id).id) {

                listsAux = incluiList(listsAux, list, linhaIdListMultiSelectString.id) 
            }
        })
    }

    return listsAux
}

export const calculaFiltroList = ( grafico, camposList, filtroList, filtroLists, item ) => {

    let filtroListAux = []

    camposList([], null, grafico, filtroList, filtroLists)?.forEach( (linhaCamposList, ind) => {

        if (
                linhaCamposList.filtro === 'S' &&
                (
                    linhaCamposList.id === item.id ||
                    (
                        !pesquisaList(filtroList, linhaCamposList.id).id &&
                        (
                            !linhaCamposList.list ||
                            linhaCamposList.list.length > 1
                        )
                    )
                )
        ) {

            filtroListAux.push({
                id: linhaCamposList.name,
                descricao: linhaCamposList.label,
                type: linhaCamposList.type,
                tamMax: linhaCamposList.tamMax,
                numMin: linhaCamposList.numMin,
                numMax: linhaCamposList.numMax,
                list: linhaCamposList.list,
                ordem: linhaCamposList.label, /*linhaCamposList.ordem,*/
            })
        }
    })

    return filtroListAux
}

export const removeFiltroList = (filtroList, setRefresh, refresh, ind) => {

    filtroList.splice(ind, 1)

    setRefresh(!refresh)
}

export const objetoFiltroList = (id, dataInicial, dataFinal, idListString, camposList) => {

    let type = ''

    if (id) {

        type = pesquisaList(camposList([], null, false, [], []), id).type
    }

    return {
        id: id,
        type: type,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
        idListString: idListString,
        idListMultiSelectInteiro: [],
        idListMultiSelectString: [],
    }
}

export const incluiFiltroList = (camposList, filtroList, setRefresh, refresh, id) => {

    filtroList.push(
        objetoFiltroList(id, null, null, '', camposList)
    )

    if (setRefresh) {
    
        setRefresh(!refresh)
    }
}

export const incluiFiltroListSelecaoGrafico = (campoAgrupamento, filtro, filtroList, filtroLists, setRefresh, refresh, camposList, carregarDados) => {

    const linhaCamposList = pesquisaList(camposList([], null, false, filtroList, filtroLists), campoAgrupamento)

    if (!pesquisaList(filtroList, campoAgrupamento).id) {

        incluiFiltroList(camposList, filtroList, setRefresh, refresh, campoAgrupamento)
    }

    const indexFiltroList = pesquisaIndexList(filtroList, campoAgrupamento)

    switch (linhaCamposList.type) {
        case HARDCODE.typeData:

            filtroList[indexFiltroList].dataInicial = filtro
            filtroList[indexFiltroList].dataFinal = filtro

            break
    
        case HARDCODE.typeValor:

            filtroList[indexFiltroList].valorInicial = filtro
            filtroList[indexFiltroList].valorFinal = filtro

            break
        
        case HARDCODE.typePercentual:

            filtroList[indexFiltroList].percentualInicial = filtro
            filtroList[indexFiltroList].percentualFinal = filtro

            break

        case HARDCODE.typeInteiro:

            filtroList[indexFiltroList].inteiroInicial = filtro
            filtroList[indexFiltroList].inteiroFinal = filtro

            break

        case HARDCODE.typeString:

            filtroList[indexFiltroList].string = filtro

            break
        
        case HARDCODE.typeStringNotUpperCase:

            filtroList[indexFiltroList].string = filtro

            break

        case HARDCODE.typeStringLike :

            filtroList[indexFiltroList].string = filtro
    
            break

        case HARDCODE.typeDiaMes :

            filtroList[indexFiltroList].diaMesInicial = filtro
            filtroList[indexFiltroList].diaMesFinal = filtro
    
            break

        case HARDCODE.typeCpf:

            filtroList[indexFiltroList].cpf = filtro

            break

        case HARDCODE.typeCelular:

            filtroList[indexFiltroList].celular = filtro

            break
        
        case HARDCODE.typeEmail:

            filtroList[indexFiltroList].email = filtro

            break

        case HARDCODE.typeListString:

            filtroList[indexFiltroList].idListString = filtro

            break

        case HARDCODE.typeListMultiSelectInteiro:

            if (!pesquisaList(filtroList[indexFiltroList].idListMultiSelectInteiro, filtro).id) {

                filtroList[indexFiltroList].idListMultiSelectInteiro.push(pesquisaList(linhaCamposList.list, filtro))
            }

            break

        case HARDCODE.typeListMultiSelectString:

            if (!pesquisaList(filtroList[indexFiltroList].idListMultiSelectString, filtro).id) {

                filtroList[indexFiltroList].idListMultiSelectString.push(pesquisaList(linhaCamposList.list, filtro))
            }

            break

        default:
            
            break
    }

    carregarDados()
}