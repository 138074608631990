import HARDCODE from '../business-const/HardCode/HardCode'

import ApiService from './ApiService'

import AuthService from './AuthService'



class ContaInvestimentosService extends ApiService {

    constructor() {
        super(HARDCODE.apiContaInvestimentos)
    }

    buscarContaInvestimentos(variaveisGlobais, instituicaoFinanceira, numeroConta) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&instituicaoFinanceira=${instituicaoFinanceira}`

        params = `${params}&numeroConta=${numeroConta}`

        return this.get(variaveisGlobais, `${HARDCODE.apiContaInvestimentosBuscarContaInvestimentos}/?${params}`, false)
    }
}

export default ContaInvestimentosService