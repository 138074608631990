import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class VwClienteAcessoApoliceComissaoService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoApoliceComissao)
    }

    async buscarApoliceComissaoList(variaveisGlobais, filtroList) {

        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceComissaoBuscarApoliceComissaoList}/?${params}`,
            paramsAux, false)
    }
    
    async sumApoliceComissao(variaveisGlobais, campoAgrupamento, filtroList) {

        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, true)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoApoliceComissaoSumApoliceComissao}/?${params}`,
            paramsAux, false)
    }
}

export default VwClienteAcessoApoliceComissaoService