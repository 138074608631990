import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    difMesAnoMeses,
    mes,
    ano,
    mesMesAno,
    anoMesAno,
    formataMesAnoComBarra,
    formataMesAnoSemBarra,
    formataMesAnoTextoComBarra,
    calculaMeses,
    calculaMesAnoFinal,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosTexto,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosValorDestaque2Valores,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
 } from '../../../business-rules/List/ListService'

import tipoProjetoVidaSonhoList from '../../../business-rules/List/tipoProjetoVidaSonhoList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import familiaList from '../../../business-rules/List/familiaList'

import {
    calculaRound2NULLZero,
    calculaIntervaloTempoAnos,
} from '../cliente-form/ClienteService'

import {
    idadeFamiliarDataSimulacao,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosLinhaProjetoVidaSonho,
} from '../projetoVidaSonho-form/ProjetoVidaSonhoServiceProjetoVidaSonho'

import {
    converteTaxaAnualEmMensal,
    converteTaxaAnualEmPeriodicidade,
} from './EstudoServiceMatematicaFinanceira'

import {
    calculaGraficoAcumulacaoMensal,
} from './EstudoServiceCalculo'



const trataRecorrencia = (projetoVidaSonho) => {

    const projetoVidaSonhoAux = []

    let indexAux = 900000

    projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, indexProjetoVidaSonho) => {

        projetoVidaSonhoAux.push(
            {
                ...linhaProjetoVidaSonho,
                idOrigem: linhaProjetoVidaSonho.id,
                indexOrigem: indexProjetoVidaSonho,
                exibirComparacaoCenarios: true,
            }
        )

        if (linhaProjetoVidaSonho.recorrencia) {

            //const meses = calculaMeses(linhaProjetoVidaSonho.mesAnoInicioAcumulacao, linhaProjetoVidaSonho.mesAnoFimAcumulacao)

            let mesAnoFinalAnt = formataMesAnoSemBarra(linhaProjetoVidaSonho.mesRealizacao,
                anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao))

            let gerarRecorrencia = true

            let anual = false

            if (anoMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao) === anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)) {

                anual = true
            }

            let mesAnoInicial
            let mesAnoFinal

            let qtdeMesesPVSAnt = calculaMeses(linhaProjetoVidaSonho.mesAnoInicioAcumulacao, linhaProjetoVidaSonho.mesAnoFimAcumulacao)

            while (gerarRecorrencia) {

                if (anual) {

                    mesAnoInicial = formataMesAnoSemBarra(mesMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao),
                        anoMesAno(mesAnoFinalAnt) + 1)

                    mesAnoFinal = formataMesAnoSemBarra(mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao),
                        anoMesAno(mesAnoFinalAnt) + 1)

                } else {

                    mesAnoInicial = calculaMesAnoFinal(mesMesAno(mesAnoFinalAnt), anoMesAno(mesAnoFinalAnt), 2)

                    //mesAnoFinal = formataMesAnoSemBarra(mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao),
                    //    anoMesAno(mesAnoFinalAnt) + anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao) -
                    //        anoMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao) + 1)
                    mesAnoFinal = formataMesAnoSemBarra(mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao),
                        anoMesAno(mesAnoFinalAnt) + Math.trunc(difMesAnoMeses(mesMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao),
                            anoMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao),
                                mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao),
                                    anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)) / 12) + 1)
                }

                if (anoMesAno(mesAnoFinal) <= linhaProjetoVidaSonho.anoLimiteRecorrencia) {

                    projetoVidaSonhoAux.push(
                        {
                            ...linhaProjetoVidaSonho,
                            id: indexAux++,
                            idadeRealizacao:
                                linhaProjetoVidaSonho.idadeRealizacao
                                    ?
                                        linhaProjetoVidaSonho.idadeRealizacao + anoMesAno(mesAnoFinal) -
                                            anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)
                                    :
                                        '',
                            anoRealizacao:
                                linhaProjetoVidaSonho.anoRealizacao
                                    ?
                                        linhaProjetoVidaSonho.anoRealizacao + anoMesAno(mesAnoFinal) -
                                            anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)
                                    :
                                        '',
                            mesAnoInicioAcumulacao: mesAnoInicial,
                            mesAnoFimAcumulacao: mesAnoFinal,
                            valorAcumulacaoJaRealizado: 0,
                            idOrigem: linhaProjetoVidaSonho.id,
                            indexOrigem: null,
                            exibirComparacaoCenarios: qtdeMesesPVSAnt !== calculaMeses(mesAnoInicial, mesAnoFinal),
                            
                        }
                    )

                    mesAnoFinalAnt = formataMesAnoSemBarra(linhaProjetoVidaSonho.mesRealizacao,
                        anoMesAno(mesAnoFinal))

                    qtdeMesesPVSAnt = calculaMeses(mesAnoInicial, mesAnoFinal)

                } else {

                    gerarRecorrencia = false
                }
            }
        }
    })

    return projetoVidaSonhoAux
}

export const consideraProjetoVidaSonho = (values, linhaProjetoVidaSonho) => {

    return dadosCompletosLinhaProjetoVidaSonho(values, linhaProjetoVidaSonho) &&
        !pesquisaList(values.exclusaoFamiliares, linhaProjetoVidaSonho.familiar).id &&
        !pesquisaList(values.exclusaoDespesasGarantias,
            LABEL.projetoVidaSonho + '/' + (linhaProjetoVidaSonho.idOrigem ? linhaProjetoVidaSonho.idOrigem : linhaProjetoVidaSonho.id)).id
}

export const anoFinalProjetoVidaSonho = ( values, anoInicial, anoRealizacao, idadeRealizacao, familiar ) => {

    let anoFinal

    if (anoRealizacao) {

        anoFinal = anoRealizacao

    } else {

        anoFinal = anoInicial + idadeRealizacao - idadeFamiliarDataSimulacao(values, familiar)
    }

    return anoFinal
}

export const calculaTracarMeta = (values, linhaProjetoVidaSonho, mesInicial, anoInicial, anoFinal) => {

    let mesInicialAcumulacao = mesMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao)
    let anoInicialAcumulacao = anoMesAno(linhaProjetoVidaSonho.mesAnoInicioAcumulacao)

    let mesAnoInicialAcumulacao =
        formataMesAnoTextoComBarra(linhaProjetoVidaSonho.mesAnoInicioAcumulacao)

    if (
            anoInicialAcumulacao < anoInicial || 
            (
                anoInicialAcumulacao === anoInicial &&
                mesInicialAcumulacao < mesInicial
            )
    ) {

        mesInicialAcumulacao = mesInicial
        anoInicialAcumulacao = anoInicial

        mesAnoInicialAcumulacao =
            formataMesAnoComBarra(mesInicialAcumulacao, anoInicialAcumulacao)
    }

    const mesFinalAcumulacao = mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)
    const anoFinalAcumulacao = anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)

    const fatorConversaoMensal =
        pesquisaList(tipoPeriodicidadeList(false, false), linhaProjetoVidaSonho.tipoPeriodicidadeAporte).fatorConversaoMensal

    const fatorTaxaRetornoMensalReal = converteTaxaAnualEmMensal(values.taxaRetornoAnualReal) / 100

    const fatorTaxaRetornoPeriodicidadeAporteReal =
        converteTaxaAnualEmPeriodicidade(values.taxaRetornoAnualReal, fatorConversaoMensal) / 100

    let mesesAcumulacao = difMesAnoMeses(mesInicialAcumulacao, anoInicialAcumulacao, mesFinalAcumulacao, anoFinalAcumulacao)

    //const restoQtdeAportes = (mesesAcumulacao - 1) % fatorConversaoMensal
    const restoQtdeAportes = mesesAcumulacao % fatorConversaoMensal

    //const qtdeAportes = 1 + Math.trunc((mesesAcumulacao - 1) / fatorConversaoMensal)
    const qtdeAportes = mesesAcumulacao / fatorConversaoMensal

    let mesesPosAcumulacao = difMesAnoMeses(mesFinalAcumulacao, anoFinalAcumulacao,
        round(linhaProjetoVidaSonho.mesRealizacao, 0), anoFinal) - 2
        
    //if (restoQtdeAportes > 0) {

    //    mesesAcumulacao = mesesAcumulacao - restoQtdeAportes
    //    mesesPosAcumulacao = mesesPosAcumulacao + restoQtdeAportes
    //}

    return { mesInicialAcumulacao, anoInicialAcumulacao, mesAnoInicialAcumulacao, mesFinalAcumulacao, anoFinalAcumulacao,
        fatorTaxaRetornoMensalReal, fatorTaxaRetornoPeriodicidadeAporteReal, restoQtdeAportes, qtdeAportes, mesesAcumulacao, mesesPosAcumulacao }
}

const limpaDadosAdicionaisProjetoVidaSonho = (values, indexOrigem) => {

    values.projetoVidaSonho[indexOrigem].descricaoTipoProjetoVidaSonho = ''
    values.projetoVidaSonho[indexOrigem].nomeCurtoFamiliar = ''
    values.projetoVidaSonho[indexOrigem].valorAporteProjetoVidaSonho = ''
}



export const calculaProjetoVidaSonho = (values, estudo, report) => {
    let projetoVidaSonhoTemp = []
    let projetoVidaSonho = []
    let projetoVidaSonhoTracarMeta = []
    let panelDespesasTotaisProjetoVidaSonho

    if (values.projetoVidaSonho) {
        let descricaoTipoProjetoVidaSonho = null
        let nomeCurtoFamiliar = null

        let mesInicial = mes(values.dataSimulacaoEstudo)
        let anoInicial = ano(values.dataSimulacaoEstudo)
        let anoFinal
        
        let valor = 0
        let idadeAnoRealizacao
        let maiorAnoFinal = ''
        let anoMesOrdem
        let valorAcumulacaoJaRealizado = 0
        let valorAcumulacaoJaRealizadoVF = 0
        let valorAcumulacaoPendente = 0
        let valorAcumulacaoJaRealizadoExcedente = 0
        let valorAcumulacaoNecessarioVP
        let valorPresente
        let valorAporteProjetoVidaSonho
        let valorResponsabilidadeCliente = 0
        let valorResponsabilidadeConjuge = 0

        let valorTotal = 0
        let valorAcumulacaoJaRealizadoTotal = 0
        let valorAcumulacaoJaRealizadoVFTotal = 0
        let valorAcumulacaoPendenteTotal = 0
        let valorResponsabilidadeClienteTotal = 0
        let valorResponsabilidadeConjugeTotal = 0
        
        trataRecorrencia(values.projetoVidaSonho)?.forEach( (linhaProjetoVidaSonho, index) => {

            if (consideraProjetoVidaSonho(values, linhaProjetoVidaSonho)) {
                
                descricaoTipoProjetoVidaSonho =
                    linhaProjetoVidaSonho.descricao
                        ?
                            linhaProjetoVidaSonho.descricao
                        :
                            pesquisaDescricaoList(tipoProjetoVidaSonhoList, 
                                linhaProjetoVidaSonho.tipoProjetoVidaSonho)

                nomeCurtoFamiliar =
                    pesquisaDescricaoList(familiaList(values), 
                        linhaProjetoVidaSonho.familiar)

                valor = calculaRound2NULLZero(linhaProjetoVidaSonho.valor)

                valorAcumulacaoJaRealizado = 0
                valorAcumulacaoJaRealizadoVF = 0
                valorAcumulacaoPendente = valor

                idadeAnoRealizacao =
                    linhaProjetoVidaSonho.anoRealizacao
                        ?
                            linhaProjetoVidaSonho.anoRealizacao
                        :
                            linhaProjetoVidaSonho.idadeRealizacao

                anoFinal =
                    anoFinalProjetoVidaSonho(values, anoInicial, linhaProjetoVidaSonho.anoRealizacao,
                        linhaProjetoVidaSonho.idadeRealizacao, linhaProjetoVidaSonho.familiar)

                calculaGraficoAcumulacaoMensal(null, anoInicial, null, anoFinal, valorAcumulacaoPendente, 0, null,
                    estudo.current.graficoAnos, estudo.current.graficoProjetoVidaSonho,
                        estudo.current.graficoDespesasAcumuladas, values, estudo, 0, 0, true, null, null, null, null, null)

                anoMesOrdem = (anoFinal * 100) + 12
                            
                if (linhaProjetoVidaSonho.tracarMeta && !values.naoCalcularAportesNecessariosRealizacaoPVS) {

                    const { mesInicialAcumulacao, anoInicialAcumulacao, mesAnoInicialAcumulacao, mesFinalAcumulacao, anoFinalAcumulacao,
                        fatorTaxaRetornoMensalReal, fatorTaxaRetornoPeriodicidadeAporteReal, restoQtdeAportes, qtdeAportes, mesesAcumulacao,
                            mesesPosAcumulacao } = calculaTracarMeta(values, linhaProjetoVidaSonho, mesInicial, anoInicial, anoFinal)

                    if (restoQtdeAportes === 0 && mesesAcumulacao > 0 && mesesPosAcumulacao >= 0) {

                        anoMesOrdem = (anoFinal * 100) + linhaProjetoVidaSonho.mesRealizacao

                        valorAcumulacaoJaRealizado =
                            values.naoConsiderarValorAcumulacaoJaRealizadoPVS
                                ?
                                    0
                                :
                                    calculaRound2NULLZero(linhaProjetoVidaSonho.valorAcumulacaoJaRealizado)

                        valorAcumulacaoJaRealizadoVF =
                            round(valorAcumulacaoJaRealizado * Math.pow(
                                1 + fatorTaxaRetornoMensalReal, mesesAcumulacao + mesesPosAcumulacao), 2)

                        valorAcumulacaoPendente = valor - valorAcumulacaoJaRealizadoVF

                        if (fatorTaxaRetornoMensalReal === 0) {

                            if (valorAcumulacaoPendente < 0) {

                                valorAcumulacaoJaRealizadoExcedente = valorAcumulacaoPendente * -1

                                valorAporteProjetoVidaSonho = 0

                            } else {

                                valorAcumulacaoJaRealizadoExcedente = 0

                                valorAporteProjetoVidaSonho = round(valorAcumulacaoPendente / qtdeAportes, 2)
                            }
                        
                        } else {

                            if (valorAcumulacaoPendente < 0) {

                                valorAcumulacaoJaRealizadoExcedente = round((valorAcumulacaoPendente * -1) /
                                    Math.pow(1 + fatorTaxaRetornoMensalReal, mesesAcumulacao + mesesPosAcumulacao), 2)

                                valorAporteProjetoVidaSonho = 0

                            } else {

                                valorAcumulacaoJaRealizadoExcedente = 0

                                if (mesesPosAcumulacao > 0) {

                                    valorPresente = valorAcumulacaoPendente /
                                        Math.pow(1 + fatorTaxaRetornoMensalReal, mesesPosAcumulacao)
    
                                } else {
                                    
                                    valorPresente = valorAcumulacaoPendente
                                }

                                valorAporteProjetoVidaSonho = round(valorPresente *
                                    (fatorTaxaRetornoPeriodicidadeAporteReal /
                                        (Math.pow(1 + fatorTaxaRetornoPeriodicidadeAporteReal, qtdeAportes) - 1)) *
                                            (1 / (1 + fatorTaxaRetornoPeriodicidadeAporteReal)), 2)
                            }
                        }

                        if (valorAcumulacaoPendente < 0) {

                            valorAcumulacaoNecessarioVP = linhaProjetoVidaSonho.valorAcumulacaoJaRealizado - valorAcumulacaoJaRealizadoExcedente

                        } else {

                            valorAcumulacaoNecessarioVP = 0
                        }

                        calculaGraficoAcumulacaoMensal(mesInicialAcumulacao, anoInicialAcumulacao,
                            mesFinalAcumulacao, anoFinalAcumulacao, 0, valorAporteProjetoVidaSonho, null,
                                estudo.current.graficoAnos, estudo.current.graficoProjetoVidaSonhoAnualTotal,
                                    null, values, estudo, 0, 0, false, null, null, null, null, null)

                        projetoVidaSonhoTracarMeta.push(
                            {
                                id: linhaProjetoVidaSonho.id,
                                ordem: anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao) +
                                    ("0" + mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)).slice(-2) +
                                        ("0000" + linhaProjetoVidaSonho.familiar).slice(-4),
                                        //("000000000000000000000" + valor).slice(-21),
                                familiar: linhaProjetoVidaSonho.familiar,
                                nomeCurtoFamiliar: nomeCurtoFamiliar,
                                nomeCurtoFamiliarFormatado: formataTableCellBodyExibeDadosTitulo(nomeCurtoFamiliar, report),
                                descricao: descricaoTipoProjetoVidaSonho,
                                descricaoFormatado: formataTableCellBodyExibeDadosTitulo(descricaoTipoProjetoVidaSonho, report),
                                valor: formataTableCellBodyExibeDadosValor(valor, report),
                                valorAcumulacaoJaRealizadoExcedente: valorAcumulacaoJaRealizadoExcedente,
                                valorAcumulacaoNecessarioVP: valorAcumulacaoNecessarioVP,
                                valorAcumulacaoJaRealizadoVFValorAcumulacaoNecessarioVP:
                                    valorAcumulacaoJaRealizadoExcedente === 0
                                        ?
                                            formataTableCellBodyExibeDadosValor(valorAcumulacaoJaRealizadoVF, report)
                                        :
                                            formataTableCellBodyExibeDadosValorDestaque2Valores(valorAcumulacaoJaRealizadoVF,
                                                valorAcumulacaoNecessarioVP, report),
                                valorAcumulacaoPendente: formataTableCellBodyExibeDadosValor(valorAcumulacaoPendente, report),
                                idadeAnoRealizacao:
                                    formataTableCellBodyExibeDadosTexto(idadeAnoRealizacao, report),
                                mesesPosAcumulacao: mesesPosAcumulacao,
                                //mesAnoInicioAcumulacao:
                                //    formataTableCellBodyExibeDadosTexto(mesAnoInicialAcumulacao, report),
                                //mesAnoFimAcumulacao:
                                //    formataTableCellBodyExibeDadosTexto(
                                //        formataMesAnoTextoComBarra(linhaProjetoVidaSonho.mesAnoFimAcumulacao), report),
                                periodoAcumulacao: formataTableCellBodyExibeDadosTexto(mesAnoInicialAcumulacao
                                    + ' - ' + formataMesAnoTextoComBarra(linhaProjetoVidaSonho.mesAnoFimAcumulacao), report),
                                qtdeAportes: formataTableCellBodyExibeDadosTexto(qtdeAportes, report),
                                valorAporteProjetoVidaSonho: valorAporteProjetoVidaSonho,
                                valorAporteProjetoVidaSonhoFormatado:
                                    formataTableCellBodyExibeDadosValor(valorAporteProjetoVidaSonho, report),
                                exibirComparacaoCenarios: linhaProjetoVidaSonho.exibirComparacaoCenarios,
                            }
                        )

                        // Campos do controle de aportes: no caso dos Projetos de Vida/Sonho o campo valorAporteProjetoVidaSonho só é calculado
                        // quando é o cenário principal, em função deste campo poder ser diferente de um cenário para o outro
                        if (linhaProjetoVidaSonho.indexOrigem !== null) {

                            values.projetoVidaSonho[linhaProjetoVidaSonho.indexOrigem].descricaoTipoProjetoVidaSonho =
                                descricaoTipoProjetoVidaSonho
                            values.projetoVidaSonho[linhaProjetoVidaSonho.indexOrigem].nomeCurtoFamiliar =
                                nomeCurtoFamiliar

                            if (values.cenarioAtual === values.cenarioPrincipal) {
                            
                                values.projetoVidaSonho[linhaProjetoVidaSonho.indexOrigem].valorAporteProjetoVidaSonho =
                                    valorAporteProjetoVidaSonho
                            }
                        }

                    } else {

                        if (linhaProjetoVidaSonho.indexOrigem !== null) {

                            limpaDadosAdicionaisProjetoVidaSonho(values, linhaProjetoVidaSonho.indexOrigem)
                        }
                    }

                } else {

                    if (linhaProjetoVidaSonho.valorAcumulacaoJaRealizado) {

                        valorAcumulacaoJaRealizado =
                            values.naoConsiderarValorAcumulacaoJaRealizadoPVS
                                ?
                                    0
                                :
                                    calculaRound2NULLZero(linhaProjetoVidaSonho.valorAcumulacaoJaRealizado)

                        valorAcumulacaoPendente = valor - valorAcumulacaoJaRealizado

                        projetoVidaSonhoTracarMeta.push(
                            {
                                id: linhaProjetoVidaSonho.id,
                                ordem: anoMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao) +
                                    ("0" + mesMesAno(linhaProjetoVidaSonho.mesAnoFimAcumulacao)).slice(-2) +
                                        ("0000" + linhaProjetoVidaSonho.familiar).slice(-4),
                                        //("000000000000000000000" + valor).slice(-21),
                                familiar: linhaProjetoVidaSonho.familiar,
                                nomeCurtoFamiliar: nomeCurtoFamiliar,
                                nomeCurtoFamiliarFormatado: formataTableCellBodyExibeDadosTitulo(nomeCurtoFamiliar, report),
                                descricao: descricaoTipoProjetoVidaSonho,
                                descricaoFormatado:
                                    formataTableCellBodyExibeDadosTitulo(descricaoTipoProjetoVidaSonho, report),
                                valor: formataTableCellBodyExibeDadosValor(valor, report),
                                valorAcumulacaoJaRealizadoExcedente: 0,
                                valorAcumulacaoNecessarioVP: 0,
                                valorAcumulacaoJaRealizadoVFValorAcumulacaoNecessarioVP: 
                                    formataTableCellBodyExibeDadosValor(valorAcumulacaoJaRealizado, report),
                                valorAcumulacaoPendente: formataTableCellBodyExibeDadosValor(valorAcumulacaoPendente, report),
                                idadeAnoRealizacao: '',
                                mesesPosAcumulacao: 0,
                                periodoAcumulacao: '',
                                //mesAnoInicioAcumulacao: '',
                                //mesAnoFimAcumulacao: '',
                                qtdeAportes: '',
                                valorAporteProjetoVidaSonho: 0,
                                valorAporteProjetoVidaSonhoFormatado: formataTableCellBodyExibeDadosValor(0, report),
                                exibirComparacaoCenarios: linhaProjetoVidaSonho.exibirComparacaoCenarios,
                            }
                        )
                    }

                    if (linhaProjetoVidaSonho.indexOrigem !== null) {

                        limpaDadosAdicionaisProjetoVidaSonho(values, linhaProjetoVidaSonho.indexOrigem)
                    }
                }

                if (!maiorAnoFinal || anoFinal > maiorAnoFinal) {

                    maiorAnoFinal = anoFinal
                }

                if (
                        (
                            values.morteInvalidezEstudo === "A" ||
                            values.morteInvalidezEstudo === "M"
                        ) &&
                        linhaProjetoVidaSonho.familiar === HARDCODE.idFamiliarCliente
                ) {

                    valorResponsabilidadeCliente = 0

                } else {

                    valorResponsabilidadeCliente = valorAcumulacaoPendente
                }

                if (
                        (
                            values.morteInvalidezEstudo === "A" ||
                            values.morteInvalidezEstudo === "M"
                        ) &&
                        linhaProjetoVidaSonho.familiar === HARDCODE.idFamiliarConjuge
                ) {
                    
                    valorResponsabilidadeConjuge = 0
                } else {

                    valorResponsabilidadeConjuge = valorAcumulacaoPendente
                }

                projetoVidaSonhoTemp.push({
                    ordem: ("0000" + linhaProjetoVidaSonho.familiar).slice(-4) + anoMesOrdem + 
                        ("000000000000000000000" + valor).slice(-21),
                    familiar: linhaProjetoVidaSonho.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricaoTipoProjetoVidaSonho: descricaoTipoProjetoVidaSonho,
                    valor: valor,
                    valorAcumulacaoJaRealizado: valorAcumulacaoJaRealizado,
                    valorAcumulacaoJaRealizadoVF: valorAcumulacaoJaRealizadoVF,
                    valorAcumulacaoPendente: valorAcumulacaoPendente,
                    idadeAnoRealizacao: idadeAnoRealizacao,
                    valorResponsabilidadeCliente: valorResponsabilidadeCliente,
                    valorResponsabilidadeConjuge: valorResponsabilidadeConjuge,
                })

                valorTotal += valor
                valorAcumulacaoJaRealizadoTotal += valorAcumulacaoJaRealizado
                valorAcumulacaoJaRealizadoVFTotal += valorAcumulacaoJaRealizadoVF
                valorAcumulacaoPendenteTotal += valorAcumulacaoPendenteTotal
                valorResponsabilidadeClienteTotal += valorResponsabilidadeCliente
                valorResponsabilidadeConjugeTotal += valorResponsabilidadeConjuge
            }
        })

        projetoVidaSonhoTemp = sortAsc(projetoVidaSonhoTemp)

        let familiarAnt = null

        projetoVidaSonhoTemp.forEach( (linhaProjetoVidaSonho, index) => {
            if (linhaProjetoVidaSonho.familiar !== familiarAnt) {
                familiarAnt = linhaProjetoVidaSonho.familiar

                valor = 0
                valorAcumulacaoJaRealizado = 0
                valorAcumulacaoJaRealizadoVF = 0
                valorAcumulacaoPendente = 0
                valorResponsabilidadeCliente = 0
                valorResponsabilidadeConjuge = 0

                projetoVidaSonhoTemp.forEach( (linhaProjetoVidaSonhoTotal, index) => {
                    if (linhaProjetoVidaSonhoTotal.familiar === linhaProjetoVidaSonho.familiar) {

                        valor += linhaProjetoVidaSonhoTotal.valor
                        valorAcumulacaoJaRealizado += linhaProjetoVidaSonhoTotal.valorAcumulacaoJaRealizado
                        valorAcumulacaoJaRealizadoVF += linhaProjetoVidaSonhoTotal.valorAcumulacaoJaRealizadoVF
                        valorAcumulacaoPendente += linhaProjetoVidaSonhoTotal.valorAcumulacaoPendente
                        valorResponsabilidadeCliente += linhaProjetoVidaSonhoTotal.valorResponsabilidadeCliente
                        valorResponsabilidadeConjuge += linhaProjetoVidaSonhoTotal.valorResponsabilidadeConjuge
                    }
                })

                projetoVidaSonho.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaProjetoVidaSonho.nomeCurtoFamiliar,
                            report),
                    valor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report),
                    valorAcumulacaoJaRealizado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulacaoJaRealizado, report),
                    valorAcumulacaoJaRealizadoVF: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulacaoJaRealizadoVF, report),
                    valorAcumulacaoPendente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulacaoPendente, report),
                    valorResponsabilidadeCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeCliente, report),
                    valorResponsabilidadeConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorResponsabilidadeConjuge, report),
                })
            }

            projetoVidaSonho.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("          " + 
                        linhaProjetoVidaSonho.descricaoTipoProjetoVidaSonho, report),
                valor: formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valor, report),
                valorAcumulacaoJaRealizado: 
                    formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valorAcumulacaoJaRealizado, report),
                valorAcumulacaoJaRealizadoVF: 
                    formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valorAcumulacaoJaRealizadoVF, report),
                valorAcumulacaoPendente: 
                    formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valorAcumulacaoPendente, report),
                idadeAnoRealizacao: 
                    formataTableCellBodyExibeDadosTexto(linhaProjetoVidaSonho.idadeAnoRealizacao, report),
                valorResponsabilidadeCliente: 
                    formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valorResponsabilidadeCliente, report),
                valorResponsabilidadeConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaProjetoVidaSonho.valorResponsabilidadeConjuge, report),
            })
        })

        projetoVidaSonho.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valor:
                formataTableCellBodyExibeDadosTotalValor(valorTotal, report),
            valorAcumulacaoJaRealizado:
                formataTableCellBodyExibeDadosTotalValor(valorAcumulacaoJaRealizadoTotal, report),
            valorAcumulacaoJaRealizadoVF:
                formataTableCellBodyExibeDadosTotalValor(valorAcumulacaoJaRealizadoVFTotal, report),
            valorAcumulacaoPendente:
                formataTableCellBodyExibeDadosTotalValor(valorAcumulacaoPendenteTotal, report),
            valorResponsabilidadeCliente:
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeClienteTotal, report),
            valorResponsabilidadeConjuge:
                formataTableCellBodyExibeDadosTotalValor(valorResponsabilidadeConjugeTotal, report),
        })

        panelDespesasTotaisProjetoVidaSonho = {
            descricao: LABEL.projetoVidaSonho,
            valorAcumuladoPainel: valorTotal,
            valorDespesaClientePainel: valorResponsabilidadeClienteTotal,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: valorResponsabilidadeConjugeTotal,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: calculaIntervaloTempoAnos(values, maiorAnoFinal),
        }

    } else {

        panelDespesasTotaisProjetoVidaSonho = {
            descricao: LABEL.projetoVidaSonho,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.projetoVidaSonho = projetoVidaSonho

    estudo.current.projetoVidaSonhoTracarMeta = sortAsc(projetoVidaSonhoTracarMeta)

    estudo.current.panelDespesasTotais[HARDCODE.indexEstudoProjetoVidaSonho] = panelDespesasTotaisProjetoVidaSonho
}