import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class ObtemPDFBase64FromUrlService extends ApiService {

    constructor() {
        super(HARDCODE.apiObtemPDFBase64FromUrl)
    }

    obter(variaveisGlobais, idUsuario, urlPdf) {

        const body = {
            obtemPDFBase64FromUrlDTO: {
                idUsuario: idUsuario,
                urlPdf: urlPdf,
            }
        }
        
        return this.post(variaveisGlobais, HARDCODE.apiObtemPDFBase64FromUrlObter, body, false)
    }

    obterConverter(variaveisGlobais, idUsuario, urlPdf) {

        const body = {
            obtemPDFBase64FromUrlDTO: {
                idUsuario: idUsuario,
                urlPdf: urlPdf,
            }
        }
        
        return this.post(variaveisGlobais, HARDCODE.apiObtemPDFBase64FromUrlObterConverter, body, false)
    }
}

export default ObtemPDFBase64FromUrlService