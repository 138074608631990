import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



class PropostaService extends ApiService {

    constructor() {
        super(HARDCODE.apiProposta)
    }

    async atualizarPropostaList(variaveisGlobais, idUsuario, idArquivo, seguradora, jsonPropostaList) {

        const propostaListDTO = {
            idUsuarioLogado: AuthService.obterUsuarioLogado(variaveisGlobais),
            idUsuario: idUsuario,
            idArquivo: idArquivo,
            seguradora: seguradora,
	        jsonPropostaList: jsonPropostaList,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiPropostaAtualizarPropostaList, propostaListDTO, false)
    }
}

export default PropostaService