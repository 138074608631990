const NUMMIN = { 
    numeroSUSEP: 0,

    ordem: 0,

    larguraLogoTopbar: 10,
    larguraLogoReport: 10,
    alturaLogoTopbar: 10,
    alturaLogoReport: 10,

    estudoReportCapaTitulo1MargemTopo: 20,
    estudoReportCapaClienteConjugeTitulo2MargemTopo: 20,
    estudoReportCapaClienteConjugeTitulo2MargemEsquerda: 0,

    margem: 0,
    
    diasAntecedenciaEnvioLembreteAporte: 1,
    
    idade: 0,
    idadeNascimento: -5,

    tempoMinimoEntreAportesResgatesCalculoIRAnos: 1,

    qtdeDependentes: 0,

    peso: 1,
    imc: 1,
    qtdeFumoSemanal: 1,
    
    perc: 0,
    percMaiorQueZero: 0.01,

    anosReajusteAumento: 1,

    dia: 1,
    mes: 1,
    ano: 2000,
    meses: 1,
    anos: 1,

    anoMes: 190001,

    anosExibicaoRelatorioEvolucaoReservaFinanceira: 1,

    cotaConsorcio: 1,
    numeroAssembleiaParticipacao: 1,
    prazoTotalGrupoConsorcio: 1,
    numeroTotalParticipantesGrupoConsorcio: 1,

    percRentabilidadeAnualRecursoFinanceiro: -100,

    taxa: 0.01,
    taxaNegativa: -99.99,
    percIR: 10,

    qtdeParcelas: 1,
    numeroCobertura: 1,

    duracaoPremioExtra: 1,
}

export default NUMMIN