import React from 'react'

import HARDCODE from '../../business-const/HardCode/HardCode'

//import ReactWhatsapp from 'react-whatsapp'

import {
    IconButtonWhatsapp,
} from '../../business-components/IconButton/IconButton'

export const WhatsApp = ({className, numero, numeroFormatado}) => {

    if (!numero) {

        numero = HARDCODE.whatsappUrl
    }

    return (
        <a
            className={className}
            target="_blank"
            rel="noopener noreferrer" 
            href={"https://wa.me/" + numero}
        >
            <IconButtonWhatsapp/>


            {numero === HARDCODE.whatsappUrl ? HARDCODE.whatsappUrlFormatado : numeroFormatado}
        </a>
    )
}
/*
  <>
            <ReactWhatsapp
                number={HARDCODE.whatsappUrl}
                element={IconButtonWhatsapp}
            />

            {HARDCODE.whatsappText}
        </>
*/