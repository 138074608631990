import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'



class VwClienteAgenteService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAgente)
    }

    async buscarClienteAgenteList(variaveisGlobais, filtroList) {

        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, false)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAgenteBuscarClienteAgenteList}/?${params}`, paramsAux, false)
    }

    async countClienteAgenteTotal(variaveisGlobais, filtroList) {

        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, null, filtroList, false)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAgenteCountClienteAgenteTotal}/?${params}`, paramsAux, false)
    }
    
    async countClienteAgente(variaveisGlobais, campoAgrupamento, filtroList) {

        const { params, paramsAux } = await this.calculaParamsFiltroList(variaveisGlobais, campoAgrupamento, filtroList, false)

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAgenteCountClienteAgente}/?${params}`, paramsAux, false)
    }
}

export default VwClienteAgenteService