import HARDCODE from '../../../business-const/HardCode/HardCode'

export const calculaNecessidadeProtecao = ( values, estudo ) => {
    
    const despesasTotaisCliente =
        estudo.current.panelDespesasTotais[HARDCODE.indexEstudoTotalDespesas].valorDespesaClientePainel +
            //(values.morteInvalidezEstudo !== "I" => retirado quando tratou "Estava gerando erro no gráfico da Projeção da Sucessão Vitalícia"
            //    ?
                    values.sucessaoVitaliciaCliente
            //    :
            //        0
            //)

    const garantiasAtuaisCliente = estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoTotalGarantias].valorReceitaClientePainel

    const despesasTotaisConjuge = 
        estudo.current.panelDespesasTotais[HARDCODE.indexEstudoTotalDespesas].valorDespesaConjugePainel +
            //(values.morteInvalidezEstudo !== "I" => retirado quando tratou "Estava gerando erro no gráfico da Projeção da Sucessão Vitalícia"
            //    ?
                    values.sucessaoVitaliciaConjuge
            //    :
            //        0
            //)

    const garantiasAtuaisConjuge = estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoTotalGarantias].valorReceitaConjugePainel

    values.necessidadeProtecaoCliente =
        despesasTotaisCliente > garantiasAtuaisCliente
            ?
                despesasTotaisCliente - garantiasAtuaisCliente
            :
                0

    const valorPrevidenciaPrivadaCliente =
        values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia
            ?
                values.valorPrevidenciaPrivadaCliente
            :
                0

    values.necessidadeProtecaoClienteCalculoMorte =
        despesasTotaisCliente > garantiasAtuaisCliente - values.valorCSVidaInteiraApoliceCliente - valorPrevidenciaPrivadaCliente
            ?
                despesasTotaisCliente - (garantiasAtuaisCliente - values.valorCSVidaInteiraApoliceCliente - valorPrevidenciaPrivadaCliente)
            :
                0

    values.necessidadeProtecaoClienteCalculoInvalidez =
        despesasTotaisCliente > garantiasAtuaisCliente - valorPrevidenciaPrivadaCliente
            ?
                despesasTotaisCliente - (garantiasAtuaisCliente - valorPrevidenciaPrivadaCliente)
            :
                0

    values.necessidadeProtecaoConjuge =
        despesasTotaisConjuge > garantiasAtuaisConjuge
            ?
                despesasTotaisConjuge - garantiasAtuaisConjuge
            :
                0

    const valorPrevidenciaPrivadaConjuge =
        values.utilizarPrevidenciaPrivadaDeduzirNecessidadeSucessaoVitalicia
            ?
                values.valorPrevidenciaPrivadaConjuge
            :
                0

    values.necessidadeProtecaoConjugeCalculoMorte =
        despesasTotaisConjuge > garantiasAtuaisConjuge - values.valorCSVidaInteiraApoliceConjuge - valorPrevidenciaPrivadaConjuge
            ?
                despesasTotaisConjuge - (garantiasAtuaisConjuge - values.valorCSVidaInteiraApoliceConjuge - valorPrevidenciaPrivadaConjuge)
            :
                0
    
    values.necessidadeProtecaoConjugeCalculoInvalidez =
        despesasTotaisConjuge > garantiasAtuaisConjuge - valorPrevidenciaPrivadaConjuge
            ?
                despesasTotaisConjuge - (garantiasAtuaisConjuge - valorPrevidenciaPrivadaConjuge)
            :
                0
}