// alterado - revisado

import MESSAGE from '../../business-const/Message/Message'

import toastr from 'toastr'

const optionsPadrao = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "1000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
}

const optionsAlerta = {
    "closeButton": true,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-top-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "1000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut"
  }

export const mostrarMensagem = ( titulo, mensagem, tipo) => {

    toastr[tipo](mensagem, titulo)
}

export const mensagemSucesso = ( mensagem ) => {

    toastr.options = optionsPadrao 

    mostrarMensagem(MESSAGE().sucesso, mensagem, 'success')
}

export const mensagemInformacao = ( mensagem ) => {

    toastr.options = optionsPadrao 

    mostrarMensagem(MESSAGE().informacao, mensagem, 'info')
}

export const mensagemAlerta = ( mensagem ) => {

    toastr.options = optionsAlerta

    mostrarMensagem(MESSAGE().alerta, mensagem, 'warning')
}

export const mensagemErro = ( mensagem ) => {

    toastr.options = optionsPadrao 

    mostrarMensagem(MESSAGE().erro, mensagem, 'error')
}

export const mensagemErroErroTexto = ( erro ) => {

    return erro.response
        ?
            erro.response.data
                ?
                    erro.response.data
                :
                    erro.response
        :
            erro
}

export const mensagemErroErro = ( erro ) => {

    const textoErro = mensagemErroErroTexto(erro)

    mensagemErro(textoErro)

    return textoErro
}