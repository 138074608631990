// alterado - revisado

const TAMMAX = {
    email: 100,
    senha: 40,
    pin: 6,

    descricaoFilialUnidade: 40,
    
    descricaoWhiteLabel: 40,
    descricaoFunil: 40,
    descricaoEtapa: 40,

    descricaoTipoPendencia: 40,

    nome: 80,
    nomeCurto: 20,

    telefone: 13,
    celular: 14,

    linkCliente: 4096,

    razaoSocial: 100,
    nomeFantasia: 80,
    cnpj: 18,
    inscricaoEstadual: 80,
    numeroSusep: 80,
    site: 256,
    link: 512,
    grupo: 40,
    subgrupo: 40,

    profissaoQualificacao: 50,

    numeroConta: 40,
    numeroContaCliente: 40,
    codigoAgente: 80,

    identificacaoUsuario: 100,

    tituloSubTotalInputDiaDia: 20,

    textoMorte: 15,
    estudoReportPrimeiraReuniaoTextoAbertura: 1024,

    estudoReportCapaTitulo1: 50,
    estudoReportCapaTitulo2: 100,

    estudoReportOQueEsperarDesseTrabalho: 4224,

    estudoReportDetalhamentoDiaDiaDespesasVitaliciasTextoCabecalho: 1024,
    estudoReportDetalhamentoEducacaoTextoCabecalho: 1024,
    estudoReportDetalhamentoFinanciamentoTextoCabecalho: 1024,
    estudoReportDetalhamentoDiaDiaDespesasTemporariasTextoCabecalho: 1024,

    estudoReportDetalhamentoPatrimonioImobilizadoTextoCabecalho: 1024,
    estudoReportDetalhamentoReceitaMensalTextoCabecalho: 1024,
    estudoReportDetalhamentoRecursosFinanceirosTextoCabecalho: 1024,
    
    estudoReportDisclaimer: 2560,

    textoDescricaoAporteEnvioLembreteAporte: 100,

    descricaoFormulario: 40,

    descricaoTemplate: 40,
    
    descricaoPagina: 40,
    tituloPagina: 80,
    html: 4096,

    descricaoItemGestaoPatrimonial: 40,

    tipoOrigemClienteOutras: 80,
    formacao: 40,
    empresa: 40,
    profissaoOutras: 120,

    cep: 10,
    cepSemFormatacao: 8,
    endereco: 80,
    numeroEndereco: 20,
    complementoEndereco: 30,
    bairro: 60,
    cidade: 60,
    uf: 2,

    cpf: 14,

    altura: 3,
    
    contato: 100,

    observacao: 1024,
    //observacaoCliente: 4096,

    tipoFumoOutros: 40,
    tipoEsporteHobbyRiscoOutros: 40,
    informacoesComplementares: 512,
    procedimentoCirurgia: 256,
    causaObito: 512,

    perguntaMultiLine: 4096,

    descricaoReceitaMensalOutras: 40,
    descricaoDiaDiaDiversasOutros: 40,
    descricaoGrupoDespesaOutras: 40,
    descricaoFinanciamentolOutros: 40,
    descricaoProjetoVidaSonho: 40,

    numeroConsorcio: 15,
    grupoConsorcio: 5,

    descricaoPatrimonioImobilizadoOutros: 40,
    descricaoRecursosFinanceirosOutros: 40,

    instituicao: 80,

    seguradoraOutras: 80,

    numeroApolice: 30,//15,
    numeroCotacao: 15,
    numeroProposta: 25,//15,
    motivoCancelamento: 50,
    descricaoCobertura: 150,
    classeAjusteRisco: 30,

    url: 256,

    numeroCobertura: 15,
    codigoCobertura: 15,

    agencia: 8,
    conta: 15,

    nomeAcao: 80,

    nomeFundo: 80,

    dia: 2,
    mes: 2,
    mesAno: 6,

    descricaoCenario: 25,
    descricaoSimulacao: 40,

    descricaoEspecializacaoPeriodoSabatico: 40,

    assuntoEmail: 100,
    textoEmail: 4096,

    tituloAnotacao: 80,
}

export default TAMMAX