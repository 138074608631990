import HARDCODE from '../../../business-const/HardCode/HardCode'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import statusApoliceList from '../../../business-rules/List/SeguroVida/statusApoliceList'



export const incrementaContSeguroVidaApolice = ( values ) => {

    if (!values.contSeguroVidaApolice) {

        values.contSeguroVidaApolice = 0
    }

    values.contSeguroVidaApolice = values.contSeguroVidaApolice + 1
}

export const obtemContSeguroVidaApolice = ( values ) => {

    let contSeguroVidaApolice = ''

    if (!values.contSeguroVidaApolice) {

         incrementaContSeguroVidaApolice(values)

         contSeguroVidaApolice = values.contSeguroVidaApolice

    } else {

        contSeguroVidaApolice = 1

        while (contSeguroVidaApolice <= values.contSeguroVidaApolice) {

            if (!pesquisaList(values.seguroVidaApolice, contSeguroVidaApolice).id) {
                break
            }

            contSeguroVidaApolice++
        }

        if (contSeguroVidaApolice > values.contSeguroVidaApolice) {

            incrementaContSeguroVidaApolice(values)

            contSeguroVidaApolice = values.contSeguroVidaApolice
        }  
    }

    return contSeguroVidaApolice
}



export const processaValoresDefaultIniciaisSeguroVidaApolice = ( item ) => {

    if (!item.tipoPeriodicidadePagtoPremio) {

        item.tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal
    }

    if (!item.dataEmissao) {

        item.dataEmissao = null
    }
}



export const calculaSeguroVidaApoliceSeguradoraOutras = ( item ) => {

    if (item.seguradora !== HARDCODE.idSeguradoraOutras) {

        item.seguradoraOutras = ''
    }
}

export const processaAlteracaoSeguroVidaApoliceSeguradora = ( item ) => {

    calculaSeguroVidaApoliceSeguradoraOutras(item)
}



export const dadosCompletosLinhaSeguroVidaApolice = ( linhaSeguroVidaApolice ) => {

    return  linhaSeguroVidaApolice.tipoPeriodicidadePagtoPremio
}



export const dadosCompletosSeguroVidaApolice = ( values ) => {

    let dadosCompletosSeguroVidaApoliceAux = true

    values.seguroVidaApolice?.forEach( (linhaSeguroVidaApolice, index) => {
        
        if (!dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaApolice)) {
            dadosCompletosSeguroVidaApoliceAux = false
        }
    })

    return dadosCompletosSeguroVidaApoliceAux
}



export const seguroVidaApoliceAtiva = ( statusApolice ) => {

    let ativa = true
    
    if (statusApolice) {

        if (pesquisaList(statusApoliceList, statusApolice).ativa === 'N') {

            ativa = false
        }
    }

    return ativa
}