import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import {
    usuarioInfo,
} from './UsuarioService'



class ApoliceCobrancaService extends ApiService {

    constructor() {
        super(HARDCODE.apiApoliceCobranca)
    }

    async atualizarSalvarDeletarApoliceCobrancaList(variaveisGlobais, idUsuario, idArquivo, seguradora, jsonApoliceCobrancaList) {

        const apoliceCobrancaListDTO = {
            idUsuario: idUsuario,
            idArquivo: idArquivo,
            seguradora: seguradora,
	        jsonApoliceCobrancaList: jsonApoliceCobrancaList,
            logAcessoApiDTO: await usuarioInfo(),
        }

        return this.post(variaveisGlobais, HARDCODE.apiApoliceCobrancaAtualizarSalvarDeletarApoliceCobrancaList,
            apoliceCobrancaListDTO, false)
    }
}

export default ApoliceCobrancaService