import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList
} from '../../../business-rules/List/ListService'

import familiaresList from '../../../business-rules/List/familiaresList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import {
    calculaValorMensal,
} from '../cliente-form/ClienteService'

import {
    incrementaContSeguroVidaCotacao,
} from './SeguroVidaCotacaoServiceCotacao'

import {
    incluiSeguroVidaCotacaoCoberturaDefault,
} from './SeguroVidaCotacaoServiceCobertura'

import {
    calculaTempoCoberturaSeguroVida,
    calculaValorAcumuladoCoberturaSeguroVida,
} from '../seguroVida-form/SeguroVidaService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    dadosCompletosLinhaSeguroVidaCobertura,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'



export const processaRegistrosDefaultIniciaisSeguroVidaCotacao = ( values, familiar ) => {

    if (familiar) {

        if (!values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao = []
        }

        if (!values.seguroVidaCotacao) {

            values.seguroVidaCotacao = []
        }
        
        if (!values.seguroVidaCotacaoCobertura) {

            values.seguroVidaCotacaoCobertura = []
        }
        
        if (
                !pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao, familiar).id
        ) {

            values.familiaresProcessouRegistrosDefaultIniciaisSeguroVidaCotacao.push(
                {
                    id: familiar
                }
            )

            incrementaContSeguroVidaCotacao(values)

            values.seguroVidaCotacao.push(
                {
                    id: values.contSeguroVidaCotacao,
                    familiar: familiar,
                }
            )

            incluiSeguroVidaCotacaoCoberturaDefault (values, values.contSeguroVidaCotacao)
        }
    }
}



export const calculaTotaisSeguroVidaCotacao = ( values ) => {

    let familiarSeguroVidaCotacao = Number.parseInt(values.familiarSeguroVidaCotacao)

    const indexStepsValorAcompanhamentoSeguroVida = HARDCODE.indexAcompanhamentoSeguroVidaCotacao

    values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0
    values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] = 0

    let indexStepsValorSeguroVidaCotacao = ''

    values.stepsValorSeguroVidaCotacao?.forEach( (linhaStepsValorSeguroVidaCotacao, index) => {
        values.stepsValorSeguroVidaCotacao[index] = 0
        values.stepsValor2SeguroVidaCotacao[index] = 0
    })

    values.totalSeguroVidaCotacao = []

    if (!values.familiarSeguroVidaCotacao) {

        values.familiarSeguroVidaCotacao = HARDCODE.idFamiliarCliente
    }

    let valorAcumulado
    let valorPremio
    
    let valorTotal
    let valorPremioTotal

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        valorTotal = 0
        valorPremioTotal = 0

        values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

            if (
                    linhaSeguroVidaCotacao.familiar === linhaFamiliaresList.id &&
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
            ) {

                values.seguroVidaCotacaoCobertura?.forEach( (linhaSeguroVidaCotacaoCobertura, indexSeguroVidaCotacaoCobertura) => {

                    if (
                            linhaSeguroVidaCotacaoCobertura.idSeguroVidaCotacao === linhaSeguroVidaCotacao.id &&
                            dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCotacaoCobertura, true)
                    ) {

                        let tempoCoberturaSeguroVida =
                            calculaTempoCoberturaSeguroVida(values, linhaSeguroVidaCotacaoCobertura, linhaFamiliaresList)

                        valorAcumulado =
                            calculaValorAcumuladoCoberturaSeguroVida(linhaSeguroVidaCotacaoCobertura, tempoCoberturaSeguroVida)

                        valorPremio =
                            calculaValorMensal(linhaSeguroVidaCotacaoCobertura.valorPremio,
                                linhaSeguroVidaCotacao.tipoPeriodicidadePagtoPremio)

                        if (pesquisaList(tipoCoberturaSeguroVidaList,
                            linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida).cobreMorte === "S") {

                            valorTotal += valorAcumulado
                        }

                        valorPremioTotal += valorPremio
                        
                        if (linhaSeguroVidaCotacao.familiar === familiarSeguroVidaCotacao) {

                            indexStepsValorSeguroVidaCotacao = linhaSeguroVidaCotacaoCobertura.grupoCoberturaSeguroVida

                            values.stepsValorSeguroVidaCotacao[indexStepsValorSeguroVidaCotacao] += valorAcumulado
                            values.stepsValor2SeguroVidaCotacao[indexStepsValorSeguroVidaCotacao] +=valorPremio
                        }
                    }
                })
            }
        })

        //if (
        //        valorTotal !== 0 ||
        //        valorPremioTotal !== 0 ||
        //        linhaFamiliaresList.id === HARDCODE.idFamiliarCliente ||
        //        linhaFamiliaresList.id === HARDCODE.idFamiliarConjuge
        //) {
    
            values.totalSeguroVidaCotacao.push(
                {
                    id: linhaFamiliaresList.id,
                    descricao: linhaFamiliaresList.descricao,
                    title: LABEL.morteQualquerCausa,
                    valor: valorTotal,
                    title2: LABEL.valorPremioTotal,
                    valor2: valorPremioTotal,
                }
            )

            values.stepsValorAcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorTotal
            values.stepsValor2AcompanhamentoSeguroVida[indexStepsValorAcompanhamentoSeguroVida] +=
                valorPremioTotal
        //}
    })
}



export const calculaValorCSCotacao = ( values, id, idTipoCoberturaSeguroVida) => {

    let valorCS = 0

    values.seguroVidaCotacaoCobertura?.forEach( (linhaSeguroVidaCotacaoCobertura, index) => {

        if (
                linhaSeguroVidaCotacaoCobertura.idSeguroVidaCotacao === id &&
                dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaCotacaoCobertura, true) &&
                linhaSeguroVidaCotacaoCobertura.tipoCoberturaSeguroVida === idTipoCoberturaSeguroVida
        ) {

            valorCS += round(linhaSeguroVidaCotacaoCobertura.valorCS, 2)
        }
    })

    return valorCS
}