// alterado - revisado

const tipoEducacaoList = [
    {
        id: 10,
        descricao: 'Berçário',
        grupoDespesa: 610,
        ordem: 10,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 2000,
        idadeInicialDefault: 0,
        idadeFinalDefault: 0,
        alteraIdadeAutomaticamente: [{id: 20}],
    },
    {
        id: 20,
        descricao: 'Educação Infantil',
        ordem: 20,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 1500,
        idadeInicialDefault: 1,
        idadeFinalDefault: 5,
        alteraIdadeAutomaticamente: [{id: 30}, {id: 35}],
        grupoDespesa: 610,
    },
    {
        id: 25,
        descricao: 'Educação Infantil (Bilíngue)',
        ordem: 25,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [{id: 30}, {id: 35}],
        grupoDespesa: 610,
    },
    {
        id: 30,
        descricao: 'Fundamental - 1ª a 5ª',
        ordem: 30,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 1500,
        idadeInicialDefault: 6,
        idadeFinalDefault: 10,
        alteraIdadeAutomaticamente: [{id: 40}, {id: 45}],
        grupoDespesa: 610,
    },
    {
        id: 35,
        descricao: 'Fundamental - 1ª a 5ª (Bilíngue)',
        ordem: 35,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [{id: 40}, {id: 45}],
        grupoDespesa: 610,
    },
    {
        id: 40,
        descricao: 'Fundamental - 6ª a 9ª',
        ordem: 40,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 2000,
        idadeInicialDefault: 11,
        idadeFinalDefault: 14,
        alteraIdadeAutomaticamente: [{id: 50}, {id: 55}],
        grupoDespesa: 610,
    },
    {
        id: 45,
        descricao: 'Fundamental - 6ª a 9ª (Bilíngue)',
        ordem: 45,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [{id: 50}, {id: 55}],
        grupoDespesa: 610,
    },
    {
        id: 50,
        descricao: 'Ensino Médio',
        ordem: 50,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 2500,
        idadeInicialDefault: 15,
        idadeFinalDefault: 17,
        alteraIdadeAutomaticamente: [{id: 60}],
        grupoDespesa: 610,
    },
    {
        id: 55,
        descricao: 'Ensino Médio (Bilíngue)',
        ordem: 55,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [{id: 60}],
        grupoDespesa: 610,
    },
    {
        id: 60,
        descricao: 'Superior',
        ordem: 60,
        tipoPeriodicidadePagtoDefault: 'M',
        valorDefault: 3500,
        idadeInicialDefault: 18,
        idadeFinalDefault: 22,
        alteraIdadeAutomaticamente: [{id: 70}],
        grupoDespesa: 610,
    },
    {
        id: 70,
        descricao: 'Pós-graduação',
        ordem: 70,
        tipoPeriodicidadePagtoDefault: 'M',
        //valorDefault: 3500,
        //idadeInicialDefault: 23,
        //idadeFinalDefault: 24,
        alteraIdadeAutomaticamente: [],
        grupoDespesa: 610,
    },
    {
        id: 80,
        descricao: 'Intercâmbio',
        ordem: 80,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [],
        grupoDespesa: 610,
    },
    {
        id: 90,
        descricao: 'Idiomas',
        ordem: 90,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [],
        grupoDespesa: 610,
    },
    {
        id: 900,
        descricao: 'Outros',
        ordem: 900,
        tipoPeriodicidadePagtoDefault: 'M',
        alteraIdadeAutomaticamente: [],
        grupoDespesa: 610,
    },
]

export default tipoEducacaoList