import ApiService from './ApiService'

import HARDCODE from '../business-const/HardCode/HardCode'

import AuthService from './AuthService'

import {
    usuarioInfo,
} from './UsuarioService'



class VwClienteAcessoPendenciaService extends ApiService {

    constructor() {
        super(HARDCODE.apiVwClienteAcessoPendencia)
    }

    async buscarClienteList(variaveisGlobais, tipoPendencia) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`

        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        params = `${params}&tipoPendencia=${tipoPendencia}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoPendenciaBuscarClienteList}/?${params}`, paramsAux, false)
    }
    
    async countClientePendencias(variaveisGlobais) {

        let params = `idUsuario=${AuthService.obterUsuarioLogado(variaveisGlobais)}`
        
        params = `${params}&jsonUsuarioSelecionado=${AuthService.usuarioSelecionadoList(variaveisGlobais)}`

        const paramsAux = {
            params: {
                jsonLogAcessoApi: await usuarioInfo()
            }
        }

        return this.getParams(variaveisGlobais, `${HARDCODE.apiVwClienteAcessoPendenciaCountPendencias}/?${params}`, paramsAux, false)
    }
}

export default VwClienteAcessoPendenciaService