import LABELESPECIAL from '../../business-const/Label/LabelEspecial'

const tipoMoedaList = [
    {
        id: 10, // HARDCODEBACKEND.tipoMoedaReal
        descricao: 'Real',
        prefixSuffix: {prefix: LABELESPECIAL.moedaReal + ' ', suffix: ''},
        ordem: 10,
    },
    {
        id: 20, // HARDCODEBACKEND.tipoMoedaEuro
        descricao: 'Euro',
        prefixSuffix: {prefix: '', suffix: ' ' + LABELESPECIAL.moedaEuro},
        ordem: 20,
    },
    {
        id: 30, // HARDCODEBACKEND.tipoMoedaDolarAmericado
        descricao: 'Dólar Americano',
        prefixSuffix: {prefix: LABELESPECIAL.moedaDolarAmericano + ' ', suffix: ''},
        ordem: 30,
    },
    {
        id: 40, // HARDCODEBACKEND.tipoMoedaDolarCanadense
        descricao: 'Dólar Canadense',
        prefixSuffix: {prefix: LABELESPECIAL.moedaDolarCanadense + ' ', suffix: ''},
        ordem: 40,
    },
    {
        id: 900,
        descricao: 'Genérica',
        prefixSuffix: {prefix: LABELESPECIAL.moedaGenerica + ' ', suffix: ''},
        ordem: 900,
    },
]

export default tipoMoedaList