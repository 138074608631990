import familiaresList from '../../../business-rules/List/familiaresList'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import {
    grupoCoberturaSeguroVidaList
 } from '../../../business-rules/List/SeguroVida/grupoCoberturaSeguroVidaList'

import tipoCoberturaSeguroVidaList from '../../../business-rules/List/SeguroVida/tipoCoberturaSeguroVidaList'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    dadosCompletosLinhaSeguroVidaCobertura,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'



export const incrementaContSeguroVidaPropostaCobertura = ( values ) => {
    if (!values.contSeguroVidaPropostaCobertura) {
        values.contSeguroVidaPropostaCobertura = 0
    }
    values.contSeguroVidaPropostaCobertura = values.contSeguroVidaPropostaCobertura + 1
}

export const obtemContSeguroVidaPropostaCobertura = ( values ) => {

    let contSeguroVidaPropostaCobertura = ''

    if (!values.contSeguroVidaPropostaCobertura) {
         incrementaContSeguroVidaPropostaCobertura(values)
         contSeguroVidaPropostaCobertura = values.contSeguroVidaPropostaCobertura

    } else {
        contSeguroVidaPropostaCobertura = 1

        while (contSeguroVidaPropostaCobertura <= values.contSeguroVidaPropostaCobertura) {
            if (!pesquisaList(values.seguroVidaPropostaCobertura, contSeguroVidaPropostaCobertura).id) {
                break
            }

            contSeguroVidaPropostaCobertura++
        }

        if (contSeguroVidaPropostaCobertura > values.contSeguroVidaPropostaCobertura) {
            incrementaContSeguroVidaPropostaCobertura(values)
            contSeguroVidaPropostaCobertura = values.contSeguroVidaPropostaCobertura
        }  
    }

    return contSeguroVidaPropostaCobertura
}



export const incluiSeguroVidaPropostaCoberturaDefault = ( values, idSeguroVidaProposta ) => {

    grupoCoberturaSeguroVidaList?.forEach( (linhaGrupoCoberturaSeguroVidaList, index) => {

        incrementaContSeguroVidaPropostaCobertura(values)
        values.seguroVidaPropostaCobertura.push(
            {
                id: values.contSeguroVidaPropostaCobertura,
                idSeguroVidaProposta: idSeguroVidaProposta,
                grupoCoberturaSeguroVida: linhaGrupoCoberturaSeguroVidaList.id,
            }
        )
    })
}



export const excluirPropostaCoberturas = (values, idSeguroVidaProposta) => {

    let index = values.seguroVidaPropostaCobertura.length - 1

    while (index >= 0) {
        
        if (values.seguroVidaPropostaCobertura[index].idSeguroVidaProposta === idSeguroVidaProposta) {

            values.seguroVidaPropostaCobertura.splice(index, 1)
        }

        index -= 1
    }
}



export const dadosCompletosSeguroVidaPropostaCobertura = ( values ) => {
    
    let dadosIncompletos = ''
    let familiares = []
    let linhaSeguroVidaProposta

    familiaresList(values)?.forEach( (linhaFamiliaresList, index) => {
        
        values.seguroVidaPropostaCobertura?.forEach( (linhaSeguroVidaPropostaCobertura, index) => {

            linhaSeguroVidaProposta = pesquisaList(values.seguroVidaProposta,
                linhaSeguroVidaPropostaCobertura.idSeguroVidaProposta)

            if (
                    linhaSeguroVidaProposta.familiar === linhaFamiliaresList.id &&
                    linhaSeguroVidaPropostaCobertura.tipoCoberturaSeguroVida &&
                    !dadosCompletosLinhaSeguroVidaCobertura(linhaSeguroVidaPropostaCobertura, true)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaresList,
                    dadosIncompletos,
                        (linhaSeguroVidaProposta.numeroProposta
                            ?
                                linhaSeguroVidaProposta.numeroProposta + ": "
                            :
                                ""
                        ) +
                            pesquisaDescricaoList(grupoCoberturaSeguroVidaList,
                                linhaSeguroVidaPropostaCobertura.grupoCoberturaSeguroVida) + " - " +
                                    pesquisaDescricaoList(tipoCoberturaSeguroVidaList,
                                        linhaSeguroVidaPropostaCobertura.tipoCoberturaSeguroVida))
            }
        })
    })

    return dadosIncompletos
}