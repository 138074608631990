import React from 'react'

import { Link } from '@material-ui/core'

import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import {
    COLOR
} from '../../business-const/Color/Color'

import {
    FONTSIZE,
    FONTWEIGHT,
} from '../../business-const/Style/Style'



export const LinkPadraoBase = ( props ) => {

    const { tooltip, ...rest} = props

    return (
        <Link
            href="#"
            style={{
                color: rest.disabled ? COLOR().disabled : COLOR().linkPadrao,
                fontFamily: HARDCODE.fontFamily // mudança da fonte
            }}
            {...rest}
            title={tooltip ? tooltip : rest.title}
        >
            {rest.title}
        </Link>
    )
}

export const LinkPadraoBaseSubmit = ( props ) => {

    return (
        <LinkPadraoBase
            component="button"
            {...props}
        />
    )
}

export const LinkPadraoSubmit = ( props ) => {

    const { className, ...rest} = props

    return (
        <div className={className}>
            <LinkPadraoBaseSubmit
                {...rest}
            />
        </div>
    )
}

export const LinkPadraoNoSubmit = ( props ) => {

    const { className, ...rest} = props

    return (
        <div className={className}>
            <LinkPadraoBase
                {...rest}
            />
        </div>
    )
}

export const LinkPadraoSubmitSpan = ( props ) => {

    const { className, ...rest} = props

    return (
        <span className={className}>
            <LinkPadraoBaseSubmit
                {...rest}
            />
        </span>
    )
}



export const LinkPadraoSubmitDashboard = ( props ) => {
    
    return (
        <LinkPadraoBaseSubmit
            style={{
                color: props.disabled ? COLOR().disabled : COLOR().linkPadrao,
                fontSize: FONTSIZE().LinkPadraoSubmitDashboard,
                fontWeight: FONTWEIGHT().LinkPadraoSubmitDashboard,
                fontFamily: HARDCODE.fontFamily // mudança da fonte
            }}
            {...props}
        />
    )
}



export const LinkPadraoSubmitEnviarPinEmail = ( props ) => {

    return (
        <LinkPadraoBaseSubmit
            title={LABEL.linkEnviarPinEmail}
            className="mt-4"
            {...props}
        />
    )
}