import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import TAMMAX from '../TamMax/TamMax'

const formPrimeiraReuniaoInvestimento30List = [
    {
        id: 10,
        descricao: 'Me fale um pouco sobre você (naturalidade, formação...)',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoPessoalFamilia,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 10,
    },
    {
        id: 20,
        descricao: 'Conte-me sobre sua família. Possui dependentes?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoPessoalFamilia,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Como planeja a educação dos seus dependentes?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoPessoalFamilia,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Qual é a coisa mais importante que o dinheiro lhe proporciona atualmente?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoPessoalFamilia,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 40,
    },
    {
        id: 50,
        descricao:
            'Quais são os fatores ou circunstâncias em sua vida que podem afetar o seu planejamento financeiro? ',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoPessoalFamilia,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Onde trabalha? Há quanto tempo? Quais são as suas perspectivas profissionais?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'O que você sentiria falta do trabalho que realiza hoje em dia?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 70,
    },
    {
        id: 80,
        descricao: 'Como você se organiza financeiramente? Possui orçamento?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 80,
    },
    {
        id: 90,
        descricao: 'Qual é a sua renda mensal? Quais as fontes desta renda?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 90,
    },
    {
        id: 100,
        descricao: 'Qual é o seu gasto mensal familiar? (Padrão de vida)',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 100,
    },
    {
        id: 110,
        descricao: 'Qual a sua capacidade mensal de poupança? Você é um poupador disciplinado?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaContextoProfissional,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 110,
    },
    {
        id: 120,
        descricao: 'Quais são as suas proteções atuais? (Pessoal, profissional e patrimonial)',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaSeguranca,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 120,
    },
    {
        id: 130,
        descricao: 'Quais as principais preocupações que sente quando pensa em segurança?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaSeguranca,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 130,
    },
    {
        id: 140,
        descricao: 'O que é independência financeira para você?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaIndependenciaFinanceira,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 140,
    },
    {
        id: 150,
        descricao: 'Sente alguma preocupação quando pensa em Independência Financeira?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaIndependenciaFinanceira,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 150,
    },
    {
        id: 160,
        descricao: 'Quando espera atingir este momento? Tem algum montante em mente?',
        default: true,
        grupoPergunta: LABEL.grupoPerguntaIndependenciaFinanceira,
        completo: true,
        type: 'MultiLine',
        tamMax: TAMMAX.perguntaMultiLine,
        ordem: 160,
    },
    {
        id: 170,
        descricao: 'Possui despesas do dia a dia?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeDiaDia,
        compoeCompleto: true,
        ordem: 170,
    },
    {
        id: 180,
        descricao: 'Possui despesas com educação?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeEducacao,
        compoeCompleto: true,
        ordem: 180,
    },
    {
        id: 190,
        descricao: 'Possui financiamentos?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeFinanciamento,
        compoeCompleto: true,
        ordem: 190,
    },
    {
        id: 200,
        descricao: 'Possui projetos de vida/sonho?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeProjetoVidaSonho,
        compoeCompleto: true,
        ordem: 200,
    },
    {
        id: 210,
        descricao: 'Possui patrimônio imobilizado?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypePatrimonioImobilizado,
        compoeCompleto: true,
        ordem: 210,
    },
    {
        id: 220,
        descricao: 'Possui receita mensal?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeReceitaMensal,
        compoeCompleto: true,
        ordem: 220,
    },
    {
        id: 230,
        descricao: 'Possui recursos financeiros?',
        default: true,
        grupoPergunta: '',
        completo: false,
        type: HARDCODE.formTypeRecursosFinanceiros,
        compoeCompleto: true,
        ordem: 230,
    },
]
 
export default formPrimeiraReuniaoInvestimento30List