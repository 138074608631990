import HARDCODE from '../../business-const/HardCode/HardCode'

import LABEL from '../../business-const/Label/Label'

import TAMMAX from '../TamMax/TamMax'

import {
    formataArrayDataHora,
} from '../../business-components/Date/Date'

import {
    formataCpf,
} from '../../business-components/Formata/Formata'

import {
    pesquisaDescricaoList,
    incluiCampoList,
} from './ListService'

import instituicaoFinanceiraList from './instituicaoFinanceiraList'

import statusIntegracaoList from './statusIntegracaoList'



export const camposClienteAgenteList = ( list, columnsExibe, grafico, filtroList, filtroLists ) => {

    let camposListAux = []

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'cpfCliente',
        label: LABEL.cpf,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeCpf,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataCpf(list[dataIndex].cpfCliente))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 10,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'nomeCliente',
        label: LABEL.nome,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.nome,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 20,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'instituicaoFinanceira',
        label: LABEL.instituicaoFinanceira,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.instituicaoFinanceiraLists,
        columnsDefault: 'N',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(instituicaoFinanceiraList, list[dataIndex].instituicaoFinanceira))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 30,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'numeroContaCliente',
        label: LABEL.numeroContaCliente,
        filtro: 'S',
        filtroGrafico: 'N',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.numeroContaCliente,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 40,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'codigoAgente',
        label: LABEL.codigoAgente,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.codigoAgente,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 50,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoUsuario',
        label: LABEL.usuario,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.identificacaoUsuario,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 60,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'identificacaoGestorLider',
        label: LABEL.gestorLider,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeStringLike,
        tamMax: TAMMAX.identificacaoUsuario,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'N',
        options: null,
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'S',
        ordem: 70,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'statusIntegracao',
        label: LABEL.statusIntegracao,
        filtro: 'S',
        filtroGrafico: 'S',
        type: HARDCODE.typeListMultiSelectString,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: filtroLists.statusIntegracaoLists,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (pesquisaDescricaoList(statusIntegracaoList, list[dataIndex].statusIntegracao))
            },
        },
        permiteAgrupar: 'S',
        permiteGerarPDFQuebra: 'N',
        ordem: 80,
    })
    
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataHoraInclusao',
        label: LABEL.dataHoraInclusao,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayDataHora(list[dataIndex].dataHoraInclusao))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 90,
    })

    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'dataHoraAlteracao',
        label: LABEL.dataHoraAlteracao,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => {
                return (formataArrayDataHora(list[dataIndex].dataHoraAlteracao))
            },
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 100,
    })

    /*
    incluiCampoList({
        camposList: camposListAux,
        columnsExibe: columnsExibe,
        grafico: grafico,
        filtroList: filtroList,
        name: 'links',
        label: LABEL.links,
        filtro: 'N',
        filtroGrafico: 'N',
        type: null,
        tamMax: null,
        numMin: null,
        numMax: null,
        list: null,
        columnsDefault: 'S',
        options: {
            customBodyRenderLite: (dataIndex) => (
                <div
                    className="flex items-center"
                >
                    <CustomizedDialogsPopup
                        maxWidth="lg"
                        popupTitle={LABEL.extratoProposta}
                        tooltipTitle={LABEL.extratoProposta}
                        PopupButton={IconButtonDocument}
                        popupComponent={
                            <PopupComponent/>
                        }
                    />
                </div>
            ),
        },
        permiteAgrupar: 'N',
        permiteGerarPDFQuebra: 'N',
        ordem: 110,
    })
    */

    return camposListAux
}

export default camposClienteAgenteList