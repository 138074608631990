import React from 'react'

const calendarRoutes = [
    {
        path: '/agenda',
        exact: true,
        component: React.lazy(() => import('./Agenda')),
    },
]

export default calendarRoutes
