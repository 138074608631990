import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    round
} from '../../../business-components/round/round'

import {
    formataTableCellBodyExibeDadosTitulo,
    formataTableCellBodyExibeDadosValor,
    formataTableCellBodyExibeDadosSubTotalTitulo,
    formataTableCellBodyExibeDadosSubTotalValor,
    formataTableCellBodyExibeDadosTotalTitulo,
    formataTableCellBodyExibeDadosTotalValor,
} from '../../../business-components/Formata/Formata'

import {
    sortAsc
} from '../../../business-components/Sort/Sort'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import tipoPatrimonioImobilizadoList from '../../../business-rules/List/tipoPatrimonioImobilizadoList'

import titularList from '../../../business-rules/List/titularList'

import {
    calculaIntervaloTempoAnosMorteAteVitalicio,
} from '../cliente-form/ClienteService'

import {
    dadosCompletosLinhaPatrimonioImobilizado,
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'



const calculaPatrimonioImobilizadoDetalhado = (estudo, linhaTipoPatrimonioImobilizado, descricaoTipoPatrimonioImobilizado, valor) => {

    const patrimonioImobilizadoDetalhado =
        {
            ordem: valor * -1,
            descricao: descricaoTipoPatrimonioImobilizado,
            valor: valor,
        }
    
    if (linhaTipoPatrimonioImobilizado.tipoAgrupamento === HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis) {

        estudo.current.patrimonioImobilizadoDetalhadoBensMoveisImoveis.push( 
            patrimonioImobilizadoDetalhado
        )

    } else {

        estudo.current.patrimonioImobilizadoDetalhadoNegocios.push( 
            patrimonioImobilizadoDetalhado
        )
    }
}

const formataPatrimonioImobilizadoDetalhado = (values, estudo, report, tipoAgrupamento) => {

    let patrimonioImobilizadoDetalhado

    if (tipoAgrupamento === HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis) {

        patrimonioImobilizadoDetalhado = [...estudo.current.patrimonioImobilizadoDetalhadoBensMoveisImoveis]

    } else {

        patrimonioImobilizadoDetalhado = [...estudo.current.patrimonioImobilizadoDetalhadoNegocios]
    }

    patrimonioImobilizadoDetalhado = sortAsc(patrimonioImobilizadoDetalhado)

    let valorTotal = 0

    patrimonioImobilizadoDetalhado.forEach( (linhaPatrimonioImobilizado, index) => {

        valorTotal += linhaPatrimonioImobilizado.valor

        patrimonioImobilizadoDetalhado[index].nivel = HARDCODE.reportNivelDados
    })

    if (valorTotal > 0) {

        patrimonioImobilizadoDetalhado.push({
            nivel: HARDCODE.reportNivelDadosTotal,
            descricao: LABEL.total,
            valor: valorTotal,
        })
    }

    if (tipoAgrupamento === HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis) {

        estudo.current.patrimonioImobilizadoDetalhadoBensMoveisImoveis = [...patrimonioImobilizadoDetalhado]

        estudo.current.valorPatrimonioImobilizadoDetalhadoBensMoveisImoveis = valorTotal

    } else {

        estudo.current.patrimonioImobilizadoDetalhadoNegocios = [...patrimonioImobilizadoDetalhado]

        estudo.current.valorPatrimonioImobilizadoDetalhadoNegocios = valorTotal
    }
}

export const calculaGarantiasPatrimonioImobilizado = (values, estudo, report) => {
    let patrimonioImobilizadoTemp = []
    let patrimonioImobilizado = []
    estudo.current.patrimonioImobilizadoDetalhadoBensMoveisImoveis = []
    estudo.current.valorPatrimonioImobilizadoDetalhadoBensMoveisImoveis = 0
    estudo.current.patrimonioImobilizadoDetalhadoNegocios = []
    estudo.current.valorPatrimonioImobilizadoDetalhadoNegocios = 0
    let panelGarantiasAtuaisPatrimonioImobilizado

    if (values.patrimonioImobilizado) {
        let descricaoTipoPatrimonioImobilizado = null
        let nomeCurtoFamiliar = null

        let linhaTipoPatrimonioImobilizado
        
        let valor
        let valorAcumulado
        let valorGarantiaCliente
        let valorGarantiaConjuge

        let valorTotal = 0
        let valorAcumuladoTotal = 0
        let valorGarantiaClienteTotal = 0
        let valorGarantiaConjugeTotal = 0

        values.patrimonioImobilizado.forEach( (linhaPatrimonioImobilizado, index) => {

            if (
                    dadosCompletosLinhaPatrimonioImobilizado(linhaPatrimonioImobilizado) &&
                    !pesquisaList(values.exclusaoFamiliares, linhaPatrimonioImobilizado.familiar).id &&
                    !pesquisaList(values.exclusaoDespesasGarantias,
                        LABEL.patrimonioImobilizado + '/' + linhaPatrimonioImobilizado.id).id
            ) {

                linhaTipoPatrimonioImobilizado = pesquisaList(tipoPatrimonioImobilizadoList, 
                    linhaPatrimonioImobilizado.tipoPatrimonioImobilizado)
                
                descricaoTipoPatrimonioImobilizado = 
                    linhaPatrimonioImobilizado.descricaoOutros
                        ?
                            linhaPatrimonioImobilizado.descricaoOutros
                        :
                            linhaTipoPatrimonioImobilizado.descricao

                nomeCurtoFamiliar = 
                    pesquisaDescricaoList(titularList(values, 'completo'), 
                        linhaPatrimonioImobilizado.familiar)

                valor = round (linhaPatrimonioImobilizado.valor, 2)

                if (
                    linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCliente ||
                    linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarConjuge ||
                    linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal
                ) {

                    valorAcumulado = valor

                    calculaPatrimonioImobilizadoDetalhado(estudo, linhaTipoPatrimonioImobilizado, descricaoTipoPatrimonioImobilizado, valor)

                } else {

                    valorAcumulado = 0
                }

                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                if (
                        (
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCliente ||
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarConjuge ||
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal
                        ) &&
                        linhaPatrimonioImobilizado.considerarGarantiaMorteInvalidezCliente
                ) {
                    valorGarantiaCliente = valorAcumulado
                }

                if (
                        (
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCliente ||
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarConjuge ||
                            linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal
                        ) &&
                        linhaPatrimonioImobilizado.considerarGarantiaMorteInvalidezConjuge
                ) {

                    valorGarantiaConjuge = valorAcumulado
                }

                //if (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal) {
                //    valorGarantiaCliente = 0 calculaValorPerc(valorAcumulado, linhaPatrimonioImobilizado.percPatrimonioImobilizadoCliente)
                //    valorGarantiaConjuge = 0 valorAcumulado - valorGarantiaCliente
                //}

                patrimonioImobilizadoTemp.push({
                    ordem: ("0000" + (linhaPatrimonioImobilizado.familiar === HARDCODE.idFamiliarCasal ? 0 : linhaPatrimonioImobilizado.familiar)).slice(-4) + 
                        ("000000000000000000000" + valor).slice(-21),
                    familiar: linhaPatrimonioImobilizado.familiar,
                    nomeCurtoFamiliar: nomeCurtoFamiliar,
                    descricaoTipoPatrimonioImobilizado: descricaoTipoPatrimonioImobilizado,
                    valor: valor,
                    valorAcumulado: valorAcumulado,
                    valorGarantiaCliente: valorGarantiaCliente,
                    valorGarantiaConjuge: valorGarantiaConjuge,
                })

                valorTotal += valor
                valorAcumuladoTotal += valorAcumulado
                valorGarantiaClienteTotal += valorGarantiaCliente
                valorGarantiaConjugeTotal += valorGarantiaConjuge
            }
        })

        patrimonioImobilizadoTemp = sortAsc(patrimonioImobilizadoTemp)

        let familiarAnt = null

        patrimonioImobilizadoTemp.forEach( (linhaPatrimonioImobilizado, index) => {
            if (linhaPatrimonioImobilizado.familiar !== familiarAnt) {
                familiarAnt = linhaPatrimonioImobilizado.familiar

                valor = 0
                valorAcumulado = 0
                valorGarantiaCliente = 0
                valorGarantiaConjuge = 0

                patrimonioImobilizadoTemp.forEach( (linhaPatrimonioImobilizadoTotal, index) => {
                    if (linhaPatrimonioImobilizadoTotal.familiar === linhaPatrimonioImobilizado.familiar) {
                        valor += linhaPatrimonioImobilizadoTotal.valor
                        valorAcumulado += linhaPatrimonioImobilizadoTotal.valorAcumulado
                        valorGarantiaCliente += linhaPatrimonioImobilizadoTotal.valorGarantiaCliente
                        valorGarantiaConjuge += linhaPatrimonioImobilizadoTotal.valorGarantiaConjuge
                    }
                })

                patrimonioImobilizado.push({
                    descricao: 
                        formataTableCellBodyExibeDadosSubTotalTitulo(linhaPatrimonioImobilizado.nomeCurtoFamiliar, report),
                    valor: 
                        formataTableCellBodyExibeDadosSubTotalValor(valor, report),
                    valorAcumulado: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorAcumulado, report),
                    valorGarantiaCliente: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaCliente, report),
                    valorGarantiaConjuge: 
                        formataTableCellBodyExibeDadosSubTotalValor(valorGarantiaConjuge, report),
                })
            }

            patrimonioImobilizado.push({
                descricao: 
                    formataTableCellBodyExibeDadosTitulo("          " +
                        linhaPatrimonioImobilizado.descricaoTipoPatrimonioImobilizado, report),
                valor: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valor, report),
                valorAcumulado: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorAcumulado, report),
                valorGarantiaCliente: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorGarantiaCliente, report),
                valorGarantiaConjuge: 
                    formataTableCellBodyExibeDadosValor(linhaPatrimonioImobilizado.valorGarantiaConjuge, report),
            })
        })

        patrimonioImobilizado.push({
            descricao:
                formataTableCellBodyExibeDadosTotalTitulo(LABEL.total, report),
            valor:
                formataTableCellBodyExibeDadosTotalValor(valorTotal, report),
            valorAcumulado:
                formataTableCellBodyExibeDadosTotalValor(valorAcumuladoTotal, report),
            valorGarantiaCliente:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaClienteTotal, report),
            valorGarantiaConjuge:
                formataTableCellBodyExibeDadosTotalValor(valorGarantiaConjugeTotal, report),
        })

        formataPatrimonioImobilizadoDetalhado(values, estudo, report, HARDCODE.tipoAgrupamentoPatrimonioImobilizadoBensMoveisImoveis)

        formataPatrimonioImobilizadoDetalhado(values, estudo, report, HARDCODE.tipoAgrupamentoPatrimonioImobilizadoNegocios)

        panelGarantiasAtuaisPatrimonioImobilizado = {
            descricao: LABEL.patrimonioImobilizado,
            valorAcumuladoPainel: valorAcumuladoTotal,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: valorGarantiaClienteTotal,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: valorGarantiaConjugeTotal,
            anosProtegerProtegido: calculaIntervaloTempoAnosMorteAteVitalicio(values),
        }

    } else {

        panelGarantiasAtuaisPatrimonioImobilizado = {
            descricao: LABEL.patrimonioImobilizado,
            valorAcumuladoPainel: 0,
            valorDespesaClientePainel: 0,
            valorReceitaClientePainel: 0,
            valorDespesaConjugePainel: 0,
            valorReceitaConjugePainel: 0,
            anosProtegerProtegido: '',
        }
    }

    estudo.current.patrimonioImobilizado = patrimonioImobilizado

    estudo.current.panelGarantiasAtuais[HARDCODE.indexEstudoPatrimonioImobilizado] = panelGarantiasAtuaisPatrimonioImobilizado
}