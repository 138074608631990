import readXlsxFile from 'read-excel-file'

import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    comparaArraysJSON
} from '../../../business-components/Array/Array'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'

import {
    getBase64,
    getBlobFromBase64,
} from '../../../business-components/UploadFile/UploadFile'

import seguradoraList from '../../../business-rules/List/SeguroVida/seguradoraList'

import {
    putConverter,
} from '../../arquivo/ArquivoService'

import {
    adicionaErro,
} from '../integracaoClienteArquivo-list/IntegracaoClienteArquivoService'

import {
    dadosCompletosLinhaSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'



export const incrementaContSeguroVidaCotacao = ( values ) => {

    if (!values.contSeguroVidaCotacao) {

        values.contSeguroVidaCotacao = 0
    }

    values.contSeguroVidaCotacao = values.contSeguroVidaCotacao + 1
}



export const processaValoresDefaultIniciaisSeguroVidaCotacao = ( item ) => {

    if (!item.tipoPeriodicidadePagtoPremio) {

        item.tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal
    }

    if (!item.dataCotacao) {

        item.dataCotacao = null
    }
}



export const dadosCompletosSeguroVidaCotacao = ( values ) => {

    let dadosCompletosSeguroVidaCotacaoAux = true

    values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, index) => {
        
        if (!dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)) {
            dadosCompletosSeguroVidaCotacaoAux = false
        }
    })

    return dadosCompletosSeguroVidaCotacaoAux
}




const analisaPDFTxtTabelaEvolucaoValorResgate = (values, item, arquivo, XLSXPDFTxt, XLSXPDF) => {

    let errosAux = 0

    let listaErrosAux = ''

    let processou = false

    seguradoraList()?.forEach( (linhaSeguradora, indexSeguradora) => {

        if (linhaSeguradora.integracaoCotacao) {

            linhaSeguradora.integracaoCotacao?.forEach( (linhaIntegracaoCotacao, indexIntegracaoCotacao) => {

                if (!processou) {

                    if (XLSXPDF === HARDCODE.PDF && linhaIntegracaoCotacao.XLSXPDF === HARDCODE.PDF) {
                        //console.log(XLSXPDFTxt)

                        let matchChaveIntegracao = 0

                        linhaIntegracaoCotacao.chaveIntegracao?.forEach((linhaChaveIntegracao, indexChaveIntegracao) => {

                            if (XLSXPDFTxt.indexOf(linhaChaveIntegracao) !== -1) {

                                matchChaveIntegracao++
                            }
                        })

                        if (
                                matchChaveIntegracao > 0 &&
                                matchChaveIntegracao === linhaIntegracaoCotacao.chaveIntegracao.length
                        ) {

                            let erros = []

                            item.seguradora = linhaSeguradora.id
                            
                            linhaIntegracaoCotacao.rotinaIntegracao(values, item, XLSXPDFTxt, arquivo[0].name, erros)

                            errosAux = erros.length

                            listaErrosAux = erros

                            processou = true
                        }

                    } else {

                        if (XLSXPDF === HARDCODE.XLSX && linhaIntegracaoCotacao.XLSXPDF === HARDCODE.XLSX) {

                            const cabecalho = XLSXPDFTxt[linhaIntegracaoCotacao.cabecalho]

                            if (comparaArraysJSON(linhaIntegracaoCotacao.chaveIntegracao, cabecalho)) {

                                let erros = []
                                
                                item.seguradora = linhaSeguradora.id
                            
                                linhaIntegracaoCotacao.rotinaIntegracao(
                                    values, item, linhaIntegracaoCotacao, XLSXPDFTxt/*, arquivo[0].name, erros*/)

                                errosAux = erros.length

                                listaErrosAux = erros

                                processou = true
                            }
                        }
                    }
                }
            })
        }
    })

    if (!processou) {

        errosAux++

        listaErrosAux = adicionaErro(listaErrosAux, MESSAGE().layoutNaoParametrizadoSistema, arquivo[0])
    }

    return { errosAux, listaErrosAux }
}

export const processaUploadTabelaEvolucaoValorResgate = async (variaveisGlobais, values, item, arquivo) => {

    let erros = 0

    let listaErros = ''

    try {

        const conteudo = await getBase64(arquivo[0])

        if (arquivo[0].type === HARDCODE.PDFApplication) {

            const { PDFTxt, mensagemErro } = await putConverter(variaveisGlobais, conteudo)

            if (PDFTxt) {

                const { errosAux, listaErrosAux } =
                    analisaPDFTxtTabelaEvolucaoValorResgate(values, item, arquivo, PDFTxt, HARDCODE.PDF)

                erros = errosAux

                listaErros = listaErrosAux

            } else {

                erros++

                listaErros = adicionaErro(listaErros, mensagemErro, arquivo[0])
            }

        } else {
            
            const XLSXBlob = getBlobFromBase64(conteudo)
                
            const XLSX = await readXlsxFile(XLSXBlob)

            const { errosAux, listaErrosAux } =
                analisaPDFTxtTabelaEvolucaoValorResgate(values, item, arquivo, XLSX, HARDCODE.XLSX)

            erros = errosAux

            listaErros = listaErrosAux
        }

    } catch (erro) {

        const textoErro = mensagemErroErro(erro)
        
        erros++

        listaErros = adicionaErro(listaErros, textoErro, arquivo[0])
    }

    return { erros, listaErros }
}



export const existeTabelaEvolucaoValorResgate = (values, familiar) => {

    let existe = false

    if (values.seguroVidaCotacao) {

        values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

            if (
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate &&
                linhaSeguroVidaCotacao.tabelaEvolucaoValorResgate.length > 0 &&
                (
                    !familiar ||
                    linhaSeguroVidaCotacao.familiar === familiar
                ) &&
                dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
            ) {

                existe = true
            }
        })
    }

    return existe
}



export const possuiCotacaoSeguroVidaApenasComparacaoPreco = (values, seguradora, utilizarApenasComparacaoPreco) => {

    let possui = false

    if (values.seguroVidaCotacao) {

        values.seguroVidaCotacao?.forEach( (linhaSeguroVidaCotacao, indexSeguroVidaCotacao) => {

            if (
                    linhaSeguroVidaCotacao.seguradora === seguradora &&
                    (
                        (
                                utilizarApenasComparacaoPreco &&
                                linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco
                        ) ||
                        (
                                !utilizarApenasComparacaoPreco &&
                                !linhaSeguroVidaCotacao.utilizarApenasComparacaoPreco
                        )
                    ) &&
                    dadosCompletosLinhaSeguroVidaApolice(linhaSeguroVidaCotacao)
            ) {

                possui = true
            }
        })
    }

    return possui

}