import HARDCODE from '../../business-const/HardCode/HardCode'



const sexoList = (listaCompleta) => {

    const sexoListAux = []

    sexoListAux.push(
        {
            id: HARDCODE.idSexoMasculino, 
            descricao: 'Masculino',
            ordem: 10,
        },
        {
            id: HARDCODE.idSexoFeminino, 
            descricao: 'Feminino',
            ordem: 20,
        },
    )

    if (listaCompleta) {

        sexoListAux.push(
            {
                id: 'N', // HARDCODEBACKEND.idSexoNaoInformado
                descricao: 'Não Informado',
                ordem: 90,
            },
        )
    }

    return sexoListAux
}

export default sexoList