import HARDCODE from '../../../business-const/HardCode/HardCode'



const statusPropostaList = [
    {
        id: HARDCODE.idStatusPropostaPendente,
        name: HARDCODE.idStatusPropostaPendente,
        descricao: 'Pendente',
        label: 'Pendente',
        cor: '#fffe01',
        ordem: 10,
    },
    {
        id: HARDCODE.idStatusPropostaEmAnalise,
        name: HARDCODE.idStatusPropostaEmAnalise,
        descricao: 'Em Análise',
        label: 'Em Análise',
        cor: '#01aff2',
        ordem: 20,
    },
    {
        id: HARDCODE.idStatusPropostaImplantada,
        name: HARDCODE.idStatusPropostaImplantada,
        descricao: 'Implantada',
        label: 'Implantada',
        cor: '#c7dfb1',
        ordem: 30,
    },
    {
        id: HARDCODE.idStatusPropostaPaga,
        name: HARDCODE.idStatusPropostaPaga,
        descricao: 'Paga',
        label: 'Paga',
        cor: '#92cf52',
        ordem: 40,
    },
    {
        id: HARDCODE.idStatusPropostaCancelada,
        name: HARDCODE.idStatusPropostaCancelada,
        descricao: 'Cancelada',
        label: 'Cancelada',
        cor: '',
        ordem: 50,
    },
    {
        id: HARDCODE.idStatusPropostaRecusada,
        name: HARDCODE.idStatusPropostaRecusada,
        descricao: 'Recusada',
        label: 'Recusada',
        cor: '#fe0000',
        ordem: 60,
    },
    {
        id: HARDCODE.idStatusPropostaNaoInformado,
        name: HARDCODE.idStatusPropostaNaoInformado,
        descricao: 'Não Informado',
        label: 'Não Informado',
        cor: '',
        ordem: 70,
    },
]

export default statusPropostaList
