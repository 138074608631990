// alterado

import React from 'react'

import { MatxLayouts } from './index'

import { MatxSuspense } from 'app/components'

import useSettings from 'app/hooks/useSettings'

import history from 'history.js'

import PATH from '../../business-const/Path/Path'

import AuthService from '../../business-api/AuthService'

const MatxLayout = (props) => {
    const { settings } = useSettings()
    const Layout = MatxLayouts[settings.activeLayout]

    if (!AuthService.isUsuarioLogadoSelecionado(props.variaveisGlobais)) {
        history.push(PATH.pageSignin)
    }

    return (
        <MatxSuspense>
            <Layout {...props} />
        </MatxSuspense>
    )
}

export default MatxLayout
