import ClienteService from '../../../business-api/ClienteService'

import PropostaService from '../../../business-api/PropostaService'

import VwClienteAcessoPropostaService from '../../../business-api/VwClienteAcessoPropostaService'

import {
    mensagemErroErro
} from '../../../business-components/Toastr/Toastr'



export const getPropostaList = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAcessoPropostaService = new VwClienteAcessoPropostaService()

    try {
        const response = await vwClienteAcessoPropostaService.buscarPropostaList(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}

export const getCountSumProposta = async ( variaveisGlobais, filtroList ) => {

    const vwClienteAcessoPropostaService = new VwClienteAcessoPropostaService()

    try {
        const response = await vwClienteAcessoPropostaService.countSumProposta(variaveisGlobais, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return {}
    }
}

export const getSumProposta = async ( variaveisGlobais, campoAgrupamento, filtroList ) => {

    const vwClienteAcessoPropostaService = new VwClienteAcessoPropostaService()

    try {
        const response = await vwClienteAcessoPropostaService.sumProposta(variaveisGlobais, campoAgrupamento, filtroList)

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)
        
        return []
    }
}



export const putPropostaList = async ( variaveisGlobais, idArquivo, seguradora, jsonPropostaList) => {

    const propostaService = new PropostaService()

    try {

        const response = await propostaService.atualizarPropostaList(variaveisGlobais,
            ClienteService.obterUsuarioClienteSelecionado(variaveisGlobais), idArquivo, seguradora, JSON.stringify(jsonPropostaList))

        return response.data

    } catch (erro) {

        mensagemErroErro(erro)

        return 0
    }
}