// alterado - revisado

import {
    SvgIcon,
} from '@material-ui/core'

import { ReactComponent as iconButtonAniversarioSvg } from './iconButtonAniversario.svg'

import { ReactComponent as iconButtonEditSvg } from './iconButtonEdit.svg'

import { ReactComponent as iconButtonTransferirSvg } from './iconButtonTransferir.svg'

import { ReactComponent as iconButtonClearEntradaDadosSvg } from './iconButtonClearEntradaDados.svg'

import { ReactComponent as iconButtonClearSvg } from './iconButtonClear.svg'

import { ReactComponent as iconButtonVersaoSvg } from './iconButtonVersao.svg'

import { ReactComponent as iconButtonImprimirSvg } from './iconButtonImprimir.svg'

import { ReactComponent as iconButtonHelpSvg } from './iconButtonHelp.svg'

import { ReactComponent as iconButtonExpandMoreSvg } from './iconButtonExpandMore.svg'

import { ReactComponent as iconButtonExpandLessSvg } from './iconButtonExpandLess.svg'

import { ReactComponent as iconButtonWhatsappSvg } from './iconButtonWhatsapp.svg'

import { ReactComponent as iconButtonPDFSvg } from './iconButtonPdf.svg'

import { ReactComponent as iconButtonExcelSvg } from './iconButtonExcel.svg'

import { ReactComponent as iconButtonDocumentSvg } from './iconButtonDocument.svg'

import { ReactComponent as iconButtonConfiguracaoSvg } from './iconButtonConfiguracao.svg'

import { ReactComponent as iconButtonMaisInformacoesSvg } from './iconButtonMaisInformacoes.svg'



export const SvgIconButtonAniversario = ( props ) => {

    const { alerta, ...rest } = props

    return (
        <SvgIcon
            component={iconButtonAniversarioSvg}
            viewBox="0 0 24 30"
            style={
                {
                    color: alerta ? 'red' : 'blue'
                }
            }
            {...rest}
        />        
    )
}



export const SvgIconButtonEditar = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonEditSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonTransferir = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonTransferirSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonClearEntradaDados = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonClearEntradaDadosSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonClear = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonClearSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonVersao = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonVersaoSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}



export const SvgIconButtonImprimir = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonImprimirSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonHelp = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonHelpSvg}
            viewBox="0 0 24 30"
            {...props}
        />        
    )
}



export const SvgIconButtonExpandMore = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonExpandMoreSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}



export const SvgIconButtonExpandLess = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonExpandLessSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}



export const SvgIconButtonWhatsapp = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonWhatsappSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}



export const SvgIconButtonPDF = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonPDFSvg}
            viewBox="0 0 846.98 846.98"
            {...props}
        />        
    )
}



export const SvgIconButtonExcel = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonExcelSvg}
            viewBox="0 0 846.98 846.98"
            {...props}
        />        
    )
}



export const SvgIconButtonDocument = ( props ) => {
    return (
        <SvgIcon
            component={iconButtonDocumentSvg}
            viewBox="0 0 846.98 846.98"
            {...props}
        />        
    )
}



export const SvgIconButtonConfiguracao= ( props ) => {
    return (
        <SvgIcon
            component={iconButtonConfiguracaoSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}



export const SvgIconButtonMaisInformacoes= ( props ) => {
    return (
        <SvgIcon
            component={iconButtonMaisInformacoesSvg}
            viewBox="0 0 24 24"
            {...props}
        />        
    )
}