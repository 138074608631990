// alterado - revisado

const tipoInvestimentoList = [
    {
       id: 10,
       descricao: 'Nunca Investi',
       ordem: 10,
    },
    {
       id:  20,
       descricao: 'Poupança',
       ordem: 20,
    },
    {
       id: 30,
       descricao: 'Previdência Privada',
       ordem: 30,
    },
    {
        id: 40,
        descricao: 'Título de Renda Fixa',
        ordem: 40,
     },
     {
        id: 50,
        descricao: 'Fundos de Investimento',
        ordem: 50,
     },
     {
        id: 60,
        descricao: 'Bolsa de Valores',
        ordem: 60,
     },
     {
        id: 70,
        descricao: 'Derivativos',
        ordem: 70,
     },
 ]
 
 export default tipoInvestimentoList