// alterado - revisado

const whiteLabelList = [
    {
        id: 'www.hci.fin-tracks.com', 
        idConfiguracao: '4a596ad8-b7b3-4749-a470-21af875f587e',
        idWhiteLabel: 1,
    },
    {
        id: 'www.veedha.fin-tracks.com', 
        idConfiguracao: 'ae181122-e371-4564-95be-11af8c0d919a',
        idWhiteLabel: 1,
    },
    {
        id: 'www.planejamento.fiere.com.br',
        idConfiguracao: '0f7e4b4b-a21a-4446-9535-e10c69dc8516',
        idWhiteLabel: 1,
    },
    {
        id: 'www.planejamento.blumare.global',
        idConfiguracao: 'dea6dce0-612c-4606-9b5b-46b97762dabb',
        idWhiteLabel: 1,
    },
    {
        id: 'planejamento.cxbrcapital.com',
        idConfiguracao: 'a43eb48e-fbee-452e-9878-2ff3a200b499',
        idWhiteLabel: 1,
    },
    {
        id: 'planejamento.unkwealth.com.br',
        idConfiguracao: 'a43eb48e-fbee-452e-9878-2ff3a200b499',
        idWhiteLabel: 2,
    },
    //{
    //    id: 'www.centauro.fin-tracks.com', 
    //    idConfiguracao: '94112029-337a-4b8f-b95e-8c5feec20bfc',
    //},
    //{
    //    id: 'www.redoma.fin-tracks.com',
    //    idConfiguracao: 'cb47b584-0058-42a0-8462-cb2b7755e24a',
    //},
    //{
    //    id: 'www.fortuna.fin-tracks.com', 
    //    idConfiguracao: '80a4ceec-9e33-4afc-a210-b9b7a9638227',
    //},
    //{
    //    id: 'www.metlife.fin-tracks.com', 
    //    idConfiguracao: '99b1e78b-66e0-4db7-acca-0e237b2b07b8',
    //},
    //{
    //    id: 'www.desenvolvimento.fin-tracks.com', 
    //    idConfiguracao: '8067a010-3e7c-4e27-a6d9-4543edce4bd9',
    //},
]

export default whiteLabelList