// alterado - importante

export { default as Breadcrumb } from './Breadcrumb/Breadcrumb'
//export { default as MatxDivider } from './MatxDivider/MatxDivider'
export { default as MatxMenu } from './MatxMenu/MatxMenu'
export { default as MatxToolbarMenu } from './MatxToolbarMenu/MatxToolbarMenu'
export { default as MatxLoading } from './MatxLoading/MatxLoading'
export { default as MatxSuspense } from './MatxSuspense/MatxSuspense'
//export { default as MatxSearchBox } from './MatxSearchBox/MatxSearchBox'
export { default as MatxVerticalNav } from './MatxVerticalNav/MatxVerticalNav'
export { default as MatxVerticalNavExpansionPanel } from './MatxVerticalNav/MatxVerticalNavExpansionPanel'
//export { default as MatxHorizontalNav } from './MatxHorizontalNav/MatxHorizontalNav'
export { default as MatxSidenavContainer } from './MatxSidenav/MatxSidenavContainer'
export { default as MatxSidenav } from './MatxSidenav/MatxSidenav'
export { default as MatxSidenavContent } from './MatxSidenav/MatxSidenavContent'
//export { default as Chatbox } from './chat-box-2/Chatbox'
//export { default as ChatAvatar } from './ChatAvatar/ChatAvatar'

export { MatxLayouts } from './MatxLayout/index'
export { default as MatxLayout } from './MatxLayout/MatxLayout'
export { default as MatxTheme } from './MatxTheme/MatxTheme'

//export { default as RectangleAvatar } from './RectangleAvatar/RectangleAvatar'
//export { default as MatxListItem1 } from './MatxListItem1/MatxListItem1'
//export { default as MatxSnackbar } from './MatxSnackbar/MatxSnackbar'
//export { default as ChatHead } from './ChatHead/ChatHead'
//export { default as AddToCartButton } from './AddToCartButton/AddToCartButton'
export { default as GlobalCss } from '../styles/GlobalCss'

//export { EchartTheme } from './MatxTheme/EchartTheme'
//export { default as EchartCreator } from './charts/EchartCreator'
//export { default as RechartCreator } from './charts/RechartCreator'

//export { default as RichTextEditor } from './RichTextEditor/RichTextEditor'
//export { default as ConfirmationDialog } from './ConfirmationDialog/ConfirmationDialog'
//export { default as MatxProgressBar } from './MatxProgressBar/MatxProgressBar'
//export { default as SimpleCard } from './cards/SimpleCard'

//export { default as MatxLogo } from './MatxLogo/MatxLogo'
//export { default as FacebookIcon } from './icons/FacebookIcon'
//export { default as GoogleIcon } from './icons/GoogleIcon'
