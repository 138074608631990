const tipoFinanciamentoList = [
    {
        id: 10,
        descricao: 'Consórcio',
        ordem: 10,
        informaTabelaSAC: 'N',
        informaConsorcio: 'N',
        informaParcelasAdicionais: 'S',//'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'N',
    },
    {
        id: 15,
        descricao: 'Empréstimo',
        ordem: 15,
        informaTabelaSAC: 'N',
        informaConsorcio: 'N',
        informaParcelasAdicionais: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
    {
        id: 20,
        descricao: 'Equipamentos',
        ordem: 20,
        informaTabelaSAC: 'N',
        informaConsorcio: 'N',
        informaParcelasAdicionais: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
    {
        id: 30,
        descricao: 'Imóvel',
        ordem: 30,
        informaTabelaSAC: 'S',
        informaConsorcio: 'S',
        informaParcelasAdicionais: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
    {
        id: 40,
        descricao: 'Terreno',
        ordem: 40,
        informaTabelaSAC: 'N',
        informaConsorcio: 'N',
        informaParcelasAdicionais: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
    {
        id: 50,
        descricao: 'Veículo',
        ordem: 50,
        informaTabelaSAC: 'N',
        informaConsorcio: 'S',
        informaParcelasAdicionais: 'S',//'N',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
    {
        id: 900,
        descricao: 'Outros',
        ordem: 900,
        informaTabelaSAC: 'N',
        informaConsorcio: 'N',
        informaParcelasAdicionais: 'S',
        informaTemSeguro: 'S',
        grupoDespesa: 710,
        ativo: 'S',
    },
]

export default tipoFinanciamentoList