const tipoPilarList = [
    {
       id: 10,
       descricao: 'Carteira Diversificada',
       ordem: 10,
    },
    {
       id:  20,
       descricao: 'Inovações/Tendências',
       ordem: 20,
    },
    {
       id: 30,
       descricao: 'Dolarização de Patrimônio',
       ordem: 30,
    },
    {
        id: 40,
        descricao: 'Planejamento de Proteção Sucessória',
        ordem: 40,
     },
 ]
 
 export default tipoPilarList