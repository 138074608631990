import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    espacoAntes,
} from '../../../business-components/Formata/Formata'

import {
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    getObterConverterPDFBase64FromUrl,
    espaco,
    fimLinha,
    identificaTipoCoberturaSeguroVida,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoXLSX,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
} from './IntegracaoClienteArquivoService'



export const importaApoliceAzos = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replaceAll('\u0000', 'r')

    PDFTxt = PDFTxt.replaceAll('Prorssão', 'Profissão')
    
    PDFTxt = PDFTxt.replaceAll(espaco + 'atéR$' + espaco, espaco + 'até R$' + espaco)

    PDFTxt = PDFTxt.replaceAll(espaco + 'deR$' + espaco, espaco + 'de R$' + espaco)

    PDFTxt = PDFTxt.replaceAll(espaco + 'Nãoaplicado' + espaco, espaco + 'Não aplicado' + espaco)



    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do Segurado' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome do segurado' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CPF' + fimLinha, fimLinha,
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone' + fimLinha, fimLinha,
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Email' + fimLinha, fimLinha,
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de nascimento' + fimLinha, fimLinha,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Sexo' + fimLinha, fimLinha,
        HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão' + fimLinha, fimLinha, 
        HARDCODE.importacaoProfissaoSegurado, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '02 Endereço Residencial' + fimLinha + 'Cidade' + fimLinha, fimLinha,
        HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Estado' + fimLinha, fimLinha,
        HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Rua' + fimLinha, fimLinha,
        HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
        
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro' + fimLinha, fimLinha,
        HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Número' + fimLinha, fimLinha,
        HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Complemento' + fimLinha, fimLinha + '03 Dados da apólice' + fimLinha,
        HARDCODE.importacaoComplementoEnderecoSeguradoCliente, false, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, fimLinha,
        HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Número da Apólice' + fimLinha, fimLinha,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'do dia' + espaco, fimLinha, 
        HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Número da proposta' + fimLinha, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Final de' + fimLinha + 'vigência:' + fimLinha,
        fimLinha + 'Prêmio Líquido' + espaco, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Final de' + fimLinha + 'vigência:' + fimLinha,
            fimLinha + 'Prêmio Líquido' + fimLinha, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Final de' + fimLinha + 'vigência' + fimLinha,
                fimLinha + 'Prêmio Líquido' + espaco, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

            if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

                obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
                    'Tipo de cobertura: Capital segurado: Franquia: Prêmio mensal (cobertura): Final de vigência' + fimLinha,
                        fimLinha + 'Prêmio Líquido Mensal' + fimLinha, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)

                if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

                    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
                        'Tipo de cobertura: Capital segurado: Franquia: Prêmio mensal (cobertura):Final de vigência' + fimLinha,
                            fimLinha + 'Prêmio Líquido Mensal' + fimLinha, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)
                
                    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {
        
                        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha +
                            'Tipo de cobertura: Capital segurado: Franquia: Prêmio anual (cobertura):Final de vigência' + fimLinha,
                                fimLinha + 'Prêmio Líquido Anual' + fimLinha, HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)
                    }
                }
            }
        }
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Periodicidade' + fimLinha, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Cartão de Crédito' + espaco, fimLinha,
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
    }

    

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {
        /*
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'COBERTURA DE' + fimLinha + 'MORTE' + fimLinha,
                    'COBERTURA DE MORTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ' + fimLinha + 'PERMANENTE' + fimLinha + 'TOTAL POR' + fimLinha + 'ACIDENTE' + fimLinha + 'MAJORADA' + fimLinha,
                    'INVALIDEZ PERMANENTE TOTAL POR ACIDENTE MAJORADA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DOENÇAS GRAVES' + fimLinha + '10' + fimLinha + 'ATÉ' + espaco,
                    'DOENÇAS GRAVES 10 ATÉ' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'ASSISTÊNCIA' + fimLinha + 'FUNERAL FAMILIARs' + fimLinha + 'ATÉ' + espaco,
                    'ASSISTÊNCIA FUNERAL FAMILIAR ATÉ' + espaco)
        */
        
        const linhasCobertura = []
        let indexLinhasCobertura = 0

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
        let indexLinhasPosicaoAux = 0
        let linhaCoberturaAux = ''

        const coberturas = []

        let dadosCobertura
        let indexDadosCobertura

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

            if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('/') === -1) {

                linhaCoberturaAux = espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux])

            } else {

                linhasCobertura.push(espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux]))

                linhaCoberturaAux = ''
            }

            indexLinhasPosicaoAux += 1
        }

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

            indexDadosCobertura = 0

            descricaoCobertura = ''

            while (indexDadosCobertura < dadosCobertura.length && dadosCobertura[indexDadosCobertura] !== 'R$') {

                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexDadosCobertura]

                indexDadosCobertura++
            }

            tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            valorCS = trataValorTexto(dadosCobertura[indexDadosCobertura + 1])
            valorPremio = trataValorTexto(dadosCobertura[indexDadosCobertura + 5])

            dataTerminoCobertura = trataData(dadosCobertura[indexDadosCobertura + 6])

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio,
                    classeAjusteRisco, valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
            
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaListaApolicesAzosImplantacao = async (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (XLSX[linha][0]) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][0])

            if (usuarioClienteSelecionado) {

                const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

                const { PDFTxt, mensagemErroPDFTxt } = await getObterConverterPDFBase64FromUrl(variaveisGlobais, XLSX[linha][12])

                if (mensagemErroPDFTxt) {

                    erros.push(MESSAGE().erroPDFTxt
                        .replace("$identificacao$", XLSX[linha][10])
                            .replace("$mensagemErroPDFTxt$", mensagemErroPDFTxt)
                    )

                } else {

                    const jsonRegistrosAux = importaApoliceAzos(PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao,
                        seguradora, nomeArquivo, erros, variaveisGlobais)
                    
                    const qtdeErros = jsonRegistros.jsonRegistros[indexJsonRegistros].qtdeErros

                    jsonRegistros.jsonRegistros[indexJsonRegistros] = {...jsonRegistrosAux[0]}

                    jsonRegistros.jsonRegistros[indexJsonRegistros].qtdeErros = qtdeErros
                    
                    jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado

                    obtemDadoXLSX(XLSX, jsonRegistros, linha, 12, HARDCODE.importacaoUrlApolice, true, null, erros, nomeArquivo)
                }

            } else {
            
                erros.push(MESSAGE().agenteNaoEncontradoApolice
                    .replace("$numeroApolice$", XLSX[linha][10])
                        .replace("$codigoAgente$", XLSX[linha][0])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }

    return jsonRegistros.jsonRegistros
}