const mesesEmergencialList = [
   {
      id: 1, // HARDCODE.idMesesEmergencialDefault
      descricao: 'Sem Emergencial',
      ordem: 1,
      meses: 0,
   },
   {
      id: 6,
      descricao: '6 meses',
      ordem: 6,
      meses: 6,
   },
   {
      id: 12,
      descricao: '12 meses',
      ordem: 12,
      meses: 12,
   },
]

export default mesesEmergencialList