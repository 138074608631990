import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import {
    formataTableCellBodyExibeDadosValorDestaqueValorTexto,
} from '../../../business-components/Formata/Formata'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import morteInvalidezEstudoList from '../../../business-rules/List/morteInvalidezEstudoList'

import tipoGrupoDespesaList from '../../../business-rules/List/tipoGrupoDespesaList'

import tipoPeriodicidadeList from '../../../business-rules/List/tipoPeriodicidadeList'

import {
    dadosCompletosConjuge,
    tituloCliente,
    tituloConjuge,
} from '../cliente-form/ClienteService'



export const calculaReport = (values, report) => {

    return values.disposicaoRelatorio === HARDCODE.disposicaoRelatorioRetrato ? report : false
}



export const calculaDescricao = (descricao, descricaoOutros) => {

    return descricaoOutros
        ?
            descricaoOutros
        : 
            descricao
}

export const anoPrimeiraLetraMaiscula = () => {

    return LABEL.ano.charAt(0).toUpperCase() + LABEL.ano.substring(1)
}

export const calculaDescricaoGrupoDespesa = (grupoDespesa, descricaoGrupoDespesaOutras) => {

    return descricaoGrupoDespesaOutras
        ?
            descricaoGrupoDespesaOutras
        : 
            pesquisaDescricaoList(tipoGrupoDespesaList, grupoDespesa)
}

export const formataValorTipoPeriodicidade = (valor, decimais, semMoeda, destaque, tipoPeriodicidade, tempo, report) => {

    return formataTableCellBodyExibeDadosValorDestaqueValorTexto(valor, decimais, semMoeda, destaque,
        !tipoPeriodicidade || tipoPeriodicidade === HARDCODE.idTipoPeriodicidadeMensal
            ?
                ''
            :
                tempo
                    ?
                        pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).descricaoTempo.substring(0, 
                            pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).descricaoTempo.length -
                                (valor === 1 ? 1 : 0))
                    :
                        pesquisaList(tipoPeriodicidadeList(false, false), tipoPeriodicidade).descricao,
        report)
}

export const tituloEstudoMorteInvalidez = (values, idadeMorteAte) => {

    return pesquisaList(morteInvalidezEstudoList(values, idadeMorteAte), values.morteInvalidezEstudo).descricaoResumida
}

export const tituloEstudoResponsabilidade = () => {

    return LABEL.responsabilidades
}

export const tituloEstudoMorteInvalidezCliente = ( values ) => {
    return tituloEstudoMorteInvalidez(values, values.idadeSimulacaoMorteCliente) + "<br/>" + tituloCliente(values)
}

export const tituloEstudoMorteInvalidezConjuge = ( values ) => {
    return tituloEstudoMorteInvalidez(values, values.idadeSimulacaoMorteConjuge) + "<br/>" + tituloConjuge(values)
}

export const tituloEstudoResponsabilidadeCliente = (values) => {

    return tituloEstudoResponsabilidade() + "<br/>" + tituloEstudoMorteInvalidezCliente(values)
}

export const tituloEstudoResponsabilidadeConjuge = (values) => {

    return tituloEstudoResponsabilidade() + "<br/>" + tituloEstudoMorteInvalidezConjuge(values)
}

export const tituloEstudoGarantia = () => {

    return LABEL.garantias
}

export const tituloEstudoGarantiaCliente = (values) => {

    return tituloEstudoGarantia() + "<br/>" + tituloEstudoMorteInvalidezCliente(values)
}

export const tituloEstudoGarantiaConjuge = (values) => {
    
    return tituloEstudoGarantia() + "<br/>" + tituloEstudoMorteInvalidezConjuge(values)
}



export const tituloSimulacaoMorteClienteConjuge = ( values ) => {
    
    return values.idadeSimulacaoMorteCliente + ' ' + LABEL.anos + ' ' + tituloCliente(values) +
        (dadosCompletosConjuge(values)
            ?
                ' / ' + values.idadeSimulacaoMorteConjuge + ' ' + LABEL.anos + ' ' + tituloConjuge(values)
            :
                ''
        )
}



export const formataTempoCobertura = ( anos, brancoSemAnos ) => {
    return  anos || anos === 0
        ?
            anos + " " + (anos > 1 ? LABEL.anos : anos === LABEL.vitalicio ? "" : LABEL.ano)
        :
            brancoSemAnos
                ?
                    ''
                :
                    '-'
}



export const calculaIdadeCorrespondenteAno = (values, estudo, ano, familiar) => {

    return (
            familiar && familiar === HARDCODE.idFamiliarConjuge ? values.idadeDataSimulacaoConjuge : values.idadeDataSimulacaoCliente
        ) + ano - estudo.current.anoAtual
}



export const ateMais100 = (idade) => {

    return idade
        ?
            idade
        :
            LABEL.ateMais100
}



export const exibirRelatorioModoApresentacao = (values) => {

    return (
        values.tabEstudoFormList === LABEL.resumo ||
        values.tabEstudoFormList === LABEL.gestaoPatrimonial ||
        //values.tabEstudoFormList === LABEL.previdenciaPrivada ||
        values.tabEstudoFormList === LABEL.relatorio ||
        //values.tabEstudoFormList === LABEL.consorcio ||
        values.tabEstudoFormList === LABEL.comparacaoFinanciamentos ||
        values.tabEstudoFormList === LABEL.comparacaoCenarios ||
        values.tabEstudoFormList === LABEL.comparacaoEstudos
    )
}



export const relatorioModoApresentacao = (values) => {

    return exibirRelatorioModoApresentacao(values) && values.modoApresentacao
}