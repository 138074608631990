import HARDCODE from '../../business-const/HardCode/HardCode'

import {
    differenceInDays,
    differenceInYears,
    parseISO,
    format,
} from 'date-fns'

import {
    pesquisaList
} from '../../business-rules/List/ListService'



export const dataAtual = () => {
        
    return new Date()
}


export const dataValida = (data) => {

    return data && '-' + data + '-' !== HARDCODE.invalidDate
}

export const dataValidaOuNull = (data) => {

    return !data || dataValida(data)
}

export const difDatasDias = (data1, data2) => {

    return differenceInDays(data2, data1)
}

export const difDatasAnos = (data1, data2) => {

    return differenceInYears(data2, data1)
}

export const difDatasStringAnos = (data1, data2) => {

    return difDatasAnos(parseISO(formataDataAmericana(data1)), parseISO(formataDataAmericana(data2)))
}

export const difMesAnoMeses = ( mes1, ano1, mes2, ano2 ) => {

    let meses = 0

    if (ano2 >= ano1) {

        if (ano2 === ano1) {

            if (mes2 >= mes1) {

                meses += mes2 - mes1 + 1
            }

        } else {

            meses += 12 - mes1 + 1

            meses += (ano2 - ano1 - 1) * 12

            meses += mes2
        } 
    } 

    return meses
}

export const ano = (data) => {

    return dataValida(data) ? parseISO(formataDataAmericana(data)).getFullYear() : null
}

export const mes = (data) => {

    return dataValida(data) ? parseISO(formataDataAmericana(data)).getMonth() + 1 : null
}

export const dia = (data) => {

    return dataValida(data) ? parseISO(formataDataAmericana(data)).getDate() : null
}

export const mesMesAno = (mesAno) => {

    return mesAno ? parseInt(mesAno.substring(0, 2)) : mesAno
}

export const anoMesAno = (mesAno) => {

    return mesAno ? parseInt(mesAno.substring(2, 6)) : mesAno
}

export const diaDiaMes = (diaMes) => {

    return diaMes ? parseInt(diaMes.substring(0, 2)) : diaMes
}

export const mesDiaMes = (diaMes) => {

    return diaMes ? parseInt(diaMes.substring(2, 4)) : diaMes
}

export const dataAnoMesDia = (ano, mes, dia) => {

    return new Date(ano, mes - 1, dia)
}

export const dataHoraAnoMesDiaHoraMinuto = (ano, mes, dia, hora, minuto) => {

    return new Date(ano, mes - 1, dia, hora, minuto)
}

export const primeiroDiaDataInformada = (data) => {

    return dataAnoMesDia(ano(data), mes(data), 1)
}

export const primeiroDiaMesAtual = () => {

    return primeiroDiaDataInformada(dataAtual())
}

export const ultimoDiaMesAtual = () => {

    const dataAtualAux = dataAtual()

    return dataAnoMesDia(ano(dataAtualAux), mes(dataAtualAux) + 1, 0)
}

export const formataData = (data) => {

    return dataValida(data) ? format(Date.parse(data), "dd/MM/yyyy") : null
}

export const arrayDataToData = (arrayData) => {

    return dataAnoMesDia(arrayData[0], arrayData[1], arrayData[2])
}

export const formataArrayData = (arrayData) => {

    return arrayData ? formataData(arrayDataToData(arrayData)) : null
}

export const formataDataHora = (dataHora) => {

    return dataHora  && dataValida(dataHora) ? format(Date.parse(dataHora), "dd/MM/yyyy HH:mm") : ''
}

export const arrayDataHoraToDataHora = (arrayDataHora) => {

    return dataHoraAnoMesDiaHoraMinuto(
        arrayDataHora[0], arrayDataHora[1], arrayDataHora[2], arrayDataHora[3], arrayDataHora[4]
    )
}

export const formataArrayDataHora = (arrayDataHora) => {

    return arrayDataHora
        ? 
            formataDataHora(arrayDataHoraToDataHora(arrayDataHora))
        :
            null
}

export const formataDataAmericana = (data) => {

    return data ? format(Date.parse(data), "yyyy-MM-dd") : ''
}

export const formataArrayDataAmericana = (arrayData) => {

    return arrayData ? format(arrayDataToData(arrayData), "yyyy-MM-dd") : ''
}

export const formataDataAmericanaSemTraco = (data) => {

    return data ? format(Date.parse(data), "yyyyMMdd") : ''
}

export const formataDataEmMesAnoSemBarra = (data) => {

    return format(Date.parse(data), "MMyyyy")
}

export const formataMesAnoComBarra = (mes, ano) => {

    return mes && ano ? (('0' + mes).slice(-2) + '/' + ano) : (mes + '' + ano)
}

export const formataMesAnoSemBarra = (mes, ano) => {

    return mes && ano ? (('0' + mes).slice(-2) + ano) : (mes + '' + ano)
}

export const formataAnoMesSemBarra = (mes, ano) => {

    return mes && ano ? (ano + ('0' + mes).slice(-2)) : (ano + '' + mes)
}

export const formataDataEmDiaMesComBarra = (data) => {

    return data ? format(Date.parse(data), "dd/MM") : data
}

export const formataMesAnoTextoComBarra = (mesAno) => {

    return mesAno ? ('0' + mesMesAno(mesAno)).slice(-2) + '/' + anoMesAno(mesAno) : mesAno
}

export const formataAnoMesInteiroComBarra = (anoMes) => {

    return anoMes ? ('' + anoMes).substring(0, 4) + '/' + ('' + anoMes).substring(4, 6) : anoMes
}

export const formataMesDiaInteiroDiaMesComBarra = (mesDia) => {

    return mesDia ? ('0' + mesDia).slice(-4).substring(2, 4) + '/' + ('0' + mesDia).slice(-4).substring(0, 2) : mesDia
}

export const calculaIdade = (data) => {
    
    return difDatasAnos (parseISO(formataDataAmericana(data)), dataAtual())
}

export const calculaIdadeFutura = (data, dataFutura) => {
    
    return difDatasAnos (parseISO(formataDataAmericana(data)), dataFutura)
}

export const calculaMeses = (mesAnoInicial, mesAnoFinal) => {
    
    const mesInicial = mesMesAno(mesAnoInicial)
    const anoInicial = anoMesAno(mesAnoInicial)
    
    const mesFinal = mesMesAno(mesAnoFinal)
    const anoFinal = anoMesAno(mesAnoFinal)

    let meses = 0

    if (anoFinal >= anoInicial) {

        if (anoInicial === anoFinal) {

            if (mesFinal >= mesInicial) {

                meses = mesFinal - mesInicial + 1
            }

        } else {

            meses = 12 - mesInicial + 1 + mesFinal + ((anoFinal - anoInicial - 1) * 12)
        }
    }

    return meses
}

export const calculaMesAnoFinal = (mesInicial, anoInicial, meses) => {

    const mesesTotal = mesInicial + meses - 1

    const anos = Math.trunc(mesesTotal / 12)

    const mesFinal = mesesTotal - (anos * 12) === 0 ? 12 : mesesTotal - (anos * 12)

    const anoFinal = anoInicial + anos - 1 + (mesesTotal % 12 === 0 ? 0 : 1)

    return formataMesAnoSemBarra(mesFinal, anoFinal)
}

export const calculaMaiorMesAno = (mes1, ano1, mes2, ano2) => {
    
    let mes
    let ano

    if (ano1 > ano2) {

        mes = mes1
        ano = ano1

    } else {

        if (ano2 > ano1) {

            mes = mes2
            ano = ano2

        } else {

            if (mes1 > mes2) {
                
                mes = mes1

            } else {

                mes = mes2
            }

            ano = ano1
        }
    }

    return { mes, ano }
}


const converteMesExtensoMes = (mesExtenso) => {

    const mesExtensoList = [
        {id: 'JANEIRO', mes: 1},
        {id: 'FEVEREIRO', mes: 2},
        {id: 'MARÇO', mes: 3},
        {id: 'ABRIL', mes: 4},
        {id: 'MAIO', mes: 5},
        {id: 'JUNHO', mes: 6},
        {id: 'JULHO', mes: 7},
        {id: 'AGOSTO', mes: 8},
        {id: 'SETEMBRO', mes: 9},
        {id: 'OUTUBRO', mes: 10},
        {id: 'NOVEMBRO', mes: 11},
        {id: 'DEZEMBRO', mes: 12},
    ]

    return pesquisaList(mesExtensoList, mesExtenso).mes
}

export const converteDataExtensoData = (dataExtenso) => {

    const dataExtensoSplit = dataExtenso.split(' ')

    return dataAnoMesDia(dataExtensoSplit[5], converteMesExtensoMes(dataExtensoSplit[3]), dataExtensoSplit[1])
}

const stringToDate = (stringData, format) => {

    var normalized = stringData.replace(/[^a-zA-Z0-9]/g, '-')
    var normalizedFormat= format.toLowerCase().replace(/[^a-zA-Z0-9]/g, '-')
    var formatItems = normalizedFormat.split('-')
    var dateItems = normalized.split('-')

    var monthIndex = formatItems.indexOf("mm")
    var dayIndex = formatItems.indexOf("dd")
    var yearIndex = formatItems.indexOf("yyyy")
    var hourIndex = formatItems.indexOf("hh")
    var minutesIndex = formatItems.indexOf("ii")
    var secondsIndex = formatItems.indexOf("ss")

    var today = dataAtual();

    var year = yearIndex>-1 ? dateItems[yearIndex] : today.getFullYear()
    var month = monthIndex>-1 ? dateItems[monthIndex]-1 : today.getMonth()-1
    var day  = dayIndex>-1 ? dateItems[dayIndex] : today.getDate()

    var hour = hourIndex>-1 ? dateItems[hourIndex] : today.getHours()
    var minute = minutesIndex>-1 ? dateItems[minutesIndex] : today.getMinutes()
    var second = secondsIndex>-1 ? dateItems[secondsIndex] : today.getSeconds()

    return new Date(year, month, day, hour, minute, second)
}

export const stringParaData = ( stringData, format ) => {
    
    return stringToDate(stringData, format ? format : "yyyy-mm-dd hh:ii:ss")
}

export const dataAtualSemHora = () => {
        
        return parseISO(formataDataAmericana(dataAtual()))
}

export const somaDiasData = (data, dias) => {

    if (data) {

        data.setDate(data.getDate() + dias)
    }

    return data
}

export const somaAnosData = (data, anos) => {

    if (data) {

        data.setFullYear(data.getFullYear() + anos) 
    }

    return data
}



export const  comparaData = (data1, data2) => {

    return (!data1 && !data2) || (data1 && data2 && JSON.stringify({data: data1}) === JSON.stringify({data: data2}))
}