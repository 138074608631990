import HARDCODE from '../../business-const/HardCode/HardCode'



const acumulacaoList = [
    {
       id: HARDCODE.analisePatrimonialChart,
    },
    {
        id: HARDCODE.receitaAnualChart,
    },
    {
        id: HARDCODE.despesaAnualChart,
    },
    {
        id: HARDCODE.balancoReceitaXDespesaAnualChart,
    },
    {
        id: HARDCODE.consumoReservaAposentadoriaChart,
    },
    {
        id: HARDCODE.diagnosticoAposentadoriaChart,
    },
    {
        id: HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraEstudoChart,
    },
    {
        id: HARDCODE.estudoFormProjetoVidaSonhoTracarMeta,
    },
 ]
  
 export default acumulacaoList