import HARDCODE from '../HardCode/HardCode'

import LABEL from '../Label/Label'

import {
    STYLEHTML
} from '../../business-const/Style/Style'

import {
    tituloCliente,
    tituloConjuge,
} from '../../views/clientes/cliente-form/ClienteService'

const messageObrigatorio = 'obrigatório'

const MESSAGE = ( values, report ) => {

    return {
        alertaInformacoesNaoSalvas: 'Existem informações que não foram salvas. Você quer sair sem salvar?',

        campoObrigatorio: messageObrigatorio,

        campoImportanteITCMD: 'importante para a obtenção da alíquota do ITCMD no cálculo do Inventário',
        campoDesejavel: 'desejável',

        campoObrigatorioRadioGroup:
            <span
                className="m-0 text-helperText text-error"
            >
                {messageObrigatorio}
            </span>,


        sucesso: 'Sucesso',
        informacao: 'Informação',
        alerta: 'Alerta',
        erro: 'Erro',



        sessaoExpirou: 'A sua sessão expirou. Por favor, faça o login novamente.',
        sessaoExpirouToken: 'A sua sessão expirou em função de ter ocorrido login com o mesmo E-mail em outro dispositivo ou browser. Por favor, faça o login novamente.',

        formatoPadraoSenha:
            'A senha precisa ter:<br/>' +
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Pelo menos 1 letra maiúscula<br/>' +
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Pelo menos 1 letra minúscula<br/>' +
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Pelo menos 1 número<br/>' +
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Pelo menos 1 caractere especial' + HARDCODE.htmlEspaco4 + '<b>#?!@$%^&*-+_</b><br/>' +
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Não pode conter espaços em branco<br/>' + 
            HARDCODE.htmlEspaco4 + HARDCODE.htmlMarcadorCircle + ' Mínimo 8 caracteres',

        pinEnviadoComSucesso: 'PIN enviado com sucesso!',

        senhasDifererentes: 'As senhas informadas estão diferentes.',

        emailDiferenteEnviado: 'O E-mail foi alterado e por isso um novo PIN deve ser gerado.',

        erroTipoIntegracao: 'Houve um erro na integração.',

        alertaMfa: 'Enviamos um PIN para o seu E-mail para garantir que o acesso está sendo feito pelo usuário proprietário dos dados e da licença. Por favor, verifique o seu E-mail e informe o PIN enviado no campo abaixo, para então proceder com o acesso à Fin-Track$. Eventualmente o E-mail enviado pode cair na caixa de SPAM.',
        alertaQtdeLogins: 'Este é o $qtdeLogins$o login feito no dia de hoje. Normalmente não é necessário fazer mais de um login no mesmo dia. Então, por questões de segurança da informação, LGPD e regras de licenciamento, enviamos um PIN para o seu E-mail para garantir que o acesso está sendo feito pelo usuário proprietário dos dados e da licença. Por favor, verifique o seu E-mail e informe o PIN enviado no campo abaixo, para então proceder com o acesso à Fin-Track$. Eventualmente o E-mail enviado pode cair na caixa de SPAM.',// 'Importante ressaltar que o compartilhamento de login é proibido, e quando detectado, pode causar o bloqueio do acesso aos dados até que a situação de compartilhamento seja regularizada.',



        respostaLinkVencido: 'O link que você utilizou está vencido. Por favor, entre em contato com o suporte: ' + HARDCODE.whatsappText + '.',
        respostaProcessadaComSucesso: 'A sua resposta foi processada com sucesso. Muito obrigado!',
        respostaNaoProcessadaComSucesso: 'Não foi possível processar a resposta enviada. Por favor, entre em contato com o suporte: ' + HARDCODE.whatsappText + '.',
        videoRedirecinando: 'Redirecinando para o $1$.',
        videoNaoFoiPossivelRedirecionar: 'Não foi possível redirecionar para o $1$. Por favor, entre em contato com o suporte: ' + HARDCODE.whatsappText + '.',


        linkInvalido: 'Link inválido.',
        linkSignatureExpirou: 'O link da promoção da assinatura expirou.',
        linkSignatureNaoPodeSerAplicado: 'O link da promoção da assinatura não pode ser aplicado.',
        linkSignatureAplicadoComSucesso: 'Link promocional aplicado com sucesso.',



        cadastrarDadosEmpresaEndereco: 'Por favor, cadastre os dados da Empresa e o Endereço.',
        realizarAssinatura: 'O período do Free-track terminou. É necessário realizar a assinatura para continuar utilizando o Fin-tracks.',
        cobrancaEmAtraso: 'Existe uma cobrança em aberto a mais de 15 dias. A partir de 30 dias de atraso, o acesso ao sistema será bloqueado e só será liberado no dia seguinte a regulização do pagamento pendente.',
        acessoBloqueadoPagtoAtraso: 'Existem cobranças em aberto a mais de 30 dias. Por favor, realize o pagamento para liberar o acesso ao sistema. O acesso será liberado no dia seguinte a regulização dos pagamentos pendentes.',

        

        orientacaoTokenIntegracao: 'Esses tokens não poderão ser consultados posteriormente.<br><br>Caso seja necessário, será possível gerar um novo token para habilitar a integração.',



        f5NomeCurto: '(*) Se o campo "Nome Curto" for informado ou alterado, após salvar, tecle F5 para refletir a mudança no topo da tela e no relatório imediatamente.',



        cartaoCreditoName: 'Nome Impresso no Cartão',
        cartaoCreditoValid: 'Válido até',

        cartaoCreditoInvalidCardNumber: 'O número do cartão está inválido',
        cartaoCreditoInvalidCardNumberIncompleto: 'O número do cartão precisa ter 16 dígitos',
        cartaoCreditoInvalidExpiryDate: 'A data de expiração está inválida',
        cartaoCreditoMonthOutOfRange: 'O mês de expiração deve estar entre 01 e 12',
        cartaoCreditoYearOutOfRange: 'O ano de expiração não pode estar no passado',
        cartaoCreditoDateOutOfRange: 'A data de expiração não pode estar no passado',
        cartaoCreditoInvalidCvc: 'O Cvc está inválido',
        cartaoCreditoCardNumberPlaceholder: 'Número do Cartão',
        cartaoCreditoExpiryPlaceholder: 'MM/AA',
        cartaoCreditoCvcPlaceholder: 'CVC',

        dadosNecessariosPagamentoBoleto: 'Para realizar a assinatura pagando com boleto é necessário informar a Razão Social, o CNPJ e os dados do Endereço no item 1. Dados Cadastrais',



        formatoBackgroundCapaRelatorio: 'Formato ideal: Largura: 818px, Altura: 1065px',
        formatoBackgroundCapaRelatorioPaginaInteira: 'Formato ideal: Largura: 820px, Altura: 1169px',
        formatoBackgroundCapaRelatorioPaisagem: 'Formato ideal: Largura: 816px, Altura: 1028px',
        formatoBackgroundCapaRelatorioPaisagemPaginaInteira: 'Formato ideal: Largura: 816px, Altura: 1128px',

        formatoBackgroundPaginaRelatorio: 'Formato máximo: Largura: 816px, Altura: 1157px',
        formatoBackgroundPaginaRelatorioPaisagem: 'Formato máximo: Largura: 816px, Altura: 1128px',

        f5Imagem: 'Clique em Salvar e tecle F5 para visualizar as mudanças imediatamente.',
        f5Cor: 'Tecle F5 para visualizar as mudanças imediatamente.',
        //formatoMaximoImagemPaginaPaginaAdicional: 'Formato máximo de uma imagem: Largura: 680px, Altura: 900px',
        //formatoMaximoImagemPaginaPaginaAdicionalPaginaInteira: 'Formato máximo de uma imagem: Largura: 1250px, Altura: 1780px',
        


        excelNomeColunaInvalido: 'Linha $linha$ - Nome da coluna inválido: $nomeColuna$',
        excelValorListaInvalido: 'Linha $linha$ - Valor não encontrado na lista do campo $campo$: $valor$',
        pdfValorListaInvalido: '$identificacao$ - Valor não encontrado na lista do campo $campo$: $valor$',
        excelValorInvalido: 'Linha $linha$ - Valor inválido do campo $campo$: $valor$',
        pdfValorInvalido: '$identificacao$ - Valor inválido do campo $campo$: $valor$',
        campoNaoEncontradoPDFInicio: '$identificacao$ - Campo não encontrado (início): $campo$',
        campoNaoEncontradoPDFFim: '$identificacao$ - Campo não encontrado (fim): $campo$',
        campoNaoConvertido: '$identificacao$ - Não foi possível realizar a conversão: $campo$ - $valor$',
        campoNaoEncontradoXLSX: '$identificacao$ - Campo não encontrado: $campo$',
        campoNaoEncontradoPDF: '$identificacao$ - Campo não encontrado: $campo$',
        agenteNaoEncontradoProposta: 'Proposta $numeroProposta$ - Agente não encontrado: $codigoAgente$',
        agenteNaoEncontradoApolice: 'Apólice $numeroApolice$ - Agente não encontrado: $codigoAgente$',
        agenteNaoEncontradoCpfSeguradoCliente: 'CPF $cpfSeguradoCliente$ - Agente não encontrado: $codigoAgente$',
        tipoCoberturaSeguroVidaNaoEncontrado: '$identificacao$ - Tipo de cobertura de seguro de vida não encontrada: $descricaoCobertura$ (Segurado: $cpfSegurado$ - $nomeSegurado$)',
        statusCoberturaNaoEncontrado: '$identificacao$ - Status de cobertura de seguro de vida não encontrada: $descricaoStatusCobertura$ (Segurado: $cpfSegurado$ - $nomeSegurado$)',
        erroPDFTxt: '$identificacao$ - Erro PDF: $mensagemErroPDFTxt$',
        numeroContaNaoEncontrado: '$identificacao$ - Conta não encontrada: $numeroConta$',
        cpfNaoInformadonumeroContaEncontrado: '$identificacao$ - CPF não informado para a conta: $numeroConta$',
        encontradoMaisDeUmClienteCpfSeguradoCliente: '$identificacao$ - Foi encontrado mais de um cliente para o mesmo CPF: $cpfSeguradoCliente$ - $nomeSeguradoCliente$',
        encontradoMaisDeUmClienteNomeNascimentoSeguradoCliente: '$identificacao$ - Foi encontrado mais de um cliente para o mesmo Nome e Nascimento: $nomeSeguradoCliente$ - $nascimentoSeguradoCliente$',
        encontradoMaisDeUmClienteCpfRespPagto: '$identificacao$ - Foi encontrado mais de um cliente para o mesmo CPF do Responsável pelo Pagamento: $cpfRespPagto$ - $nomeRespPagto$',
        encontradoMaisDeUmClienteNomeNascimentoRespPagto: '$identificacao$ - Foi encontrado mais de um cliente para o mesmo Nome e Nascimento do Responsável pelo Pagamento: $nomeRespPagto$ - $nascimentoRespPagto$',
        erroAtualizacaoPosicaoInvestimentos: '$identificacao$ - Erro na atualização da Posição de Investimento do Cliente: $cpfSeguradoCliente$ - Conta: $numeroConta$',
        erroGravacaoCliente: '$identificacao$ - Erro na atualização do Cliente: $cpfSeguradoCliente$ - $nomeSeguradoCliente$',
        clienteEncontradoPertenceOutroUsuario: '$identificacao$ - O cliente encontrado pertence a outro usuário: $cpfSeguradoCliente$ - $nomeSeguradoCliente$',
        inconsistenciaPesquisaCpfSeguradoCliente: '$identificacao$ - Inconsistência na pesquisa do CPF: $cpfSeguradoCliente$ - $nomeSeguradoCliente$',
        respPagtoDiferenteSeguradoCpfNaoEncontrado: '$identificacao$ - Responsável pelo pagamento é diferente do segurado e não foi encontrado o responsável pelo pagamento: $cpfRespPagto$ - $nomeRespPagto$',
        encontradoMaisDeUmClienteCpfSeguradoClienteTransferencia: 'Foi encontrado mais de um cliente para o mesmo CPF: $cpfSeguradoCliente$',
        naoFoiEncontradoSeguradoClienteTransferencia: 'Não foi encontrado o cliente para o CPF: $cpfSeguradoCliente$',
        ocorreuErroTransferenciaSeguradoClienteTransferencia: 'Ocorreu um erro na transferência do CPF: $cpfSeguradoCliente$',
        importarClienteExcelFormErroAberturaArquivoExcel: 'Ocorreu um erro na abertura do arquivo Excel. Normalmente isso ocorre quando o arquivo Excel é alterado após ser selecionado na aplicação. Se for esse o caso, basta repetir o processo de importação do arquivo.<br/><br/><br/>Mensagem de erro para fins de suporte:<br/><br/>$mensagemErro$',
        statusIntegracaoCliente: 'Uploads processados = $1$<br><br>Uploads não processados (arquivos cujo o layout não foi reconhecido) = $2$<br><br>Erros = $3$<br><br><b>Total Arquivos</b> = $4$',
        statusProcessamentoIntegracao: 'Arquivos processados = $1$<br><br>Arquivos em processamento = $2$<br><br>Arquivos com erro = $3$<br><br><b>Total de arquivos</b> = $4$<br><br><b>Registros processados</b> = $5$',
        statusImportacaoPDFListaErros: '<br><br>Lista de Erros:<br><br>$1$',
        layoutNaoParametrizadoSistema: 'O layout do arquivo PDF não foi parametrizado no sistema.',
        statusIntegracaoCotacaoTabelaEvolucaoValorResgate: 'Ocorreram erros no processamento do arquivo PDF = $1$' +
            '<br/><br/>$2$',
        importarClienteExcelFormQtdeErro: '1 erro encontrado.',
        importarClienteExcelFormQtdeErros: '$qtdeErros$ erros encontrados.',
        importarClienteExcelFormQtdeCadastrado: '1 cadastro realizado.',
        importarClienteExcelFormQtdeCadastrados: '$qtdeCadastros$ cadastros realizados com sucesso.',
        importarClienteExcelFormOrientacaoErros: 'Você pode consultar as regras de preenchimento da planilha no Help.',



        dataInvalida: 'data inválida',
        dataHoraInvalida: 'data/hora inválida',

        cpfInvalido: LABEL.cpf + ' inválido',

        cnpjInvalido: LABEL.cnpj + ' inválido',

        diaInvalido: 'Dia inválido (01 ... 31)',

        mesInvalido: 'Mês inválido (01 ... 12)',

        mesAnoInvalido: 'Mês/Ano inválido',

        diaMesInvalido: 'Dia/Mês inválido',

        tamanhoMaximo: 'Tamanho máximo de $tamMax$ caracteres',

        numeroMinimo: 'Número mínimo = $numMin$',

        numeroMaximo: 'Número máximo = $numMax$',

        emailInvalido: 'Este E-mail é inválido',


        dadosObrigatoriosClienteDevemSerInformados: 'Os dados obrigatórios do cliente devem ser informados.',
        
        
        
        dadosInvalidosTracarMeta: 'Verifique os dados informados para garantir a compatibilidade do período de acumulação com a realização do projeto de vida/sonho e a periodicidade dos aportes.',



        incluidoPerguntas: 'Incluído a partir das informações da 1ª Reunião',

        incluidoIntegracao: 'Incluído a partir da Integração',

        aluguelIncluidoPatrimonioImobilizado: 'Incluído a partir das informações do Patrimônio Imobilizado',

        fgtsContribuicaoIncluidoReceitaMensal: 'Incluído a partir das informações da Receita Mensal',

        seguroVidaIncluidoReceitaMensal: 'Incluído a partir das informações da Receita Mensal',



        gerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico:
            '<span ' +  STYLEHTML().styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico + '>' + 
            'Valor $descricao$ = </span>$1$<br/><br/>' +
            '<span ' +  STYLEHTML().styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico + '>' + 
            'Valor Presente Início $descricao$ = </span>$2$<br/><br/>' +
            '<span ' +  STYLEHTML().styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico + '>' + 
            'Meses para acumular o valor necessário para realizar $descricao$ = </span>$3$<br/><br/>' +
            '<span ' +  STYLEHTML().styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico + '>' + 
            'Mês/Ano Final do Período de Acumulação = </span>$4$<br/><br/>' +
            '<span ' +  STYLEHTML().styleHtmlGerarLancamentosPlanejamentoEspecializacaoPeriodoSabatico + '>' + 
            'Mês/Ano a partir do qual é víavel realizar $descricao$  = </span>$5$',



        estudoReportPrimeiraReuniaoTextoAbertura: 'Olá $nomeCurtoCliente$,' +
            '<br/><br/>' + 
            'Gostaria de agradecer o tempo dedicado para nossa conversa de hoje. Estou enviando um relatório com os principais destaques financeiros que comentamos.',
        
        estudoReportCapaTitulo1: 'Planejamento Financeiro de Padrão de Vida',
        profissaoQualificacao: 'Guia Financeiro',
        estudoReportCapaTitulo2: 'a sua trilha financeira',

        estudoReportOQueEsperarDesseTrabalho: 'Aqui começa nossa jornada em busca do <b>planejamento financeiro para manutenção de seu padrão de vida</b>, de forma segura e perene.<br/><br/>' +

            'A ideia do relatório é traduzir em números <b>as suas necessidades de proteção e acumulação financeira</b>, a fim de identificar, atingir e garantir o seu plano de vida.<br/><br/>' +
        
            'A partir do diagnóstico financeiro você saberá quais os <b>riscos inerentes à perda de renda precoce e à sobrevivência por longo período, sem condições financeiras</b>.<br/><br/>' +
            'Ou seja, transformar sonhos em metas, avaliando quando, quanto e o onde investir seu dinheiro para torná-los realidade, custe o que custar, pois os riscos não podem ser desconsiderados!<br/><br/>' +
        
            'Para o <b>diagnóstico de ' + LABEL.aposentadoria.toLowerCase() + '</b>, a partir de hipóteses de rentabilidade líquida de sua carteira de investimentos, garantias atuais de renda decorrentes de pensões públicas e privada, aluguéis e de negócios que independam da sua força de trabalho, faremos um diagnóstico que pode resultar em três cenários:<br/><br/>' +
        
            '&#9679 <span style="color: green"><b>Quem já têm recursos, rendas e garantias excedentes à suas necessidades</b></span>, dentro da expectativa de vida estimada, e<br/>' +
            '&nbsp;&nbsp;&nbsp;&nbsp;que, portanto, podem viver até melhor do que hoje pois tudo vai correr bem;<br/>' +
            '&#9679 <span style="color: gold"><b>Quem já têm alguns recursos, rendas e garantias, mas são insuficientes</b></span> ou estão mal aplicadas, podendo levar ao não<br/>' +
            '&nbsp;&nbsp;&nbsp;&nbsp;atingimento dos objetivos e requerem alguns ajustes;<br/>' +
            '&#9679 <span style="color: red"><b>E os que estão em situação complicada</b></span>, pois tem poucas reservas, nenhuma ou pouca renda garantida e nenhum ou<br/>' +
            '&nbsp;&nbsp;&nbsp;&nbsp;pouco seguro, e com certeza precisam tomar atitudes com urgência para corrigir sua rota em busca do padrão de vida<br/>' +
            '&nbsp;&nbsp;&nbsp;&nbsp;perene.<br/><br/>' +
        
            'Caso precise guardar mais dinheiro as sugestões podem ser fazer previdência privada, com VGBL ou PGBL, dependendo do tipo de declaração de IR, e da possibilidade de abater despesas dedutíveis. Outra possibilidade seria fazer também uma carteira de investimentos. E para quem gosta de renda, investir em imóveis, fundos imobiliários ou ações que pagam dividendos.<br/><br/>' +
        
            'Após a sobrevivência na ' + LABEL.aposentadoria.toLowerCase() + ', trataremos os riscos de perder a capacidade de geração de renda precocemente, por invalidez temporária ou permanente, e em caso de morte.<br/><br/>' +
        
            'Quanto à proteção de riscos, precisamos avaliar quanto e quais tipos de seguros de pessoas os clientes precisam.<br/><br/>' +
        
            'As coberturas de proteção de renda podem ser os seguros de incapacidade temporária (<b>DIT</b>), diárias por internação hospitalar (<b>DIH</b>), que protegem de forma mensal, e por um prazo limitado a no máximo um ano, e os seguros de invalidez parcial ou total, por acidente ou doença (<b>IFPA e IFPD</b>), que pagam um capital à vista, ou em forma de renda, temporária ou vitalícia. As coberturas de doenças graves (<b>DG</b>) pagam um capital à vista, independente da incapacidade gerada, ao segurado em caso de diagnóstico de doença ou procedimento cirúrgico coberto.<br/><br/>' +
        
            'Já os <b>seguros de morte podem ser temporários ou vitalícios</b>, que aumentam os prêmios devido ao aumento da idade, ou nivelados, que mantêm o mesmo prêmio durante a vigência da apólice. Há ainda os seguros <b>Dotais</b>, que pagam uma reserva por sobrevivência ao final do prazo.<br/><br/>' +
        
            'Por vezes o cliente pode até ter coberturas de seguro, porém estes podem não ser os mais indicados para diferentes tipos de necessidade, por exemplo, alguns seguros temporários, que aumentam todos os anos e apólices de vida em grupo, não se prestam à proteção na ' + LABEL.aposentadoria.toLowerCase() + ' e com despesas de sucessão patrimonial, que são vitalícias.<br/><br/>' +
            '    Outra cobertura essencial e de baixo custo é o seguro, ou serviço, de assistência funeral (<b>SAF</b>), pois traz paz de espírito e tranquilidade para que os familiares não sejam explorados em um momento tão delicado.',

        estudoReportDetalhamentoDiaDiaDespesasVitaliciasTextoCabecalho: '',//'A proteção complementar às garantias atuais, a fim de manter o padrão de vida durante a fase de acumulação até a idade planejada para a ' + LABEL.aposentadoria.toLowerCase() + ', projetadas até a expectativa de vida escolhida, está na tabela abaixo.',
        estudoReportDetalhamentoEducacaoTextoCabecalho: '',//'Capital inicial, complementar às garantias atuais, necessário para garantir a formação dos dependentes até a formação do mais jovem.',
        estudoReportDetalhamentoFinanciamentoTextoCabecalho: '',//'Para que não restem dívidas, o quadro abaixo mostra qual necessidade de proteção complementar às garantias atuais cada participante do plano deveria ter.',
        estudoReportDetalhamentoDiaDiaDespesasTemporariasTextoCabecalho: '',//'A proteção complementar às garantias atuais, a fim de manter o padrão de vida durante a fase de acumulação até a idade planejada para a ' + LABEL.aposentadoria.toLowerCase() + ', projetadas até seu respectivo ano final, está na tabela abaixo.',

        estudoReportDetalhamentoPatrimonioImobilizadoTextoCabecalho: '',//'Abaixo apresentamos o patrimômio imobilizado nominal de cada participante, incluindo imóveis, veículos, direitos autorais e participação em negócios, entre outros.',
        estudoReportDetalhamentoReceitaMensalTextoCabecalho: '',//'As receitas atuais, bem como as previsões de receitas garantidas na ' + LABEL.aposentadoria.toLowerCase() + ', por participante, são apresentadas na tabela abaixo.',
        estudoReportDetalhamentoRecursosFinanceirosTextoCabecalho: '',//'Abaixo temos os investimentos, previdência privada, FGTS e outros ativos atuais, por participante, que foram levados em conta para a ' + LABEL.aposentadoria.toLowerCase() + ' e análise de necessidades de proteção em caso de morte de cada participante.',

        estudoReportDisclaimer: '<b>Nota importante:</b><br/><br/>' +
        'Este estudo, e quaisquer anexos, são dirigidos somente ao destinatário indicado na capa e pode conter informações confidenciais, e tem por objetivo fornecer informações que possam ajudar o destinatário no seu $estudoReportCapaTitulo1$.<br/><br/>' + 
        'O Estudo foi elaborado com informações fornecidas pelo cliente, levando em consideração suas necessidades financeiras, objetivos pessoais e ativos financeiros atuais.<br/><br/>' +
        'Este estudo não representa oferta de negociação de fundos de investimento, valores mobiliários, seguros ou quaisquer outros instrumentos financeiros, mas sim uma orientação sobre o que essas opções podem representar no $estudoReportCapaTitulo1$.<br/><br/>' +
        'As análises efetuadas não são e não podem ser tratadas como decisões de investimento ou recomendações de compra ou venda de qualquer ativo negociado no mercado financeiro. São de caráter meramente informativo e têm o objetivo simplesmente prover um guia para informar aos clientes sobre opções disponíveis no mercado.<br/><br/>' +
        'Esse estudo é baseado em taxas atuais e não é uma projeção ou afirmação do potencial de taxas futuras.<br/><br/>' +
        'Não nos responsabilizamos por decisões de investimento tomadas com base nas informações enviadas, nem pela exatidão e/ou veracidade dos dados fornecidos, sendo quaisquer opiniões e/ou informações baseadas em métodos conhecidos e aceitos pelos clientes como eficazes e suficientes para sua utilização.<br/><br/>' +
        'As informações contidas neste relatório são consideradas válidas na data da divulgação deste relatório e foram obtidas de fontes públicas consideradas confiáveis, ou fornecidas diretamente pelo cliente.<br/><br/>' +
        'O $estudoReportCapaTitulo1$ é um guia que deve ser atualizado sempre que ocorrerem mudanças na situação financeira familiar, ou novos objetivos sejam traçados, para que estes sejam alcançados. Sempre que houver alguma mudança significativa na vida financeira, é recomendado que o estudo seja atualizado. O $profissaoQualificacao$ tem o papel de identificar, quantificar e auxiliar na manutenção dos objetivos, mas a responsabilidade pelas decisões é sempre do cliente.',

        estudoReportPrimeiraReuniaoTextoSegurancaDados: '<b>Segurança dos Dados:</b> Os servidores ficam na nuvem, em um provedor chamado Heroku (Salesforce), que por sua vez utiliza a infraestrutura da Amazon. O acesso ao site é feito em um ambiente seguro, com certificado ssl, através de https. O backup dos dados é feito diariamente. Os acessos ao sistema são controlados por login e senha, sendo que apenas o usuário e os gestores da empresa responsáveis pelo atendimento do cliente tem acesso aos seus dados. Todo processo de coleta, armazenamento e tratamento dos dados estão em concordância com a normas de segurança exigidas pela LGPD - LEI Nº 13.709 DE 14 DE AGOSTO DE 2018.',

        resumoEvolucaoReservaFinanceira: 'Com a reserva inicial de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>$1$</span> mais a realização de aportes no valor total de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>$2$</span> e<br/>um rendimento total de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>$3$</span>, a reserva no início da aposentadoria será de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>$4$</span>.<br/><b>Importante:</b> Os aportes planejados precisam ser reajustados anualmente pela inflação para que o poder de compra seja mantido.',

        diagnosticoAposentadoriaIndependenciaFinanceiraComNovosAportes:
            'Para ter reserva suficiente para consumir mensalmente <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>' + HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraPadraoVida +
            '</span> na ' + LABEL.aposentadoria.toLowerCase() + ' e assim manter o padrão de vida desejado, existem 2 opções de aporte:<br/><div ' + STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem1oItem +
            '><span ' +  STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorDepositoMensalViverRentabilidade +
            '>' + HARDCODE.htmlMarcadorSquare + '</span> Aportar <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '> + ' + HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAporteMensalPreservarReserva +
            '</span> por mês até a ' + LABEL.aposentadoria.toLowerCase() + HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraComNovosAportesAporteUnico + ', para viver de rentabilidade mantendo reserva no valor de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' + 
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraRecursosFinanceirosPreservarReserva +
            '</span>;</div><div ' + STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem2oItem + '>' +
            '<span ' +  STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorDepositoMensalConsumirRecursos + '>' +
            HARDCODE.htmlMarcadorSquare + '</span> Aportar <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' +
            ' + ' + HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAporteMensalConsumirReserva + '</span> por mês até a ' + LABEL.aposentadoria.toLowerCase() +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraComNovosAportesAporteUnico +
            ', para a reserva ser suficiente até <span ' + 
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero  + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAnos + '</span> anos.<div>' + 
            '<div ' + STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargemImportante +
            '><b>Importante:</b> Os aportes planejados precisam ser reajustados anualmente pela inflação para que o poder de compra seja mantido.</div>',
        diagnosticoAposentadoriaIndependenciaFinanceiraComNovosAportesAporteUnico:
            ', ou <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '> + ' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAporteUnico +
            '</span> de uma única vez',
        diagnosticoAposentadoriaIndependenciaFinanceiraSemNovosAportes:
            'No caso de não realização de novos aportes além dos já planejados, existem 3 opções de padrão de vida para a ' + LABEL.aposentadoria.toLowerCase() + ':<br/><div ' +
            STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem1oItem + 
            '><span ' +  STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorViverRentabilidadeSemAporte +
            '>' + HARDCODE.htmlMarcadorSquare + '</span> Retirar o total de <span ' + 
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraPreservarReservaSemAporte +
            '</span> por mês, para viver de rentabilidade mantendo reserva no valor de <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraRecursosFinanceirosPreservarReservaSemAporte +
            '</span>;</div><div ' + STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem2oItem + '>' + 
            '<span ' +  STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorConsumirReservaSemAporte + '>' +
            HARDCODE.htmlMarcadorSquare + '</span> Retirar o total de <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraConsumirReservaSemAporte +
            '</span> por mês, para a reserva ser suficientes até os <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAnos + '</span> anos;<br/></div>' +
            '</div><div ' + STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraMargem2oItem + '>'+
            //'<span ' +  HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraCorSituacaoAtualSuperavitDeficit + '>' +
            '<span ' +  STYLEHTML().styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraCorSituacaoAtualSuperavit + '>' +
            HARDCODE.htmlMarcadorSquare + '</span> Manter o padrão de vida de <span ' + STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero +
            '>' + HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraPadraoVida + '</span> na ' + LABEL.aposentadoria.toLowerCase() + ' e ter reserva suficiente até <span ' +
            STYLEHTML(report).styleHtmlDiagnosticoAposentadoriaIndependenciaFinanceiraValorNumero  + '>' +
            HARDCODE.diagnosticoAposentadoriaIndependenciaFinanceiraAnos + '</span> anos.</div>',

        diagnosticoAposentadoriaDepositoUnicoManterPadraoVida: 'Para <u>manter o padrão de vida</u>, com um depósito <span style="font-size: 15px"><b>único</b></span>, são necessários',
        diagnosticoAposentadoriaDepositoUnicoViverRentabilidade: 'Para <u>viver de rentabilidade</u>, com um depósito <span style="font-size: 15px"><b>único</b></span>, são necessários',
        
        
        projetoVidaSonhoTracarMetaImportante: '<b>Importante:</b> Os aportes planejados precisam ser reajustados anualmente pela inflação para que o poder de compra seja mantido.',
        
        
        
        tabelaEvolucaoValorResgateImportante: '<b>Importante:</b> A projeção dos valores futuros do Capital Segurado, Prêmio Anualizado, Prêmio Anualizado Acumulado e Valor Resgate foi feita com a taxa média de <b>$1$</b> de Inflação, a qual pode sofrer variações com o passar do tempo.',
        tabelaEvolucaoCSImportante: '<b>Importante:</b> A projeção dos valores futuros do Capital Segurado, Prêmio Anualizado e Prêmio Anualizado Acumulado foi feita com a taxa média de <b>$1$</b> de Inflação, a qual pode sofrer variações com o passar do tempo.',
        tabelaEvolucaoCancelamentoApoliceImportante: '<b>Importante:</b> A projeção dos valores futuros do Prêmio Anualizado Acumulado e Valor Resgate foi feita com a taxa média de <b>$1$</b> de Inflação, a qual pode sofrer variações com o passar do tempo.',



        previdenciaPrivadaResumo: '<b>$1$</b> $2$=> <b>$3$</b> = <b>$4$</b> (aportes) + <b>$5$</b> (rentabilidade)',
        previdenciaPrivadaAporteMensalPGBL: 'Simulação de aportes mensais de <b>$1$</b> no PGBL',



        naoContribuiINSSRPPSOuAposentador:
            HARDCODE.htmlMarcadorCircle + '<span> VGBL</span><br/><br/>' +
            HARDCODE.htmlMarcadorCircle + '<span> Declaração SIMPLIFICADA ou COMPLETA</span>',
        VGBLDeclaracaoSimplificada:
            HARDCODE.htmlMarcadorCircle + '<span> VGBL sem limite de contribuição</span><br/><br/>' +
            HARDCODE.htmlMarcadorCircle + '<span> Declaração SIMPLIFICADA</span>',
        PGBLVGBLDeclaracaoCompleta:
            HARDCODE.htmlMarcadorCircle + '<span> <b>PGBL</b> até 12% da renda tributável (</span><span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorPrincipal + '>$1$</span> <b>anual</b> ou <span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorPrincipal + '>$2$</span> por <b>mês</b>)<br/><br/>' +
            HARDCODE.htmlMarcadorCircle + '<span> <b>VGBL</b> para o que exceder 12% da renda tributável</span><br/><br/>' +
            HARDCODE.htmlMarcadorCircle + '<span> Declaração COMPLETA</span>',
        beneficioPGBLVGBLDeclaracaoCompleta:
            'Benefício fiscal com o Imposto Renda = <span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorPrincipal + '>$1$</span>',
        fundamentoVGBLDeclaracaoSimplificada1:
            'A "Despesa Dedutível Anual com o Benefício da Previdência Privada" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$1$</span> = $2$ + $3$ [$4$ $5$])' +
            ' é <span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaMaiorMenor + '>menor</span> que a "Despesa Dedutível da Renda Tributável Anual" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$6$</span> = $7$ $8$).',
        fundamentoVGBLDeclaracaoSimplificada2:
            'A "Despesa Dedutível Anual com o Benefício da Previdência Privada" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$1$</span> = $2$ + $3$ [$4$ $5$])' +
            ' é <span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaMaiorMenor + '>menor</span> que a "Despesa Dedutível Limite" (<span' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$6$</span>).',
        fundamentoPGBLVGBLDeclaracaoCompleta1:
            'A "Despesa Dedutível Anual com o Benefício da Previdência Privada" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$1$</span> = $2$ + $3$ [$4$ $5$])' +
            ' é <span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaMaiorMenor + '>maior</span> que a "Despesa Dedutível da Renda Tributável Anual" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$6$</span> = $7$ $8$)',
        fundamentoPGBLVGBLDeclaracaoCompleta2:
            'A "Despesa Dedutível Anual com o Benefício da Previdência Privada" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$1$</span> = $2$ + $3$ [$4$ $5$])' +
            ' é <span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaMaiorMenor + '>maior</span> que a "Despesa Dedutível Limite" (<span ' +
                STYLEHTML(report).styleHtmlPrevidenciaPrivadaValorSecundario + '>$6$</span>).',
        vantagensPrevidenciaPrivada:
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Benefício tributário:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Não tem incidência de come-cotas;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Permite a dedução de até 12% da renda tributável do imposto de renda, no caso do PGBL.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Portabilidade ilimitada. Diferente dos demais investimentos:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' O montante do fundo é transferido sem que seja necessário resgatar o valor e, consequentemente, sem pagar impostos;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Não há reinicio a tabela Regressiva no maior percentual do Imposto de Renda.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Sucessão patrimonial:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Os herdeiros podem acessar o capital investido sem a necessidade de inventário, o que reduz os custos e facilita os processos nesse momento.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Em caso de acometimento de uma doença grave, fica isento de pagar imposto de renda no momento dos saques.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Permite a escolha entre a tabela Progressiva ou Regressiva.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> Permite a troca da tabela Progressiva para Regressiva, ou vice-versa, a qualquer momento, durante o período de acumulação do plano:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Até a hora do primeiro resgate;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Ou até o momento da contratação de uma modalidade de renda.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> A tabela Regressiva é mais interessante quando:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' De fato consegue-se contribuir para o plano por, no mínimo, oito anos;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Pretende receber um valor de benefício que não irá se enquadrar nas faixas mais baixas de tributação da tabela Progressiva;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Você já sabe que vai ter, na aposentadoria, mais de uma fonte de renda tributável sujeita ao ajuste anual.</span><br/><br/>' +
            HARDCODE.htmlMarcadorWhiteRightPointingTriangle + '<span> A tabela Progressiva é mais interessante quando:</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Se os rendimentos forem baixos o suficiente para serem enquadrados na faixa de isenção ou de 7,5%, mesmo após somar com outra eventual fonte de renda tributável;</span><br/>' +
            '<span ' + STYLEHTML(report).styleHtmlPrevidenciaPrivadaIdentacao + '>' + HARDCODE.htmlMarcadorCircle + ' Se for necessário resgatar o plano no curto prazo e antes de acumular um grande montante.',


        elaboradoPor: 'Elaborado por <b>$1$</b> em $2$',



        alertaCamposObritagoriosCadastroCliente: '1. ' + LABEL.tabClienteFormListCadastro + ' - <u>' + (values ? tituloCliente(values) : '') + '</u>',

        alertaCamposObritagoriosCadastroConjuge: '1. ' + LABEL.tabClienteFormListCadastro + ' - <u>' + (values ? tituloConjuge(values) : '') + '</u>',

        alertaCamposObritagoriosCadastroFamiliares: '1. ' + LABEL.tabClienteFormListCadastro + ' - <u>' + LABEL.familiares + '</u>',

        alertaCamposObritagoriosDespesasDiaDia: '2. ' + LABEL.tabClienteFormListFluxoCaixa + ' - <u>' + LABEL.diaDia + '</u>',

        alertaCamposObritagoriosDespesasEducacao: '2. ' + LABEL.tabClienteFormListFluxoCaixa + ' - <u>' + LABEL.educacao + '</u>',

        alertaCamposObritagoriosDespesasFinanciamento: '2. ' + LABEL.tabClienteFormListFluxoCaixa + ' - <u>' + LABEL.financiamento + '</u>',
    
        alertaCamposObritagoriosDespesasProjetoVidaSonho: '2. ' + LABEL.tabClienteFormListFluxoCaixa + ' - <u>' + LABEL.projetoVidaSonho + '</u>',
    
        alertaCamposObritagoriosGarantiasPatrimonioImobilizado: '3. ' + LABEL.tabClienteFormListPatrimonio + ' - <u>' + LABEL.patrimonioImobilizado + '</u>',

        alertaCamposObritagoriosGarantiasReceitaMensal: '2. ' + LABEL.tabClienteFormListFluxoCaixa + ' - <u>' + LABEL.receitaMensal + '</u>',

        alertaCamposObritagoriosGarantiasRecursosFinanceiros: '3. ' + LABEL.tabClienteFormListPatrimonio + ' - <u>' + LABEL.recursosFinanceiros + '</u>',
        
        alertaCamposObritagoriosGarantiasSeguroVidaCotacao: '8. ' + LABEL.tabClienteFormListAcompanhamento + ' - <u>' + LABEL.seguroVida + ' - ' + LABEL.cotacao + '</u>',
        
        alertaCamposObritagoriosGarantiasSeguroVidaProposta: '8. ' + LABEL.tabClienteFormListAcompanhamento + ' - <u>' + LABEL.seguroVida + ' - ' + LABEL.proposta + '</u>',
        
        alertaCamposObritagoriosGarantiasSeguroVida: '3. ' + LABEL.tabClienteFormListPatrimonio + ' - <u>' + LABEL.seguroVida + '</u>',
    
        alertaCamposObritagoriosUfEnderecoResidencialItcmd: 'O cálculo do Inventário utiliza a UF do endereço residencial para a obtenção da alíquota do ITCMD quando existem Recursos Financeiros',
    
        orientacaoConfiguracaoPendencias: 'Configure os "Tipos de Pendências" em "Configuração - Parâmetros" para você poder usufruir da funcionalidade de controle de pendências.',
        orientacaoConfiguracaoFunisEtapas: 'Configure os "Funis" em "Configuração - Parâmetros" para você poder usufruir da funcionalidade de funis e etapas.',
        orientacaoUtilizacaoAportes: 'Para você poder usufruir da funcionalidade de Aportes você deve especificar quais Projetos de Vida/Sonho e/ou Recursos Financeiros deverão fazer parte do Controle de Aportes.',
    }
}

export default MESSAGE