import { useContext } from 'react'

import useSingleton from '../../business-components/useSingleton/useSingleton'

import history from 'history.js'

import AppContext from '../../contexts/AppContext'

import MESSAGE from '../../business-const/Message/Message'

import PATH from '../../business-const/Path/Path'

import {
    dataAtualSemHora,
} from '../../business-components/Date/Date'

import {
    mensagemSucesso,
    mensagemAlerta,
    mensagemErro,
} from '../../business-components/Toastr/Toastr'

import {
    delay,
} from '../../business-components/Delay/Delay'

import {
    pesquisaList
} from '../../business-rules/List/ListService'

import respostaSellFluxList from '../../business-rules/List/respostaSellFluxList'

import {
    postRespostaSellFlux,
    postReiniciarFreetrack,
} from './SessionService'



const Resposta = () => {

    const { variaveisGlobais } = useContext(AppContext)

    useSingleton( async () => {

        const queryParams = new URLSearchParams(window.location.search)

        const id = queryParams.get('id')
        const email = queryParams.get('email')
        const nome = queryParams.get('nome')
        const celular = queryParams.get('celular')

        const linhaRespostaSellFlux = pesquisaList(respostaSellFluxList, id)

        if (linhaRespostaSellFlux.id && linhaRespostaSellFlux.validade < dataAtualSemHora()) {

            mensagemErro(MESSAGE().respostaLinkVencido)

            history.push({
                pathname: PATH.pageSignin,
                email: email,
                signature: '',
            })

        } else {

            let respostaOk

            if (linhaRespostaSellFlux.id) {

                respostaOk = await postRespostaSellFlux(variaveisGlobais, linhaRespostaSellFlux.idSellFlux, email, nome, celular)

                if (respostaOk && linhaRespostaSellFlux.reiniciarFreetrack === "S") {

                    respostaOk = await postReiniciarFreetrack(variaveisGlobais, email)
                }

            } else {

                respostaOk = false
            }

            if (respostaOk) {

                mensagemSucesso(
                    linhaRespostaSellFlux.mensagem
                        ?
                            linhaRespostaSellFlux.mensagem
                        :
                            linhaRespostaSellFlux.video === 'S'
                                ?
                                    MESSAGE().videoRedirecinando.replace('$1$', linhaRespostaSellFlux.descricao)
                                :
                                    MESSAGE().respostaProcessadaComSucesso
                )

            } else {

                mensagemAlerta(
                    linhaRespostaSellFlux.video === 'S'
                        ?
                            MESSAGE().videoNaoFoiPossivelRedirecionar.replace('$1$', linhaRespostaSellFlux.descricao)
                        :
                            MESSAGE().respostaNaoProcessadaComSucesso
                )
            }

            history.push({
                pathname: PATH.pageSignin,
                email: email,
                signature: linhaRespostaSellFlux.signature,
            })

            if (linhaRespostaSellFlux.redirect) {

                await delay(2)

                window.location.replace(linhaRespostaSellFlux.redirect)
            }
        }
    })



    return (
        ''
    )
}

export default Resposta