const tipoBaixaParcelaList = [
    {
        id: 'A',
        descricao: 'Automática',
        chaves: [
            {
                id: 'BAIXA_AUTOMATICA',
            },
        ],
        ordem: 10,
    },
    {
        id: 'M',
        descricao: 'Manual',
        chaves: [
            {
                id: 'BAIXA_MANUAL',
            },
        ],
        ordem: 20,
    },
]

export default tipoBaixaParcelaList