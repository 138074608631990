export const primNome = (nome) => {
    let primNome = null

    if (nome) {

        primNome = nome.split(' ').slice(0, 1)
        
        primNome = primNome.join('')
    }

    return primNome
}



export const nomeSobrenome = (nome) => {

    let nomeSobrenome = null

    if (nome) {

        const partesNome = nome.split(' ')
    
        nomeSobrenome = partesNome[0]
        
        if (partesNome.length > 1) {
            
            nomeSobrenome += " " + partesNome[partesNome.length - 1];
        }
    }

    return nomeSobrenome
}



export const capitalize = (string) => {

    let stringNova = ''

    if (string) {

        const stringPartes = string.trim().split(" ")

        for (let i = 0; i < stringPartes.length; i++) {

            if (stringPartes[i]) {
            
                stringNova += (stringNova ? ' ' : '') + stringPartes[i][0].toUpperCase() + stringPartes[i].toLowerCase().substr(1)
            }
        }

    } else {

        stringNova = string
    }

    //return string.charAt(0).toUpperCase() + string.substr(1)
    return stringNova
}



export const semAcento = (string) => {

    return string.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
}



export const descricaoMenu = (menu) => {

    return menu.substring(menu.indexOf(' ') + 1)
}



export const semZerosEsquerda = (string) => {

    let index = 0

    string = '' + string

    while (string.substring(index, index + 1) === '0') {

        index++
    }

    return string.substring(index)
}