// alterado - revisado

const tipoProjetoVidaSonhoList = [
    {
        id: 10,
        descricao: 'Casa',
        ordem: 10,
    },
    {
        id: 20,
        descricao: 'Imóvel',
        ordem: 20,
    },
    {
        id: 30,
        descricao: 'Carro',
        ordem: 30,
    },
    {
        id: 40,
        descricao: 'Viagem',
        ordem: 40,
    },
    {
        id: 50,
        descricao: 'Casamento',
        ordem: 50,
    },
    {
        id: 60,
        descricao: 'Festa 15 Anos',
        ordem: 60,
    },
    {
        id: 70,
        descricao: 'Reserva de Emergência',
        ordem: 70,
    },
    {
        id: 900,
        descricao: 'Outros',
        ordem: 900,
    },
]

export default tipoProjetoVidaSonhoList