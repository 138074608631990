// alterado - revisado

const expectativaVidaList = [
    {id: 0, idade: 0, anosExpectativaVidaHomem: 71, anoExpectativaVidaMulher: 76, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 1, idade: 1, anosExpectativaVidaHomem: 70, anoExpectativaVidaMulher: 75, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 2, idade: 2, anosExpectativaVidaHomem: 69, anoExpectativaVidaMulher: 74, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 3, idade: 3, anosExpectativaVidaHomem: 68, anoExpectativaVidaMulher: 73, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 4, idade: 4, anosExpectativaVidaHomem: 67, anoExpectativaVidaMulher: 72, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 5, idade: 5, anosExpectativaVidaHomem: 66, anoExpectativaVidaMulher: 71, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 6, idade: 6, anosExpectativaVidaHomem: 65, anoExpectativaVidaMulher: 70, idadeExpectativaVidaHomem: 71, idadeExpectativaVidaMulher: 76},
    {id: 7, idade: 7, anosExpectativaVidaHomem: 65, anoExpectativaVidaMulher: 69, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 76},
    {id: 8, idade: 8, anosExpectativaVidaHomem: 64, anoExpectativaVidaMulher: 68, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 76},
    {id: 9, idade: 9, anosExpectativaVidaHomem: 63, anoExpectativaVidaMulher: 68, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 10, idade: 10, anosExpectativaVidaHomem: 62, anoExpectativaVidaMulher: 67, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 11, idade: 11, anosExpectativaVidaHomem: 61, anoExpectativaVidaMulher: 66, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 12, idade: 12, anosExpectativaVidaHomem: 60, anoExpectativaVidaMulher: 65, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 13, idade: 13, anosExpectativaVidaHomem: 59, anoExpectativaVidaMulher: 64, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 14, idade: 14, anosExpectativaVidaHomem: 58, anoExpectativaVidaMulher: 63, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 15, idade: 15, anosExpectativaVidaHomem: 57, anoExpectativaVidaMulher: 62, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 16, idade: 16, anosExpectativaVidaHomem: 56, anoExpectativaVidaMulher: 61, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 17, idade: 17, anosExpectativaVidaHomem: 55, anoExpectativaVidaMulher: 60, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 18, idade: 18, anosExpectativaVidaHomem: 54, anoExpectativaVidaMulher: 59, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 19, idade: 19, anosExpectativaVidaHomem: 53, anoExpectativaVidaMulher: 58, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 20, idade: 20, anosExpectativaVidaHomem: 52, anoExpectativaVidaMulher: 57, idadeExpectativaVidaHomem: 72, idadeExpectativaVidaMulher: 77},
    {id: 21, idade: 21, anosExpectativaVidaHomem: 52, anoExpectativaVidaMulher: 56, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 22, idade: 22, anosExpectativaVidaHomem: 51, anoExpectativaVidaMulher: 55, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 23, idade: 23, anosExpectativaVidaHomem: 50, anoExpectativaVidaMulher: 54, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 24, idade: 24, anosExpectativaVidaHomem: 49, anoExpectativaVidaMulher: 53, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 25, idade: 25, anosExpectativaVidaHomem: 48, anoExpectativaVidaMulher: 52, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 26, idade: 26, anosExpectativaVidaHomem: 47, anoExpectativaVidaMulher: 51, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 27, idade: 27, anosExpectativaVidaHomem: 46, anoExpectativaVidaMulher: 50, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 77},
    {id: 28, idade: 28, anosExpectativaVidaHomem: 45, anoExpectativaVidaMulher: 50, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 29, idade: 29, anosExpectativaVidaHomem: 44, anoExpectativaVidaMulher: 49, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 30, idade: 30, anosExpectativaVidaHomem: 43, anoExpectativaVidaMulher: 48, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 31, idade: 31, anosExpectativaVidaHomem: 42, anoExpectativaVidaMulher: 47, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 32, idade: 32, anosExpectativaVidaHomem: 41, anoExpectativaVidaMulher: 46, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 33, idade: 33, anosExpectativaVidaHomem: 40, anoExpectativaVidaMulher: 45, idadeExpectativaVidaHomem: 73, idadeExpectativaVidaMulher: 78},
    {id: 34, idade: 34, anosExpectativaVidaHomem: 40, anoExpectativaVidaMulher: 44, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 35, idade: 35, anosExpectativaVidaHomem: 39, anoExpectativaVidaMulher: 43, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 36, idade: 36, anosExpectativaVidaHomem: 38, anoExpectativaVidaMulher: 42, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 37, idade: 37, anosExpectativaVidaHomem: 37, anoExpectativaVidaMulher: 41, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 38, idade: 38, anosExpectativaVidaHomem: 36, anoExpectativaVidaMulher: 40, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 39, idade: 39, anosExpectativaVidaHomem: 35, anoExpectativaVidaMulher: 39, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 40, idade: 40, anosExpectativaVidaHomem: 34, anoExpectativaVidaMulher: 38, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 78},
    {id: 41, idade: 41, anosExpectativaVidaHomem: 33, anoExpectativaVidaMulher: 38, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 79},
    {id: 42, idade: 42, anosExpectativaVidaHomem: 32, anoExpectativaVidaMulher: 37, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 79},
    {id: 43, idade: 43, anosExpectativaVidaHomem: 31, anoExpectativaVidaMulher: 36, idadeExpectativaVidaHomem: 74, idadeExpectativaVidaMulher: 79},
    {id: 44, idade: 44, anosExpectativaVidaHomem: 31, anoExpectativaVidaMulher: 35, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 45, idade: 45, anosExpectativaVidaHomem: 30, anoExpectativaVidaMulher: 34, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 46, idade: 46, anosExpectativaVidaHomem: 29, anoExpectativaVidaMulher: 33, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 47, idade: 47, anosExpectativaVidaHomem: 28, anoExpectativaVidaMulher: 32, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 48, idade: 48, anosExpectativaVidaHomem: 27, anoExpectativaVidaMulher: 31, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 49, idade: 49, anosExpectativaVidaHomem: 26, anoExpectativaVidaMulher: 30, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 79},
    {id: 50, idade: 50, anosExpectativaVidaHomem: 25, anoExpectativaVidaMulher: 30, idadeExpectativaVidaHomem: 75, idadeExpectativaVidaMulher: 80},
    {id: 51, idade: 51, anosExpectativaVidaHomem: 25, anoExpectativaVidaMulher: 29, idadeExpectativaVidaHomem: 76, idadeExpectativaVidaMulher: 80},
    {id: 52, idade: 52, anosExpectativaVidaHomem: 24, anoExpectativaVidaMulher: 28, idadeExpectativaVidaHomem: 76, idadeExpectativaVidaMulher: 80},
    {id: 53, idade: 53, anosExpectativaVidaHomem: 23, anoExpectativaVidaMulher: 27, idadeExpectativaVidaHomem: 76, idadeExpectativaVidaMulher: 80},
    {id: 54, idade: 54, anosExpectativaVidaHomem: 22, anoExpectativaVidaMulher: 26, idadeExpectativaVidaHomem: 76, idadeExpectativaVidaMulher: 80},
    {id: 55, idade: 55, anosExpectativaVidaHomem: 21, anoExpectativaVidaMulher: 25, idadeExpectativaVidaHomem: 76, idadeExpectativaVidaMulher: 80},
    {id: 56, idade: 56, anosExpectativaVidaHomem: 21, anoExpectativaVidaMulher: 25, idadeExpectativaVidaHomem: 77, idadeExpectativaVidaMulher: 81},
    {id: 57, idade: 57, anosExpectativaVidaHomem: 20, anoExpectativaVidaMulher: 24, idadeExpectativaVidaHomem: 77, idadeExpectativaVidaMulher: 81},
    {id: 58, idade: 58, anosExpectativaVidaHomem: 19, anoExpectativaVidaMulher: 23, idadeExpectativaVidaHomem: 77, idadeExpectativaVidaMulher: 81},
    {id: 59, idade: 59, anosExpectativaVidaHomem: 18, anoExpectativaVidaMulher: 22, idadeExpectativaVidaHomem: 77, idadeExpectativaVidaMulher: 81},
    {id: 60, idade: 60, anosExpectativaVidaHomem: 18, anoExpectativaVidaMulher: 21, idadeExpectativaVidaHomem: 78, idadeExpectativaVidaMulher: 81},
    {id: 61, idade: 61, anosExpectativaVidaHomem: 17, anoExpectativaVidaMulher: 21, idadeExpectativaVidaHomem: 78, idadeExpectativaVidaMulher: 82},
    {id: 62, idade: 62, anosExpectativaVidaHomem: 16, anoExpectativaVidaMulher: 20, idadeExpectativaVidaHomem: 78, idadeExpectativaVidaMulher: 82},
    {id: 63, idade: 63, anosExpectativaVidaHomem: 16, anoExpectativaVidaMulher: 19, idadeExpectativaVidaHomem: 79, idadeExpectativaVidaMulher: 82},
    {id: 64, idade: 64, anosExpectativaVidaHomem: 15, anoExpectativaVidaMulher: 18, idadeExpectativaVidaHomem: 79, idadeExpectativaVidaMulher: 82},
    {id: 65, idade: 65, anosExpectativaVidaHomem: 14, anoExpectativaVidaMulher: 17, idadeExpectativaVidaHomem: 79, idadeExpectativaVidaMulher: 82},
    {id: 66, idade: 66, anosExpectativaVidaHomem: 14, anoExpectativaVidaMulher: 17, idadeExpectativaVidaHomem: 80, idadeExpectativaVidaMulher: 83},
    {id: 67, idade: 67, anosExpectativaVidaHomem: 13, anoExpectativaVidaMulher: 16, idadeExpectativaVidaHomem: 80, idadeExpectativaVidaMulher: 83},
    {id: 68, idade: 68, anosExpectativaVidaHomem: 12, anoExpectativaVidaMulher: 15, idadeExpectativaVidaHomem: 80, idadeExpectativaVidaMulher: 83},
    {id: 69, idade: 69, anosExpectativaVidaHomem: 12, anoExpectativaVidaMulher: 15, idadeExpectativaVidaHomem: 81, idadeExpectativaVidaMulher: 84},
    {id: 70, idade: 70, anosExpectativaVidaHomem: 11, anoExpectativaVidaMulher: 14, idadeExpectativaVidaHomem: 81, idadeExpectativaVidaMulher: 84},
    {id: 71, idade: 71, anosExpectativaVidaHomem: 11, anoExpectativaVidaMulher: 13, idadeExpectativaVidaHomem: 82, idadeExpectativaVidaMulher: 84},
    {id: 72, idade: 72, anosExpectativaVidaHomem: 10, anoExpectativaVidaMulher: 12, idadeExpectativaVidaHomem: 82, idadeExpectativaVidaMulher: 84},
    {id: 73, idade: 73, anosExpectativaVidaHomem: 10, anoExpectativaVidaMulher: 12, idadeExpectativaVidaHomem: 83, idadeExpectativaVidaMulher: 85},
    {id: 74, idade: 74, anosExpectativaVidaHomem: 9, anoExpectativaVidaMulher: 11, idadeExpectativaVidaHomem: 83, idadeExpectativaVidaMulher: 85},
    {id: 75, idade: 75, anosExpectativaVidaHomem: 9, anoExpectativaVidaMulher: 11, idadeExpectativaVidaHomem: 84, idadeExpectativaVidaMulher: 86},
    {id: 76, idade: 76, anosExpectativaVidaHomem: 8, anoExpectativaVidaMulher: 10, idadeExpectativaVidaHomem: 84, idadeExpectativaVidaMulher: 86},
    {id: 77, idade: 77, anosExpectativaVidaHomem: 8, anoExpectativaVidaMulher: 9, idadeExpectativaVidaHomem: 85, idadeExpectativaVidaMulher: 86},
    {id: 78, idade: 78, anosExpectativaVidaHomem: 7, anoExpectativaVidaMulher: 9, idadeExpectativaVidaHomem: 85, idadeExpectativaVidaMulher: 87},
    {id: 79, idade: 79, anosExpectativaVidaHomem: 7, anoExpectativaVidaMulher: 8, idadeExpectativaVidaHomem: 86, idadeExpectativaVidaMulher: 87},
    {id: 80, idade: 80, anosExpectativaVidaHomem: 6, anoExpectativaVidaMulher: 8, idadeExpectativaVidaHomem: 86, idadeExpectativaVidaMulher: 88},
    {id: 81, idade: 81, anosExpectativaVidaHomem: 6, anoExpectativaVidaMulher: 7, idadeExpectativaVidaHomem: 87, idadeExpectativaVidaMulher: 88},
    {id: 82, idade: 82, anosExpectativaVidaHomem: 6, anoExpectativaVidaMulher: 7, idadeExpectativaVidaHomem: 88, idadeExpectativaVidaMulher: 89},
    {id: 83, idade: 83, anosExpectativaVidaHomem: 5, anoExpectativaVidaMulher: 6, idadeExpectativaVidaHomem: 88, idadeExpectativaVidaMulher: 89},
    {id: 84, idade: 84, anosExpectativaVidaHomem: 5, anoExpectativaVidaMulher: 6, idadeExpectativaVidaHomem: 89, idadeExpectativaVidaMulher: 90},
    {id: 85, idade: 85, anosExpectativaVidaHomem: 5, anoExpectativaVidaMulher: 6, idadeExpectativaVidaHomem: 90, idadeExpectativaVidaMulher: 91},
    {id: 86, idade: 86, anosExpectativaVidaHomem: 5, anoExpectativaVidaMulher: 5, idadeExpectativaVidaHomem: 91, idadeExpectativaVidaMulher: 91},
    {id: 87, idade: 87, anosExpectativaVidaHomem: 4, anoExpectativaVidaMulher: 5, idadeExpectativaVidaHomem: 91, idadeExpectativaVidaMulher: 92},
    {id: 88, idade: 88, anosExpectativaVidaHomem: 4, anoExpectativaVidaMulher: 4, idadeExpectativaVidaHomem: 92, idadeExpectativaVidaMulher: 92},
    {id: 89, idade: 89, anosExpectativaVidaHomem: 4, anoExpectativaVidaMulher: 4, idadeExpectativaVidaHomem: 93, idadeExpectativaVidaMulher: 93},
    {id: 90, idade: 90, anosExpectativaVidaHomem: 4, anoExpectativaVidaMulher: 4, idadeExpectativaVidaHomem: 94, idadeExpectativaVidaMulher: 94},
    {id: 91, idade: 91, anosExpectativaVidaHomem: 3, anoExpectativaVidaMulher: 4, idadeExpectativaVidaHomem: 94, idadeExpectativaVidaMulher: 95},
    {id: 92, idade: 92, anosExpectativaVidaHomem: 3, anoExpectativaVidaMulher: 3, idadeExpectativaVidaHomem: 95, idadeExpectativaVidaMulher: 95},
    {id: 93, idade: 93, anosExpectativaVidaHomem: 3, anoExpectativaVidaMulher: 3, idadeExpectativaVidaHomem: 96, idadeExpectativaVidaMulher: 96},
    {id: 94, idade: 94, anosExpectativaVidaHomem: 3, anoExpectativaVidaMulher: 3, idadeExpectativaVidaHomem: 97, idadeExpectativaVidaMulher: 97},
    {id: 95, idade: 95, anosExpectativaVidaHomem: 2, anoExpectativaVidaMulher: 2, idadeExpectativaVidaHomem: 97, idadeExpectativaVidaMulher: 97},
    {id: 96, idade: 96, anosExpectativaVidaHomem: 2, anoExpectativaVidaMulher: 2, idadeExpectativaVidaHomem: 98, idadeExpectativaVidaMulher: 98},
    {id: 97, idade: 97, anosExpectativaVidaHomem: 2, anoExpectativaVidaMulher: 2, idadeExpectativaVidaHomem: 99, idadeExpectativaVidaMulher: 99},
    {id: 98, idade: 98, anosExpectativaVidaHomem: 1, anoExpectativaVidaMulher: 1, idadeExpectativaVidaHomem: 99, idadeExpectativaVidaMulher: 99},
    {id: 99, idade: 99, anosExpectativaVidaHomem: 1, anoExpectativaVidaMulher: 1, idadeExpectativaVidaHomem: 100, idadeExpectativaVidaMulher: 100},
]

export default expectativaVidaList