export const grupoCoberturaSeguroVidaList = [
    {
        id: 0, // HARDCODE.idGrupoCoberturaSeguroVidaMorte
        descricao: 'Morte',
        descricao1aParte: 'Morte',
        descricao2aParte: '',
        ordem: 0,
    },
    {
        id: 1,
        descricao: 'Invalidez por Acidente',
        descricao1aParte: 'Invalidez',
        descricao2aParte: 'por Acidente',
        ordem: 1,
    },
    {
        id: 2,
        descricao: 'Invalidez por Doença',
        descricao1aParte: 'Invalidez',
        descricao2aParte: 'por Doença',
        ordem: 2,
    },
    {
        id: 3,
        descricao: 'Doenças',
        descricao1aParte: 'Doenças',
        descricao2aParte: '',
        ordem: 3,
    },
    {
        id: 4,
        descricao: 'Incapacidade Temp.',
        descricao1aParte: 'Incapacidade',
        descricao2aParte: 'Temporária',
        ordem: 4,
    },
    {
        id: 5, // HARDCODE.idGrupoCoberturaSeguroVidaFuneral
        descricao: 'Funeral',
        descricao1aParte: 'Funeral',
        descricao2aParte: '',
        ordem: 5,
    },
    {
        id: 6,
        descricao: 'Assistências',
        descricao1aParte: 'Assistências',
        descricao2aParte: '',
        ordem: 6,
    },
]

export const grupoCoberturaSeguroVida1aParteList = [
    grupoCoberturaSeguroVidaList[0].descricao1aParte,
    grupoCoberturaSeguroVidaList[1].descricao1aParte,
    grupoCoberturaSeguroVidaList[2].descricao1aParte,
    grupoCoberturaSeguroVidaList[3].descricao1aParte,
    grupoCoberturaSeguroVidaList[4].descricao1aParte,
    grupoCoberturaSeguroVidaList[5].descricao1aParte,
    grupoCoberturaSeguroVidaList[6].descricao1aParte,
]

export const grupoCoberturaSeguroVida2aParteList = [
    grupoCoberturaSeguroVidaList[0].descricao2aParte,
    grupoCoberturaSeguroVidaList[1].descricao2aParte,
    grupoCoberturaSeguroVidaList[2].descricao2aParte,
    grupoCoberturaSeguroVidaList[3].descricao2aParte,
    grupoCoberturaSeguroVidaList[4].descricao2aParte,
    grupoCoberturaSeguroVidaList[5].descricao2aParte,
    grupoCoberturaSeguroVidaList[6].descricao2aParte,
]