import HARDCODE from '../../../business-const/HardCode/HardCode'



const tipoConsorcioList = [
    {
       id: HARDCODE.idTipoConsorcioBensImoveis,
       descricao: 'Bens Imóveis',
       ordem: 10,
    },
    {
       id:  HARDCODE.idTipoConsorcioBensMoveis,
       descricao: 'Bens Móveis',
       ordem: 20,
    },
 ]
 
 export default tipoConsorcioList