import HARDCODE from '../../../business-const/HardCode/HardCode'

import MESSAGE from '../../../business-const/Message/Message'

import {
    dataAtual,
    difDatasAnos,
    ano,
} from '../../../business-components/Date/Date'

import {
    espacoAntes,
} from '../../../business-components/Formata/Formata'

import {
    calculaValorAnual,
} from '../cliente-form/ClienteService'

import {
    trataValorTexto,
    trataData,
} from '../cliente-form/ClienteServiceImportacao'

import {
    espaco,
    fimLinha,
    virgula,
    identificaTipoCoberturaSeguroVida,
    calculaDataTerminoCobertura,
    indexAtualJsonRegistros,
    obtemDadoPDF,
    obtemDadoPDFAnterior,
    obtemDadoPDFAvulso,
    obtemDadoXLSX,
    encontrarData,
    timeSeguradora,
    idAgenteTimeSeguradora,
    jsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosInicializacao,
    jsonRegistrosJsonRegistrosAtualizaErros,
    incluiCoberturas,
    incluiTabelaEvolucaoValorResgateCoberturaVidaInteira,
} from './IntegracaoClienteArquivoService'



export const importaPropostaIcatuHorizonte = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {



    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)



    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    if (PDFTxt.indexOf('☒ Adiantamento por Doença Terminal (cobertura básica)') !== -1) {

        PDFTxt = PDFTxt.replace(fimLinha + '☒ Morte Natural ou Acidental (cobertura básica)' + espaco,
            fimLinha + '☒ Morte Natural ou Acidental - Adiantamento por Doença Terminal (cobertura básica)' + espaco)
    }

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Invalidez Permanente Total ou Parcial por Acidente5' + fimLinha +
        '☒ Majoração de Membros?6', fimLinha + '☒ Invalidez Permanente Total ou Parcial por Acidente + Majoração de Membros')
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Invalidez Permanente Total ou Parcial por Acidente5' + fimLinha + '☐ Majoração de Membros?6', 
        fimLinha + '☒ Invalidez Permanente Total ou Parcial por Acidente')

    PDFTxt = PDFTxt.replace(espaco + 'Doenças Graves7' + espaco, espaco + 'Doenças Graves' + espaco)
    
    PDFTxt = PDFTxt.replace(espaco + 'Serviço de Assistência Funeral8' + espaco, espaco + 'Serviço de Assistência Funeral' + espaco)
    PDFTxt = PDFTxt.replace(espaco + '  ☒ Individual    ☐ Familiar' + espaco, espaco + '- Individual' + espaco)
    PDFTxt = PDFTxt.replace(espaco + '  ☐ Individual    ☒ Familiar' + espaco, espaco + '- Familiar' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária9' + fimLinha +
        '☐ com franquia reduzida ☐ com LER/DORT/LTC' + fimLinha, fimLinha + '☒ Diária por Incapacidade Temporária' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária9' + fimLinha +
        '☒ com franquia reduzida ☐ com LER/DORT/LTC' + fimLinha, fimLinha +
            '☒ Diária por Incapacidade Temporária - com franquia reduzida' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária9' + fimLinha +
        '☐ com franquia reduzida ☒ com LER/DORT/LTC' + fimLinha, fimLinha +
            '☒ Diária por Incapacidade Temporária - com LER/DORT/LTC' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária9' + fimLinha +
        '☒ com franquia reduzida ☒ com LER/DORT/LTC' + fimLinha, fimLinha +
            '☒ Diária por Incapacidade Temporária - com franquia reduzida - com LER/DORT/LTC' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Internação Hospitalar (DIH)10' + fimLinha +
        '☐ adicional UTI11' + fimLinha, fimLinha + '☒ Diária por Internação Hospitalar (DIH)' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Internação Hospitalar (DIH)10' + fimLinha +
        '☒ adicional UTI11' + fimLinha, fimLinha + '☒ Diária por Internação Hospitalar (DIH) - adicional UTI' + espaco)

    PDFTxt = PDFTxt.replace(espaco + 'Indenização Especial de Invalidez por Doença12' + espaco, espaco + 'Indenização Especial de Invalidez por Doença' + espaco)



    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Nº da proposta:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome completo Sexo Residente no Brasil?' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)
        
    if (jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados) {

        obtemDadoPDFAvulso (jsonRegistros,
            jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.substring(0,
                jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.length - 26),
                    HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome completo Sexo' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros,
            jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.substring(0,
                jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.length - 14),
                    HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    }
    

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.indexOf('☒ MASC.') !== -1) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].sexoSeguradoCliente = HARDCODE.idSexoMasculino

    } else {

        jsonRegistros.jsonRegistros[indexJsonRegistros].sexoSeguradoCliente = HARDCODE.idSexoFeminino
    }


    let linhaDados

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data de nascimento CPF Renda mensal' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados) {

        linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

        obtemDadoPDFAvulso (jsonRegistros, linhaDados[0], HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhaDados[1], HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhaDados[3], HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)

    } else {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome Social Data de Nascimento CPF' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)
        
        linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

        obtemDadoPDFAvulso (jsonRegistros, linhaDados[linhaDados.length - 2], HARDCODE.importacaoNascimentoSeguradoCliente,
            true, null, erros, nomeArquivo)

        obtemDadoPDFAvulso (jsonRegistros, linhaDados[linhaDados.length - 1], HARDCODE.importacaoCpfSeguradoCliente,
            true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão Renda Mensal   Origem dos Recursos' + fimLinha, espaco + 'R$',
            HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'R$' + espaco, espaco,
            HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)
    }


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço residencial Número Complemento' + fimLinha,
        fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
    

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Bairro Cidade UF CEP' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[linhaDados.length - 1],
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)
    

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone E-mail' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Telefone E-mail                                         Você é US Person1?' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)
    }

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0] + linhaDados[1],
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDFAvulso (jsonRegistros, linhaDados[2],
        HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)

    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].profissaoSegurado) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão País de nascimento Nacionalidade Você é US Person1?' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)
    
        linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)
    
        let dado = ''
        let indexLinhaDados = 0
    
        while (indexLinhaDados < linhaDados.length - 6) {
    
            dado += (dado ? espaco : '') + linhaDados[indexLinhaDados]
    
            indexLinhaDados++
        }

        obtemDadoPDFAvulso (jsonRegistros, dado, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)
    }
        

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Pagamento' + fimLinha + 'Período de' + espaco + fimLinha + 'Vigência' + fimLinha,
        fimLinha + 'Prêmio Total', HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '   Periodicidade:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Código Icatu' + fimLinha, fimLinha,
        HARDCODE.importacaoCodigoAgente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Código Icatu' + fimLinha, fimLinha,
        HARDCODE.importacaoCodigoAgente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data e hora da assinatura:' + espaco, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura = dataAtual()
    }




    const usuarioClienteSelecionado =
        idAgenteTimeSeguradora(timeSeguradoraAux, jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)

    if (usuarioClienteSelecionado) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

            const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
            let indexLinhasCoberturaAux = 0

            const linhasCobertura = []
            let indexLinhasCobertura = -1

            while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

                if (
                        linhasCoberturaAux[indexLinhasCoberturaAux].substring(0, 1) === '☒' &&
                        linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(',') !== -1
                ) {

                    indexLinhasCobertura++

                    linhasCobertura.push(linhasCoberturaAux[indexLinhasCoberturaAux])
                }

                indexLinhasCoberturaAux++
            }



            const coberturas = []

            let numeroCobertura
            let codigoCobertura
            let descricaoCobertura
            let dadosCobertura
            let valorCS
            let valorPremioLiquido
            let valorIof
            let valorPremio
            let classeAjusteRisco = ''
            let valorPremioExtra = ''
            let duracaoPremioExtra = ''
            let dataTerminoCobertura
            let valorRendaMensal = ''

            indexLinhasCobertura = 0

            while (indexLinhasCobertura < linhasCobertura.length) {

                dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

                const ajusteIndex1 = dadosCobertura[dadosCobertura.length - 1] === 'ANOS' ? 1 : 0

                const ajusteIndex2 = dadosCobertura[dadosCobertura.length - 2 - ajusteIndex1] === 'ANOS' ? 1 : 0
                
                valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 5 - ajusteIndex1 - ajusteIndex2])
                valorPremioLiquido = ''
                valorIof = ''
                valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 3 - ajusteIndex1 - ajusteIndex2])

                descricaoCobertura = ''

                indexLinhasCoberturaAux = 1

                while (indexLinhasCoberturaAux < dadosCobertura.length - 5 - 1 - ajusteIndex1 - ajusteIndex2) {
                
                    descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
                
                    indexLinhasCoberturaAux++
                }

                if (dadosCobertura[dadosCobertura.length - 1] === 'VITALÍCIO') {

                    dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                        HARDCODE.importacaoDataAssinatura, dadosCobertura[dadosCobertura.length - 1])

                } else {

                    dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                        HARDCODE.importacaoDataAssinatura, dadosCobertura[dadosCobertura.length - 2])
                }

                const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                    jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                    null, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra,
                        duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)

                indexLinhasCobertura += 1
            }

            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

        }

    } else {

        erros.push(MESSAGE().agenteNaoEncontradoProposta
            .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                .replace("$codigoAgente$", jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)
        )
    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaPropostaIcatuEssencial = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {



    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)



    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    if (PDFTxt.indexOf('☒ Morte Natural ou Acidental + Adiantamento por Doença Terminal') !== -1) {

        PDFTxt = PDFTxt.replace('☒ Morte Natural ou Acidental + Adiantamento por Doença Terminal' + espaco + fimLinha,
            '☒ Morte Natural ou Acidental + Adiantamento por Doença Terminal' + espaco)
    }

    PDFTxt = PDFTxt.replace('      ☐ ' + fimLinha + 'Majoração de Membros3?', '')
    
    PDFTxt = PDFTxt.replace('☒ Invalidez Permanente Total ou Parcial por Acidente (IPA)2      ☒ ' + fimLinha,
        '☒ Invalidez Permanente Total ou Parcial por Acidente (IPA) - Majoração de Membros')
    
    PDFTxt = PDFTxt.replace(espaco + 'Indenização Especial de Invalidez por Doença (IED)4' + espaco,
        espaco + 'Indenização Especial de Invalidez por Doença (IED)' + espaco)

    PDFTxt = PDFTxt.replace(espaco + 'Diagnóstico Definitivo de 24 Doenças Graves (DG)5' + espaco,
        espaco + 'Diagnóstico Definitivo de 24 Doenças Graves (DG)' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária (DIT)6' + fimLinha +
        espaco + '☐ com franquia reduzida  ☐ com LER/DORT/LTC' + fimLinha,
            fimLinha + '☒ Diária por Incapacidade Temporária(DIT)' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária (DIT)6' + fimLinha +
        espaco + '☒ com franquia reduzida  ☐ com LER/DORT/LTC' + fimLinha,
            fimLinha + '☒ Diária por Incapacidade Temporária(DIT) - com franquia reduzida' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária (DIT)6' + fimLinha +
        espaco + '☐ com franquia reduzida  ☒ com LER/DORT/LTC' + fimLinha,
            fimLinha + '☒ Diária por Incapacidade Temporária(DIT) - com LER/DORT/LTC' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária (DIT)6' + fimLinha +
        espaco + '☒ com franquia reduzida  ☒ com LER/DORT/LTC' + fimLinha,
            fimLinha + '☒ Diária por Incapacidade Temporária(DIT) - com franquia reduzida - com LER/DORT/LTC' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Internação Hospitalar (DIH)7                 ☐ adicional UTI8' + espaco,
        fimLinha + '☒ Diária por Internação Hospitalar (DIH)' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Internação Hospitalar (DIH)7                 ☒ adicional UTI8' + espaco,
        fimLinha + '☒ Diária por Internação Hospitalar (DIH) - adicional UTI' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Diária por Incapacidade Temporária (DIT)6' + fimLinha +
        espaco + '☐ com franquia reduzida  ☐ com LER/DORT/LTC' + fimLinha,
            fimLinha + '☒ Diária por Incapacidade Temporária(DIT)' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '☒ Serviço de Assistência Funeral       ☒ Individual      ☐ Familiar' + espaco,
        fimLinha + '☒ Serviço de Assistência Funeral - Individual' + espaco)
    PDFTxt = PDFTxt.replace(fimLinha + '☒ Serviço de Assistência Funeral       ☐ Individual      ☒ Familiar' + espaco,
        fimLinha + '☒ Serviço de Assistência Funeral - Familiar' + espaco)



    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Nº da proposta:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaPendente


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome completo Sexo' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros,
        jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.substring(0,
            jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.length - 14),
                HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    

    if (jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.indexOf('☒ MASC.') !== -1) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].sexoSeguradoCliente = HARDCODE.idSexoMasculino

    } else {

        jsonRegistros.jsonRegistros[indexJsonRegistros].sexoSeguradoCliente = HARDCODE.idSexoFeminino
    }


    let linhaDados

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome Social Data de Nascimento CPF' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)
    
    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[linhaDados.length - 2], HARDCODE.importacaoNascimentoSeguradoCliente,
        true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[linhaDados.length - 1], HARDCODE.importacaoCpfSeguradoCliente,
        true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Profissão Renda Mensal Origem dos recursos' + fimLinha, espaco + 'R$',
        HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'R$' + espaco, espaco,
        HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Endereço residencial (Av./Rua)' + fimLinha,
        fimLinha, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'CEP UF DDD       Celular País de residência                Nacionalidade' + fimLinha, fimLinha,
        HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[0],
        HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDFAvulso (jsonRegistros, linhaDados[2] + linhaDados[3] + ((linhaDados[2] + linhaDados[3]).length < 8 ? linhaDados[4] : ''),
        HARDCODE.importacaoCelularSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'E-mail                                                                                                                                                                              Autoriza envio por e-mail?' + fimLinha, espaco,
        HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].emailSeguradoCliente) {

        obtemDadoPDFAnterior(PDFTxt, jsonRegistros, fimLinha + 'É aposentado? Motivo  Causa da Aposentadoria por invalidez' + fimLinha, fimLinha,
            HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)

        linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(espaco)

        obtemDadoPDFAvulso(jsonRegistros, linhaDados[0], HARDCODE.importacaoEmailSeguradoCliente, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Coberturas (marcar apenas as coberturas contratadas) Capital segurado Prêmio mensal1 Vigência' + fimLinha,
        fimLinha + 'Prêmio mensal total1:', HARDCODE.importacaoPropostaCoberturas, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

        jsonRegistros.indexInicial = 1

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Coberturas (marcar apenas as coberturas contratadas) Capital segurado Prêmio mensal1 Vigência' + fimLinha,
            fimLinha + 'Prêmio mensal total1:', HARDCODE.importacaoPropostaCoberturas, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Coberturas (marcar apenas as coberturas contratadas) Capital segurado Prêmio anual1 Vigência' + fimLinha,
                fimLinha + 'Prêmio anual total1:', HARDCODE.importacaoPropostaCoberturas, true, null, erros, nomeArquivo)
        }
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + '   Periodicidade:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome completo ou razão social % de distrib. E-mail Código Icatu' + fimLinha +
        'Para uso do corretor - Corretagem' + fimLinha + 'Nome completo ou razão social % de distrib. E-mail Código Icatu' + fimLinha,
            fimLinha + 'O segurado poderá consultar a situação cadastral do corretor de seguros, por meio do número de seu registro na SUSEP, nome completo, CNPJ ou' +
                fimLinha, HARDCODE.importacaoLinhaDados, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados) {

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nome completo ou razão social % de distrib. E-mail Código Icatu' + fimLinha,
                fimLinha + 'O segurado poderá consultar a situação cadastral do corretor de seguros, por meio do número de seu registro na SUSEP, nome completo, CNPJ ou' +
                    fimLinha, HARDCODE.importacaoLinhaDados, true, null, erros, nomeArquivo)
    }

    linhaDados = jsonRegistros.jsonRegistros[indexJsonRegistros].linhaDados.split(fimLinha)
    
    linhaDados = linhaDados[linhaDados.length - 2].split(espaco)

    obtemDadoPDFAvulso(jsonRegistros, linhaDados[linhaDados.length - 1], HARDCODE.importacaoCodigoAgente,
        true, null, erros, nomeArquivo)


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Data e hora da assinatura:' + espaco, espaco,
        HARDCODE.importacaoDataAssinatura, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].dataAssinatura = dataAtual()
    }



    const usuarioClienteSelecionado =
        idAgenteTimeSeguradora(timeSeguradoraAux, jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)

    if (usuarioClienteSelecionado) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado

        if (jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas) {

            const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas.split(fimLinha)
            let indexLinhasCoberturaAux = 0

            const linhasCobertura = []
            let indexLinhasCobertura = -1

            while (indexLinhasCoberturaAux < linhasCoberturaAux.length) {

                if (
                        linhasCoberturaAux[indexLinhasCoberturaAux].substring(0, 1) === '☒' &&
                        linhasCoberturaAux[indexLinhasCoberturaAux].indexOf(',') !== -1
                ) {

                    indexLinhasCobertura++

                    linhasCobertura.push(linhasCoberturaAux[indexLinhasCoberturaAux])
                }

                indexLinhasCoberturaAux++
            }



            const coberturas = []

            let numeroCobertura
            let codigoCobertura
            let descricaoCobertura
            let dadosCobertura
            let valorCS
            let valorPremioLiquido
            let valorIof
            let valorPremio
            let classeAjusteRisco = ''
            let valorPremioExtra = ''
            let duracaoPremioExtra = ''
            let dataTerminoCobertura
            let valorRendaMensal = ''

            indexLinhasCobertura = 0

            while (indexLinhasCobertura < linhasCobertura.length) {

                dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

                const ajusteIndex = dadosCobertura[dadosCobertura.length - 1] === 'ANOS' ? 3 : 1

                valorCS = trataValorTexto(dadosCobertura[dadosCobertura.length - 3 - ajusteIndex])
                valorPremioLiquido = ''
                valorIof = ''
                valorPremio = trataValorTexto(dadosCobertura[dadosCobertura.length - 1 - ajusteIndex])

                descricaoCobertura = ''

                indexLinhasCoberturaAux = 1

                while (indexLinhasCoberturaAux < dadosCobertura.length - 3 - 1 - ajusteIndex) {
                
                    descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexLinhasCoberturaAux]
                
                    indexLinhasCoberturaAux++
                }

                if (dadosCobertura[dadosCobertura.length - 1] === 'VITALÍCIO') {

                    dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                        HARDCODE.importacaoDataAssinatura, dadosCobertura[dadosCobertura.length - 1])

                } else {

                    dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                        HARDCODE.importacaoDataAssinatura, Number.parseInt(dadosCobertura[dadosCobertura.length - 2]) -
                            difDatasAnos (jsonRegistros.jsonRegistros[indexJsonRegistros].nascimentoSeguradoCliente, dataAtual()) + 1)
                }

                const tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                    jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

                incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                    null, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco, valorPremioExtra,
                        duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)

                indexLinhasCobertura += 1
            }

            jsonRegistros.jsonRegistros[indexJsonRegistros].propostaCoberturas = coberturas

        }

    } else {

        erros.push(MESSAGE().agenteNaoEncontradoProposta
            .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                .replace("$codigoAgente$", jsonRegistros.jsonRegistros[indexJsonRegistros].codigoAgente)
        )
    }



    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
    


    return jsonRegistros.jsonRegistros
}



export const importaListaPropostasIcatu = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)


    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    while (linha < XLSX.length) {

        if (
                XLSX[linha][0] &&
                ('' + XLSX[linha][7]).toUpperCase().indexOf('AGUARDANDO ASSINATURA') === -1 &&
                ('' + XLSX[linha][7]).toUpperCase().indexOf('PROPOSTA EXPIRADA') === -1
        ) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 1, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            let agente = XLSX[linha][5]

            const divisaoComissao = agente.indexOf('/')

            if (divisaoComissao !== -1) {

                agente = agente.substring(0, divisaoComissao).trim()
            }

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, agente)

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           
            
            if (('' + XLSX[linha][7]).length === 62) {

                obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoDataAssinatura, true, null, erros, nomeArquivo)
            }

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 3, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)


            obtemDadoXLSX(XLSX, jsonRegistros, linha, 2, HARDCODE.importacaoTipoPeriodicidadePagtoPremioValor, false,
                null, erros, nomeArquivo)
            
            if (jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremioValor) {

                jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeMensal

            } else {

                jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio = HARDCODE.idTipoPeriodicidadeAnual
            }
                

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 10, HARDCODE.importacaoTipoFormaPagtoPremio, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoObservacao, false, null, erros, nomeArquivo)

            let dataUltimaAlteracao = ''

            let index = 0

            jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = HARDCODE.idStatusPropostaNaoInformado

            while (index < linhaIntegracao.statusProposta.length) {

                if (jsonRegistros.jsonRegistros[indexJsonRegistros].observacao.indexOf(linhaIntegracao.statusProposta[index].id) === 0) {
            
                    jsonRegistros.jsonRegistros[indexJsonRegistros].statusProposta = linhaIntegracao.statusProposta[index].idConversao

                    if (linhaIntegracao.statusProposta[index].atualizaDataUltimaAlteracao) {

                        dataUltimaAlteracao = encontrarData(jsonRegistros.jsonRegistros[indexJsonRegistros].observacao)
                    }

                    break
                }

                index++
            }

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 6, HARDCODE.importacaoDataUltimaAlteracao, true, null, erros, nomeArquivo)

            if (dataUltimaAlteracao) {

                if (dataUltimaAlteracao.length === 10) {

                    jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao = trataData(dataUltimaAlteracao)

                } else {

                    const dataUltimaAlteracaoAux = trataData(dataUltimaAlteracao + '/' +
                        ano(jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao))

                    if (dataUltimaAlteracaoAux < jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao) {

                        jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao = trataData(dataUltimaAlteracao + '/' +
                            (ano(jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao) + 1))

                    } else {

                        jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao = dataUltimaAlteracaoAux
                    }
                }

            } else {

                jsonRegistros.jsonRegistros[indexJsonRegistros].dataUltimaAlteracao = null
            }


            if (usuarioClienteSelecionado) {
            
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
    
            } else {
        
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", agente)
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }

    return jsonRegistros.jsonRegistros
}



export const importaApoliceIcatuHorizonte = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    obtemDadoPDF(PDFTxt, jsonRegistros, 'CNPJ 42.283.770/0001-39 certifica que' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'nascido(a) em' + espaco, virgula,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF nº' + espaco, virgula,
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Certificado nº:' + espaco, espaco,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Vigência do Certificado: a partir de' + espaco, fimLinha, 
        HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta nº:' + espaco, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
   
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade de Pagamento:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, espaco,
        HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)
   


    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Pagamento' + fimLinha + 'Valor do' + fimLinha + 'Prêmio' + fimLinha,
        fimLinha + 'Caso deseje utilizar o valor de resgate para quitar a(s) cobertura(s) de Morte Qualquer Causa',
            HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        /*
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ PERMANENTE TOTAL OU PARCIAL' + fimLinha + 'POR ACIDENTE     ' + fimLinha,
                    'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ PERMANENTE TOTAL OU PARCIAL' + fimLinha + 'POR ACIDENTE - MA' + fimLinha,
                    'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE - MA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR' + fimLinha + 'ACIDENTE' + fimLinha,
                    'INVALIDEZ PERMANENTE TOTAL OU PARCIAL POR ACIDENTE' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'ADIANTAMENTO POR DOENÇA EM ESTÁGIO' + fimLinha + 'TERMINAL            ' + fimLinha,
                    'ADIANTAMENTO POR DOENÇA EM ESTÁGIO TERMINAL' + espaco)
                    
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INDENIZAÇÃO ESPECIAL POR MORTE' + fimLinha + 'ACIDENTAL               ' + fimLinha,
                    'INDENIZAÇÃO ESPECIAL POR MORTE ACIDENTAL' + espaco)
                      
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'INDENIZAÇÃO ESPECIAL DE INVALIDEZ POR' + fimLinha + 'DOENÇA           ' + fimLinha,
                    'INDENIZAÇÃO ESPECIAL DE INVALIDEZ POR DOENÇA' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR INTERNAÇÃO HOSPITALAR -' + fimLinha + 'ADICIONAL UTI       ' + fimLinha,
                    'DIÁRIA POR INTERNAÇÃO HOSPITALAR - ADICIONAL UTI' + espaco)

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIÁRIA POR INCAPACIDADE TEMPORÁRIA -' + fimLinha + 'MÓDULO 1          ' + fimLinha,
                    'DIÁRIA POR INCAPACIDADE TEMPORÁRIA - MÓDULO 1' + espaco)
        
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(
                'DIAGNÓSTICO DEFINITIVO DE 24 DOENÇAS' + fimLinha + 'GRAVES            ' + fimLinha,
                    'DIAGNÓSTICO DEFINITIVO DE 24 DOENÇAS GRAVES' + espaco)
        */
       
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replaceAll(espaco + 'ANOS' + espaco, 'ANOS' + espaco)



        const linhasCobertura = []
        let indexLinhasCobertura = 0

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
        let indexLinhasPosicaoAux = 0
        let linhaCoberturaAux = ''

        const coberturas = []

        let dadosCobertura
        let indexDadosCobertura

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura
        let valorRendaMensal

        while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

            if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('R$') === -1) {

                linhaCoberturaAux = espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux])

            } else {

                linhasCobertura.push(espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux]))

                linhaCoberturaAux = ''
            }

            indexLinhasPosicaoAux += 1
        }

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

            indexDadosCobertura = 0

            descricaoCobertura = ''

            while (dadosCobertura[indexDadosCobertura] !== 'R$') {

                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexDadosCobertura]

                indexDadosCobertura++
            }

            if (descricaoCobertura === 'MORTE QUALQUER CAUSA' && dadosCobertura[indexDadosCobertura + 2] === 'VITALÍCIO') {

                descricaoCobertura += espaco + dadosCobertura[indexDadosCobertura + 2]
            }

            tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            valorCS = trataValorTexto(dadosCobertura[indexDadosCobertura + 1])
            valorPremio = trataValorTexto(dadosCobertura[indexDadosCobertura + 5])

            dataTerminoCobertura = calculaDataTerminoCobertura(jsonRegistros, indexJsonRegistros,
                HARDCODE.importacaoDataEmissao, dadosCobertura[indexDadosCobertura + 2])

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco,
                    valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
            
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaApoliceIcatuEssencial = (PDFTxt, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

    PDFTxt = PDFTxt.replace(fimLinha + 'Vida Em Grupo - Apólice nº:' + fimLinha, fimLinha + 'Vida Em Grupo - Apólice nº:' + espaco)

    PDFTxt = PDFTxt.replace(fimLinha + '60 dia(s)' + fimLinha + 'para' + fimLinha + 'doença.' + fimLinha + '0 dia(s) para eventos' +
        fimLinha + 'por acidente pessoal' + fimLinha + 'coberto.' + fimLinha + 'Titular' +
            fimLinha + 'Regra de Capital Diárias Carência Franquia', '')


    obtemDadoPDF(PDFTxt, jsonRegistros, 'CNPJ 42.283.770/0001-39 certifica que' + fimLinha, fimLinha,
        HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente =
        jsonRegistros.jsonRegistros[indexJsonRegistros].nomeSeguradoCliente.replace(',', espaco)
    
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'nascido(a) em' + espaco, virgula,
        HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'CPF nº' + espaco, virgula,
        HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Certificado nº:' + espaco, espaco,
        HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Proposta nº:' + espaco, espaco,
        HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)
   
    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade de Pagamento:' + espaco, fimLinha,
        HARDCODE.importacaoTipoPeriodicidadePagtoPremio, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) {

        jsonRegistros.indexInicial -= 100

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Periodicidade de Pagamento:' + espaco, fimLinha,
            HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Valor do Prêmio Total:' + espaco, fimLinha,
        HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)

    jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = 
        calculaValorAnual(jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado,
            jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) 

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, espaco,
        HARDCODE.importacaoStatusApolice, false, linhaIntegracao.statusApolice, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].statusApolice) {

        jsonRegistros.indexInicial -= 100

        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Status da Apólice:' + espaco, espaco,
            HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice, erros, nomeArquivo)
    }
   
    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Vigência do Certificado: a partir de' + espaco, fimLinha, 
        HARDCODE.importacaoDataEmissao, false, null, erros, nomeArquivo)

    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataEmissao) {

        // Vida em Grupo
        obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Vigência da Apólice: de' + espaco, espaco, 
            HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

        obtemDadoPDFAnterior(PDFTxt, jsonRegistros, espaco + 'Valor do Sorteio:' + espaco, fimLinha, 
            HARDCODE.importacaoDataTerminoCoberturaString, false, null, erros, nomeArquivo)

        if (!jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString) {

            jsonRegistros.indexInicial -= 300

            obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Certificado nº:' + espaco, fimLinha, 
                HARDCODE.importacaoDataTerminoCoberturaString, true, null, erros, nomeArquivo)

            const dados = jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString.split(espaco)

            jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString = dados[dados.length - 1]
        }
    }

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Titular Cônjuge Filhos' + fimLinha,
        fimLinha + 'Renda de Eventos Aleatórios - Apólice'/*+ fimLinha*/,
            HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)
    
    if (!jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'Titular Cônjuge Filhos' + fimLinha,
            fimLinha + 'BENEFÍCIOS ADICIONAIS' + fimLinha,
                HARDCODE.importacaoCoberturas, true, null, erros, nomeArquivo)
    }

    const coberturasAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'BENEFÍCIOS ADICIONAIS' + fimLinha,
        fimLinha + 'BENEFICIÁRIOS'/*'Seguro Viagem'*/ + fimLinha, HARDCODE.importacaoCoberturas, false, null, erros, nomeArquivo)
    
    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replace(fimLinha + 'NOME TITULAR CÔNJUGE FILHOS' + fimLinha,
                espaco)
                
        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.replace('NOME TITULAR CÔNJUGE FILHOS' + fimLinha +
                'ASSISTÊNCIA DOMICILIAR' + fimLinha + 'SEGURO VIAGEM' + fimLinha, '')

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas =
            coberturasAux + fimLinha + jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas
            
    } else {

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturasAux

    }


    if (jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas) {

        const linhasCobertura = []
        let indexLinhasCobertura = 0

        const linhasCoberturaAux = jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas.split(fimLinha)
        let indexLinhasPosicaoAux = 0
        let linhaCoberturaAux = ''

        const coberturas = []

        let dadosCobertura
        let indexDadosCobertura

        let numeroCobertura
        let codigoCobertura
        let descricaoCobertura
        let tipoCoberturaSeguroVida
        let valorCS
        let valorPremioLiquido
        let valorIof
        let valorPremio =
            jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString
                ?
                    0
                :
                    ''
        let classeAjusteRisco
        let valorPremioExtra
        let duracaoPremioExtra
        let dataTerminoCobertura =
            jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString
                ?
                    trataData(jsonRegistros.jsonRegistros[indexJsonRegistros].dataTerminoCoberturaString)
                :
                    ''
        let valorRendaMensal

        while (indexLinhasPosicaoAux < linhasCoberturaAux.length) {

            if (
                    linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('VIDA EM GRUPO - APÓLICE Nº:') === -1 &&
                    linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('RENDA DE EVENTOS ALEATÓRIOS - APÓLICE') === -1 &&
                    linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('Nº: ') === -1 &&
                    linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('15 DIA(S) PARA EVENTOS') === -1 &&
                    linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('POR DOENÇA COBERTA.') === -1
            ) {

                if (linhasCoberturaAux[indexLinhasPosicaoAux].indexOf('R$') === -1) {

                    linhaCoberturaAux = espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux])

                } else {

                    linhasCobertura.push(espacoAntes(linhaCoberturaAux, linhasCoberturaAux[indexLinhasPosicaoAux]))

                    linhaCoberturaAux = ''
                }
            }

            indexLinhasPosicaoAux += 1
        }

        while (indexLinhasCobertura < linhasCobertura.length) {

            dadosCobertura = linhasCobertura[indexLinhasCobertura].split(espaco)

            indexDadosCobertura = 0

            descricaoCobertura = ''

            while (dadosCobertura[indexDadosCobertura] !== 'R$') {

                descricaoCobertura += (descricaoCobertura ? espaco : '') + dadosCobertura[indexDadosCobertura]

                indexDadosCobertura++
            }

            tipoCoberturaSeguroVida = identificaTipoCoberturaSeguroVida(descricaoCobertura, linhaIntegracao,
                jsonRegistros.jsonRegistros[indexJsonRegistros], nomeArquivo, erros)

            valorCS = trataValorTexto(dadosCobertura[indexDadosCobertura + 1])

            incluiCoberturas(coberturas, numeroCobertura, codigoCobertura, descricaoCobertura, tipoCoberturaSeguroVida,
                HARDCODE.idStatusCoberturaAtivaPagandoPremio, valorCS, valorPremioLiquido, valorIof, valorPremio, classeAjusteRisco,
                    valorPremioExtra, duracaoPremioExtra, dataTerminoCobertura, valorRendaMensal)
            
            indexLinhasCobertura++
        }

        jsonRegistros.jsonRegistros[indexJsonRegistros].coberturas = coberturas
    }


    
    jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    return jsonRegistros.jsonRegistros
}



export const importaListaApolicesIcatuImplantacao = (XLSX, idUsuarioLogado, jsonUsuario, equipeList, linhaIntegracao, seguradora,
    nomeArquivo, erros, variaveisGlobais) => {

    const timeSeguradoraAux = timeSeguradora(idUsuarioLogado, equipeList, seguradora)

    let jsonRegistros = jsonRegistrosInicializacao()

    let linha = linhaIntegracao.cabecalho + 1

    let usuarioClienteSelecionadoUnico = true
    let usuarioClienteSelecionadoAnt = '-x-'

    while (linha < XLSX.length) {

        if (XLSX[linha][5] && (XLSX[linha][16] === 'Emitida' || XLSX[linha][16] === 'Cancelada')) {

            jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

            

            const usuarioClienteSelecionado = idAgenteTimeSeguradora(timeSeguradoraAux, XLSX[linha][77])

            if (usuarioClienteSelecionado !== usuarioClienteSelecionadoAnt) {

                if (usuarioClienteSelecionadoAnt !== '-x-') {

                    usuarioClienteSelecionadoUnico = false
                }

                usuarioClienteSelecionadoAnt = usuarioClienteSelecionado
            }

            const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)

           

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 24/*5*/, HARDCODE.importacaoNumeroApolice, true, null, erros, nomeArquivo)

            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice = 
                jsonRegistros.jsonRegistros[indexJsonRegistros].numeroApolice.replace("'", "")

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 24, HARDCODE.importacaoNumeroProposta, true, null, erros, nomeArquivo)

            jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta = 
                jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta.replace("'", "")
    
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 16, HARDCODE.importacaoStatusApolice, true, linhaIntegracao.statusApolice,
                erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 13, HARDCODE.importacaoDataEmissao, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 15, HARDCODE.importacaoDataCancelamento, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 17, HARDCODE.importacaoMotivoCancelamento, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 6, HARDCODE.importacaoNomeSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 8, HARDCODE.importacaoCpfSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 7, HARDCODE.importacaoNascimentoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 70, HARDCODE.importacaoSexoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoProfissaoSegurado, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 57, HARDCODE.importacaoCidadeSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 58, HARDCODE.importacaoUfSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 60, HARDCODE.importacaoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 56, HARDCODE.importacaoBairroSeguradoCliente, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 59, HARDCODE.importacaoCepSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoNumeroEnderecoSeguradoCliente, true, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoComplementoEnderecoSeguradoCliente, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 61, HARDCODE.importacaoCelularSeguradoCliente, false, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 62, HARDCODE.importacaoEmailSeguradoCliente, false, null, erros, nomeArquivo)
            
            //obtemDadoXLSX(XLSX, jsonRegistros, linha, ?, HARDCODE.importacaoRendaMensal, true, null, erros, nomeArquivo)

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 50, HARDCODE.importacaoValorPremioAnualizado, true, null, erros, nomeArquivo)
            
            obtemDadoXLSX(XLSX, jsonRegistros, linha, 66, HARDCODE.importacaoTipoPeriodicidadePagtoPremio, true, null, erros, nomeArquivo)
           
            jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado = 
                calculaValorAnual(jsonRegistros.jsonRegistros[indexJsonRegistros].valorPremioAnualizado,
                    jsonRegistros.jsonRegistros[indexJsonRegistros].tipoPeriodicidadePagtoPremio) 

            obtemDadoXLSX(XLSX, jsonRegistros, linha, 18, HARDCODE.importacaoTipoFormaPagtoPremioConversao, true,
                linhaIntegracao.formaPagtoPremio, erros, nomeArquivo)


            
            if (usuarioClienteSelecionado) {
               
                jsonRegistros.jsonRegistros[indexJsonRegistros].usuarioClienteSelecionado = usuarioClienteSelecionado
    
            } else {
            
                erros.push(MESSAGE().agenteNaoEncontradoProposta
                    .replace("$numeroProposta$", jsonRegistros.jsonRegistros[indexJsonRegistros].numeroProposta)
                        .replace("$codigoAgente$", XLSX[linha][77])
                )
            }

            jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)
        }

        linha++
    }

    if (usuarioClienteSelecionadoUnico) {

        jsonRegistros.jsonRegistros?.forEach( (linha, ind) => {

            jsonRegistros.jsonRegistros[ind].usuarioClienteSelecionado = ''
        })
    }

    return jsonRegistros.jsonRegistros
}



export const importaCotacaoIcatuTabelaEvolucaoValorResgate = (values, item, PDFTxt, nomeArquivo, erros) => {

    const jsonRegistros = jsonRegistrosInicializacao()

    jsonRegistros.jsonRegistros.push(jsonRegistrosJsonRegistrosInicializacao(erros))

     

    obtemDadoPDF(PDFTxt, jsonRegistros, espaco + 'DATA DO ESTUDO:' + espaco, fimLinha,
        HARDCODE.importacaoDataCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Nº ESTUDO:' + espaco, fimLinha,
        HARDCODE.importacaoNumeroCotacao, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Morte com Vigência Vitalícia (cobertura básica)' + espaco,
        espaco, HARDCODE.importacaoValorCS, true, null, erros, nomeArquivo)

    obtemDadoPDF(PDFTxt, jsonRegistros, fimLinha + 'Saldamento - Cobertura básica (R$)' + fimLinha,
        fimLinha + 'OBSERVAÇÕES GERAIS' + fimLinha,
            HARDCODE.importacaoTabelaEvolucaoValorResgate, true, null, erros, nomeArquivo)

    const indexJsonRegistros = indexAtualJsonRegistros(jsonRegistros)



    if (jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate) {

        const linhasTabelaEvolucaoValorResgateAux =
            jsonRegistros.jsonRegistros[indexJsonRegistros].tabelaEvolucaoValorResgate.split(fimLinha)
        let indexLinhasTabelaEvolucaoValorResgateAux = 0

        const linhasTabelaEvolucaoValorResgate = []
        let indexLinhasTabelaEvolucaoValorResgate = 0

        let numeroCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].numeroCotacao
        let dataCotacao = jsonRegistros.jsonRegistros[indexJsonRegistros].dataCotacao
        let ano
        let idade
        let valorCS = jsonRegistros.jsonRegistros[indexJsonRegistros].valorCS
        let valorPremioAnualizadoAcumulado
        let valorResgate
        let valorSaldado
        let beneficioProlongado

        item.tabelaEvolucaoValorResgate = []

        let dadosTabelaEvolucaoValorResgate

        while (indexLinhasTabelaEvolucaoValorResgateAux < linhasTabelaEvolucaoValorResgateAux.length) {

            if (linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux].indexOf('/') === -1) {

                linhasTabelaEvolucaoValorResgate.push(
                    linhasTabelaEvolucaoValorResgateAux[indexLinhasTabelaEvolucaoValorResgateAux]
                )
            }

            indexLinhasTabelaEvolucaoValorResgateAux++
        }

        while (indexLinhasTabelaEvolucaoValorResgate < linhasTabelaEvolucaoValorResgate.length) {

            dadosTabelaEvolucaoValorResgate = linhasTabelaEvolucaoValorResgate[indexLinhasTabelaEvolucaoValorResgate].split(espaco)

            ano = Number.parseInt(dadosTabelaEvolucaoValorResgate[0])
            idade = Number.parseInt(dadosTabelaEvolucaoValorResgate[1])
            valorPremioAnualizadoAcumulado = trataValorTexto(dadosTabelaEvolucaoValorResgate[2])
            valorResgate = trataValorTexto(dadosTabelaEvolucaoValorResgate[3])
            valorSaldado = trataValorTexto(dadosTabelaEvolucaoValorResgate[4])
            beneficioProlongado = ''

            incluiTabelaEvolucaoValorResgateCoberturaVidaInteira(values, item, numeroCotacao, dataCotacao, ano, idade, valorCS,
                valorPremioAnualizadoAcumulado, valorResgate, valorSaldado, beneficioProlongado)
            
            indexLinhasTabelaEvolucaoValorResgate++
        }
    }



    
    //jsonRegistrosJsonRegistrosAtualizaErros(jsonRegistros, erros)



    //return jsonRegistros.jsonRegistros
}