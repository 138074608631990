import HARDCODE from '../../../business-const/HardCode/HardCode'

import LABEL from '../../../business-const/Label/Label'

import TAMMAX from '../../../business-rules/TamMax/TamMax'

import {
    mes,
    ano,
    formataDataEmMesAnoSemBarra,
} from '../../../business-components/Date/Date'

import {
    round
} from '../../../business-components/round/round'

import {
    pesquisaList,
    pesquisaDescricaoList,
} from '../../../business-rules/List/ListService'

import familiaList from '../../../business-rules/List/familiaList'

import tipoProjetoVidaSonhoList from '../../../business-rules/List/tipoProjetoVidaSonhoList'

import {
    idadeFamiliarDataSimulacao,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosIcompletosAlertaCamposObrigatorios
} from '../estudo-form/EstudoServiceAlertaCamposObrigatorios'

import {
    anoFinalProjetoVidaSonho,
    calculaTracarMeta,
} from '../estudo-form/EstudoServiceProjetoVidaSonho'



export const incrementaContProjetoVidaSonho = ( values ) => {

    if (!values.contProjetoVidaSonho) {

        values.contProjetoVidaSonho = 0
    }

    values.contProjetoVidaSonho++
}



export const processaRegistrosDefaultIniciaisProjetoVidaSonho = ( values, familiar ) => {

    if (familiar) {
        if (!values.familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho) {
            values.familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho = []
        }

        if (!values.projetoVidaSonho) {
            values.projetoVidaSonho = []
        }
        
        if (!pesquisaList(values.familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho, familiar).id) {

            values.familiaresProcessouRegistrosDefaultIniciaisProjetoVidaSonho.push(
                {
                    id: familiar
                }
            )

            incrementaContProjetoVidaSonho(values)
            values.projetoVidaSonho.push(
                {
                    id: values.contProjetoVidaSonho,
                    familiar: familiar,
                }
            )
        }
    }
}



const calculaProjetoVidaSonhoValorAcumulacaoJaRealizado = ( item, values) => {

    if (item.valor === item.valorAcumulacaoJaRealizado) {

        item.idadeRealizacao = ''
        item.anoRealizacao = ''
        item.tracarMeta = ''

        calculaProjetoVidaSonhoTracarMeta( item, values )
    }
}

const calculaProjetoVidaSonhoTracarMeta = ( item, values ) => {

    if (!item.idadeRealizacao && !item.anoRealizacao) {

        item.tracarMeta = false
    }

    if (!item.tracarMeta) {

        item.mesAnoInicioAcumulacao = null
        item.mesAnoFimAcumulacao = null
        item.mesRealizacao = null
        item.tipoPeriodicidadeAporte = ''
        item.recorrencia = ''
        item.controlarAportes = ''

        calculaProjetoVidaSonhoControlarAportes(item)

    } else {

        if (!item.mesAnoInicioAcumulacao && !item.mesAnoFimAcumulacao && !item.mesRealizacao) {

            item.mesAnoInicioAcumulacao = formataDataEmMesAnoSemBarra(values.dataSimulacaoEstudo)

            item.mesAnoFimAcumulacao = '11' + anoFinalProjetoVidaSonho(values, ano(values.dataSimulacaoEstudo), 
                item.anoRealizacao, item.idadeRealizacao, item.familiar)

            item.mesRealizacao = '12'
        }

        if (!item.tipoPeriodicidadeAporte) {

            item.tipoPeriodicidadeAporte = HARDCODE.idTipoPeriodicidadeMensal
        }
    }
}

const calculaProjetoVidaSonhoControlarAportes = ( item ) => {

    if (!item.controlarAportes) {

        item.diaAporte = ''
    }
}

const calculaProjetoVidaSonhoZeraCamposNaoInformados = ( item, values ) => {
    
    calculaProjetoVidaSonhoValorAcumulacaoJaRealizado(item, values)
    calculaProjetoVidaSonhoTracarMeta(item, values)
    calculaProjetoVidaSonhoControlarAportes(item)
}

export const processaAlteracaoProjetoVidaSonhoFamiliar = ( item ) => {

    if (item.idadeRealizacao && item.familiar === HARDCODE.idFamiliarFamilia) {

        item.idadeRealizacao = ''
    }
}

export const processaAlteracaoProjetoVidaSonhoTipoProjetoVidaSonho = ( item, values ) => {

    calculaProjetoVidaSonhoZeraCamposNaoInformados(item, values)
}

export const processaAlteracaoProjetoVidaSonhoValorAcumulacaoJaRealizado = ( item, values ) => {

    calculaProjetoVidaSonhoValorAcumulacaoJaRealizado(item, values)
}

export const processaAlteracaoProjetoVidaSonhoTracarMeta = ( item, values ) => {
    
    calculaProjetoVidaSonhoTracarMeta(item, values)
}

export const processaAlteracaoProjetoVidaSonhoRecorrencia = ( values, item ) => {

    if (!item.recorrencia) {

        item.anoLimiteRecorrencia = ''

    } else {

        if (!item.anoLimiteRecorrencia) {

            item.anoLimiteRecorrencia = values.anoFinalVaiAposentarCliente
        }
    }
}

export const processaAlteracaoProjetoVidaSonhoControlarAportes = ( item ) => {
    
    calculaProjetoVidaSonhoControlarAportes(item)
}



export const dadosCompletosLinhaProjetoVidaSonho = ( values, linhaProjetoVidaSonho ) => {
    const anoAtual = ano(values.dataSimulacaoEstudo)

    return  linhaProjetoVidaSonho.valor &&
        (
            (
                linhaProjetoVidaSonho.valor === linhaProjetoVidaSonho.valorAcumulacaoJaRealizado
            ) ||
            (
                linhaProjetoVidaSonho.idadeRealizacao &&
                linhaProjetoVidaSonho.idadeRealizacao >=
                    idadeFamiliarDataSimulacao(values, linhaProjetoVidaSonho.familiar)
            ) ||
            (
                linhaProjetoVidaSonho.anoRealizacao && 
                linhaProjetoVidaSonho.anoRealizacao >= anoAtual
            )
        ) &&
        (
            !linhaProjetoVidaSonho.valorAcumulacaoJaRealizado ||
            linhaProjetoVidaSonho.valor === linhaProjetoVidaSonho.valorAcumulacaoJaRealizado ||
            (
                linhaProjetoVidaSonho.valor !== linhaProjetoVidaSonho.valorAcumulacaoJaRealizado &&
                linhaProjetoVidaSonho.tracarMeta
            ) 
        ) &&
        (
            !linhaProjetoVidaSonho.tracarMeta ||
            (
                linhaProjetoVidaSonho.tracarMeta &&
                linhaProjetoVidaSonho.mesAnoInicioAcumulacao &&
                linhaProjetoVidaSonho.mesAnoInicioAcumulacao.length === TAMMAX.mesAno &&
                linhaProjetoVidaSonho.mesAnoFimAcumulacao &&
                linhaProjetoVidaSonho.mesAnoFimAcumulacao.length === TAMMAX.mesAno &&
                linhaProjetoVidaSonho.mesRealizacao
            )
        ) &&
        (
            !linhaProjetoVidaSonho.recorrencia ||
            linhaProjetoVidaSonho.anoLimiteRecorrencia
        ) &&
        (
            !linhaProjetoVidaSonho.controlarAportes ||
            linhaProjetoVidaSonho.diaAporte
        )
}

export const dadosInvalidosLinhaProjetoVidaSonhoTracarMeta = ( values, linhaProjetoVidaSonho ) => {

    let dadosInvalidos = false

    if (dadosCompletosLinhaProjetoVidaSonho(values, linhaProjetoVidaSonho)) {

        const mesInicial = mes(values.dataSimulacaoEstudo)

        const anoInicial = ano(values.dataSimulacaoEstudo)

        const anoFinal = anoFinalProjetoVidaSonho(values, anoInicial, linhaProjetoVidaSonho.anoRealizacao,
            linhaProjetoVidaSonho.idadeRealizacao, linhaProjetoVidaSonho.familiar)

        const { restoQtdeAportes, mesesAcumulacao, mesesPosAcumulacao } =
            calculaTracarMeta(values, linhaProjetoVidaSonho, mesInicial, anoInicial, anoFinal)

        if (!(restoQtdeAportes === 0 && mesesAcumulacao > 0 && mesesPosAcumulacao >= 0)) {

            dadosInvalidos = true
        }
    }

    return dadosInvalidos
}

export const calculaTotaisProjetoVidaSonho = ( values ) => {

    const indexStepsValorFluxoCaixa = HARDCODE.indexFluxoCaixaProjetoVidaSonho

    values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 0

    values.totalProjetoVidaSonho = []

    let valorTotal = 0

    familiaList(values)?.forEach( (linhaFamiliaList, index) => {
        valorTotal = 0

        values.projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, index) => {
            if (linhaProjetoVidaSonho.familiar === linhaFamiliaList.id &&
                dadosCompletosLinhaProjetoVidaSonho(values, linhaProjetoVidaSonho)
                ) {

                valorTotal = valorTotal + round(linhaProjetoVidaSonho.valor, 2)
            }
        })

        values.totalProjetoVidaSonho.push(
            {
                id: linhaFamiliaList.id,
                descricao: linhaFamiliaList.descricao,
                valor: valorTotal,
            }
        )

        values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] = 
            values.stepsValorFluxoCaixa[indexStepsValorFluxoCaixa] + valorTotal
    })
}



export const dadosCompletosProjetoVidaSonho = ( values ) => {

    let dadosIncompletos = ''
    let familiares = []

    familiaList(values)?.forEach( (linhaFamiliaList, index) => {
        
        values.projetoVidaSonho?.forEach( (linhaProjetoVidaSonho, index) => {

            if (
                    linhaProjetoVidaSonho.familiar === linhaFamiliaList.id &&
                    linhaProjetoVidaSonho.tipoProjetoVidaSonho &&
                    !dadosCompletosLinhaProjetoVidaSonho(values, linhaProjetoVidaSonho)
            ) {

                dadosIncompletos = dadosIcompletosAlertaCamposObrigatorios(familiares, linhaFamiliaList,
                    dadosIncompletos, pesquisaDescricaoList(tipoProjetoVidaSonhoList,
                        linhaProjetoVidaSonho.tipoProjetoVidaSonho) +
                        (linhaProjetoVidaSonho.descricao ? LABEL.traco + linhaProjetoVidaSonho.descricao : ''))
            }
        })
    })

    return dadosIncompletos
}