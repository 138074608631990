// alterado - revisado

import HARDCODE from '../../business-const/HardCode/HardCode'

const tipoPrazoRecebtoRendaCoberturaSeguroVidaList = ( informaTipoPrazoRecebtoRenda ) => {
    let tipoPrazoRecebtoRendaCoberturaSeguroVidaList = []

    tipoPrazoRecebtoRendaCoberturaSeguroVidaList.push (
        {
            id: 10,
            descricao: '5 anos',
            ordem: 10,
            anosRecebtoRenda: 5,
        },
        {
            id: 20,
            descricao: '10 anos',
            ordem: 20,
            anosRecebtoRenda: 10,
        },
        {
            id: 30,
            descricao: '15 anos',
            ordem: 30,
            anosRecebtoRenda: 15,
        },
        {
            id: 40,
            descricao: '20 anos',
            ordem: 40,
            anosRecebtoRenda: 20,
        },
        {
            id: 50,
            descricao: '25 anos',
            ordem: 50,
            anosRecebtoRenda: 25,
        },
        {
            id: 60,
            descricao: '30 anos',
            ordem: 60,
            anosRecebtoRenda: 30,
        },
    )
        
    if (informaTipoPrazoRecebtoRenda === "V") {
        tipoPrazoRecebtoRendaCoberturaSeguroVidaList.push (
            {
                id: 900,
                descricao: 'Vitalício',
                ordem: 900,
                anosRecebtoRenda: HARDCODE.anosRecebtoRendaVitalicio,
        },
        )
    }

    return tipoPrazoRecebtoRendaCoberturaSeguroVidaList
}

export default tipoPrazoRecebtoRendaCoberturaSeguroVidaList