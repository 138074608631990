// alterado - revisado

import fintracksSvg from './fin-tracks.svg'

import desenvolvidoPorFintracksSvg from './desenvolvidoPorFin-tracks.svg'

import taxasTopbarSvg from './taxasTopbar.svg'

import boletimFocusSvg from './boletimFocus.svg'

import condicaoComercial20Mai22Mai23Svg from './condicaoComercial20Mai22Mai23.svg'

import condicaoComercialMai23Jun23EmDianteSvg from './condicaoComercialMai23Jun23EmDiante.svg'

import condicaoComercialPrecoPromocional150Svg from './condicaoComercialPrecoPromocional150.svg'

import condicaoComercialPrecoPromocional165Svg from './condicaoComercialPrecoPromocional165.svg'

import condicaoComercialPrecoPromocional210Svg from './condicaoComercialPrecoPromocional210.svg'

import condicaoComercialPrecoPromocional180Svg from './condicaoComercialPrecoPromocional180.svg'

import condicaoComercialPreco300Svg from './condicaoComercialPreco300.svg'

import importarXLSXHelpSvg from './importarXLSXHelp.svg'

import homemSvg from './homem.svg'

import mulherSvg from './mulher.svg'



export const SvgImageFintracks = ( props ) => {
    return (
        <img
            className='w-full'
            src={fintracksSvg}
            alt={''}
            {...props}
        />
    )
}


export const SvgImageDesenvolvidoPorFintracks = ( props ) => {
    return (
        <img
            className='w-full'
            height="25"
            width="150" 
            src={desenvolvidoPorFintracksSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageTaxasTopbar = ( props ) => {
    return (
        <img
            className='w-full'
            width="40px"
            height="40px"
            src={taxasTopbarSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageBoletimFocus = ( props ) => {
    return (
        <img
            className='w-full'
            width="60px"
            height="70px"
            src={boletimFocusSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercial20Mai22Mai23 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercial20Mai22Mai23Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialMai23Jun23EmDiante = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialMai23Jun23EmDianteSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialPrecoPromocional150 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialPrecoPromocional150Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialPrecoPromocional165 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialPrecoPromocional165Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialPrecoPromocional210 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialPrecoPromocional210Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialPrecoPromocional180 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialPrecoPromocional180Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageCondicaoComercialPreco300 = ( props ) => {
    return (
        <img
            className='w-full'
            src={condicaoComercialPreco300Svg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageImportarXLSXHelp = ( props ) => {
    return (
        <img
            className='w-full'
            src={importarXLSXHelpSvg}
            alt={''}
            {...props}
        />
    )
}



export const SvgImageHomem = ( props ) => {
    return (
        <img
            className='w-full'
            width="100px"
            height="110px"
            src={homemSvg}
            alt={''}
            {...props}
        />
    )
}

export const SvgImageMulher = ( props ) => {
    return (
        <img
            className='w-full'
            width="100px"
            height="110px"
            src={mulherSvg}
            alt={''}
            {...props}
        />
    )
}