import LABEL from '../../../business-const/Label/Label'

import MESSAGE from '../../../business-const/Message/Message'

import {
    pesquisaList,
} from '../../../business-rules/List/ListService'

import {
    dadosCompletosCliente,
    dadosCompletosConjuge,
} from '../cliente-form/ClienteService'

import {
    dadosCompletosFamiliares,
} from '../cadastro-form/CadastroServiceFamiliares'

import {
    dadosCompletosReceitaMensal,
} from '../garantias-form/GarantiasServiceReceitaMensal'

import {
    dadosCompletosDiaDia,
} from '../despesas-form/DespesasServiceDiaDia'

import {
    dadosCompletosEducacao,
} from '../despesas-form/DespesasServiceEducacao'

import {
    dadosCompletosFinanciamento,
} from '../despesas-form/DespesasServiceFinanciamento'

import {
    dadosCompletosProjetoVidaSonho,
} from '../projetoVidaSonho-form/ProjetoVidaSonhoServiceProjetoVidaSonho'

import {
    dadosCompletosPatrimonioImobilizado,
} from '../garantias-form/GarantiasServicePatrimonioImobilizado'

import {
    dadosCompletosRecursosFinanceiros,
} from '../garantias-form/GarantiasServiceRecursosFinanceiros'

import {
    dadosCompletosSeguroVidaApolice,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaApolice'

import {
    dadosCompletosSeguroVidaCobertura,
} from '../seguroVida-form/SeguroVidaServiceSeguroVidaCobertura'

import {
    dadosCompletosSeguroVidaCotacao,
} from '../seguroVidaCotacao-form/SeguroVidaCotacaoServiceCotacao'

import {
    dadosCompletosSeguroVidaCotacaoCobertura,
} from '../seguroVidaCotacao-form/SeguroVidaCotacaoServiceCobertura'

import {
    dadosCompletosSeguroVidaProposta,
} from '../seguroVidaProposta-form/SeguroVidaPropostaServiceProposta'

import {
    dadosCompletosSeguroVidaPropostaCobertura,
} from '../seguroVidaProposta-form/SeguroVidaPropostaServiceCobertura'

import {
    calculaItcmdEnderecoResidencial,
    possuiRecursosFinanceirosInventario,
} from './EstudoServiceSucessaoVitalicia'



export const calculaAlertaCamposObrigatorios = ( values/*, estudo*/ ) => {

    let camposObrigatorios

    values.alertaCamposObrigatorios = []

    if (!dadosCompletosCliente(values)) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosCadastroCliente)
    }

    if (values.possuiConjuge && !dadosCompletosConjuge(values, true)) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosCadastroConjuge)
    }

    camposObrigatorios = dadosCompletosFamiliares(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosCadastroFamiliares +
            ": <br/><br/>" + camposObrigatorios)
    }
        
    camposObrigatorios = dadosCompletosReceitaMensal(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasReceitaMensal +
            ": <br/><br/>" + camposObrigatorios)
    }
      
    camposObrigatorios = dadosCompletosDiaDia(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosDespesasDiaDia +
            ": <br/><br/>" + camposObrigatorios)
    }
    
    camposObrigatorios = dadosCompletosEducacao(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosDespesasEducacao +
            ": <br/><br/>" + camposObrigatorios)
    }
    
    camposObrigatorios = dadosCompletosFinanciamento(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosDespesasFinanciamento +
            ": <br/><br/>" + camposObrigatorios)
    }
    
    camposObrigatorios = dadosCompletosProjetoVidaSonho(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosDespesasProjetoVidaSonho +
            ": <br/><br/>" + camposObrigatorios)
    }
  
    camposObrigatorios = dadosCompletosPatrimonioImobilizado(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasPatrimonioImobilizado +
            ": <br/><br/>" + camposObrigatorios)
    }
    
    camposObrigatorios = dadosCompletosRecursosFinanceiros(values)
    if (camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasRecursosFinanceiros +
            ": <br/><br/>" + camposObrigatorios)
    }
           
    camposObrigatorios = dadosCompletosSeguroVidaCotacaoCobertura(values)
    if (!dadosCompletosSeguroVidaCotacao(values) || camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasSeguroVidaCotacao +
            ": <br/><br/>" + camposObrigatorios)
    }
    
    camposObrigatorios = dadosCompletosSeguroVidaPropostaCobertura(values)
    if (!dadosCompletosSeguroVidaProposta(values) || camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasSeguroVidaProposta +
            ": <br/><br/>" + camposObrigatorios)
    }
 
    camposObrigatorios = dadosCompletosSeguroVidaCobertura(values)
    if (!dadosCompletosSeguroVidaApolice(values) || camposObrigatorios) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosGarantiasSeguroVida +
            ": <br/><br/>" + camposObrigatorios)
    }

    if (!calculaItcmdEnderecoResidencial(values) && possuiRecursosFinanceirosInventario(values/*, estudo*/)) {
        values.alertaCamposObrigatorios.push(MESSAGE(values).alertaCamposObritagoriosUfEnderecoResidencialItcmd)
    }
}

export const dadosIcompletosAlertaCamposObrigatorios = (familiares, linhaTitularList, dadosIncompletos,
    descricaoTipo) => {

    if (!pesquisaList(familiares, linhaTitularList.id).id) {

        familiares.push({
            id: linhaTitularList.id
        })

        if (dadosIncompletos) {
            dadosIncompletos += '<br/><br/>'
        }

        dadosIncompletos += LABEL.espacoAlertaCamposObrigatorios + "<b>" + linhaTitularList.descricao +
            "</b> => "

        dadosIncompletos += descricaoTipo

    } else {
        
        dadosIncompletos += ", " + descricaoTipo
    }

    return dadosIncompletos
}