// alterado - revisado

import {
    primNome
} from '../../business-components/String/String'

const nomeCurto = ( nomeCurto, nome ) => {

    if (nomeCurto) {
        return nomeCurto
    } else {
        return primNome(nome)
    }
}

export const nomeCurtoCliente = ( values ) => {

    return nomeCurto (values.nomeCurtoCliente, values.nomeCliente)
}

export const nomeCurtoConjuge = ( values ) => {

    return nomeCurto (values.nomeCurtoConjuge, values.nomeConjuge)
}

export const nomeCurtoFamiliar = ( item ) => {

    return nomeCurto (item.nomeCurtoFamiliar, item.nomeFamiliar)
}