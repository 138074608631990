// alterado - revisado

export const DASHBOARD = '_dashboard'

export default class LocalStorageService {

    static setarItem(chave, valor) {

        localStorage.setItem(chave, JSON.stringify(valor))
    }

    static obterItem(chave) {

        let item = localStorage.getItem(chave)

        if (item && item !== "undefined") {
            item = JSON.parse(item)
        }

        return item
    }

    static removerItem(chave){

        localStorage.removeItem(chave)
    }
    
    static setarDashboardSelecionado(dashboard, title, funil, etapa) {

        LocalStorageService.setarItem(DASHBOARD,
            {
                dashboard: dashboard,
                title: title.replace("<br/>", " ").replace("<br/>", ""),
                funil: funil,
                etapa: etapa
            }
        )
    }

    static obterDashboardSelecionado() {
        
        return LocalStorageService.obterItem(DASHBOARD)
    }
}